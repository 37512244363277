import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import _ from 'lodash';
import { format, isEqual } from 'date-fns';
import Url from 'url-parse';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import classnames from 'classnames';

import DividerContent from '../../atoms/DividerContent';
import DividerDottedV from '../../atoms/DividerDottedV';
import FlexBox from '../../atoms/FlexBox';
import IconDownload from '../../atoms/IconDownload';
import IconStar from '../../atoms/IconStar';
import ProgressStatus from '../../atoms/ProgressStatus';
import BarEditProgressData from '../../molecules/BarEditProgressData';
import Tab from '../../molecules/Tab';
import Search from '../../molecules/Search';
import GraphWithToggleBtn from '../../organisms/GraphWithToggleBtn';
import ModalAddItem from '../../organisms/ModalAddItem';
import ModalProgressDisplayEdit from '../../organisms/ModalProgressDisplayEdit';
import ModalModifyFav from '../../organisms/ModalModifyFav';
import ModalOperationMemoEdit from '../../organisms/ModalOperationMemoEdit';
import ModalFilterThreshold from '../../organisms/ModalFilterThreshold';
import PullDownCommon from '../../organisms/PullDownCommon';
import PullDownProgressAvgData from '../../organisms/PullDownProgressAvgData';
import PullDownProgressDisplay from '../../organisms/PullDownProgressDisplay';
import PullDownProgressSplit from '../../organisms/PullDownProgressSplit';
import ReportTable from '../../organisms/ReportTable';
import StatusFilter from '../../organisms/StatusFilter';
import User from '../../../utils/user';
import ModalErrorMessage from '../../organisms/ModalErrorMessage';

import compValue from '../../_util/compValue';
import getSumObj from '../../_util/getSumObj';

import REPORT_SUM_DESP from '../../_const/REPORT_SUM_DESP';
import REPORT_SUM_ITEMS from '../../_const/REPORT_SUM_ITEMS';
import DISPITEM_TYPE from '../../_const/DISPITEM_TYPE';

const dateFormat = 'YYYY-MM-DD';
const ERRNUM = 4;

const StyledTableMenu = styled(FlexBox)`
  height: 32px;
  margin-bottom: 32px;
`

const StyledFilterSection = styled.div`
  display: none;
  height: 32px;
  &.is-shown {
    display: flex;
    align-items: center;
  }
`

const backendApi = process.env.REACT_APP_BACKEND_URI;

const FILTER_INIT = {
  dataExist: 1,    // データのあるアカウントのみ表示するか 0:すべて、1:アクティブのみ
  device:    null, // 対象デバイス 0:全デバイス、1:PC、4:SP、8:TB、256:MO
  aw:        null, // 対象媒体adwords 検索対象とするなら1、対象としないなら0もしくは空
  ss:        null, // 対象媒体Y!SS 検索対象とするなら1、対象としないなら0もしくは空
  ydn:       null, // 対象媒体YDN 検索対象とするなら1、対象としないなら0もしくは空
  cr:        null, // 対象媒体Criteo 検索対象とするなら1、対象としないなら0もしくは空
  tw:        null, // 対象媒体twitter 検索対象とするなら1、対象としないなら0もしくは空
  fb:        null, // 対象媒体Facebook 検索対象とするなら1、対象としないなら0もしくは空
  zeus:      null, // 対象媒体Xリスティング 検索対象とするなら1、対象としないなら0もしくは空
  line:      null, // 対象媒体LINE 検索対象とするなら1、対象としないなら0もしくは空
  dbm:       null, // 対象媒体DBM 検索対象とするなら1、対象としないなら0もしくは空
  indeed:    null, // 対象媒体INDEED 検索対象とするなら1、対象としないなら0もしくは空
  sn:        null, // 対象媒体SmartNews 検索対象とするなら1、対象としないなら0もしくは空
  rtbh:      null, // 対象媒体RTB House 検索対象とするなら1、対象としないなら0もしくは空
  aa:        null, // 対象媒体Amazon 検索対象とするなら1、対象としないなら0もしくは空
  asa:       null, // 対象媒体Apple 検索対象とするなら1、対象としないなら0もしくは空
  bing:      null, // 対象媒体Microsoft 検索対象とするなら1、対象としないなら0もしくは空
  la:        null, // 対象媒体Logicad 検索対象とするなら1、対象としないなら0もしくは空
  una:       null, // 対象媒体Universe ads 検索対象とするなら1、対象としないなら0もしくは空
  xmedia:    null, // 対象媒体xmedia 検索対象とするなら1、対象としないなら0もしくは空
  ex:        2,    // 外部データ連携 0:なし,1:外部データ（外部CV）,2:すべて
  division:  0,    // 分割設定 データ分割を行う場合に指定する。1:日別、2:週別、3:月別、4:曜日別、5:デバイス別、6:コンバージョン別
  label:     null,
};

class Base extends React.Component {
  constructor(props) {
    super(props);

    const url = new Url(window.location.href, true);
    const cookieResultListNo = Cookies.getJSON('rakuda-result-list-no');

    this.state = {
      accounts: [],
      clients: [],
      dispItems: [], // 表示項目の候補
      editingDispItemId: 1, // 編集中の表示項目設定のID
      dataTableHeader: [], // 表示項目
      dataTableOriginal: [], // レポートデータ（Table用）
      dataTableCompare: [], // 比較レポートデータ（Table用）
      dataTable: [], // レポートデータ（Table用）を表示用に間引きしたもの
      dataTableFooter: null, // 比較元データの合計行（Table用）
      dataGraph: [], // 比較元データ（グラフ用）
      dataGraphCompare: [], // 比較データ（グラフ用）
      division: (url.query.division !== undefined) ? parseInt(url.query.division) : 0, // Tableの分割ID
      graphDivision: 1, // グラフの分割ID 1:日別、2:週別、3:月別
      graphCandidate: [],
      selectedLabel: undefined,
      selectedCustomReport: undefined,
      checked: [],
      addItems: [],
      isFilteredTab: url.query.filter !== undefined,
      isInitFilterItems: true,
      isShownTab: (url.query.label === undefined && url.query.customReport === undefined),
      isOpenedDisplayModal: false,
      isOpenedFavModal: false,
      isOpenedOpeMemoEditModal: false,
      isOpenedThresholdModal: false,
      isOpenedAddItemModal: false,
      isOpenedErrorModal: false,
      errorText: 'エラーが発生しました',
      thresholdModalId: null,
      thresholdModalHeading: '',
      headerThresholdTmp: [],
      headerThreshold: [],
      labelData: [],
      labelDataFiltered: [],
      requestParam: {
        // 必須項目
        // ---
        target: props.target, // レポートデータの対象 1:クライアント、2:アカウント、3:キャンペーン、4:広告グループ、5:キーワード
        clientId:  ((props.target === 1 || props.target === 2) && url.query.filter !== undefined) ? url.query.filter : null, // クライアントID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        accountId:  (props.target === 3 && url.query.filter !== undefined) ? url.query.filter : null, // アカウントID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        campaignId: (props.target === 4 && url.query.filter !== undefined) ? url.query.filter : null, // キャンペーンID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        adgroupId:  (props.target === 5 && url.query.filter !== undefined) ? url.query.filter : null, // 広告グループID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        keywordId:  null, // キーワードID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空

        // ラベルorカスタムレポートを適用する
        label: null, // ラベル設定。登録したラベルの中で、表示したいラベルのIDを指定する。1など。指定しない場合は空
        customReport: null, // カスタムレポート設定。登録したカスタムレポートの中で、表示したいカスタムレポートのIDを指定する。

        // 検索窓で設定する項目
        // ---
        searchText: null, // 検索対象としたいクライアント名やアカウント名などを入力する（対象レポートによって検索対象の名称は変化する）
        matchType: 0,

        // フィルタで設定する項目
        // ---
        sectionId: null, // 部署ID 1など。指定しない場合は空
        groupId:   null, // グループID 1など。指定しない場合は空
        dataExist: 1,    // データのあるアカウントのみ表示するか 0:すべて、1:アクティブのみ
        aw:        1,    // 対象媒体adwords 検索対象とするなら1、対象としないなら0もしくは空
        ss:        1,    // 対象媒体Y!SS 検索対象とするなら1、対象としないなら0もしくは空
        ydn:       1,    // 対象媒体YDN 検索対象とするなら1、対象としないなら0もしくは空
        cr:        1,    // 対象媒体Criteo 検索対象とするなら1、対象としないなら0もしくは空
        tw:        1,    // 対象媒体twitter 検索対象とするなら1、対象としないなら0もしくは空
        fb:        1,    // 対象媒体Facebook 検索対象とするなら1、対象としないなら0もしくは空
        zeus:      1,    // 対象媒体Xリスティング 検索対象とするなら1、対象としないなら0もしくは空
        line:      1,    // 対象媒体LINE 検索対象とするなら1、対象としないなら0もしくは空
        dbm:       1,    // 対象媒体DBM 検索対象とするなら1、対象としないなら0もしくは空
        indeed:    1,    // 対象媒体Indeed 検索対象とするなら1、対象としないなら0もしくは空
        bing:      1,    // 対象媒体Microsoft 検索対象とするなら1、対象としないなら0もしくは空
        xmedia:    1,    // xmedia 検索対象とするなら1、対象としないなら0もしくは空
        device:    null, // 対象デバイス 0:全デバイス、1:PC、4:SP、8:TB、256:MO
        alNot:     null, // アラート非表示 非表示とするなら1、表示するなら0もしくは空
        al1:       null, // 媒体予算切れアラート 表示とするなら1、非表示とするなら0もしくは空
        al2:       null, // ヨミ予算切れアラート 表示とするなら1、非表示とするなら0もしくは空
        al3:       null, // 媒体予算オーバーペース 表示とするなら1、非表示とするなら0もしくは空
        al4:       null, // ヨミ予算切れアラート 表示とするなら1、非表示とするなら0もしくは空
        ex:        2, // 外部データ連携 0:なし,1:外部データ（外部CV）,2:すべて
        alHis: (url.query.alertId !== undefined) ? url.query.alertId : null,

        // 分割で設定する項目
        // ---
        division: 0, // 分割設定 データ分割を行う場合に指定する。1:日別、2:週別、3:月別、4:曜日別、5:デバイス別、6:コンバージョン別
        multiCv:  null, // CV分割。複数CV、外部CVデータを表示する。※複数CVについてはtarget=2(アカウントレポートのみ)もしくはtarget=3(キャンペーンレポートのみ) CV分割を表示するなら1、表示しないなら0もしくは空

        // 着地予測の計算
        // ---
        avgData: null, // 平均データ 1:過去1日、3:過去3日、7:過去7日、0もしくは空なら指定なし

        // 期間設定関連
        // ---
        sDate:     format(props.dateRange.startDate, dateFormat),        // レポートデータ取得開始日 2018-01-01
        eDate:     format(props.dateRange.endDate, dateFormat),          // レポートデータ取得終了日 2018-01-01
        sDateC:    format(props.dateRangeCompare.startDate, dateFormat), // レポートデータ取得開始日（比較用）※比較する場合は必須 2018-01-01
        eDateC:    format(props.dateRangeCompare.endDate, dateFormat),   // レポートデータ取得終了日（比較用）※比較する場合は必須 2018-01-01
        compare:   (props.isEnabledCompare) ? 1 : null, // 比較設定 過去データを比較するなら1、しないなら0もしくは空
        compS:     null, // 比較設定。比較の際に比較結果を数値で表示するか、パーセンテージで表示するか 0:数値、1:パーセンテージ。未指定の場合は登録されているデフォルト値を適用する
        timeSpan:  null, // 指定期間 1:機能、2:過去7日間、3:過去14日間、4:今月、5:過去30日間、6:6先月、7:全期間 ※指定なしならsDate、eDateの値をそのまま適用する
        timeSpanC: null, // 指定期間（比較用） 1:前の期間、2:前年同期 ※指定なしならsDateC、eDateCの値をそのまま適用する

        // ソート関連
        // ---
        sortColNo: null, // ソートする項目 ソートするなら設定する。項目の番号はレポート項目詳細 >> 各レポート項目の詳細についてはこちらの「項目No」を参照
        orderby:   null, // ソート順 1:昇順、2:降順 ※指定なしなら昇順

        // ページャ関連
        // ---
        row:  null, // レポートデータを表示する行数。指定なしなら50
        page: null, // レポートデータを表示するページ数。指定なしなら1	rowで50件ずつ表示するとしたら、2を指定すれば51～100件目のデータを表示する

        // ページロード時にただTableを生成するためにレポートデータを取得するだけのときには使わないもの
        // ---
        resultListNo: (cookieResultListNo) ? cookieResultListNo : null, // 表示設定指定。登録されている表示設定の中で、表示したい設定の番号を指定する。1など。空の場合は自身で設定したデフォルト番号を使用する
        dl:           null, // csvファイルダウンロード csvファイルダウンロードなら1を設定する
        d:            null, // ダッシュボード画面からのリクエストかどうか ダッシュボード画面からのリクエストの場合1を設定する
        view:         null, // MyView表示設定。登録したMy Viewの中で、表示したいMy ViewのIDを指定する。1など。指定しない場合は空

        // 用途不明のもの
        // ---
        al:              null, // 自身で設定したアラート表示設定。登録されているアラートの中で、表示したいアラートのIDを指定する。 1など。指定しない場合は空
        alertOnly:       null, // デフォルトアラートのあるデータのみ表示 デフォルトアラートのあるデータのみ表示させるなら1
        addSearchResult: 1, // 自身で追加したオリジナル項目 表示するなら1、表示しないなら空
      },
      favoriteData: [],
      favParams: {
        id: null,
        name: '',
        url: '',
        shareSetting: 0,
        userIds: [],
      },
      opeMemoEditParam: {
        id: null,
        memo: '',
        note: '',
        clientId: '',
        accountId: '',
        mediaType: '',
        shareSetting: 0,
        userIds: [],
      },
      addItemParam: null,
      tabItems: [ // タブの中身
        {
          label: "クライアント",
          link: "/progress/1/",
        },
        {
          label: "アカウント",
          link: "/progress/2/",
        },
        {
          label: "キャンペーン",
          link: "/progress/3/",
        },
        {
          label: "広告グループ",
          link: "/progress/4/",
        },
        {
          label: "キーワード/ターゲティング",
          link: "/progress/5/",
        },
      ],
    };
    props.switchGlobalCatId(1);
    props.toggleDatePicker(true);
  }
  componentDidMount = async () => {
    await this.init();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    // LINKタグにて同じベージを更新した際に再取得を行う
    if (prevProps.location !== this.props.location) {
      await this.init();
    }
    if (
      // 日付が切り替わったときにレポートデータを更新する。
      !isEqual(prevProps.dateRange.startDate, this.props.dateRange.startDate)
      || !isEqual(prevProps.dateRange.endDate, this.props.dateRange.endDate)
      || !isEqual(prevProps.dateRangeCompare.startDate, this.props.dateRangeCompare.startDate)
      || !isEqual(prevProps.dateRangeCompare.endDate, this.props.dateRangeCompare.endDate)
      // 比較ON/OFFが切り替わったときにレポートデータを更新する。
      || prevProps.isEnabledCompare !== this.props.isEnabledCompare
    ) {
      // プログレス表示
      this.props.startProgressing();

      await this.setState({
        division: (this.props.isEnabledCompare) ? 0 : this.state.division,
        requestParam: Object.assign({}, this.state.requestParam, {
          compare: (this.props.isEnabledCompare) ? 1 : null,
          division: (this.props.isEnabledCompare) ? 0 : this.state.division,
          sDate: format(this.props.dateRange.startDate, dateFormat),
          eDate: format(this.props.dateRange.endDate, dateFormat),
          sDateC: format(this.props.dateRangeCompare.startDate, dateFormat),
          eDateC: format(this.props.dateRangeCompare.endDate, dateFormat),
        }),
      });
      await this.getReportDataAll();
      await this.getFilterThreshold();

      if (!this.state.isOpenedErrorModal) {
        // フィルタに含まれる表示設定項目をテーブル反映
        await this.setFilterTableThreshold();
      }

      //　プログレス非表示
      this.props.endProgressing();
    }
  }

  // 初期処理
  init = async () => {
    // プログレス表示
    this.props.startProgressing();

    // まず保存されている表示項目を取得
    await this.getDispSettingItems();

    // フィルタに値を設定する
    // URLのクエリパラメータを取得し、パラメータの指定がある場合はその値をフィルタへ、
    // ない場合は以前のフィルタ条件をフィルタへ反映する。
    const searchParam = new Url(window.location.search, true);
    const param = this.state.requestParam;
    const keys = _.keys(searchParam.query);
    const isOnlyIdFilter = keys.length === 1 && keys.indexOf('filter') === 0;
    if (_.isEmpty(searchParam.query) || isOnlyIdFilter) {
      // クエリパラメータの指定がないため、
      // localStorageに保存されたフィルタ条件を反映
      let filterData = {};
      filterData = localStorage.getItem('filter');
      if (filterData) {
        let storage = JSON.parse(filterData);
        if (keys.indexOf('filter') !== -1) {
          storage.filter = searchParam.query.filter;
        }
        this.setState({
          requestParam: {
            ...param,
            label:     (typeof storage.label === 'undefined' || storage.label === null) ? null : storage.label,  // ラベル設定
            dataExist: (typeof storage.dataExist === 'undefined' || storage.dataExist === null) ? null : storage.dataExist, // データのあるアカウントのみ表示するか
            aw:        (typeof storage.aw === 'undefined' || storage.aw === null) ? null : storage.aw,    // 対象媒体adwords
            ss:        (typeof storage.ss === 'undefined' || storage.ss === null) ? null : storage.ss,    // 対象媒体Y!SS
            ydn:       (typeof storage.ydn === 'undefined' || storage.ydn === null) ? null : storage.ydn,    // 対象媒体YDN
            cr:        (typeof storage.cr === 'undefined' || storage.cr === null) ? null : storage.cr,    // 対象媒体Criteo
            tw:        (typeof storage.tw === 'undefined' || storage.tw === null) ? null : storage.tw,    // 対象媒体twitter
            fb:        (typeof storage.fb === 'undefined' || storage.fb === null) ? null : storage.fb,    // 対象媒体Facebook
            zeus:      (typeof storage.zeus === 'undefined' || storage.zeus === null) ? null : storage.zeus,    // 対象媒体Xリスティング
            line:      (typeof storage.line === 'undefined' || storage.line === null) ? null : storage.line,    // 対象媒体LINE
            dbm:       (typeof storage.dbm === 'undefined' || storage.dbm === null) ? null : storage.dbm,    // 対象媒体DBM
            indeed:    (typeof storage.indeed === 'undefined' || storage.indeed === null) ? null : storage.indeed,    // 対象媒体Indeed
            sn:        (typeof storage.sn === 'undefined' || storage.sn === null) ? null : storage.sn, 
            rtbh:      (typeof storage.rtbh === 'undefined' || storage.rtbh === null) ? null : storage.rtbh, 
            aa:        (typeof storage.aa === 'undefined' || storage.aa === null) ? null : storage.aa, 
            asa:       (typeof storage.asa === 'undefined' || storage.asa === null) ? null : storage.asa, 
            bing:      (typeof storage.bing === 'undefined' || storage.bing === null) ? null : storage.bing, // 対象媒体Microsoft
            la:        (typeof storage.la === 'undefined' || storage.la === null) ? null : storage.la, 
            una:       (typeof storage.una === 'undefined' || storage.una === null) ? null : storage.una, 
            xmedia:    (typeof storage.xmedia === 'undefined' || storage.xmedia === null) ? null : storage.xmedia,    // 対象媒体xmedia
            device:    (typeof storage.device === 'undefined' || storage.device === null) ? null : storage.device,  // 対象デバイス
            ex:        (typeof storage.ex === 'undefined' || storage.ex === null) ? null : storage.ex,      // 外部データ連携
            alNot:     (typeof storage.alNot === 'undefined' || storage.alNot === null) ? null : storage.alNot,
            al1:       (typeof storage.al1 === 'undefined' || storage.al1 === null) ? null : storage.al1,
            al2:       (typeof storage.al2 === 'undefined' || storage.al2 === null) ? null : storage.al2,
            al3:       (typeof storage.al3 === 'undefined' || storage.al3 === null) ? null : storage.al3,
            al4:       (typeof storage.al4 === 'undefined' || storage.al4 === null) ? null : storage.al4,
            division:  (typeof storage.division === 'undefined' || storage.division === null) ? null : storage.division,
          }
        });
        // URL書き換え
        const queryBase = _.pickBy(storage, o => o !== null);
        let queries = await queryString.stringify(queryBase, {
          skipNull: true
        });
        const urlString = `${window.location.pathname}?${queries}`
        await window.history.replaceState(null, '', urlString);
      }
    } else {
      // クエリパラメータの指定があるため、
      // そのままフィルタに反映する
      const queryParam = searchParam.query;
      const keys = _.keys(queryParam);
      keys.forEach((key) => {
        if (queryParam.hasOwnProperty(key)) {
          queryParam[key] = isFinite(queryParam[key]) ? Number(queryParam[key]) : queryParam[key];
        }
      });
      let queryParamFormat = Object.assign(_.cloneDeep(FILTER_INIT), queryParam);
      if (_.get(queryParamFormat, 'filter', null)) {
        delete queryParamFormat.filter;
      }
      if (_.get(queryParamFormat, 'label', null)) {
        delete queryParamFormat.label;
      }
      if (_.get(queryParamFormat, 'customReport', null)) {
        delete queryParamFormat.customReport;
      }
      await this.setState({
        requestParam: {
          ...param,
          ...queryParamFormat,
        }
      });
      // localStorage保存
      await localStorage.setItem('filter', JSON.stringify(queryParamFormat));
    }

    // レポートデータを取得
    await this.getReportDataAll();

    if (!this.state.isOpenedErrorModal) {
      // ラベルデータ取得
      await this.getLabelData();

      // 表示項目をフィルタに反映
      await this.getFilterThreshold();

      // 保存されていた表示項目の閾値をフィルタに反映
      await this.setFilterThreshold();

      // フィルタをテーブルに適用する
      await this.setFilterTableThreshold();
    }

    //　プログレス非表示
    this.props.endProgressing();
  }
  getClients = () => {
    return axios.get(backendApi + 'client', {
      params: {
        ...User.apiParams()
      }
    })
    .then((response) => {
      this.setState({
        clients: response.data
      });
    })
    .catch(() => {
    });
  }
  getAccounts = () => {
    return axios.get(backendApi + 'account', {
      params: {
        ...User.apiParams()
      }
    })
    .then((response) => {
      this.setState({
        accounts: response.data
      });
    })
    .catch(() => {
    });
  }
  getDispSettingItems = async () => {
    // propsからデータを設定するか、ない場合データを取得する
    if (_.isEmpty(this.props.clients) || _.isEmpty(this.props.accouts)) {
      await this.getClients();
      await this.getAccounts();
    } else {
      await this.setState({
        clients: this.props.clients,
        accounts: this.props.accounts
      });
    }

    // APIをリクエストして表示項目のデータを取得
    return axios.get(backendApi + 'dispItem', {
      params: {
        ...User.apiParams(),
        func: 2,
        target: this.state.requestParam.target,
        resultListNo: this.state.editingDispItemId,
        division: this.state.division,
        compare: (this.props.isEnabledCompare) ? 1 : null,
      }
    })
    .then((response) => {
      this.setState({
        dispItems: response.data,
      });
    })
    .catch(() => {
    });
  }
  getReportData = (purposeId = 0, updateHeader = true) => {
    // purposeIdはレポートデータの利用目的ごとにデータの取得方法を振り分けるためのIDです
    // 0: Table用
    // 1: 比較元のグラフデータ
    // 2: 比較のグラフデータ
    // 3: Table用比較データ

    // 比較オフ時に purposeId: 2 のリクエストがあった場合、空の配列を返す。
    if (purposeId === 2 && this.props.isEnabledCompare === false) {
      return {
        'dataGraphCompare': [],
      }
    }

    // nullが入っているパラメータを除外する
    const pickedParam = _.pickBy(this.state.requestParam, o => o !== null);

    // リクエストパラーメタ用オブジェクトをマージ
    const mergeParam = {
      ...User.apiParams(),
      ...pickedParam
    };

    // グラフ用のデータを取得する場合は一部パラメータを書き換える。
    switch (purposeId) {
      case 0:
        // 表に使用するレポートデータ取得の場合
        Object.assign(mergeParam, {
          division: this.state.division,
        });
        break;
      case 1:
        // 比較元のグラフデータ取得関連の処理
        Object.assign(mergeParam, {
          graph: 1,
          division: this.state.graphDivision,
          compare: null,
        });
        break;
      case 2:
        // 比較のグラフデータ取得関連の処理
        Object.assign(mergeParam, {
          graph: 1,
          division: this.state.graphDivision,
          compare: null,
          sDate: format(this.props.dateRangeCompare.startDate, dateFormat),
          eDate: format(this.props.dateRangeCompare.endDate, dateFormat),
        });
        break;
      case 3:
        // 比較のテーブルデータ取得処理
        Object.assign(mergeParam, {
          division: this.state.graphDivision,
          compare: null,
          sDate: format(this.props.dateRangeCompare.startDate, dateFormat),
          eDate: format(this.props.dateRangeCompare.endDate, dateFormat),
        });
        break;
      default:
        break;
    }

    // APIをリクエストしてレポートデータを取得
    return axios.get(
      backendApi + 'report', {
        params: mergeParam
      }
    )
    .then((response) => {
      if (response.data.errorCode) {
        // APIリクエストエラー
      } else {
        // 成功
        // setStateはPromise.allでまとめて行うので、
        // ここではstateを更新したい内容のオブジェクトを返す。
         // 分割の有無で受け取るレポートデータのプロパティ名が違う
        const reportJsonList = (response.data.reportJsonListDivided)
              ? response.data.reportJsonListDivided
              : response.data.reportJsonList;
        switch (purposeId) {
          case 0:
            // 表に使用するレポートデータ取得の場合
            if (updateHeader) {
              return {
                dataTableHeader: response.data.dispItem,
                dataTableOriginal: reportJsonList,
                dataTable: reportJsonList,
                dataTableFooter: response.data.total,
                selectedLabel: response.data.selectedLabel,
                selectedCustomReport: response.data.selectedCustomReport,
              }
            } else {
              // headerを更新しない
              return {
                dataTableOriginal: reportJsonList,
                dataTable: reportJsonList,
                dataTableFooter: response.data.total,
                selectedLabel: response.data.selectedLabel,
                selectedCustomReport: response.data.selectedCustomReport,
              }
            }
          case 1:
            // グラフデータ(比較元)取得の場合
            const objGraph = {};
            objGraph['dataGraph'] = response.data.data;
            objGraph.graphCandidate = response.data.candidate;
            return objGraph;
          case 2:
            // グラフデータ(比較先)取得の場合
            const objGraphCompare = {};
            objGraphCompare['dataGraphCompare'] = response.data.data;
            return objGraphCompare;
          case 3:
            // テーブルデータ(比較)の場合
            return {
              dataTableCompare: reportJsonList
            }
          default:
            break;
        }
      }
    })
    .catch((err) => {
      // サーバエラー
      this.setState({
        isOpenedErrorModal: true,
        errorText: err.response.data.errorMessage
      });
    });
  }
  getReportDataForTable = () => {
    // テーブルに使うレポートデータの取得
    return Promise.all([
      this.getReportData(0),
      this.getReportData(3)
    ]).then((response) => {
      this.setState(Object.assign(...response));
    });
  }
  getReportDataForGraph = () => {
    // グラフに使うレポートデータの取得
    if (this.state.requestParam.target >= ERRNUM) return;
    return Promise.all([
      this.getReportData(1),
      this.getReportData(2),
    ]).then((response) => {
      this.setState(Object.assign(...response));
    });
  }
  getReportDataAll = async (bool = true) => {
    // URIのクエリパラメータにラベル、カスタムレポートのIDが存在するかどうかを判断し、
    // 存在する場合はレポートデータのリクエストパラメータを更新する。
    const queries = queryString.parse(window.location.search);

    if (queries.customReport !== undefined) {
      await this.setState({
        requestParam: {
          ...this.state.requestParam,
          customReport: parseInt(queries.customReport),
        }
      })
    } else if (queries.label !== undefined) {
      await this.setState({
        requestParam: {
          ...this.state.requestParam,
          label: parseInt(queries.label),
        }
      })
    } else {
      this.setState({
         requestParam: {
           ...this.state.requestParam,
           customReport: null,
           label: null,
         }
      })
    }

    // 全レポートデータ更新
    const promises = (this.state.requestParam.target < 3)
      ? [
          this.getReportData(0, bool),
          this.getReportData(1),
          this.getReportData(2),
          this.getReportData(3),
        ]
      : [
          this.getReportData(0, bool),
          this.getReportData(3)
        ];
    return Promise.all(promises).then((response) => {
      // test code
      if(_.includes(response, undefined)) return;
      this.setState(Object.assign(...response));
    });
  }

  getLabelData = () => {
    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'label', {
      params: {
        ...User.apiParams(),
      }
    })
    .then((response) => {
      this.setState({
        labelData: response.data,
      });

      const filteredData = this.state.labelData.filter((item, index) => {
        return (
          (
            // 現在選択されているtargetに合致するもののみ
            item.target === this.state.requestParam.target)
          )
      });
      this.setState({
        labelDataFiltered: filteredData,
      });
    })
    .catch(() => {
    });
  }

  // お気に入りデータを取得
  getFavoriteData = () => {
    return axios.get(backendApi + 'favorite', {
      params: {
        ...User.apiParams(),
      }
    })
    .then((response) => {
      this.setState({
        favoriteData: response.data,
      });
    })
    .catch(() => {
    });
  }

  // フィルタ条件に使用できるデーブルヘッダ項目を収集
  getFilterThreshold = () => {
    const tableHeaderIds = this.state.dataTableHeader.slice(2).map(x => x.id);
    const regex = /Compare$/g;
    const noTableHeaderIds = this.state.dataTableHeader.slice(2).map(x => x.id.replace(regex, ''));
    const tmpHeaderForFilter = this.props.selectableDispItems.map(x => {
      // Comporeがある場合、Compareが入っている値を返さなければならない
      const dispItemTmp = x.dispItem.filter(y => noTableHeaderIds.indexOf(y.id) >= 0);
      const dispItem = dispItemTmp.map(x => {
        const index = noTableHeaderIds.indexOf(x.id);
        if (index >= 0) {
          return {
            id: tableHeaderIds[index],
            label: x.label
          };
        } else {
          return x;
        }
      });
      if (dispItem.length > 0) {
        return {
          categoryName: x.categoryName,
          dispItem: dispItem
        }
      } else {
        return null;
      }
    }).filter(z => z);
    const headerForFilter = _.compact(tmpHeaderForFilter);
    this.setState({
      headerThresholdTmp: _.cloneDeep(headerForFilter),
      headerThreshold: _.cloneDeep(headerForFilter)
    });
  }

  // 閾値の条件をlocalStorageからフィルタ内の表示項目に反映
  setFilterThreshold = () => {
    const dispItem = localStorage.getItem('dispItem');
    if (dispItem) {
      let storage = JSON.parse(dispItem);
      const headerThresholdStorage = this.state.headerThreshold.map(item => {
        const categoryName = item.categoryName;
        const index = _.findIndex(storage, (item) => {
          return item.categoryName === categoryName;
        });
        let newDispItem = null;
        if (index >= 0) {
          newDispItem = item.dispItem.map(ditem => {
            const id = ditem.id;
            const iindex = _.findIndex(storage[index].dispItem, (i) => {
              return i.id === id;
            });
            if (iindex >= 0) {
              const hasNumber = _.has(storage[index].dispItem[iindex], 'number');
              if (hasNumber) {
                ditem = _.cloneDeep(storage[index].dispItem[iindex]);
              }
            }
            return ditem;
          });
        } else {
          newDispItem = _.cloneDeep(item.dispItem);
        }
        return {
          categoryName,
          dispItem: newDispItem
        }
      });
      this.setState({
        headerThreshold: _.cloneDeep(headerThresholdStorage),
        headerThresholdTmp: _.cloneDeep(headerThresholdStorage)
      });
    }
  }

  parseNumAndCheck(strNumDef) {
    let regex = null;
    let strNum = strNumDef;
    let ans = strNumDef;
    let isNoError = true;
    if (typeof(strNumDef) === 'string') {
      if (strNumDef.indexOf(',') !== -1) {
        regex = /,/g;
      } else if (strNumDef.indexOf('%') !== -1) {
        regex = '%';
      } else {
        isNoError = false;
      }
      if (regex) {
        strNum = strNumDef.replace(regex, '');
      }
      ans = Number(strNum);
    }
    return [ans, isNoError];
  }

  parseAddItem(strNum) {
    let regex = null;
    let ans = Number(strNum);
    if (_.isNaN(ans)) {
      if (strNum == null) {
        return '-';
      }
      if (strNum.indexOf(',') !== -1) {
        regex = /,/g;
      } else if (strNum.indexOf('%') !== -1) {
        regex = '%';
      } else if (strNum.indexOf('¥') !== -1) {
        regex = '¥';
      } else {
        return '-';
      }
      ans = Number(strNum.replace(regex, ''));
    }
    return ans;
  }

  addtionStr(prev, crr, def) {
    if (def === '-') return def;
    if (crr === '-') {
      return prev;
    } else if (prev === '-') {
      return crr;
    } else {
      return Number(prev) + Number(crr);
    }
  }

  // テーブルにフィルタ条件を反映する
  setFilterTableThreshold = () => {
    const tmpTableFooter = _.cloneDeep(this.state.dataTableFooter);
    const tmp = this.state.headerThresholdTmp.map(items => {
      return _.filter(items.dispItem, 'number');
    });
    const filtered = _.flatten(tmp);
    let dataTable = [];

    // 単純合計しない項目を抽出
    const calcItems = REPORT_SUM_DESP.map(x => x.label);

    // フィルタする全IDを取得（計算用IDを追加）
    const headerIds = _
      .chain(this.state.dataTableHeader)
      .map(x => x.id)
      .pull('division','clientName', 'alertInfo', 'media', 'accountName', 'campaignName', 'adgroupName', 'keywordName')
      .union(REPORT_SUM_ITEMS)
      .value();
    // 表示するIDのうち、単純合計しないIDを取得
    const calcSumHeaderIds = _
      .chain(this.state.dataTableHeader)
      .map(x => x.id)
      .filter(id => {
        const tmpId = id.replace(/Compare$/, '');
        return calcItems.indexOf(tmpId) !== -1;
      })
      .value();
    
    // 単純合計しても問題ないIDのみを抽出
    const sumHeaderIds = headerIds.filter(x => calcSumHeaderIds.indexOf(x) === -1);

    // 分割あり（比較なし）
    if (_.has(this.state.dataTable[0], 'itemsDivided')) {
      const dataTableTmp = this.state.dataTable.map(item => {
        // 計算用の合計データを算出
        const tmpDivision = _.map(item, itemDivided => {
          return _.pick(itemDivided, headerIds.concat('division'));
        });
        const totalSum = getSumObj(tmpDivision, REPORT_SUM_ITEMS, tmpTableFooter);

        // 条件を満たす分割データをフィルタする
        const itemsDivided = item.itemsDivided.filter(itemDivided => {
          return filtered.every(x => compValue(x.matchType, itemDivided[x.id], x.number));
        });
        // 分割詳細データのみ取り出し
        const picksTableData = _.map(itemsDivided, itemDivided => {
          return _.pick(itemDivided, headerIds.concat('division'));
        });

        // 単純合計
        const totalTmp = getSumObj(picksTableData, sumHeaderIds, tmpTableFooter);
        // 計算合計
        calcSumHeaderIds.forEach(id => {
          let tmp = null;
          const baseId = id.replace(/Compare$/, '');
          const calcDef = _.find(REPORT_SUM_DESP, ['label', baseId]);
          if (calcDef.mType === 0) {
            tmp = totalTmp[calcDef.val1] / totalTmp[calcDef.val2];
          } else {
            tmp = totalTmp[calcDef.val1] * totalTmp[calcDef.val2];
          }
          // 割合については百分率に変換
          if (calcDef.type >= 5 || calcDef.type <= 7) {
            tmp *= 100;
          }
          totalTmp[id] = tmp;
        });
        
        let amount = {};
        if (_.isEmpty(totalTmp)) {
          const zeroTotal = _.fromPairs(sumHeaderIds.map(id => [id, 0]));
          amount = Object.assign(item.amount, zeroTotal);
        } else {
          amount = Object.assign(item.amount, totalTmp);
        }
        if (itemsDivided.length > 0) {
          return {
            amount: amount,
            itemsDivided: itemsDivided
          };
        } else {
          return null;
        }
      });
      dataTable = dataTableTmp.filter(x => x != null);
    } else {
      // 分割なし
      dataTable = this.state.dataTable.filter(item => {
        return filtered.every(x => {
          // 比較データ
          if (x.id.endsWith('Compare')) {
            return compValue(x.matchType, item[x.id].src, x.number);
          } else {
            return compValue(x.matchType, item[x.id], x.number);
          }
        });
      });
    }

    // 合計行を算出する
    // 比較先用計算合計
    let tmpComp = [];
    if (_.has(this.state.dataTableCompare[0], 'amount')) {
      tmpComp = _.map(this.state.dataTableCompare, item => {
        return _.pick(item.amount, REPORT_SUM_ITEMS);
      });
    } else {
      tmpComp = _.map(this.state.dataTableCompare, item => {
        return _.pick(item, REPORT_SUM_ITEMS);
      });
    }
    const totalTmpComp = getSumObj(tmpComp, REPORT_SUM_ITEMS, tmpTableFooter);

    // 比較計算合計
    let tmpData = [];
    if (_.has(this.state.dataTable[0], 'amount')) {
      tmpData = _.map(this.state.dataTable, item => {
        return _.pick(item.amount, REPORT_SUM_ITEMS);
      });
    } else {
      tmpData = _.map(this.state.dataTable, item => {
        return _.pick(item, REPORT_SUM_ITEMS);
      });
    }
    const tmpTotalData = getSumObj(tmpData, REPORT_SUM_ITEMS, tmpTableFooter);

    // 必要な項目のみ抽出
    let picksTableData = [];
    if (_.has(dataTable[0], 'amount')) {
      picksTableData = _.map(dataTable, item => {
        return _.pick(item.amount, sumHeaderIds);
      });
    } else {
      picksTableData = _.map(dataTable, item => {
        return _.pick(item, headerIds);
      });
    }
    // 単純合計
    const totalTmp = getSumObj(picksTableData, sumHeaderIds, tmpTableFooter);

    // 割合合計
    calcSumHeaderIds.forEach(id => {
      const baseId = id.replace(/Compare$/, '');
      const isComp = id.endsWith('Compare');
      const calcDef = _.find(REPORT_SUM_DESP, ['label', baseId]);
      let tmp = null;
      if (calcDef.mType === 0) {
        if (isComp) {
          tmp = {};
          tmp.src = tmpTotalData[calcDef.val1] / tmpTotalData[calcDef.val2];
          tmp.dest = totalTmpComp[calcDef.val1] / totalTmpComp[calcDef.val2];
        } else {
          tmp = totalTmp[calcDef.val1] / totalTmp[calcDef.val2];
        }
      } else {
        if (isComp) {
          tmp.src = tmpTotalData[calcDef.val1] * tmpTotalData[calcDef.val2];
          tmp.dest = totalTmpComp[calcDef.val1] * totalTmpComp[calcDef.val2];
        } else {
          tmp = tmpTotalData[calcDef.val1] * tmpTotalData[calcDef.val2];
        }
      }
      // 割合については百分率に変換
      if (calcDef.type >= 5 || calcDef.type <= 7) {
        if (isComp) {
          tmp.src *= 100;
          tmp.dest *= 100;
        } else {
          tmp *= 100;
        }
      }
      if (isComp) {
        _.set(totalTmp, id, tmp);
      } else {
        totalTmp[id] = tmp;
      }
    })

    // 元の合計データにマージ
    let dataTableFooter = {};
    if (_.isEmpty(totalTmp)) {
      const zeroTotal = _.fromPairs(headerIds.map(id => [id, 0]));
      dataTableFooter = Object.assign(this.state.dataTableFooter, zeroTotal);
    } else {
      dataTableFooter = Object.assign(this.state.dataTableFooter, totalTmp);
    }
    this.setState({
      headerThreshold: _.cloneDeep(this.state.headerThresholdTmp),
      dataTable: dataTable,
      dataTableFooter: dataTableFooter
    });

    // ローカルストレージにフィルタ条件を保存する
    if (this.state.headerThresholdTmp) {
      localStorage.setItem('dispItem', JSON.stringify(this.state.headerThresholdTmp));
    }
  }

  render() {
    // UI操作に関する関数
    // ---
    // グラフの期間変更
    const switchGraphSplit = async (division) => {
      // プログレス表示
      this.props.startProgressing();

      await this.setState({
        graphDivision: division,
      });
      await this.getReportDataForGraph();

      // プログレス非表示
      this.props.endProgressing();
    }

    // フリーワード検索
    const setSearchQuery = async (e) => {
      e.preventDefault();

      // プログレス表示
      this.props.startProgressing();

      // 検索文字列をリクエストパラメータにマージしてレポートデータを再取得
      await this.setState({
        requestParam: {
          ...this.state.requestParam,
          searchText: e.target['searchQuery'].value,
        },
      });
      await this.getReportDataAll();

      // プログレス非表示
      this.props.endProgressing();
    }
    // フリーワード検索のルールを変更
    const switchSearchMethod = (id) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          matchType: id,
        },
      });
    }

    // フィルタ設定
    const setFilterData = async (filterParam) => {
      // プログレス表示
      this.props.startProgressing();

      // フィルタ条件をリクエストパラメータにマージしてレポートデータを再取得
      await this.setState({
        requestParam: Object.assign({}, this.state.requestParam, filterParam.param),
      });
      const queryData = {
        dataExist: (typeof filterParam.param.dataExist === 'undefined' || filterParam.param.dataExist === null) ? 1 : filterParam.param.dataExist,
        aw:        (typeof filterParam.param.aw === 'undefined' || filterParam.param.aw === null) ? null : filterParam.param.aw,
        ss:        (typeof filterParam.param.ss === 'undefined' || filterParam.param.ss === null) ? null : filterParam.param.ss,
        ydn:       (typeof filterParam.param.ydn === 'undefined' || filterParam.param.ydn === null) ? null : filterParam.param.ydn,
        cr:        (typeof filterParam.param.cr === 'undefined' || filterParam.param.cr === null) ? null : filterParam.param.cr,
        tw:        (typeof filterParam.param.tw === 'undefined' || filterParam.param.tw === null) ? null : filterParam.param.tw,
        fb:        (typeof filterParam.param.fb === 'undefined' || filterParam.param.fb === null) ? null : filterParam.param.fb,
        zeus:      (typeof filterParam.param.zeus === 'undefined' || filterParam.param.zeus === null) ? null : filterParam.param.zeus,
        line:      (typeof filterParam.param.line === 'undefined' || filterParam.param.line === null) ? null : filterParam.param.line,
        dbm:       (typeof filterParam.param.dbm === 'undefined' || filterParam.param.dbm === null) ? null : filterParam.param.dbm,
        indeed:    (typeof filterParam.param.indeed === 'undefined' || filterParam.param.indeed === null) ? null : filterParam.param.indeed,
        sn:        (typeof filterParam.param.sn === 'undefined' || filterParam.param.sn === null) ? null : filterParam.param.sn,
        rtbh:      (typeof filterParam.param.rtbh === 'undefined' || filterParam.param.rtbh === null) ? null : filterParam.param.rtbh,
        aa:        (typeof filterParam.param.aa === 'undefined' || filterParam.param.aa === null) ? null : filterParam.param.aa,
        asa:       (typeof filterParam.param.asa === 'undefined' || filterParam.param.asa === null) ? null : filterParam.param.asa,
        bing:      (typeof filterParam.param.bing === 'undefined' || filterParam.param.bing === null) ? null : filterParam.param.bing,
        la:        (typeof filterParam.param.la === 'undefined' || filterParam.param.la === null) ? null : filterParam.param.la,
        una:       (typeof filterParam.param.una === 'undefined' || filterParam.param.una === null) ? null : filterParam.param.una,
        xmedia:    (typeof filterParam.param.xmedia === 'undefined' || filterParam.param.xmedia === null) ? null : filterParam.param.xmedia,
        device:    (typeof filterParam.param.device === 'undefined' || filterParam.param.device === null) ? null : filterParam.param.device,
        alNot:     (typeof filterParam.param.alNot === 'undefined' || filterParam.param.alNot === null) ? null : filterParam.param.alNot,
        al1:       (typeof filterParam.param.al1 === 'undefined' || filterParam.param.al1 === null) ? null : filterParam.param.al1,
        al2:       (typeof filterParam.param.al2 === 'undefined' || filterParam.param.al2 === null) ? null : filterParam.param.al2,
        al3:       (typeof filterParam.param.al3 === 'undefined' || filterParam.param.al3 === null) ? null : filterParam.param.al3,
        al4:       (typeof filterParam.param.al4 === 'undefined' || filterParam.param.al4 === null) ? null : filterParam.param.al4,
        ex:        (typeof filterParam.param.ex === 'undefined' || filterParam.param.ex === null) ? 0 : filterParam.param.ex,
        division:  (typeof filterParam.param.division === 'undefined' || filterParam.param.division === null) ? null : filterParam.param.division,
        label:     (typeof filterParam.param.label === 'undefined' || filterParam.param.label === null) ? null : filterParam.param.label,
      };

      // url書き換え
      const searchParam = new Url(window.location.search, true);
      if (_.has(searchParam, 'query.filter')) {
        queryData['filter'] = searchParam.query.filter;
      }
      const queryBase = _.pickBy(queryData, o => o !== null);
      const queries = await queryString.stringify(queryBase, {
      	skipNull: true
      });
      const urlString = `${window.location.pathname}?${queries}`
      await window.history.replaceState(null, '', urlString);

      // レポートデータ取得
      await this.getReportDataAll();

      if (!this.state.isOpenedErrorModal) {
        // フィルタに含まれる表示設定項目をテーブル反映
        await this.setFilterTableThreshold();
      }

      // プログレス非表示
      this.props.endProgressing();
    }
    // フィルタステータスを削除（変更）する
    const removeStatus = async (key, value = null) => {
      // プログレス表示
      this.props.startProgressing();
      const tmp = {};
      tmp[key] = value;
      await this.setState({
        requestParam: {
          ...this.state.requestParam,
          ...tmp,
        }
      });

      // url書き換え, localStorage保存
      const searchParams = new Url(window.location.search, true);
      let queryParams = await Object.assign(_.cloneDeep(FILTER_INIT), searchParams.query);
      queryParams[key] = value;
      const queryData = {
        dataExist: (typeof queryParams.dataExist === 'undefined' || queryParams.dataExist === null) ? 1 : queryParams.dataExist,
        aw:        (typeof queryParams.aw === 'undefined' || queryParams.aw === null) ? null : queryParams.aw,
        ss:        (typeof queryParams.ss === 'undefined' || queryParams.ss === null) ? null : queryParams.ss,
        ydn:       (typeof queryParams.ydn === 'undefined' || queryParams.ydn === null) ? null : queryParams.ydn,
        cr:        (typeof queryParams.cr === 'undefined' || queryParams.cr === null) ? null : queryParams.cr,
        tw:        (typeof queryParams.tw === 'undefined' || queryParams.tw === null) ? null : queryParams.tw,
        fb:        (typeof queryParams.fb === 'undefined' || queryParams.fb === null) ? null : queryParams.fb,
        zeus:      (typeof queryParams.zeus === 'undefined' || queryParams.zeus === null) ? null : queryParams.zeus,
        line:      (typeof queryParams.line === 'undefined' || queryParams.line === null) ? null : queryParams.line,
        dbm:       (typeof queryParams.dbm === 'undefined' || queryParams.dbm === null) ? null : queryParams.dbm,
        indeed:    (typeof queryParams.indeed === 'undefined' || queryParams.indeed === null) ? null : queryParams.indeed,
        sn:        (typeof queryParams.sn === 'undefined' || queryParams.sn === null) ? null : queryParams.sn,
        rtbh:      (typeof queryParams.rtbh === 'undefined' || queryParams.rtbh === null) ? null : queryParams.rtbh,
        aa:        (typeof queryParams.aa === 'undefined' || queryParams.aa === null) ? null : queryParams.aa,
        asa:       (typeof queryParams.asa === 'undefined' || queryParams.asa === null) ? null : queryParams.asa,
        bing:      (typeof queryParams.bing === 'undefined' || queryParams.bing === null) ? null : queryParams.bing,
        la:        (typeof queryParams.la === 'undefined' || queryParams.la === null) ? null : queryParams.la,
        una:       (typeof queryParams.una === 'undefined' || queryParams.una === null) ? null : queryParams.una,
        xmedia:    (typeof queryParams.xmedia === 'undefined' || queryParams.xmedia === null) ? null : queryParams.xmedia,
        device:    (typeof queryParams.device === 'undefined' || queryParams.device === null) ? null : queryParams.device,
        alNot:     (typeof queryParams.alNot === 'undefined' || queryParams.alNot === null) ? null : queryParams.alNot,
        al1:       (typeof queryParams.al1 === 'undefined' || queryParams.al1 === null) ? null : queryParams.al1,
        al2:       (typeof queryParams.al2 === 'undefined' || queryParams.al2 === null) ? null : queryParams.al2,
        al3:       (typeof queryParams.al3 === 'undefined' || queryParams.al3 === null) ? null : queryParams.al3,
        al4:       (typeof queryParams.al4 === 'undefined' || queryParams.al4 === null) ? null : queryParams.al4,
        ex:        (typeof queryParams.ex === 'undefined' || queryParams.ex === null) ? 0 : queryParams.ex,
        division:  (typeof queryParams.division === 'undefined' || queryParams.division === null) ? null : queryParams.division,
        filter:    (typeof queryParams.filter === 'undefined' || queryParams.filter === null) ? null : queryParams.filter,
      };

      await localStorage.setItem('filter', JSON.stringify(_.omit(queryData, ['filter', 'customReport'])));
      const queryBase = _.pickBy(queryData, o => o !== null);
      const queries = await queryString.stringify(queryBase, {
      	skipNull: true
      });
      const urlString = `${window.location.pathname}?${queries}`
      await window.history.replaceState(null, '', urlString);

      // データ取得
      await this.getReportDataAll();

      // プログレス非表示
      this.props.endProgressing();
    }
    // フィルタ閾値設定モーダルを開く
    const openThresholdModal = async (e, item) => {
      await this.setState({
        isOpenedThresholdModal: true,
        thresholdModalId: item.id,
        thresholdModalHeading: item.label
      });
    }
    // フィルタ閾値設定モーダルを閉じる
    const closeThresholdModal = async (e) => {
      await this.setState({
        isOpenedThresholdModal: false,
        thresholdModalId: '',
        thresholdModalHeading: ''
      });
    }
    // フィルタ内の項目に閾値を設定する
    const setThreshold = async (data) => {
      const tmp = await this.state.headerThresholdTmp.map(items => {
        const dataIndex = _.findIndex(items.dispItem, (item) => {
          return item.id === data.id
        });
        if (dataIndex >= 0) {
          const item = items.dispItem[dataIndex];
          items.dispItem[dataIndex] = {
            ...item,
            ...data,
          }
        }
        return items;
      });
      await this.setState({
        headerThresholdTmp: tmp,
        thresholdModalId: '',
        thresholdModalHeading: ''
      });
    }
    // フィルタ閾値削除
    const removeThreshold = async (id) => {
      const tmp = await this.state.headerThresholdTmp.map(items => {
        const dataIndex = _.findIndex(items.dispItem, (item) => {
          return item.id === id
        });
        if (dataIndex >= 0) {
          const item = items.dispItem[dataIndex];
          items.dispItem[dataIndex] = {
            id: item.id,
            label: item.label
          };
        }
        return items;
      });
      await this.setState({
        headerThresholdTmp: tmp,
      });
    }

    // テーブルに反映されたフィルタ閾値を削除
    const removeThresholdTable = async (id) => {
      // プログレス表示
      this.props.startProgressing();

      // フィルタ内の閾値を削除
      await removeThreshold(id);
      // レポートデータ再取得
      await this.getReportDataAll();

      if (!this.state.isOpenedErrorModal) {
        // フィルタに含まれる表示設定項目をテーブル反映
        await this.setFilterTableThreshold();
      }

      // プログレス非表示
      this.props.endProgressing();
    }

    // 分割方法を変える
    const setDivision = async (division) => {
      // プログレス表示
      this.props.startProgressing();

      // 分割条件を変更してレポートデータを再取得
      await stateDivision(division);
      // url書き換え
      const urlString = `${window.location.pathname}${window.location.search}`;
      const urlObj = new Url(urlString, true);
      urlObj.query.division = division;
      await window.history.replaceState(null, '', urlObj.toString());
      // localStorage保存
      const def = localStorage.getItem('filter');
      if (def) {
        let obj = JSON.parse(def);
        obj.division = division;
        localStorage.setItem('filter', JSON.stringify(obj));
      }

      // プログレス非表示
      await this.props.endProgressing();
    }
    // 分割処理設定
    const stateDivision = async (division) => {
      // 分割条件を変更してレポートデータを再取得
      if (division !== null && this.state.requestParam.compare === 1) {
        // 比較が有効 & 何らかの条件で分割する場合は比較をオフにする
        this.props.offDateCompare();
        await this.setState({
          division: division,
          requestParam: Object.assign({}, this.state.requestParam, {
            compare: null
          }),
        });
        await this.getReportDataAll();
      } else {
        await this.setState({
          division: division,
        });
        await this.getReportDataForTable();
      }
    }

    // 表示項目設定モーダルを開く
    const openDisplayModal = (e) => {
      this.setState({
        editingDispItemId: parseInt(e.currentTarget.value),
        isOpenedDisplayModal: true,
      });
    }
    // 表示項目設定モーダルを閉じる
    const closeDisplayModal = (e) => {
      this.setState({
        isOpenedDisplayModal: false,
      });
    }
    // 表示項目設定を適用する
    const setDisplayItem = async (e) => {
      const selected = _.find(this.state.dispItems, o => {
        return o.id === parseInt(e.currentTarget.value)
      });
      await this.setState({
        dataTableHeader: selected.dispItem,
        requestParam: Object.assign({}, this.state.requestParam, {
          resultListNo: parseInt(e.currentTarget.value),
        }),
      });

      // 表示項目設定をフィルタに反映する
      this.getFilterThreshold();

      // 保存されていた表示項目の閾値をフィルタに反映
      await this.setFilterThreshold();

      // フィルタをテーブルに適用する
      await this.setFilterTableThreshold();

      // Cookieに選択中の表示項目設定IDをセット
      Cookies.set('rakuda-result-list-no', e.currentTarget.value);
    }
    // 表示項目設定を更新する
    const saveDisplayItem = (title, newDispItem) => {
      // プログレス表示
      this.props.startProgressing();
      this.setState({
        isOpenedDisplayModal: false,
      });

      axios.get(backendApi + 'dispItem', {
        params: {
          ...User.apiParams(),
          func: 1,
          target: this.state.requestParam.target,
          resultListNo: this.state.editingDispItemId,
          division: this.state.division,
          compare: (this.props.isEnabledCompare) ? 1 : null,
          id: this.state.editingDispItemId,
          name: title,
          dispItemData: newDispItem.map(o => { return o.id }).join(','),
          ex: this.state.requestParam.ex,
        }
      })
      .then(async (response) => {
        const selected = _.find(response.data, o => {
          return o.id === this.state.editingDispItemId
        });
        this.setState({
          dispItems: response.data,
          dataTableHeader: selected.dispItem,
          requestParam: Object.assign({}, this.state.requestParam, {
            resultListNo: this.state.editingDispItemId
          }),
        });

        // Cookieに選択中の表示項目設定IDをセット
        Cookies.set('rakuda-result-list-no', this.state.editingDispItemId);

        // レポートデータ取得
        await this.getReportDataAll(false);

        if (!this.state.isOpenedErrorModal) {
          // フィルタに表示する設定項目に反映
          await this.getFilterThreshold();

          // 保存されていた表示項目の閾値をフィルタに反映
          await this.setFilterThreshold();

          // フィルタをテーブルに適用する
          await this.setFilterTableThreshold();
        }

        // プログレス非表示
        this.props.endProgressing();
      })
      .catch(() => {
      });
    }
    // 指定の表示項目設定を初期化
    const initDisplayItem = (e) => {
      // プログレス表示
      this.props.startProgressing();

      axios.get(
        backendApi + 'dispItem', {
          params: {
            ...User.apiParams(),
            func: 1,
            target: this.state.requestParam.target,
            resultListNo: this.state.editingDispItemId,
            division: this.state.division,
            compare: (this.props.isEnabledCompare) ? 1 : null,
            id: e.currentTarget.value,
            name: '',
          }
        }
      )
      .then((response) => {
        this.setState({
          dispItems: response.data,
          dataTableHeader: response.data[this.state.editingDispItemId].dispItem,
        });

        // プログレス非表示
        this.props.endProgressing();
      })
      .catch(() => {
      });
    }

    // 表示設定・追加項目設定処理で使用する項目から「追加項目」を削除したものを作成する
    const displayableItems = () => {
      return this.props.selectableDispItems.map((item) => {
        if (item.categoryName === 'カスタマイズ') {
          const dispItem = _.reject(item.dispItem, (x) => {
            return x.id.indexOf('addItem') !== -1;
          });
          return {
            ...item,
            dispItem
          }
        } else {
          return item;
        }
      });
    }

    // 項目追加モーダルを開く
    const openAddItemModal = async (e, param = null) => {
      await this.setState({
        addItemParam: param,
      });
      await this.setState({
        isOpenedAddItemModal: true,
      });
    }

    // 項目追加モーダルを閉じる
    const closeAddItemModal = async () => {
      await this.setState({
        isOpenedAddItemModal: false,
      });
      await this.setState({
        addItemParam: null,
      });
    }

    // 追加項目を取得
    const getReportAddItem = () => {
      axios.get(backendApi + 'addReportItem', {
        params: {
          ...User.apiParams(),
        },
      })
      .then((response) => {
        this.setState({
          addItems: response.data
        });
      })
      .catch((error) => {
      })
    }

    // 着地予測設定を適用する
    const setAvgData = async (id) => {
      // プログレス表示
      this.props.startProgressing();

      await this.setState({
        requestParam: {
          ...this.state.requestParam,
          avgData: id,
        },
      });
      await this.getReportDataAll();

      // プログレス非表示
      this.props.endProgressing();
    }

    // 運用メモモーダルを開く
    const openOpeMemoEditModal = async (e, queries) => {
      const q = new Url(`?${queries}`, true).query
      const ids = q.targetIds.split(',')
      let keyname = '';
      switch(parseInt(q.target)) {
        case 1:
          keyname = 'clientId';
          break;
        case 2:
          keyname = 'accountId';
          break;
        case 3:
          keyname = 'campaignId';
          break;
        case 4:
          keyname = 'adgroupId';
          break;
        case 5:
          keyname = 'keywordId';
          break;
        default:
      }
      let checkedDataFilter = []; // チェック対象かつ取得したデータの中にあるIDを保持する変数
      ids.map(x =>
        _.find(this.state.dataTable, (o) => {
          if(o[keyname] === parseInt(x)){
            checkedDataFilter.push(o);
          }
        })
      );
      const accountIds = checkedDataFilter.map(x => x.accountId);
      await this.setState({
        opeMemoEditParam: {
          ...this.state.opeMemoEditParam,
          clientId: checkedDataFilter.map(x => x.clientId)[0],
          accountId: accountIds.length > 1 ? '' : accountIds[0],
          mediaType: accountIds.length > 1 ? '' : checkedDataFilter.map(x => x.media.id)[0],
        },
      });
      await this.setState({
        isOpenedOpeMemoEditModal: true,
      });
    }
    // 運用メモモーダルを閉じる
    const closeOpeMemoEditModal = (e) => {
      this.setState({
        isOpenedOpeMemoEditModal: false,
      });
    }
    // 運用メモ設定
    const setOperationMemo = async (param) => {
      await this.setState({
        opeMemoEditParam: {
          ...param,
          userIds: param.userIds ? param.userIds : '',
        }
      })
      return await axios.get(backendApi + 'progressModify',{
        params: {
          ...User.apiParams(),
          ...this.state.opeMemoEditParam,
          id: null,
          func: 1,
        }
      })
      .then((response) => {
        this.setState({
          opeMemoEditParam: {
            id: null,
            memo: '',
            note: '',
            clientId: '',
            accountId: '',
            mediaType: '',
            shareSetting: 0,
            userIds: [],
          },
        });
      })
      .catch(() => {
      });
    }

    // 表示項目設定レポートデータをダウンロード
    const download = async (e) => {
      e.preventDefault();

      const url = new Url(backendApi + 'report', true);
      url.set('query', Object.assign(
        User.apiParams(),
        this.state.requestParam,
        {
          dl: 1,
          division: (this.props.isEnabledCompare) ? 0 : this.state.division,　// 分割設定があればダウンロードデータも分割する
        }
      ));

      var element = document.createElement('a');
      element.href = url.toString();
      element.setAttribute('download', 'reportdata.csv');
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }


    // お気に入りモーダルを開く
    const openFavModal = async (e) => {
      await this.getFavoriteData();
      await this.setState({
        isOpenedFavModal: true
      });
    }
    // お気に入りモーダルを閉じる
    const closeFavModal = (e) => {
      this.setState({
        isOpenedFavModal: false
      });
    }
    // お気に入り登録
    const setFavoriteParam = (param) => {
      const url = `${window.location.pathname}${window.location.search}`;
      return axios.get(backendApi + 'favoriteModify', {
        params: {
          ...User.apiParams(),
          name: param.name,
          url: url,
          shareSetting: param.shareSetting,
          func: 1,
          userIds: param.checkedUsers.join(','),
        }
      })
      .then((response) => {
      })
      .catch(() => {
      });
    }

    // 各行のチェック
    const checkItem = (e) => {
      const id = parseInt(e.currentTarget.value);
      const checked = this.state.checked.concat();

      if (checked.indexOf(id) > -1) {
        checked.splice(checked.indexOf(id), 1)
      } else {
        checked.push(id);
      }
      this.setState({
        checked: checked
      });
    }

    // チェックボックスをすべて選択
    const toggleCheckAll = async (dataTable) => {
      let checked = (this.state.checked.length < dataTable.length)
        ? dataTable.map(item => {
          switch (this.props.target) {
            case 1: return parseInt(item.clientId);
            case 2: return parseInt(item.accountId);
            case 3: return parseInt(item.campaignId);
            case 4: return parseInt(item.adgroupId);
            case 5: return parseInt(item.keywordId);
            default: return ``;
          }
        })
        : [];
      this.setState({
        checked: checked
      })
    }

    // 現在のフィルタに応じてタブLINKの書き換えを行う
    (async () => {
      const searchParam = new Url(window.location.search, true);
      let data = this.state.dataTableOriginal[0];
      let tabItemsTmp = _.cloneDeep(this.state.tabItems);
      if (data) {
        if (searchParam.query.division > 0) data = data.amount;
        let clientId = await _.get(data, 'clientId', null);
        let accountId = await _.get(data, 'accountId', null);
        let campaignId = await _.get(data, 'campaignId', null);
        let adgroupId = await _.get(data, 'adgroupId', null);
        for (var i = 0; i < 5; i++) {
          searchParam.query.filter = null;
          if (i + 1 <= this.state.requestParam.target) {
            switch (i + 1) {
              case 5:
                searchParam.query.filter = adgroupId;
                break;
              case 4:
                searchParam.query.filter = campaignId;
                break;
              case 3:
                searchParam.query.filter = accountId;
                break;
              case 2:
                searchParam.query.filter = clientId;
                break;
              case 1:
                searchParam.query.filter = null;
                break;
              default:
              // 処置なし
            }
          }
          const queryBase = await _.pickBy(searchParam.query, o => o !== null);
          let queries = await queryString.stringify(queryBase, {
            skipNull: true
          });
          tabItemsTmp[i].link = `/progress/${i + 1}/?${queries}`;
        }
        if (this.state.tabItems[1].link !== tabItemsTmp[1].link) {
          this.setState({
            tabItems: _.cloneDeep(tabItemsTmp)
          });
        }
      }
    })();

    // エラーモーダルを閉じる
    const closeErrorModal = () => {
      this.setState({
        isOpenedErrorModal: false
      });
      window.setTimeout(() => {
        this.setState({
          errorText: 'エラーが発生しました',
        });
      }, 500);
    }

    return (
      <div>
        {
          (() => {
            if (this.state.selectedLabel) {
              return (
                <ProgressStatus
                  applyName = { 'ラベル' }
                  title = { this.state.selectedLabel.name }
                />
              )
            } else if (this.state.selectedCustomReport) {
              return (
                <ProgressStatus
                  applyName = { 'カスタムレポート' }
                  title = { this.state.selectedCustomReport.name }
                />
              )
            } else if (this.state.isShownTab === true) {
              return (
                <Tab
                  items = { this.state.tabItems }
                  currentId = { this.state.requestParam.target - 1 }
                  className = "m-b-40"
                />
              )
            }
          })()
        }

        {
          (() => {
            if (this.props.hasGraph === true) {
              return (
                <div>
                  <GraphWithToggleBtn
                    dateRange = { this.props.dateRange }
                    dateRangeCompare = { this.props.dateRangeCompare }
                    targetId = { this.state.requestParam.target }
                    data = { this.state.dataGraph }
                    dataCompare = { this.state.dataGraphCompare }
                    candidate = { this.state.graphCandidate }
                    division = { this.state.graphDivision }
                    isEnabledCompare = { this.props.isEnabledCompare }
                    switchGraphSplit = { switchGraphSplit }
                    hasToggleBtn
                  />
                  <DividerContent
                    className = "m-t-0"
                  />
                </div>
              )
            }
          })()
        }
        <StatusFilter
          requestParam = { this.state.requestParam }
          selectedLabel = { this.state.selectedLabel }
          editingDispItemId = { this.state.editingDispItemId }
          labelDataFiltered = { this.state.labelDataFiltered }
          headerThreshold = { this.state.headerThreshold }
          headerThresholdTmp = { this.state.headerThresholdTmp }
          setFilterData = { setFilterData }
          remove = { removeStatus }
          openThresholdModal = { openThresholdModal }
          removeThreshold = { removeThreshold }
          removeThresholdTable = { removeThresholdTable }
          isOpenedThresholdModal = { this.state.isOpenedThresholdModal }
          addItems = { this.state.addItems }
        />
        <StyledTableMenu>
          <StyledFilterSection
            className = {
              classnames({
                'is-shown': !this.state.requestParam.customReport
              })
            }
          >
            <Search
              name = "searchQuery"
              value = { this.state.requestParam.searchText }
              onSubmit = { setSearchQuery }
              className = 'm-r-16'
            />
            <PullDownCommon
              id = { this.state.requestParam.matchType }
              listWidth = '130px'
              items = {
                [
                { id: 0, label: '含む' },
                { id: 4, label: '含まない' },
                { id: 1, label: '等しい' },
                { id: 2, label: '始まる' },
                { id: 3, label: '終わる' },
                ]
              }
              onChange = { switchSearchMethod }
            />
            <DividerDottedV hasMargin />
            <PullDownProgressSplit
              target = { this.props.target }
              division = { this.state.division }
              requestParam = { this.state.requestParam }
              setDivision = { setDivision }
            />
            <PullDownProgressDisplay
              dispItems = { this.state.dispItems }
              resultListNo = { this.state.requestParam.resultListNo }
              openDisplayModal = { openDisplayModal }
              setDisplayItem = { setDisplayItem }
              initDisplayItem = { initDisplayItem }
              isDisplay = { this.state.isShownTab }
              className = "m-l-16"
            />
            <PullDownProgressAvgData
              id = { this.state.requestParam.avgData }
              setAvgData = { setAvgData }
              className = "m-l-16"
            />
            <DividerDottedV hasMargin />
          </StyledFilterSection>

          <FlexBox
            style = {{ 'cursor': 'pointer' }}
            onClick = { download }
          >
            <IconDownload
              className = 'm-r-8'
            />
            CSVダウンロード
          </FlexBox>
          <FlexBox
            style = {{ 'cursor': 'pointer' }}
            onClick = { openFavModal }
            className="m-l-16"
          >
            <IconStar
              className = 'm-r-8'
            />
            お気に入り登録
          </FlexBox>
        </StyledTableMenu>
        <BarEditProgressData
          count = { this.state.checked.length }
          checked = { this.state.checked }
          target = { this.props.target }
          division = { this.state.division }
          openOpeMemoEditModal = { openOpeMemoEditModal }
        />
        <ReportTable
          dataTableHeader = { this.state.dataTableHeader }
          dataTable = { this.state.dataTable }
          dataTableFooter = { this.state.dataTableFooter }
          checked = { this.state.checked }
          division = { this.state.division }
          isDivided = { (this.state.division !== 0 && this.state.division !== 6 && this.state.division !== 7) }
          windowHeight = { this.props.windowHeight }
          target = { this.props.target }
          targetId = { this.props.targetId }
          targetName = { this.props.targetName }
          checkItem = { checkItem }
          toggleCheckAll = { toggleCheckAll }
          sortId = { 'cost_with_fee' } // sort項目を指定する
        />
        <ModalProgressDisplayEdit
          heading = '表示項目の変更'
          editingDispItemId = { this.state.editingDispItemId }
          selectableDispItems = { displayableItems() }
          dispItems = { this.state.dispItems }
          isOpened = { this.state.isOpenedDisplayModal }
          isOpenedSideMenu = { this.props.isOpenedSideMenu }
          save = { saveDisplayItem }
          close = { closeDisplayModal }
          openAddItemModal = { openAddItemModal }
          getReportAddItem = { getReportAddItem }
          addItems = { this.state.addItems }
        />
        <ModalModifyFav
          heading = 'お気に入り登録'
          bodyText = '現在の画面をお気に入り登録します'
          users = { this.props.users }
          isOpened = { this.state.isOpenedFavModal }
          setFavorite = { setFavoriteParam }
          closeFavModal = { closeFavModal }
          modifyParams = { this.state.favParams }
          data = { this.state.favoriteData }
        />
        <ModalOperationMemoEdit
          heading = '運用メモの設定'
          isOpened = { this.state.isOpenedOpeMemoEditModal }
          users = { this.props.users }
          setOperationMemo = { setOperationMemo }
          close = { closeOpeMemoEditModal }
          modifyParams = { this.state.opeMemoEditParam }
          clients = { this.state.clients }
          accounts = { this.state.accounts }
        />
        <ModalFilterThreshold
          isOpened = { this.state.isOpenedThresholdModal }
          id = { this.state.thresholdModalId }
          heading = { this.state.thresholdModalHeading }
          close = { closeThresholdModal }
          submit = { setThreshold }
        />
        <ModalAddItem
          heading = '設定項目を追加'
          isOpenedSideMenu = { this.props.isOpenedSideMenu }
          isOpened = { this.state.isOpenedAddItemModal }
          close = { closeAddItemModal }
          selectableDispItems = { displayableItems() }
          getReportAddItem = { getReportAddItem }
          addItemParam = { this.state.addItemParam }
        />
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isOpenedErrorModal }
          close = { closeErrorModal }
          bodyText = { this.state.errorText }
        ></ModalErrorMessage>
      </div>
    )
  }
}

export default Base;
