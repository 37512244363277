import React from 'react';

class Tool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('運用ツール');
    props.toggleDatePicker(false);
  }
  // method() {
  //   this.setState();
  // }
  render() {
    return (
      <div>
        <p>Tool メディックス様が開発担当されます。</p>
      </div>
    )
  }
}

export default Tool;
