import React from 'react';
import styled from 'styled-components';

const IconAttention = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0, 0, 20, 20" className={props.className}>
    <path fillRule="evenodd" stroke="#E5987E" fill="none" d="M5.5 3.5h9a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2z"/><path fillRule="evenodd" fill="#E5987E" d="M9.5 13.5v-4h1v4h-1zm0-7h1v2h-1v-2z"/>
  </svg>
)

const StyledIconAttention = styled(IconAttention) `
  display: block;
`

export default StyledIconAttention;
