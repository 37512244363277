import React from 'react';
import styled from 'styled-components';

import Button from '../atoms/Button';
import FlexBox from '../atoms/FlexBox';
import Modal from '../molecules/Modal';
import InputText from '../atoms/InputText';
import PullDownCommon from '../organisms/PullDownCommon';
import getUnit from '../_util/getUnit';

import MATCH_TYPE from '../_const/MATCH_TYPE';

const StyledModalBody = styled.div`
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
`
const StyledButton = styled(Button)`
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`
const StyledUnitText = styled.div`
  text-align: right;
  font-size: 11px;
`

class ModalFilterThreshold extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: 'パラメータ設定',
      selected: [],
      param: {
        number: '',
        matchType: 0,
        id: ''
      }
    }
  }

  render() {
    const switchThresholdMethod = (id) => {
      this.setState({
        param: {
          ...this.state.param,
          matchType: id,
        },
      });
    }

    const closeModal = async () => {
      await this.props.close();
      this.setState({
        param: {
          number: '',
          matchType: 0,
          id: ''
        }
      });
    }

    return (
      <Modal
        heading = { this.props.heading }
        isOpened = { this.props.isOpened }
        close = { closeModal }
      >
        <StyledModalBody>
          <FlexBox>
            <PullDownCommon
              id = { this.state.param.matchType }
              listWidth = '140px'
              items = { MATCH_TYPE }
              onChange = { switchThresholdMethod }
            />
            <InputText
              type = 'text'
              value = { this.state.param.number }
              className = "m-l-32"
              width = '120'
              onChange = {
                ((e) => {
                  this.setState({
                    param: {
                      ...this.state.param,
                      number: e.currentTarget.value
                    }
                  });
                })
              }
            />
          </FlexBox>
          <StyledUnitText>単位： { getUnit(this.props.id) }</StyledUnitText>
          <FlexBox
            justify = "space-between"
            className = 'm-t-16'
          >
            <StyledButton
              type = "button"
              width = "calc(50% - 2px)"
              justify = "center"
              onClick = { closeModal }
            >キャンセル
            </StyledButton>
            <StyledButton
              type = "submit"
              width = "calc(50% - 2px)"
              justify = "center"
              color = 'orange'
              disabled = { !this.state.param.number }
              onClick = { () => {
                this.props.submit({
                  ...this.state.param,
                  id: this.props.id
                });
                closeModal();
              } }
            >保存する
            </StyledButton>
          </FlexBox>
        </StyledModalBody>
      </Modal>
    )
  }
};

export default ModalFilterThreshold;
