import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';

import FlexBox from '../atoms/FlexBox';
import InputText from '../atoms/InputText';
import PullDownHasBorder from '../molecules/PullDownHasBorder';
import PullDownListItem from '../atoms/PullDownListItem';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';

const StyledPullDownGraphSplit = styled.div`
  position: relative;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 20;
  white-space: nowrap;
`
const StyledPullDownListWrapIn = styled(PullDownListWrapIn)`
  padding-top: 24px;
  padding-bottom: 24px;
`

const ALERT_RULE_RANGES = [
  {
    id: 0,
    label: '絶対値',
    labelInList: '絶対値',
  },
  {
    id: 1,
    label: '昨日と比較する',
    labelInList: '昨日と比較する',
  },
  {
    id: 2,
    label: '過去7日間と比較する',
    labelInList: '過去7日間と比較する',
  },
  {
    id: 3,
    label: '過去14日間と比較する',
    labelInList: '過去14日間と比較する',
  },
  {
    id: 4,
    label: '過去30日間と比較する',
    labelInList: '過去30日間と比較する',
  },
  {
    id: 5,
    label: '先月と比較する',
    labelInList: '先月と比較する',
  },
  {
    id: 6,
    label: '残り',
    labelInList: '残り何日時点',
  },
  {
    id: 7,
    label: '月初',
    labelInList: '月初から何日時点',
  },
];

class PullDownAlertRuleRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenedPullDown: false,
    };
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown,
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false,
    });
  }
  render() {
    const switchValue = (item) => {
      this.props.setTiming(item.id);
      this.closePullDown();
    }

    return (
      <FlexBox>
        <StyledPullDownGraphSplit
          className = { this.props.className }
        >
          <PullDownHasBorder
            width = {
              (this.props.id >= 6) ? 'canc(23px + 2.5em)' : 'calc(23px + 16em)'
            }
            onClick = {this.togglePullDown}
          >
            <strong>
              {
                (() => {
                  const item = _.find(ALERT_RULE_RANGES, o => {
                    return o.id === this.props.id
                  });
                  return (item) ? item.label: '';
                })()
              }
            </strong>
          </PullDownHasBorder>
          <StyledPullDownListWrap
            className = {
              this.state.isOpenedPullDown === true
                ? `is-opened`
                : `is-closed`
            }
          >
            <StyledPullDownListWrapIn>
              {
                (() => {
                  return ALERT_RULE_RANGES.map((item) => {
                    return (
                      <PullDownListItem
                        key = { _.uniqueId() }
                        onClick = {
                          (() => {
                            switchValue(item)
                          })
                        }
                        className = {
                          this.props.id === item.id ? 'is-current' : '' }
                      >
                        { item.labelInList }
                      </PullDownListItem>
                    )
                  });
                })()
              }
            </StyledPullDownListWrapIn>
          </StyledPullDownListWrap>
        </StyledPullDownGraphSplit>
        {
          (() => {
            switch (this.props.id) {
              case 6: return (
                <FlexBox>
                  <InputText
                    type = "number"
                    value = { this.props.timingCustom }
                    width = { 60 }
                    max = { 30 }
                    min = { 0 }
                    step = { 1 }
                    className = "m-l-8"
                    onChange = { this.props.setTimingCustom }
                  />
                  <div className="m-l-8">日時点</div>
                </FlexBox>
              );
              case 7: return (
                <FlexBox
                  className = "m-l-8"
                >
                  から
                  <InputText
                    type = "number"
                    value = { this.props.targetDay }
                    width = { 60 }
                    max = { 30 }
                    min = { 0 }
                    step = { 1 }
                    className = "m-l-8"
                    onChange = { this.props.setTargetDay }
                  />
                  <div className="m-l-8">日時点</div>
                </FlexBox>
              );
              default: return '';
            }
          })()
        }
      </FlexBox>
    )
  }
}
export default onClickOutside(PullDownAlertRuleRange);
