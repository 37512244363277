import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'
import FONTSIZE from '../_const/FONTSIZE'

import ButtonClose from '../atoms/ButtonClose';
import IconReorder from '../atoms/IconReorder';

const StyledIconReorder = styled(IconReorder)`
  position: absolute;
  top: 50%;
  left: 16px;
  margin-top: -4px;
`
const StyledButtonClose = styled(ButtonClose)`
  position: absolute;
  top: 50%;
  right: 16px;
  margin-top: -8px;
`

const Draggable = (props) => (
  <div className={props.className}>
    <StyledIconReorder />
    {props.children}
    {
      (
        () => {
          if (props.onClose) {
            return (
              <StyledButtonClose
                onClick = { props.onClose }
              />
            )
          }
        }
      )()
    }
  </div>
);

const StyledDraggable = styled(Draggable)`
  box-shadow: 0 2px 0 0 #d8d8d8;
  cursor: grab;
  position: relative;
  margin-top: 8px;
  padding: 8px 40px;
  border-radius: 2px;
  font-size: ${FONTSIZE.S}px;
  font-weight: 600;
  background-color: ${COLOR.GRAY};
`

export default StyledDraggable;
