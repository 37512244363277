import React from 'react';
import axios from 'axios';
import styled from 'styled-components';

import Button from '../atoms/Button';
import IconImport from '../atoms/IconImport';
import Modal from '../molecules/Modal';

const StyledModalBody = styled.div`
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
`

class ModalFileImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFile: false
    }
  }

  render() {
    const upload = async (e, url, apiParams, params) => {
      e.preventDefault();
      let formElement = document.getElementById("importForm");
      let formdata = new FormData(formElement);
      await axios.post(
        url,
        formdata,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
          params: {
            func: this.props.funcId,
            ...apiParams,
            ...params
          }
        }
      )
      .then((response) => {
        this.props.closeImportModal();
        this.setState({
          hasFile: false
        });
        setTimeout(() => {
          document.importForm.reset();
          formElement = null;
          formdata = null;
        }, 100);
        alert('インポートが完了しました');
      })
      .catch((error) => {
        if(error.response) {
          this.props.changeErrorText(error.response.data.errorMessage);
          this.props.openErrorModal();
        }
      })
    }

    const checkFile = () => {
      if (document.getElementById("importFormFile").value === '') {
        this.setState({
          hasFile: false
        });
      } else {
        this.setState({
          hasFile: true
        });
      }
    }

    return (
      <Modal
        heading = { this.props.heading }
        isOpened = { this.props.isOpened }
        close = { () => {
          this.props.closeImportModal();
          document.importForm.reset();
        } }
      >
        <StyledModalBody>
          <form
            id = 'importForm'
            encType = 'multipart/form-data'
            name = 'importForm'
            onSubmit = {
              (e) => {
                upload(e, this.props.apiUrl, this.props.apiParams, this.props.params);
              }
            }
          >
            <input
              type = "file"
              name = "file"
              id = "importFormFile"
              className = "m-b-16"
              onChange = { (() => { checkFile() }) }
            />
            <Button
              type = "submit"
              width = "100%"
              justify = "center"
              disabled = { !this.state.hasFile }
            >
              <IconImport
                className = "m-r-8"
              />インポート
            </Button>
          </form>
        </StyledModalBody>
      </Modal>
    )
  }
};

export default ModalFileImport;
