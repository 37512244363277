import React from 'react';
import styled from 'styled-components';

const IconDownloadSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0, 0, 17, 18" className={props.className}>
    <path fillRule="evenodd" fill="gray" d="M16 18H0v-4h1v3h15v-3h1v4h-1zm-7-6H8v-.464L4.005 7.671l.684-.665L8 10.208V0h1v10.208l3.311-3.202.684.665L9 11.535V12z"/>
  </svg>
)
const IconDownload = styled(IconDownloadSvg) `
  display: block;
`

export default IconDownload;
