import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'
import FONTSIZE from '../_const/FONTSIZE'

import img from '../../img/icon_search.svg'

const StyledSearch = styled.input`
  width: 160px;
  height: 40px;
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 32px;
  border-top: 0;
  border-bottom: 1px solid #ccc;
  border-right: 0;
  border-left: 0;
  font-size: ${FONTSIZE.SS}px;
  background-color: ${COLOR.LIGHTGRAY};
  background-image: url(${img});
  background-repeat: no-repeat;
  background-position: top 50% right 10px;
`

class Search extends React.Component {
  render() {
    return (
      <form
        onChange = { this.props.onChange }
        onSubmit = { this.props.onSubmit }
        className = { this.props.className }
      >
        <StyledSearch
          name = { this.props.name }
          type = 'text'
          placeholder = '検索'
          value = { this.props.searchQuery }
          onChange = { this.props.changeSearchQuery }
        />
      </form>

    )
  }
}

export default Search;
