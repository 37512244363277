export default {
  ORANGE:      '#f79572',
  LIGHTORANGE: '#fff8da',
  BLUE:        '#4ba8eb',
  LIGHTBLUE:   '#e4f2fc',
  PURPLE:      '#9e7cde',
  GREEN:       '#39bf93',
  RED:         '#ed8282',
  LIGHTRED:    '#fff0eb',
  YELLOW:      '#d9b938',
  LIGHTYELLOW: '#ffeeaa',
  BLACK:       '#595959',
  GRAY:        '#ededed',
  DARKGRAY:    '#808080',
  LIGHTGRAY:   '#f5f5f5',
  WHITE:       '#ffffff',
}
