import styled from 'styled-components'

const StyledTableHeader = styled.div`
  box-shadow: inset 0 3px 0 0 #f5f5f5;
  display: flex;
  align-items: stretch;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
`

export default StyledTableHeader;
