import styled from 'styled-components'

import FONTSIZE from '../_const/FONTSIZE'

const getColors = (color) => {
  switch (color) {
    case 'gray': return `
      color: #b2b2b2;
    `;
    default: return ``
  }
}

const HeadingS = styled.div`
  margin-bottom: ${props => props.noMargin ? 0 : '24px'};
  font-size: ${FONTSIZE.S}px;
  font-weight: 600;
  ${ props => getColors(props.color) };
`;

export default HeadingS;
