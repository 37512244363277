import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import Button from '../atoms/Button';
import Radio from '../atoms/Radio';
import FlexBox from '../atoms/FlexBox';
import HeadingS from '../atoms/HeadingS';
import InputText from '../atoms/InputText';
import ModalL from '../molecules/ModalL';
import SelectUsers from '../organisms/SelectUsers';

import COLOR from '../_const/COLOR';

const StyledModalBody = styled.div`
  min-height: 100%;
  width: 700px;
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
`

const StyledButton = styled(Button)`
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

const StyledNameErrorMessage = styled.div`
  color: ${COLOR.RED};
`

class ModalModifyFav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedUsers: [],
      data: [],
      nameErrorMessage: '',
      param: {
        id: 0,
        name: '',
        url: '',
        shareSetting: 0,
        userIds: [],
      },
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    // モーダルが開かれたとき
    if (
      prevProps.isOpened !== this.props.isOpened
      && this.props.isOpened === true
    ) {
      // 選択済みの項目をセットする。
      const checkedUsers = this.props.modifyParams ? this.props.modifyParams.userIds : [];
      this.setState({
        checkedUsers: checkedUsers,
        data: this.props.data,
        param: {
          ...this.props.modifyParams
        }
      });
    }
  }

  render() {
    const submit = async () => {
      // 同じ名前での登録がないことを確認する
      const checkSameName = this.state.data.map(x => {
        if (x.id === this.state.param.id) {
          return false
        } else {
          return x.name === this.state.param.name
        }
      }).some(y => y);

      if (checkSameName) {
        this.setState({
          nameErrorMessage: '※名前が重複しています'
        })
      } else {
        this.setState({
          nameErrorMessage: ''
        })
        const checkedUsers = this.state.checkedUsers;
        await this.setState({
          param: {
            ...this.state.param,
            checkedUsers: checkedUsers
          }
        })
        // お気に入り登録処理（API実行）
        await this.props.setFavorite(this.state.param);
        this.props.closeFavModal();
      }
    }

    // 公開範囲のラジオボタンを制御
    const switchShareSetting = async (e) => {
      const id = parseInt(e.target.value);

      this.setState({
        checkedUsers: (id === 4) ? this.state.checkedUsers : [],
        param: {
          ...this.state.param,
          shareSetting: id,
        }
      });
    }

    // ユーザーの各要素をチェック
    const checkUser = (e) => {
      const id = e.target.value;
      const { checkedUsers } = this.state;

      if (checkedUsers.indexOf(id) > -1) {
        checkedUsers.splice(checkedUsers.indexOf(id), 1)
      } else {
        checkedUsers.push(id);
      }
      this.setState({
        checkedUsers: checkedUsers,
      });
    }

    // ユーザーのチェックボックスをすべて選択
    const toggleCheckUsersAll = (checkedFiltered, bool) => {
      const { checkedUsers } = this.state;

      if (bool) {
        this.setState({
          checkedUsers: _.union(
            checkedUsers,
            checkedFiltered
          )
        });
      } else {
        this.setState({
          checkedUsers: _.without(
            checkedUsers,
            ...checkedFiltered
          )
        });
      }
    }

    // ユーザーのチェックボックスをすべてクリア
    const clearCheckUsersAll = () => {
      this.setState({
        checkedUsers: []
      })
    }

    return (
      <ModalL
        heading = { this.props.heading }
        isOpened = { this.props.isOpened }
        close = { () => {
          this.props.closeFavModal();
        } }
      >
        <StyledModalBody>
          <HeadingS>{ this.props.bodyText }</HeadingS>
          <FlexBox>
            <HeadingS>名称</HeadingS>
            <StyledNameErrorMessage
              className = "m-l-16 m-b-24"
            >{ this.state.nameErrorMessage }
            </StyledNameErrorMessage>
          </FlexBox>
          <InputText
            width = { 636 }
            value = { this.state.param.name }
            className = "m-b-32"
            onChange = {
              ((e) => {
                this.setState({
                  param: {
                    ...this.state.param,
                    name: e.currentTarget.value
                  }
                });
              })
            }
          />
          <HeadingS>公開範囲</HeadingS>
          <FlexBox>
            {
              (() => {
                const items = [
                  '自身',
                  'グループ',
                  '部署',
                  '全社',
                  '特定のユーザー',
                ]
                return items.map((o, i) => {
                  return (
                    <Radio
                      className = 'm-r-24'
                      key = { _.uniqueId() }
                      name = 'share-setting'
                      id = { `share-setting-${i}` }
                      value = { String(i) }
                      checked = { this.state.param.shareSetting === i ? 'checked' : '' }
                      onChange = { switchShareSetting }
                    >
                      { o }
                    </Radio>
                  )
                });
              })()
            }
          </FlexBox>
          {
            (() => {
              // 特定のユーザーを選択した場合はユーザー一覧から対象を選択するためのUIを出す。
              if (this.state.param.shareSetting === 4) {
                return (
                  <SelectUsers
                    users = { this.props.users }
                    checked = { this.state.checkedUsers }
                    check = { checkUser }
                    toggleCheckAll = { toggleCheckUsersAll }
                    clearCheckAll = { clearCheckUsersAll }
                    className = 'm-t-24'
                  />
                )
              }
            })()
          }
          <FlexBox
            justify = "space-between"
          >
            <StyledButton
              type = "button"
              width = "calc(50% - 2px)"
              justify = "center"
              className = 'm-t-24'
              onClick = { () => {
                this.props.closeFavModal();
              } }
            >キャンセル
            </StyledButton>
            <StyledButton
              type = "submit"
              width = "calc(50% - 2px)"
              justify = "center"
              className = 'm-t-24'
              color = 'orange'
              disabled = { !this.state.param.name }
              onClick = { submit }
            >保存する
            </StyledButton>
          </FlexBox>
        </StyledModalBody>
      </ModalL>
    )
  }
};

export default ModalModifyFav;
