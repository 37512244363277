import React from 'react';
import styled from 'styled-components';

import Modal from '../molecules/Modal';
import Formula from '../organisms/Formula';

const StyledModalBody = styled.div`
  width: 600px;
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
`
class ModalAddItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestParam: {
        budget:         0, // 設定予算
        alertSettingId: 0, // 設定したアラート情報ID	アラート情報を設定するなら該当するアラート情報のIDをセット。設定しない場合は0
      },
    };
  }
  render() {
    return (
      <Modal
        heading = { this.props.heading }
        isOpenedSideMenu = { this.props.isOpenedSideMenu }
        isOpened = { this.props.isOpened }
        close = { this.props.close }
      >
        <StyledModalBody>
          <Formula
            isOpened = { this.props.isOpened }
            close = { this.props.close }
            items = { this.props.selectableDispItems }
            getReportAddItem = { this.props.getReportAddItem }
            addItemParam = { this.props.addItemParam }
          />
        </StyledModalBody>
      </Modal>
    )
  }
};

export default ModalAddItem;
