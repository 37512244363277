import React from 'react';
import styled from 'styled-components';

const IconArrowSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0, 0, 4, 5" className={props.className}>
    <path fillRule="evenodd" fill="#666" d="M3.986 2.501l.003.002-.016.011-.58.606-.122-.09L.607 4.988l-.596-.62 2.54-1.867L.011.634.607.011l2.664 1.96.122-.09.58.606.016.011-.003.003z"/>
  </svg>
)
const IconArrow = styled(IconArrowSvg) `
  display: block;
`

export default IconArrow;
