/**
 * Dealing user information using local storage.
 */
const User = {
  id: null,
  name: '',
  tableId: null,
  sectionName: '',
  sectionId: 0,
  sectionIds: [],
  orderBoxAuth: 1,
  code: null,

  /**
   * get user info from local storage.
   */
  featch: function() {
    return JSON.parse(window.localStorage.getItem('user')) || this
  },
  /**
   * get param that need to use api.
   */
  apiParams: function() {
    const data = this.featch()
    return ({
      userId: data.id,
      rcode: data.code,
    })
  },
  /**
   * save user info on local storage.
   */
  save: function(self) {
    const data = {
      id: self.id || this.id,
      name: self.name || this.name,
      tableId: self.tableId || this.tableId,
      sectionName: self.sectionName || this.sectionName,
      sectionId: self.sectionId || this.sectionId,
      sectionIds: self.sectionIds || this.sectionIds,
      orderBoxAuth: self.orderBoxAuth || this.orderBoxAuth,
      code: self.code || this.code,
    }
    window.localStorage.setItem('user', JSON.stringify(data))
  },
  /**
   * remove user info from local storage.
   */
  detach: function() {
    window.localStorage.removeItem('user')
    this.id = null
  }
}

export default User