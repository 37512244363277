import React from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import onClickOutside from 'react-onclickoutside';
import _ from 'lodash';
import classnames from 'classnames';

import PullDownListItem from '../atoms/PullDownListItem';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';
import PullDownHasBorder from '../molecules/PullDownHasBorder';

const StyledPullDownWrap = styled.div`
  width: ${ props => (props.width) ? props.width : 'auto' }
  min-width: 100px;
  position: relative;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  width: ${ props => props.listWidth ? props.listWidth : "240px" };
  position: absolute;
  top: 40px;
  left: ${ props => props.listLeft ? props.listLeft : "0" };
  z-index: 20;
`
const StyledPullDownListWrapIn = styled(PullDownListWrapIn)`
  height: ${ props => props.innerHeight ? `${props.innerHeight}px` : 'auto' }
  max-height: 240px;
`
const StyledPullDownListWrapIn2 = styled(PullDownListWrapIn)`
  padding-top: 24px;
  padding-bottom: 24px;
`

class PullDownCommon extends React.Component {
  constructor(props) {
    super(props);
    this.list = React.createRef();
    this.state = {
      innerHeight: 0,
      isOpenedPullDown: false,
    };
  }
  componentDidMount() {
    // インラインスクロールを持つドロップリストの縦幅を可変にするために内部の高さを取得する
    process.nextTick(() => {
      this.setState({
          innerHeight: this.list.current.clientHeight
      })
    })
  }
  componentDidUpdate = async (prevProps, prevState) => {
    // インラインスクロールを持つドロップリストの縦幅を可変にするために内部の高さを取得する
    if (prevProps.items !== this.props.items) {
      this.setState({
        innerHeight: this.list.current.clientHeight
      });
    }
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown,
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false,
    });
  }
  render() {
    // 要素を選択
    const selectItem = (item) => {
      this.props.onChange(item.id);
      this.closePullDown();
    }

    return (
      <StyledPullDownWrap
        className = { this.props.className }
        width = { (this.props.width) ? this.props.width : 'auto' }
      >
        <PullDownHasBorder
          width = { (this.props.width) ? this.props.width : 'auto' }
          onClick = {this.togglePullDown}
        >
          <strong>
            {
              (() => {
                const item = _.find(this.props.items, o => {
                  return o.id === this.props.id
                });
                return (item) ? item.label : this.props.placeholder
              })()
            }
          </strong>
        </PullDownHasBorder>
        <StyledPullDownListWrap
          listWidth = { this.props.listWidth }
          listLeft = { this.props.listLeft }
          className = {
            this.state.isOpenedPullDown === true
              ? `is-opened`
              : `is-closed`
          }
        >
          <StyledPullDownListWrapIn
            innerHeight = { this.state.innerHeight }
          >
            <Scrollbars>
              <StyledPullDownListWrapIn2
                ref = { this.list }
              >
                {
                  (() => {
                    const disableIds = (this.props.disableIds) ? this.props.disableIds : [];
                    return this.props.items.map((item) => {
                      return (
                        <PullDownListItem
                          key = { _.uniqueId() }
                          onClick = { (() => {
                            selectItem(item)
                          }) }
                          className = {
                            classnames({
                              'is-current': this.props.id === item.id,
                              'is-disabled': disableIds.indexOf(item.id) > -1
                            })
                          }
                        >
                          { item.label }
                        </PullDownListItem>
                      )
                    });
                  })()
                }
              </StyledPullDownListWrapIn2>
            </Scrollbars>
          </StyledPullDownListWrapIn>
        </StyledPullDownListWrap>
      </StyledPullDownWrap>
    )
  }
}
export default onClickOutside(PullDownCommon);
