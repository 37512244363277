// 媒体のログイン画面URL
const MediaLink = (id) => {
  switch (id) {
    case  1: return 'https://accounts.google.com/signin/v2/identifier?service=adwords&passive=1209600&continue=https%3A%2F%2Fads.google.com%2Fnav%2Flogin%3Fsubid%3DALL-ja-et-g-aw-a-home-awhp_xin1_signin!o2_069varAAAA&followup=https%3A%2F%2Fads.google.com%2Fnav%2Flogin%3Fsubid%3DALL-ja-et-g-aw-a-home-awhp_xin1_signin!o2_069varAAAA&flowName=GlifWebSignIn&flowEntry=ServiceLogin'; // Google Ads
    case  2: return 'https://login.bizmanager.yahoo.co.jp/login?url=https%3A%2F%2Fbusiness.yahoo.co.jp%2F'; // Yahoo!SS
    case  3: return 'https://login.bizmanager.yahoo.co.jp/login?url=https%3A%2F%2Fbusiness.yahoo.co.jp%2F'; // YDN
    case  4: return 'https://remora.xlisting.jp/agency/login/'; // Xリスティング
    case  5: return 'https://accounts.google.com/signin/v2/identifier?hl=ja&passive=true&continue=http%3A%2F%2Fsupport.google.com%2Fdisplayvideo%2Fanswer%2F9059464%3Fhl%3Dja&flowName=GlifWebSignIn&flowEntry=ServiceLogin'; // Display&Video360
    case  6: return '#'; // D2C
    case  7: return 'https://account.criteo.com/auth/XUI/criteo-login/#/login?realm=criteo&goto=https%253A%252F%252Faccount.criteo.com%252Fauth%252Foauth2%252Fauthorize%253Fclient_id%253Dcpp%2526realm%253Dcriteo%2526response_type%253Dcode%2526redirect_uri%253Dhttps%25253A%25252F%25252Fmarketing.criteo.com%25252Fauth%2526state%253Dhttps%25253A%25252F%25252Fmarketing.criteo.com%25252FHome'; // Criteo
    case  8: return '#'; // YCD
    case  9: return 'https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2Fads%2Fcreate%2F'; // Facebook
    case 10: return 'https://twitter.com/login?redirect_after_login=https://business.twitter.com/ja/advertising.html'; // Twitter
    case 11: return 'https://secure.indeed.com/account/login?hl=ja_US&co=US&continue=https%3A%2F%2Fwww.indeed.com%2Fhire%3Fhl%3Dja%26cc%3DUS&tmpl=desktop&service=&from=gnav-util-employer--tophat--employer&_ga=2.102405703.711747659.1584349107-95135852.1584349107'; // Indeed
    case 12: return 'https://www.linebiz.com/jp/login/'; // LINE
    default: return '#';
  }
}

export default MediaLink;
