import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'
import FONTSIZE from '../_const/FONTSIZE'

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: ${FONTSIZE.S}px;
  font-weight: 600;
  &:before {
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    content: '';
    display: block;
    margin-top: -2px;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid #cdcdcd;
    background-color: ${COLOR.LIGHTGRAY};
  }
  input:checked + & {
    &:before {
      border-width: 5px;
      border-color: ${COLOR.GREEN};
      background-color: ${COLOR.WHITE};
    }
  }
`
const StyledInput = styled.input`
  display: none;
`

const Radio = (props) => (
  <div className={props.className}>
    <StyledInput
      type = "radio"
      id = {props.id}
      name = {props.name}
      value = {props.value}
      checked = {props.checked}
      onChange = {props.onChange}
    />
    <StyledLabel htmlFor={props.id}>
      { props.children }
    </StyledLabel>
  </div>
);

export default Radio;
