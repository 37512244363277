import React from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import onClickOutside from 'react-onclickoutside';
import _ from 'lodash';

import PullDownLabel from '../atoms/PullDownLabel';
import PullDownListItem from '../atoms/PullDownListItem';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';
import PullDownHasBorder from '../molecules/PullDownHasBorder';

const StyledPullDownGraphSplit = styled.div`
  position: relative;
`

const StyledPullDownListWrap = styled(PullDownListWrap)`
  width: calc(22em + 72px);
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 20;
`
const StyledPullDownListWrapIn = styled(PullDownListWrapIn)`
  height: 240px;
`
const StyledPullDownListWrapIn2 = styled(PullDownListWrapIn)`
  padding-top: 24px;
  padding-bottom: 24px;
`

class PullDownAlertRuleIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenedPullDown: false,
    };
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown,
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false,
    });
  }
  render() {
    const switchSplit = (item) => {
      this.props.setTargetColId(item.id);
      this.closePullDown();
    }

    return (
      <StyledPullDownGraphSplit className={this.props.className}>
        <PullDownHasBorder
          width = "calc(23px + 16em)"
          onClick = {this.togglePullDown}
        >
          <PullDownLabel>
            {
              (() => {
                const item = _.find(this.props.items, o => {
                  return o.id === this.props.id
                });
                return (item) ? item.label : 'この指標'
              })()
            }
          </PullDownLabel>
        </PullDownHasBorder>
        <StyledPullDownListWrap
          className = {
            this.state.isOpenedPullDown === true
              ? `is-opened`
              : `is-closed`
          }
        >
          <StyledPullDownListWrapIn>
            <Scrollbars>
              <StyledPullDownListWrapIn2>
                {
                  (() => {
                    return this.props.items.map((item) => {
                      return (
                        <PullDownListItem
                          key = { _.uniqueId() }
                          onClick = { (() => {
                            switchSplit(item)
                          }) }
                          className = { this.props.id === item.id ? 'is-current' : '' }
                        >
                          { item.label }
                        </PullDownListItem>
                      )
                    });
                  })()
                }
              </StyledPullDownListWrapIn2>
            </Scrollbars>
          </StyledPullDownListWrapIn>
        </StyledPullDownListWrap>
      </StyledPullDownGraphSplit>
    )
  }
}
export default onClickOutside(PullDownAlertRuleIndex);
