import React from 'react';
import styled from 'styled-components';

const IconReorder = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0, 0, 8, 9" className={props.className}>
    <path fillRule="evenodd" fill="gray" d="M0 9V8h8v1H0zm0-5h8v1H0V4zm0-4h8v1H0V0z"/>
  </svg>
)
const StyledIconReorder = styled(IconReorder) `
  display: block;
`

export default StyledIconReorder;
