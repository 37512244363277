import React from 'react';
import styled from 'styled-components'

import ButtonSmall from './ButtonSmall';
import IconMinus from '../atoms/IconMinus';
import IconPlus from '../atoms/IconPlus';

const StyledIconMinus = styled(IconMinus)`
  display: none;
  &.is-active {
    display: block;
  }
`
const StyledIconPlus = styled(IconPlus)`
  display: block;
  &.is-active {
    display: none;
  }
`

const ButtonToggle = props => (
  <ButtonSmall
    circle
    as = { props.tag }
    size = { props.size }
    className = { props.className }
  >
    <StyledIconPlus
      className = { props.isActive ? "is-active" : "" }
    />
    <StyledIconMinus
      className = { props.isActive ? "is-active" : "" }
    />
  </ButtonSmall>
);

const StyledButtonToggle = styled(ButtonToggle)`
  cursor: pointer;
`;

export default StyledButtonToggle;
