// レポートデータの表示項目ごとの表記生成パターンを定義したオブジェクト
// https://docs.google.com/spreadsheets/d/14QZUNTpCkSHi3UzrGsG6-yF0hYM96IVvgm4yLDtC03E/
//
// 0: 数値
// 1: 小数
// 2: 通貨（￥）
// 3: 文字列
// 4: 配列（文字列の配列）
// 5: 小数・割合（%）ゲージなし
// 6: 小数・割合（%）ゲージあり
// 7: 小数（小数点以下4位まで表示）
// 8: 媒体バッヂ
// 9: 特殊文字列

export default {
  id: 0,
  division: 3,
  multiCv: 4,
  thirdPartyCv: 4,
  alertInfo: 4,
  clientName: 3,
  accountName: 3,
  campaignName: 3,
  adgroupName: 3,
  keywordName: 3,
  userName: 4,
  clientId: 0,
  accountId: 0,
  campaignId: 0,
  adgroupId: 0,
  keywordId: 0,
  media: 8,
  mediaId: 3,
  online: 0,
  kanriAccountId: 3,
  kanriPassword: 3,
  budget: 2,
  mediaBudget: 2,
  mediaBudgetStatus: 0,
  remainDailyBudget: 2,
  remainDailyMediaBudget: 2,
  label: 4,
  imp: 0,
  impCompare: 0,
  click: 0,
  clickCompare: 0,
  cv: 1,
  cvCompare: 1,
  cost: 2,
  costCompare: 2,
  cost_with_fee: 2,
  cost_with_feeCompare: 2,
  impDays: 0,
  lastImpDay: 3,
  impressionShare: 5,
  impressionShareLostBudget: 5,
  impressionShareLostRank: 5,
  impressionShareExactMatch: 5,
  qualityScore: 0,
  cpc: 2,
  cpcCompare: 2,
  cpc_with_fee: 2,
  cpc_with_feeCompare: 2,
  ctr: 5,
  ctrCompare: 5,
  cvr: 5,
  cvrCompare: 5,
  cpa: 2,
  cpaCompare: 2,
  cpa_with_fee: 2,
  cpa_with_feeCompare: 2,
  remainDays: 0,
  estimateCpa: 2,
  estimateCpa_with_fee: 2,
  estimateCv: 0,
  estimateCost: 2,
  estimateCost_with_fee: 2,
  customerUnitPrice: 2,
  costEstimateDividedByMediaBudget: 6,
  costEstimateDividedByMediaBudget_with_fee: 6,
  costEstimateDividedByBudget: 6,
  costEstimateDividedByBudget_with_fee: 6,
  avgPosition: 6,
  bugetDigestibility: 6,
  bugetDigestibility_with_fee: 6,
  mediaBugetDigestibility: 6,
  mediaBugetDigestibility_with_fee: 6,
  addItem27: 9,
  addItem28: 9,
  addItem29: 9,
  addItem30: 9,
  addItem31: 9,
  mediaFee: 9,
  targetCpa: 2,
  targetRoas: 5,
  targetMr: 5,
  costOfSales: 9,
  targetCv: 0,
  remainCv: 0,
  roas: 5,
  roasCompare: 5,
  roi: 5,
  roiCompare: 5,
  roas_with_fee: 5,
  roas_with_feeCompare: 5,
  roi_with_fee: 5,
  roi_with_feeCompare: 5,
  cpaSeparate: 2,
  cpaSeparate_with_fee: 2,
  ctr_kakeru_cvr: 0,
  revenue: 2,
  revenueCompare: 2,
  revenueNotTax: 2,
  revenueNotTaxCompare: 2,
  dayPerEndOfMonth: 2,
  dayPerEndOfMonth_with_fee: 2,
  budget_with_fee: 2,
  mediaBudget_with_fee: 2,
  remainDailyBudget_with_fee: 2,
  remainDailyMediaBudget_with_fee: 2,
  mediaCv: 1,
  mediaCvr: 5,
  mediaCpa: 2,
  mediaCpa_with_fee: 2,
  thirdPartyCvVal: 1,
  thirdPartyCvr: 5,
  thirdPartyCpa: 2,
  thirdPartyCpa_with_fee: 2,
  cv1Label: 3,
  cv1: 9,
  cv1Cvr: 5,
  cv1Cpa: 0,
  cv1Cpa_with_fee: 0,
  revenue1Label: 3,
  revenue1: 0,
  revenue1Cvr: 5,
  revenue1Cpa: 0,
  revenue1Cpa_with_fee: 0,
  assist1Label: 3,
  assist1: 0,
  assist1Cvr: 5,
  assist1Cpa: 0,
  assist1Cpa_with_fee: 0,
  yobi1Label: 3,
  yobi1: 0,
  yobi1Cvr: 5,
  yobi1Cpa: 0,
  yobi1Cpa_with_fee: 0,
  cv2Label: 3,
  cv2: 9,
  cv2Cvr: 5,
  cv2Cpa: 0,
  cv2Cpa_with_fee: 0,
  revenue2Label: 3,
  revenue2: 0,
  revenue2Cvr: 5,
  revenue2Cpa: 0,
  revenue2Cpa_with_fee: 0,
  assist2Label: 3,
  assist2: 0,
  assist2Cvr: 5,
  assist2Cpa: 0,
  assist2Cpa_with_fee: 0,
  yobi2Label: 3,
  yobi2: 0,
  yobi2Cvr: 5,
  yobi2Cpa: 0,
  yobi2Cpa_with_fee: 0,
  cv3Label: 3,
  cv3: 9,
  cv3Cvr: 5,
  cv3Cpa: 0,
  cv3Cpa_with_fee: 0,
  revenue3Label: 3,
  revenue3: 0,
  revenue3Cvr: 5,
  revenue3Cpa: 0,
  revenue3Cpa_with_fee: 0,
  assist3Label: 3,
  assist3: 0,
  assist3Cvr: 5,
  assist3Cpa: 0,
  assist3Cpa_with_fee: 0,
  yobi3Label: 3,
  yobi3: 0,
  yobi3Cvr: 5,
  yobi3Cpa: 0,
  yobi3Cpa_with_fee: 0,
  cv4Label: 3,
  cv4: 9,
  cv4Cvr: 5,
  cv4Cpa: 0,
  cv4Cpa_with_fee: 0,
  revenue4Label: 3,
  revenue4: 0,
  revenue4Cvr: 5,
  revenue4Cpa: 0,
  revenue4Cpa_with_fee: 0,
  assist4Label: 3,
  assist4: 0,
  assist4Cvr: 5,
  assist4Cpa: 0,
  assist4Cpa_with_fee: 0,
  yobi4Label: 3,
  yobi4: 0,
  yobi4Cvr: 5,
  yobi4Cpa: 0,
  yobi4Cpa_with_fee: 0,
  cv5Label: 3,
  cv5: 9,
  cv5Cvr: 5,
  cv5Cpa: 0,
  cv5Cpa_with_fee: 0,
  revenue5Label: 3,
  revenue5: 0,
  revenue5Cvr: 5,
  revenue5Cpa: 0,
  revenue5Cpa_with_fee: 0,
  assist5Label: 3,
  assist5: 0,
  assist5Cvr: 5,
  assist5Cpa: 0,
  assist5Cpa_with_fee: 0,
  yobi5Label: 3,
  yobi5: 0,
  yobi5Cvr: 5,
  yobi5Cpa: 0,
  yobi5Cpa_with_fee: 0,
  mediaCv1Label: 3,
  mediaCv1: 9,
  mediaCv1Cvr: 5,
  mediaCv1Cpa: 0,
  mediaCv1Cpa_with_fee: 0,
  mediaCv2Label: 3,
  mediaCv2: 9,
  mediaCv2Cvr: 5,
  mediaCv2Cpa: 0,
  mediaCv2Cpa_with_fee: 0,
  mediaCv3Label: 3,
  mediaCv3: 9,
  mediaCv3Cvr: 5,
  mediaCv3Cpa: 0,
  mediaCv3Cpa_with_fee: 0,
  mediaCv4Label: 3,
  mediaCv4: 9,
  mediaCv4Cvr: 5,
  mediaCv4Cpa: 0,
  mediaCv4Cpa_with_fee: 0,
  mediaCv5Label: 3,
  mediaCv5: 9,
  mediaCv5Cvr: 5,
  mediaCv5Cpa: 0,
  mediaCv5Cpa_with_fee: 0,
  mediaCv6Label: 3,
  mediaCv6: 9,
  mediaCv6Cvr: 5,
  mediaCv6Cpa: 0,
  mediaCv6Cpa_with_fee: 0,
  mediaCv7Label: 3,
  mediaCv7: 9,
  mediaCv7Cvr: 5,
  mediaCv7Cpa: 0,
  mediaCv7Cpa_with_fee: 0,
  mediaCv8Label: 3,
  mediaCv8: 9,
  mediaCv8Cvr: 5,
  mediaCv8Cpa: 0,
  mediaCv8Cpa_with_fee: 0,
  mediaCv9Label: 3,
  mediaCv9: 9,
  mediaCv9Cvr: 5,
  mediaCv9Cpa: 0,
  mediaCv9Cpa_with_fee: 0,
  mediaCv10Label: 3,
  mediaCv10: 9,
  mediaCv10Cvr: 5,
  mediaCv10Cpa: 0,
  mediaCv10Cpa_with_fee: 0,
  mediaCv11Label: 3,
  mediaCv11: 9,
  mediaCv11Cvr: 5,
  mediaCv11Cpa: 0,
  mediaCv11Cpa_with_fee: 0,
  mediaCv12Label: 3,
  mediaCv12: 9,
  mediaCv12Cvr: 5,
  mediaCv12Cpa: 0,
  mediaCv12Cpa_with_fee: 0,
  mediaCv13Label: 3,
  mediaCv13: 9,
  mediaCv13Cvr: 5,
  mediaCv13Cpa: 0,
  mediaCv13Cpa_with_fee: 0,
  mediaCv14Label: 3,
  mediaCv14: 9,
  mediaCv14Cvr: 5,
  mediaCv14Cpa: 0,
  mediaCv14Cpa_with_fee: 0,
  mediaCv15Label: 3,
  mediaCv15: 9,
  mediaCv15Cvr: 5,
  mediaCv15Cpa: 0,
  mediaCv15Cpa_with_fee: 0,
  mediaCv16Label: 3,
  mediaCv16: 9,
  mediaCv16Cvr: 5,
  mediaCv16Cpa: 0,
  mediaCv16Cpa_with_fee: 0,
  mediaCv17Label: 3,
  mediaCv17: 9,
  mediaCv17Cvr: 5,
  mediaCv17Cpa: 0,
  mediaCv17Cpa_with_fee: 0,
  mediaCv18Label: 3,
  mediaCv18: 9,
  mediaCv18Cvr: 5,
  mediaCv18Cpa: 0,
  mediaCv18Cpa_with_fee: 0,
  mediaCv19Label: 3,
  mediaCv19: 9,
  mediaCv19Cvr: 5,
  mediaCv19Cpa: 0,
  mediaCv19Cpa_with_fee: 0,
  mediaCv20Label: 3,
  mediaCv20: 9,
  mediaCv20Cvr: 5,
  mediaCv20Cpa: 0,
  mediaCv20Cpa_with_fee: 0,
  budget_label: 2,
  budget_with_fee_label: 2,
  bugetDigestibility_label: 6,
  bugetDigestibility_with_fee_label: 6,
  costEstimateDividedByBudget_label: 6,
  costEstimateDividedByBudget_with_fee_label: 6,
  allCv: 1,
  allCvCompare: 1,
  allCvr: 5,
  allCvrCompare: 5,
  allCpa: 2,
  allCpaCompare: 2,
  allCpa_with_fee: 2,
  allCpa_with_feeCompare: 2,
  allRevenue: 2,
  allRevenueCompare: 2,
  vtCv: 1,
  vtCvCompare: 1,
  cv1Roas: 5,
  cv1Roas_with_fee: 5,
  revenue1Roas: 5,
  revenue1Roas_with_fee: 5,
  assist1Roas: 5,
  assist1Roas_with_fee: 5,
  yobi1Roas: 5,
  yobi1Roas_with_fee: 5,
  cv2Roas: 5,
  cv2Roas_with_fee: 5,
  revenue2Roas: 5,
  revenue2Roas_with_fee: 5,
  assist2Roas: 5,
  assist2Roas_with_fee: 5,
  yobi2Roas: 5,
  yobi2Roas_with_fee: 5,
  cv3Roas: 5,
  cv3Roas_with_fee: 5,
  revenue3Roas: 5,
  revenue3Roas_with_fee: 5,
  assist3Roas: 5,
  assist3Roas_with_fee: 5,
  yobi3Roas: 5,
  yobi3Roas_with_fee: 5,
  cv4Roas: 5,
  cv4Roas_with_fee: 5,
  revenue4Roas: 5,
  revenue4Roas_with_fee: 5,
  assist4Roas: 5,
  assist4Roas_with_fee: 5,
  yobi4Roas: 5,
  yobi4Roas_with_fee: 5,
  cv5Roas: 5,
  cv5Roas_with_fee: 5,
  revenue5Roas: 5,
  revenue5Roas_with_fee: 5,
  assist5Roas: 5,
  assist5Roas_with_fee: 5,
  yobi5Roas: 5,
  yobi5Roas_with_fee: 5,
  labelList: 3,
  
};
