import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import COLOR from '../_const/COLOR'
import DISPITEM_TYPE from '../_const/DISPITEM_TYPE';
import FONTSIZE from '../_const/FONTSIZE'

import arrangeReportDataValue from '../_util/arrangeReportDataValue';

const StyledGraphTooltip = styled.div`
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: ${ props => props.top };
  bottom: ${ props => props.bottom };
  opacity: 0;
  pointer-events: none;
  padding: 16px;
  border-radius: 3px;
  background-color: #fff;
  transform: translate(10px, -10px);
  transition-duration: 0.2s;
  transition-property: opacity, transform;
  &.is-shown {
    opacity: 1;
    transform: translate(0, 0);
  }
  &.is-only {
    left: 50%;
  }
  &.is-first {
    left: 0;
  }
  &.is-first-half {
    left: 50%;
  }
  &.is-latter-half {
    right: 50%;
  }
  &.is-last {
    right: 0;
  }
`
const StyledHeading = styled.div`
  line-height: 1;
  white-space: nowrap;
  margin-bottom: 8px;
  font-weight: 600;
`
const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${FONTSIZE.SS}px;
  &.is-hidden {
    display: none;
  }
`
const StyledItemLine = styled.div`
  width: 8px;
  height: 0;
  margin-right: 8px;
  border-top-width: 2px;
  border-top-style: ${ props => props.isComparing === true ? 'dotted' : 'solid' };
  border-top-color: ${ props => props.summaryNo === 1 ? COLOR.ORANGE : COLOR.BLUE };
`
const StyledItemLabel = styled.div`
  white-space: nowrap;
  margin-right: 16px;
  color: #b2b2b2;
`
const StyledItemValue = styled.div`
  white-space: nowrap;
  margin-left: auto;
`

class GraphTooltip extends React.Component {
  render() {
    const {
      className,
      summary1,
      summary2,
      date,
      dateCompare,
      data1,
      data1Compare,
      data2,
      data2Compare,
      max1,
      max2,
      isEnabledCompare
    } = this.props;

    const allData = _.filter(
      [
        (max1 === 0) ? 0 : (data1 !== undefined) ? data1 / max1 : null,
        (max1 === 0) ? 0 : (data1Compare !== undefined) ? data1Compare / max1 : null,
        (max2 === 0) ? 0 : (data2 !== undefined) ? data2 / max2 : null,
        (max2 === 0) ? 0 : (data2Compare !== undefined) ? data2Compare / max2 : null
      ],
      d => d !== null
    );
    const average = (allData.length > 1)
      ? allData.reduce((prev, current) => {
        return prev + current
      }) / allData.length
      : 0;
    let top = 'auto';
    let bottom = 'auto';

    if (average >= 0.5) {
      top = `calc(${(1 - average) * 100}% + 5px)`;
    } else {
      bottom = `calc(${average * 100}% + 5px)`;
    }

    if (isEnabledCompare === false) {
      return (
        <StyledGraphTooltip
          top = { top }
          bottom = { bottom }
          className = { className }
        >
          <StyledHeading>
            { date }
          </StyledHeading>
          {
            (() => {
              if (data1 !== undefined) {
                return (
                  <StyledItem>
                    <StyledItemLine
                      summaryNo = { 1 }
                    />
                    <StyledItemLabel>
                      { summary1.label }
                    </StyledItemLabel>
                    <StyledItemValue>
                      { arrangeReportDataValue(data1, DISPITEM_TYPE[summary1.id]) }
                    </StyledItemValue>
                  </StyledItem>
                )
              }
            })()
          }
          {
            (() => {
              if (data2 !== undefined) {
                return (
                  <StyledItem>
                    <StyledItemLine
                      summaryNo = { 2 }
                    />
                    <StyledItemLabel>
                      { summary2.label }
                    </StyledItemLabel>
                    <StyledItemValue>
                      { arrangeReportDataValue(data2, DISPITEM_TYPE[summary2.id]) }
                    </StyledItemValue>
                  </StyledItem>
                )
              }
            })()
          }
        </StyledGraphTooltip>
      )
    } else {
      return (
        <StyledGraphTooltip
          top = { top }
          bottom = { bottom }
          className = { className }
        >
          <StyledHeading>
            { summary1.label }
          </StyledHeading>
          {
            (() => {
              if (data1 !== undefined) {
                return (
                  <StyledItem>
                    <StyledItemLine
                      summaryNo = { 1 }
                      isComparing = { false }
                    />
                    <StyledItemLabel>
                      { date }
                    </StyledItemLabel>
                    <StyledItemValue>
                      { arrangeReportDataValue(data1, DISPITEM_TYPE[summary1.id]) }
                    </StyledItemValue>
                  </StyledItem>
                )
              }
            })()
          }
          {
            (() => {
              if (data1Compare !== undefined) {
                return (
                  <StyledItem>
                    <StyledItemLine
                      summaryNo = { 1 }
                      isComparing = { true }
                    />
                    <StyledItemLabel>
                      { dateCompare }
                    </StyledItemLabel>
                    <StyledItemValue>
                      { arrangeReportDataValue(data1Compare, DISPITEM_TYPE[summary1.id]) }
                    </StyledItemValue>
                  </StyledItem>
                )
              }
            })()
          }
          <StyledHeading
            className = 'm-t-16'
          >
            { summary2.label }
          </StyledHeading>
          {
            (() => {
              if (data2 !== undefined) {
                return (
                  <StyledItem>
                    <StyledItemLine
                      summaryNo = { 2 }
                      isComparing = { false }
                    />
                    <StyledItemLabel>
                      { date }
                    </StyledItemLabel>
                    <StyledItemValue>
                      { arrangeReportDataValue(data2, DISPITEM_TYPE[summary2.id]) }
                    </StyledItemValue>
                  </StyledItem>
                )
              }
            })()
          }
          {
            (() => {
              if (data2Compare !== undefined) {
                return (
                  <StyledItem>
                    <StyledItemLine
                      summaryNo = { 2 }
                      isComparing = { true }
                    />
                    <StyledItemLabel>
                      { dateCompare }
                    </StyledItemLabel>
                    <StyledItemValue>
                      { arrangeReportDataValue(data2Compare, DISPITEM_TYPE[summary2.id]) }
                    </StyledItemValue>
                  </StyledItem>
                )
              }
            })()
          }
        </StyledGraphTooltip>
      )
    }
  }
}
export default GraphTooltip;
