import styled from 'styled-components'

import COLOR from '../_const/COLOR'
import FONTSIZE from '../_const/FONTSIZE'

const TableDataCell = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 18px;
  padding-left: 18px;
  font-size: ${FONTSIZE.S}px;
  ${
    props => {
      if (props.isFooter === true) {
        return `
          background-color: ${COLOR.LIGHTGRAY}
        `
      } else if (props.isParent === true) {
        return `
          font-weight: 700;
          background-color: ${COLOR.LIGHTORANGE}
        `
      }else {
        return `
          background-color: #fff
        `
      }
    }
  }
  &.has-checkbox {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.is-division {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.jc-c {
    justify-content: center;
  }
  &:after {
    width: 1px;
    height: 7px;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #d9d9d9;
  }
`;

export default TableDataCell;
