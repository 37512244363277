import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';

import Button from '../atoms/Button';
import FlexBox from '../atoms/FlexBox';
import HeadingS from '../atoms/HeadingS';
import IconSplit from '../atoms/IconSplit';
import Radio from '../atoms/Radio';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';
import PullDownS from '../molecules/PullDownS';

const DIVISION_ITEMS = [
  '分割なし',
  '日別',
  '週別',
  '月別',
  '曜日別',
  'デバイス別',
]

const StyledPullDownProgressSplit = styled.div`
  position: relative;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  width: 552px;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 10;
`
const StyledPullDownListWrapIn1 = styled(PullDownListWrapIn)`
  padding: 32px;
`
const StyledPullDownListWrapIn2 = styled(PullDownListWrapIn)`
  padding: 8px;
  border-top: 1px solid #e6e6e6;
`
const StyledRadio = styled(Radio)`
  min-width: 120px;
  margin-top: 8px;
`

class PullDownProgressSplit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      division: 0,
      isOpenedPullDown: false,
    };
  }
  componentDidUpdate = async (prevProps, prevState) => {
    // ポップアップを開いた際の処理
    if (
      prevState.isOpenedPullDown === false
      && this.state.isOpenedPullDown === true
    ) {
      this.setState({
        division: this.props.division
      });
    }
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false
    });
  }
  render() {
    const submit = () => {
      this.props.setDivision(this.state.division);
      this.closePullDown();
    }

    return (
      <StyledPullDownProgressSplit
        className = { this.props.className }
      >
        <PullDownS
          onClick = {this.togglePullDown}
        >
          <FlexBox>
            <IconSplit
              className = 'm-r-8'
            />
            分割
          </FlexBox>
        </PullDownS>
        <StyledPullDownListWrap
          className = {
            this.state.isOpenedPullDown === true
              ? `is-opened`
              : `is-closed`
          }
        >
          <StyledPullDownListWrapIn1>
            <HeadingS noMargin>分割</HeadingS>
            <FlexBox flexWrap>
              {
                (() => {
                  return DIVISION_ITEMS.map((o, i) => {
                    // if (i !== 6 || (this.props.target === 2 || this.props.target === 3)) {
                    //   return (
                    //     <StyledRadio
                    //       key = { _.uniqueId() }
                    //       name = 'division'
                    //       id = { `division-${i}` }
                    //       value = { i }
                    //       checked = { this.state.division === i ? 'checked' : '' }
                    //       onChange = {
                    //         ((e) => {
                    //           this.setState({
                    //             division: i
                    //           });
                    //         })
                    //       }
                    //     >
                    //       { o }
                    //     </StyledRadio>
                    //   )
                    // } else {
                    //   return false;
                    // }
                    // 外部CVの表示があるので全てのタブでコンバージョン分割を表示する
                    return (
                      <StyledRadio
                        key = { _.uniqueId() }
                        name = 'division'
                        id = { `division-${i}` }
                        value = { i }
                        checked = { this.state.division === i ? 'checked' : '' }
                        onChange = {
                          ((e) => {
                            this.setState({
                              division: i
                            });
                          })
                        }
                      >
                        { o }
                      </StyledRadio>
                    )
                  })
                })()
              }
            </FlexBox>
          </StyledPullDownListWrapIn1>
          <StyledPullDownListWrapIn2>
            <Button
              width = "100%"
              justify = "center"
              onClick = { submit }
            >
              適用
            </Button>
          </StyledPullDownListWrapIn2>
        </StyledPullDownListWrap>
      </StyledPullDownProgressSplit>
    )
  }
}

export default onClickOutside(PullDownProgressSplit);
