import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';

import FONTSIZE from '../_const/FONTSIZE'

import ButtonSmall from '../atoms/ButtonSmall';
import IconArrowDown from '../atoms/IconArrowDown';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';

import User from '../../utils/user';

const StyledUserAccount = styled.div`
  position: relative;
`
const StyledUserAccountMail = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  width: 225px;
  position: absolute;
  top: 24px;
  right: 0;
`
const StyledPullDownListWrapIn = styled(PullDownListWrapIn)`
  margin-top: 3px;
  padding: 28px 32px;
  font-size: ${FONTSIZE.S}px;
  &:first-child {
    margin-top: 0;
  }
`
const StyledLogOut = styled.div`
  cursor: pointer;
  text-decoration: none;
`

class PullDownAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenedPullDown: false,
    };
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false
    });
  }
  render() {
    const { user, history } = this.props;

    const logout = () => {
      User.detach();
      history.push('/login');
    }

    return (
      <StyledUserAccount>
        <StyledUserAccountMail
          onClick = {this.togglePullDown}
        >
          { (user) ? user.mailAddress : '' }
          <ButtonSmall className="m-l-8">
            <IconArrowDown />
          </ButtonSmall>
        </StyledUserAccountMail>
        <StyledPullDownListWrap
          className = {
            (this.state.isOpenedPullDown === true)
              ? `is-opened`
              : `is-closed`
          }
        >
          <StyledPullDownListWrapIn>
            <div>
              { (user) ? user.userName: '' }
            </div>
            <div className = 'm-t-8'>
              部署：{ (user) ? user.sectionName: '' }
            </div>
            <div className = 'm-t-8'>
              グループ：{ (user) ? user.groupName: '' }
            </div>
          </StyledPullDownListWrapIn>
          <StyledPullDownListWrapIn>
            <StyledLogOut
              onClick = { logout }
            >
              ログアウト
            </StyledLogOut>
          </StyledPullDownListWrapIn>
        </StyledPullDownListWrap>
      </StyledUserAccount>
    )
  }
}
export default withRouter(onClickOutside(PullDownAccount));
