import React from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import onClickOutside from 'react-onclickoutside';
import _ from 'lodash';
import classnames from 'classnames';

import IconExpand from '../atoms/IconExpand';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';
import PullDownHasBorder from '../molecules/PullDownHasBorder';
import HeadingS from '../atoms/HeadingS';

const StyledPullDownWrap = styled.div`
  width: ${ props => (props.width) ? props.width : 'auto' }
  min-width: 120px;
  position: relative;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  width: ${ props => props.listWidth ? props.listWidth : "240px" };
  position: absolute;
  top: 40px;
  left: ${ props => props.listLeft ? props.listLeft : "0" };
  z-index: 20;
`
const StyledPullDownListWrapIn = styled(PullDownListWrapIn)`
  height: ${ props => props.innerHeight ? `${props.innerHeight}px` : 'auto' }
  max-height: 240px;
`
const StyledPullDownListWrapIn2 = styled(PullDownListWrapIn)`
  padding: 24px;
`
const StyledMenuAndSelected = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 4px;
  padding-top: 10px;
  &.has-border {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
  }
`
const StyledMenuSection = styled.div`
  position: relative;
`
const StyledMenuHeading = styled(HeadingS)`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
`
const StyledMenuItems = styled.div`
  height: 0;
  overflow: hidden;
  &.is-active {
    height: auto;
  }
`
const StyledSubSection = styled.div`
  margin-top: 8px;
  padding-left: 1em;
  padding-right: 1em;
  font-weight: bold;
  cursor: pointer;
`

class PullDownformula extends React.Component {
  constructor(props) {
    super(props);
    this.list = React.createRef();
    this.state = {
      innerHeight: 0,
      isOpenedPullDown: false,
      isOpenedArr: [false, false, false, false],
    };
  }
  componentDidMount() {
    // インラインスクロールを持つドロップリストの縦幅を可変にするために内部の高さを取得する
    process.nextTick(() => {
      this.setState({
          innerHeight: this.list.current.clientHeight
      })
    })
  }
  componentDidUpdate = async (prevProps, prevState) => {
    // インラインスクロールを持つドロップリストの縦幅を可変にするために内部の高さを取得する
    if (prevProps.items !== this.props.items) {
      this.setState({
        innerHeight: this.list.current.clientHeight
      });
    }
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown,
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false,
    });
  }
  render() {
    // 要素を選択
    const selectItem = (item) => {
      this.props.onChange(item.id);
      this.closePullDown();
    }
    // 表示項目カテゴリを開閉する
    const toggleMenu = (i) => {
      const isOpenedArr = this.state.isOpenedArr;
      isOpenedArr[i] = !isOpenedArr[i];
      this.setState({
        isOpenedArr: isOpenedArr
      })
    }

    return (
      <StyledPullDownWrap
        className = { this.props.className }
        width = { (this.props.width) ? this.props.width : 'auto' }
      >
        <PullDownHasBorder
          width = { (this.props.width) ? this.props.width : 'auto' }
          onClick = {this.togglePullDown}
        >
          <strong>
            {
              (() => {
                const item = _.find(this.props.items, o => {
                  return o.id === this.props.id
                });
                return (item) ? item.label : this.props.placeholder
              })()
            }
          </strong>
        </PullDownHasBorder>
        <StyledPullDownListWrap
          listWidth = { this.props.listWidth }
          listLeft = { this.props.listLeft }
          className = {
            this.state.isOpenedPullDown === true
              ? `is-opened`
              : `is-closed`
          }
        >
          <StyledPullDownListWrapIn
            innerHeight = { this.state.innerHeight }
          >
            <Scrollbars>
              <StyledPullDownListWrapIn2
                ref = { this.list }
              >
                <StyledMenuAndSelected
                  className = {
                    classnames({
                      'has-border': this.props.hasBorder === true,
                    })
                  }
                >
                  {
                    (() => {
                      // 選択可能な表示項目一覧を生成
                      if (this.props.items.length === 0) return (<div></div>)
                      return this.props.items.map((category, index) => {
                        return (
                          <StyledMenuSection
                            key = { _.uniqueId() }
                            className = { index > 0 ? 'm-t-16' : '' }
                          >
                            <StyledMenuHeading
                              noMargin
                              onClick = { (() => { toggleMenu(index) }) }
                            >
                              { category.categoryName }
                              <IconExpand
                                className = {(() => {
                                  return (this.state.isOpenedArr[index] === true) ? 'is-expand' : ''
                                })()}
                              />
                            </StyledMenuHeading>
                            <StyledMenuItems
                              flexWrap
                              className = {(() => {
                                return (this.state.isOpenedArr[index] === true) ? 'is-active' : ''
                              })()}
                            >
                              {
                                (() => {
                                  return category.dispItem.map((item, indexChild) => {
                                    return (
                                      <StyledSubSection
                                        key = { _.uniqueId() }
                                        id = { `progress_display_select_filter_${index}_${indexChild}` }
                                        onClick = { (() => {
                                          selectItem(item)
                                        }) }
                                      >
                                        { item.label }
                                      </StyledSubSection>
                                    )
                                  });
                                })()
                              }
                            </StyledMenuItems>
                          </StyledMenuSection>
                        )
                      })
                    })()
                  }
                </StyledMenuAndSelected>
              </StyledPullDownListWrapIn2>
            </Scrollbars>
          </StyledPullDownListWrapIn>
        </StyledPullDownListWrap>
      </StyledPullDownWrap>
    )
  }
}
export default onClickOutside(PullDownformula);
