export default [
  {
    id: 0,
    label: '等しい',
    sign: '＝'
  },
  {
    id: 1,
    label: '以下',
    sign: '≦'
  },
  {
    id: 2,
    label: '以上',
    sign: '≧'
  },
  {
    id: 3,
    label: '未満',
    sign: '＜'
  },
  {
    id: 4,
    label: 'より大きい',
    sign: '＞'
  }
];
