import React from 'react';
import styled from 'styled-components';

import IconClose from '../atoms/IconClose';
// 灰色のやつ
const StyledModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: ${ props => props.isOpened ? 'auto' : 'none' };
`
const StyledModalIn = styled.div`
  width: calc(100% - 50px);
  height: calc(100% - 144px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 20px;
  z-index: 10;
  pointer-events: none;
  transition-duration: .2s;
  transition-property: width;
  &.is-opened-sidemenu {
    width: calc(100% - 160px);
  }
`
const StyledModalBg = styled.div`
  width: 100%;
  height: 100%;
  opacity: ${ props => props.isOpened ? 1 : 0 };
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition-duration: .2s;
  transition-property: opacity;
`
const StyledModalBody = styled.div`
  height: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  opacity: ${ props => props.isOpened ? 1 : 0 };
  pointer-events: ${ props => props.isOpened ? 'auto' : 'none' };
  padding: 3px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  transform: scale(${ props => props.isOpened ? 1 : 0.96 });
  transition-duration: .2s;
  transition-delay: ${ props => props.isOpened ? 0.05 : 0 }s;
  transition-property: opacity, transform;
`
const StyledModalHeading = styled.div`
  position: relative;
  padding: 16px 60px 16px 24px;
  font-weight: 600;
`
const StyledModalClose = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 24px;
  margin-top: -6px;
`
const StyledIconClose = styled(IconClose)`
  width: 12px;
  height: 12px;
  fill: #7a7a7a;
`
const StyledModalContent = styled.div`
  height: calc(100% - 56px);
  overflow-y: auto;
`

class ModalL extends React.Component {
  getClassNameIsOpened = () => {
    return (this.props.isOpened === true) ? 'is-opened' : '';
  }
  render() {
    return (
      <StyledModal
        className = { this.props.className }
        isOpened = { this.props.isOpened }
      >
        <StyledModalIn
          className = { this.props.isOpenedSideMenu === true ? 'is-opened-sidemenu' : '' }
        >
          <StyledModalBody
            isOpened = { this.props.isOpened }
          >
            <StyledModalHeading>
              { this.props.heading }
              <StyledModalClose
                onClick = { this.props.close }
              >
                <StyledIconClose/>
              </StyledModalClose>
            </StyledModalHeading>
            <StyledModalContent>
              { this.props.children }
            </StyledModalContent>
          </StyledModalBody>
        </StyledModalIn>
        <StyledModalBg
          isOpened = { this.props.isOpened }
          onClick = { this.props.close }
        />
      </StyledModal>
    )
  }
};

export default ModalL;
