import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import _ from 'lodash';
import { format } from 'date-fns';

import COLOR from '../../_const/COLOR';

import Button from '../../atoms/Button';
import ButtonEdit from '../../atoms/ButtonEdit';
import Checkbox from '../../atoms/Checkbox';
import FlexBox from '../../atoms/FlexBox';
import IconPlus from '../../atoms/IconPlus';
import PullDownCommon from '../../organisms/PullDownCommon';
import TableDataCell from '../../atoms/TableDataCell';
import TableBodyRow from '../../atoms/TableBodyRow';
import TableHeader from '../../atoms/TableHeader';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import BarEditData from '../../molecules/BarEditData';
import LinkText from '../../atoms/LinkText';
import Search from '../../molecules/Search';
import Pagination from '../../organisms/Pagination';
import PullDownExportAndImport from '../../organisms/PullDownExportAndImport';
import ModalFileExport from '../../organisms/ModalFileExport';
import ModalFileImport from '../../organisms/ModalFileImport';
import ModalErrorMessage from '../../organisms/ModalErrorMessage';
import ModalOperationMemoEdit from '../../organisms/ModalOperationMemoEdit';
import ModalConfirmDelete from '../../organisms/ModalConfirmDelete';
import HeadingM from '../../atoms/HeadingM';

import alignSearchQuery from '../../_util/alignSearchQuery';

import User from '../../../utils/user';

const TABLE_HEADER = [
  {
    id: 'accountName',
    label: 'アカウント名',
    width: '180px',
    hasSort: true,
  },
  {
    id: 'mediaTypeName',
    label: '媒体',
    width: '140px',
    hasSort: true,
  },
  {
    id: 'createDate',
    label: '作成日',
    width: '200px',
    hasSort: true,
  },
  {
    id: 'memo',
    label: '運用メモ',
    width: 'calc((100% - 600px) / 2)',
    hasSort: true,
  },
  {
    id: 'note',
    label: '備考',
    width: 'calc((100% - 600px) / 2)',
    hasSort: true,
  },
  {
    id: 'userName',
    label: '作成者',
    width: '120px',
    hasSort: true,
  },
  {
    id: 'edit',
    label: '',
    width: '80px',
    hasSort: false,
  },
];

const TABLE_HEADER_CLIENT = [
  {
    id: 'clientName',
    label: 'クライアント名',
    width: 'calc(100% / 3)',
    hasSort: true,
  },
  {
    id: 'lastModified',
    label: '最終更新日時',
    width: 'calc(100% / 3)',
    hasSort: true,
  },
  {
    id: 'userName',
    label: '作成者',
    width: 'calc(100% / 3)',
    hasSort: true,
  },
];

const EXPORT_MODAL = [
  { type: 'pulldown', label: 'label', heading: 'クライアント名' },
];

const backendApi = process.env.REACT_APP_BACKEND_URI;

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_CLIENT_FORMAT = 'YYYY年M月D日 hh:mm';
const DATE_ACCOUNT_FORMAT = 'YYYY年M月D日 hh:mm';
const EXPORT_URL = backendApi + 'progress';
const IMPORT_URL = backendApi + 'fileUpload';

let ITEMS_MAIN = [
  { type: 'export', label: '現在のメモすべてをダウンロードする', url: backendApi + 'progress', param: { dl: 1 } },
  { type: 'exportModal', label: 'クライアントを指定してダウンロードする' },
  { type: 'importModal', label: '新しいメモをインポートする' },
  { type: 'download', label: 'テンプレートをダウンロードする', url: '/template/memo_template.csv', param: 'none'},
];

let ITEMS_SUB = [
  { type: 'export', label: '現在のメモすべてをダウンロードする', url: backendApi + 'progress', param: { dl: 1 } },
  { type: 'importModal', label: '新しいメモをインポートする' },
  { type: 'download', label: 'テンプレートをダウンロードする', url: '/template/memo_template.csv', param: 'none'},
];

const StyledTable = styled.div`
  width: 100%;
  max-height: ${ props => props.windowHeight - 120 - 106 - 44 - 40 }px;
  overflow: scroll;
  position: relative;
  z-index: 1;
`

const StyleHeadingM = styled(HeadingM)`
  color: ${COLOR.ORANGE};
  cursor: pointer;
`

class OperationMemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      clients: [],
      clientTable: [],
      dataOriginal: [],
      dataFiltered: [],
      detailTable: [],
      defaultData: [],
      exportList: [],
      isOpenedFavModal: false,
      isOpenedConfirmDeleteModal: false,
      isOpenedExportModal: false,
      isOpenedImportModal: false,
      isOpenedErrorModal: false,
      errorText: 'エラーが発生しました',
      data: [],
      offset: 0,
      postPerPage: 50,
      searchQuery: '',
      searchTarget: 'clientName',
      checked: [],
      modifyParams: {
        id: null,
        memo: '',
        note: '',
        clientId: '',
        accountId: '',
        mediaType: '',
        shareSetting: 0,
        createDate: format(new Date(), DATE_FORMAT),
        userIds: [],
      },
      clientParams: {
        dl: 1,
      },
    };
    props.switchGlobalCatId(8);
    props.switchContentTitle('運用メモ');
    props.toggleDatePicker(false);
  }
  componentWillMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    // propsからデータを設定するか、ない場合データを取得する
    if (_.isEmpty(this.props.clients) || _.isEmpty(this.props.accounts)) {
      await Promise.all([
        this.getClients(),
        this.getAccounts(),
      ])
      .then((response) => {
        this.setState({
          clients: response[0].data,
          accounts: response[1].data
        });
      });
    } else {
      await this.setState({
        accounts: this.props.accounts,
        clients: this.props.clients
      });
    }

    await this.getData();
    this.arrangeViewItems();

    //　プログレス非表示
    this.props.endProgressing();
  }
  getData = () => {
    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'progress', {
      params: {
        ...User.apiParams(),
      }
    })
    .then(async (response) => {
      const detailTable = _.groupBy(response.data, 'clientId');
      let clientTable = [];
      await _.forEach(detailTable, (value, key) => {
        clientTable.push(_.maxBy(value, (o) => { return o.lastModified }));
      });
      await this.setState({
        defaultData: response.data,
        detailTable: detailTable,
        clientTable: clientTable,
        dataOriginal: clientTable,
        dataFiltered: clientTable,
        data: clientTable,
      });
      // エクスポートするリストを作成
      await this.getExportList();
    })
    .catch((error) => {
      if (error.response) {
        this.setState({
          isOpenedErrorModal: true,
          errorText: error.response.data.errorMessage
        })
      } else {
        this.setState({
          isOpenedErrorModal: true,
        })
      }
    });
  }
  getClients = () => {
    return axios.get(backendApi + 'client', {
      params: {
        ...User.apiParams()
      }
    })
  }
  getAccounts = () => {
    return axios.get(backendApi + 'account', {
      params: {
        ...User.apiParams()
      }
    })
  }
  arrangeViewItems = () => {
    // ソートやページャの条件に従って表示するレポートデータを別途作成する。
    const { offset, postPerPage } = this.state;

    // 元データを指定の条件でフィルタする
    const filteredData = this.state.dataOriginal.filter((item, index) => {
      if (this.state.searchQuery) {
        const regexp = new RegExp(
          `^(?=.*${alignSearchQuery(this.state.searchQuery)}).*$`
        );
        const param = item[this.state.searchTarget];
        if (param) {
          return (
            (
              // フリーワード検索のクエリに合致するか
              this.state.searchQuery === ''
              || alignSearchQuery(item[this.state.searchTarget]).match(regexp) !== null
            )
          )
        } else {
          return null;
        }
      } else {
        return item;
      }
    });

    // データのソートには_.orderBy()を使う。
    const sortedDataTable = _.orderBy(
      filteredData,
      this.state.sortId,
      this.state.sortOrder
    );

    // ページャ条件による絞り込みにはArray.slice()を使う。
    const arrangedDataTable = sortedDataTable.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    this.setState({
      dataFiltered: filteredData,
      data: arrangedDataTable,
    });
  }

  // エクスポートに使用するリストを作成する
  getExportList = () => {
    const exportList = this.state.clientTable.map(x => {
      return {
        'id': x.clientId,
        'label': x.clientName
      }
    });
    this.setState({
      exportList: exportList,
    });
  }

  render() {
    // フリーワード検索
    const setSearchQuery = async (e) => {
      e.preventDefault();
      await this.setState({
        offset: 0,
        searchQuery: e.target['searchQuery'].value,
        checked: [],
      });
      this.arrangeViewItems();
    }

    // フリーワード検索のルールを変更
    const switchSearchMethod = (id) => {
      this.setState({
        searchTarget: id,
      });
    }

    // 並び替え
    const sort = async (e) => {
      // セルの並び替え
      const id = e.currentTarget.dataset.id;
      const order = (id === this.state.sortId && this.state.sortOrder === 'desc') ? 'asc' : 'desc';

      await this.setState({
        sortId: id,
        sortOrder: order,
      })
      this.arrangeViewItems();
    }

    // 各行のチェック
    const checkItem = (e) => {
      const id = parseInt(e.currentTarget.value);
      const checked = this.state.checked.concat();

      if (checked.indexOf(id) > -1) {
        checked.splice(checked.indexOf(id), 1)
      } else {
        checked.push(id);
      }

      this.setState({
        checked: checked
      });
    }

    // 削除確認モーダルを開く
    const openConfirmDeleteModal = () => {
      this.setState({
        isOpenedConfirmDeleteModal: true,
      });
    }

    // 削除確認モーダルを閉じる
    const closeConfirmDeleteModal = () => {
      this.setState({
        isOpenedConfirmDeleteModal: false,
      });
    }

    // 削除
    const deleteOpeMemoItem = async () => {
      // プログレス表示
      this.props.startProgressing();

      // 削除するIDを取得
      let ids = '';
      if (this.state.modifyParams.clientId) {
        ids = this.state.checked.join(',')
      } else {
        const clientIds = this.state.checked.map(id => this.state.data.filter(item => item.id === id)[0].clientId);
        ids = _.flatten(clientIds.map(id => this.state.detailTable[id])).map(item => item.id).join(',');
      }

      // 削除API実行
      await axios.get(backendApi + 'progressModify', {
        params: {
          ...User.apiParams(),
          func: 2,
          ids: ids,
        }
      })
      .then(async (response) => {
        const detailTable = _.groupBy(response.data, 'clientId');
        let clientTable = [];
        await _.forEach(detailTable, (value, key) => {
          clientTable.push(_.maxBy(value, (o) => { return o.lastModified }));
        });
        let data = [];
        if (this.state.modifyParams.clientId) {
          if (_.isEmpty(detailTable)) {
            data = [];
          } else if (detailTable[this.state.modifyParams.clientId]){
            data = detailTable[this.state.modifyParams.clientId];
          } else {
            data = clientTable;
          }
        } else {
          data = clientTable;
        }
        await this.setState({
          defaultData: response.data,
          detailTable: detailTable,
          clientTable: clientTable,
          dataOriginal: data,
          dataFiltered: data,
          data: data,
          checked: [],
        });
        // エクスポートするリストを作成
        await this.getExportList();
        // 確認モーダルを閉じる
        closeConfirmDeleteModal();
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            isOpenedErrorModal: true,
            errorText: error.response.data.errorMessage
          })
        } else {
          this.setState({
            isOpenedErrorModal: true,
          })
        }
      });

      this.arrangeViewItems();

      //　プログレス非表示
      this.props.endProgressing();
    }

    // ページャの表示件数を変更
    const switchPostPerPage = async (num) => {
      await this.setState({
        postPerPage: num
      })
      this.arrangeViewItems();
    }

    // ページャの戻るイベント
    const goToPrevPagination = async () => {
      if (this.state.offset <= 0) return;
      await this.setState({
        offset: this.state.offset - 1
      });
      this.arrangeViewItems();
    }

    // ページャの進むイベント
    const goToNextPagination = async () => {
      if (this.state.offset >= Math.ceil(this.state.dataFiltered.length / this.state.postPerPage) - 1) return;
      await this.setState({
        offset: this.state.offset + 1
      });
      this.arrangeViewItems();
    }

    // エクスポートモーダルを開く
    const openExportModal = () => {
      this.setState({
        isOpenedExportModal: true
      });
    }
    // エクスポートモーダルを閉じる
    const closeExportModal = () => {
      this.setState({
        isOpenedExportModal: false
      });
    }
    // インポートモーダルを開く
    const openImportModal = () => {
      this.setState({
        isOpenedImportModal: true
      });
    }
    // インポートモーダルを閉じる
    const closeImportModal = () => {
      this.setState({
        isOpenedImportModal: false
      });
    }
    // エラーモーダルを開く
    const openErrorModal = () => {
      this.setState({
        isOpenedErrorModal: true
      });
    }
    // エラーモーダルを閉じる
    const closeErrorModal = () => {
      this.setState({
        isOpenedErrorModal: false
      });
      window.setTimeout(() => {
        this.setState({
          errorText: 'エラーが発生しました',
        });
      }, 500);
    }
    // エラーモーダルの本文を書き換える
    const changeErrorText = (text) => {
      this.setState({
        errorText: text
      });
    }

    // 運用メモ編集
    const setOpeMemoEdit = (param) => {
      return axios.get(backendApi + 'progressModify',{
        params: {
          ...User.apiParams(),
          ...param,
          func: 1,
          userIds: param.checkedUsers.join(','),
        }
      })
      .then(async (response) => {
        const detailTable = _.groupBy(response.data, 'clientId');
        let clientTable = [];
        _.forEach(detailTable, (value, key) => {
          clientTable.push(_.maxBy(value, (o) => { return o.lastModified }));
        });
        let data = [];
        if (this.state.modifyParams.clientId) {
          if (_.isEmpty(detailTable)) {
            data = [];
          } else if (detailTable[this.state.modifyParams.clientId]){
            data = detailTable[this.state.modifyParams.clientId];
          } else {
            data = clientTable;
          }
        } else {
          data = clientTable;
        }
        await this.setState({
          defaultData: response.data,
          detailTable: detailTable,
          clientTable: clientTable,
          dataOriginal: data,
          dataFiltered: data,
          data: data,
          checked: [],
        });
        // エクスポートするリストを作成
        await this.getExportList();
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            isOpenedErrorModal: true,
            errorText: error.response.data.errorMessage
          })
        } else {
          this.setState({
            isOpenedErrorModal: true,
          })
        }
      });
    }

    // 運用メモ記入モーダルを開く
    const openOpeMemoEditModal = (e, data) => {
      if (data) {
        this.setState({
          modifyParams: {
            ...data,
            userIds: data.userIds.split(',')
          }
        });
      } else {
        this.setState({
          modifyParams: {
            ...this.state.modifyParams,
            id: null,
            memo: '',
            note: '',
            accountId: '',
            mediaType: '',
            shareSetting: 0,
            createDate: format(new Date(), DATE_FORMAT),
            userIds: [],
          }
        });
      }
      this.setState({
        isOpenedFavModal: true
      });
    }

    // 運用メモ記入モーダルを閉じる
    const closeOpeMemoEditModal = (e) => {
      this.setState({
        isOpenedFavModal: false
      });
    }

    const setClient = async (o) => {
      const clientId = o.clientId;
      let params = {};
      if (clientId) {
        params = {
          dl: 1,
          clientId: clientId,
        };
      } else {
        params = {
          dl: 1
        };
      }
      await this.setState({
        data: this.state.detailTable[o.clientId],
        dataFiltered: this.state.detailTable[o.clientId],
        dataOriginal: this.state.detailTable[o.clientId],
        searchQuery: '',
        searchTarget: 'accountName',
        modifyParams: {
          ...this.state.modifyParams,
          clientName: o.clientName,
          clientId: o.clientId
        },
        clientParams: {
          ...User.apiParams(),
          ...params,
        }
      })
      let tmp = ITEMS_SUB.slice();
      tmp[0].param = this.state.clientParams;
      ITEMS_SUB = tmp;
    }

    const removeClient = async () => {
      await this.setState({
        data: this.state.clientTable,
        dataFiltered: this.state.clientTable,
        dataOriginal: this.state.clientTable,
        searchQuery: '',
        searchTarget: 'clientName',
        modifyParams: {
          id: null,
          memo: '',
          note: '',
          clientId: '',
          accountId: '',
          mediaType: '',
          shareSetting: 0,
          createDate: format(new Date(), DATE_FORMAT),
          userIds: [],
          clientParams: {
            ...User.apiParams(),
            dl: 1
          }
        }
      })
      let tmp = ITEMS_SUB.slice();
      tmp[0].param = this.state.clientParams;
      ITEMS_SUB = tmp;
    }

    const selectedClient = () => {
      return this.state.modifyParams.clientName ? `${this.state.modifyParams.clientName} >` : '　';
    }

    return (
      <div>
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isOpenedErrorModal }
          close = { closeErrorModal }
          bodyText = { this.state.errorText }
        ></ModalErrorMessage>
        <ModalFileExport
          heading = 'クライアントを指定してメモをダウンロードする'
          isOpened = { this.state.isOpenedExportModal }
          closeExportModal = { closeExportModal }
          modalContents = { EXPORT_MODAL }
          apiUrl = { EXPORT_URL }
          apiParams = { User.apiParams() }
          changeErrorText = { changeErrorText }
          openErrorModal = { openErrorModal }
          data = { this.state.defaultData }
          items = { this.state.exportList }
          isTarget = { false }
          pullDownWidth = { '300px' }
        ></ModalFileExport>
        <ModalFileImport
          heading = '新しいメモをインポートする'
          isOpened = { this.state.isOpenedImportModal }
          closeImportModal = { closeImportModal }
          apiUrl = { IMPORT_URL }
          apiParams = { User.apiParams() }
          changeErrorText = { changeErrorText }
          openErrorModal = { openErrorModal }
          funcId = { 7 }
        ></ModalFileImport>
        <ModalOperationMemoEdit
          heading = '運用メモ修正'
          isOpened = { this.state.isOpenedFavModal }
          users = { this.props.users }
          setOperationMemo = { setOpeMemoEdit }
          close = { closeOpeMemoEditModal }
          modifyParams = { this.state.modifyParams }
          clients = { this.state.clients }
          accounts = { this.state.accounts }
        />
        <ModalConfirmDelete
          heading = '運用メモ削除'
          isOpened = { this.state.isOpenedConfirmDeleteModal }
          close = { closeConfirmDeleteModal }
          delete = { deleteOpeMemoItem }
        >{
          (() => {
            if (!this.state.modifyParams.clientName) {
              return (
                <div>
                  本当に削除しますか？
                  <br />
                  選択されたクライアントの全ての運用メモが削除されます。
                </div>
              );
            } else {
              return (
                <div>本当に削除しますか？</div>
              );
            }
          })()
        }
        </ModalConfirmDelete>
        <FlexBox className="m-b-32" justify="space-between">
          <FlexBox>
            <FlexBox className="m-r-16">
              <Search
                name = "searchQuery"
                onSubmit = { setSearchQuery }
                value = { this.state.searchQuery }
              />
              {
                (() => {
                  if (this.state.modifyParams.clientId) {
                    return (
                      <PullDownCommon
                      id = { this.state.searchTarget }
                      listWidth = '200px'
                      className="m-l-16"
                      items = {
                        [
                          { id: 'accountName', label: 'アカウント名'},
                          { id: 'mediaTypeName', label: '媒体' },
                          { id: 'memo', label: '実施したこと' },
                          { id: 'note', label: '備考' },
                        ]
                      }
                      onChange = { switchSearchMethod }
                      />
                    )
                  }
                })()
              }
            </FlexBox>
            <PullDownExportAndImport
              items = { this.state.modifyParams.clientId ? ITEMS_SUB : ITEMS_MAIN }
              apiParams = { User.apiParams() }
              openExportModal = { openExportModal }
              openImportModal = { openImportModal }
            />
          </FlexBox>
          <Button
            onClick = { openOpeMemoEditModal }
          >
            新規運用メモの作成
            <IconPlus className="m-l-8" />
          </Button>
        </FlexBox>
        <StyleHeadingM
          onClick = { removeClient }
        >{ selectedClient() }</StyleHeadingM>
        <BarEditData
          count = { this.state.checked.length }
          onClickDelete = { openConfirmDeleteModal }
        />
        <StyledTable
          windowHeight = { this.props.windowHeight }
        >
          {
            (() => {
              if (!this.state.modifyParams.clientId) {
                return (
                  <div>
                    <TableHeader>
                      <TableHeaderCell
                        className = { 'has-checkbox' }
                      />
                      {
                        (() => {
                          return TABLE_HEADER_CLIENT.map(o => {
                            return (
                              <TableHeaderCell
                                key = { _.uniqueId() }
                                style = { { width: `${o.width}` } }
                                sortState = {
                                  (o.hasSort === false)
                                    ? undefined
                                    : (o.id !== this.state.sortId)
                                      ? 0
                                      : (this.state.sortOrder === 'asc')
                                        ? 1
                                        : 2
                                }
                                data-id = { o.id }
                                onClick = { sort }
                              >
                                { o.label }
                              </TableHeaderCell>
                            )
                          })
                        })()
                      }
                    </TableHeader>
                    <div>
                      {
                        (() => {
                          return this.state.data.map(r => {
                            return (
                              <TableBodyRow
                                key = { _.uniqueId() }
                              >
                                <TableDataCell
                                  className = { 'has-checkbox' }
                                >
                                  <Checkbox
                                    value = { r.id }
                                    onClick = { checkItem }
                                    isChecked = { this.state.checked.indexOf(r.id) > -1 }
                                  />
                                </TableDataCell>
                                {
                                  (() => {
                                    return TABLE_HEADER_CLIENT.map(o => {
                                      return (
                                        <TableDataCell
                                          key = { _.uniqueId() }
                                          style = { { width: `${o.width}` } }
                                        >
                                          {
                                            (() => {
                                              switch (o.id) {
                                                case 'clientName':
                                                  return (
                                                    <LinkText
                                                      onClick = { () => {
                                                        setClient(r)
                                                      } }
                                                    >
                                                      { r[o.id] }
                                                    </LinkText>
                                                  );
                                                case 'lastModified':
                                                  return format(r[o.id], DATE_CLIENT_FORMAT);
                                                default:
                                                  return r[o.id];
                                              }
                                            })()
                                          }
                                        </TableDataCell>
                                      )
                                    })
                                  })()
                                }
                              </TableBodyRow>
                            )
                          })
                        })()
                      }
                    </div>
                  </div>
                )
              } else {
                return (
                  <div>
                    <TableHeader>
                      <TableHeaderCell
                        className = { 'has-checkbox' }
                      />
                      {
                        (() => {
                          return TABLE_HEADER.map(o => {
                            return (
                              <TableHeaderCell
                                key = { _.uniqueId() }
                                style = { { width: `${o.width}` } }
                                sortState = {
                                  (o.hasSort === false)
                                    ? undefined
                                    : (o.id !== this.state.sortId)
                                      ? 0
                                      : (this.state.sortOrder === 'asc')
                                        ? 1
                                        : 2
                                }
                                data-id = { o.id }
                                onClick = { sort }
                              >
                                { o.label }
                              </TableHeaderCell>
                            )
                          })
                        })()
                      }
                    </TableHeader>
                    <div>
                      {
                        (() => {
                          return this.state.data.map(r => {
                            return (
                              <TableBodyRow
                                key = { _.uniqueId() }
                              >
                                <TableDataCell
                                  className = { 'has-checkbox' }
                                >
                                  <Checkbox
                                    value = { r.id }
                                    onClick = { checkItem }
                                    isChecked = { this.state.checked.indexOf(r.id) > -1 }
                                  />
                                </TableDataCell>
                                {
                                  (() => {
                                    return TABLE_HEADER.map(o => {
                                      return (
                                        <TableDataCell
                                          key = { _.uniqueId() }
                                          style = { { width: `${o.width}` } }
                                        >
                                          {
                                            (() => {
                                              switch (o.id) {
                                                case 'accountName':
                                                  return r[o.id] ? r[o.id] : '(指定なし)';
                                                case 'createDate':
                                                  return format(r[o.id], DATE_ACCOUNT_FORMAT);
                                                case 'edit':
                                                  return (
                                                    <FlexBox
                                                      width = '100%'
                                                      justify = 'center'
                                                    >
                                                      <ButtonEdit
                                                        onClick = { (e) => openOpeMemoEditModal(e, r) }
                                                      />
                                                    </FlexBox>
                                                  )
                                                default:
                                                  return r[o.id];
                                              }
                                            })()
                                          }
                                        </TableDataCell>
                                      )
                                    })
                                  })()
                                }
                              </TableBodyRow>
                            )
                          })
                        })()
                      }
                    </div>
                  </div>
                )
              }
            })()
          }
        </StyledTable>

        <div className="m-t-24">
          <Pagination
            offset = { this.state.offset }
            postPerPage = { this.state.postPerPage }
            dataCount = { this.state.dataFiltered.length }
            switchPostPerPage = { switchPostPerPage }
            goToPrevPagination = { goToPrevPagination }
            goToNextPagination = { goToNextPagination }
          />
        </div>
      </div>
    )
  }
}

export default OperationMemo;
