import React from 'react';
import styled from 'styled-components';

import Button from '../atoms/Button';
import FlexBox from '../atoms/FlexBox';
import Modal from '../molecules/Modal';

const StyledModalBody = styled.div`
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
`

class ModalAlertEditAddRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '無題です無題です',
      isOpened: [false, false, false, false],
      selected: [],
    }
  }
  render() {
    return (
      <Modal
        heading = { this.props.heading }
        isOpened = { this.props.isOpened }
        isOpenedSideMenu = { this.props.isOpenedSideMenu }
        close = { this.props.close }
      >
        <StyledModalBody>
          AND条件とOR条件<br />
          どちらで追加しますか。
          <FlexBox className="m-t-24">
            <Button
              width = '90px'
              justify = 'center'
              color = 'orange'
              value = '1'
              className = 'm-r-8'
              onClick = { this.props.setAddCondition }
            >
              OR
            </Button>
            <Button
              width = '90px'
              justify = 'center'
              color = 'orange'
              value = '2'
              onClick = { this.props.setAddCondition }
            >
              AND
            </Button>
          </FlexBox>
        </StyledModalBody>
      </Modal>
    )
  }
};

export default ModalAlertEditAddRule;
