export default [
  {
    id: 1,
    label: 'Google広告',
    value: 'aw',
  },
  {
    id: 2,
    label: 'Yahoo!SS',
    value: 'ss',
  },
  {
    id: 3,
    label: 'YDN',
    value: 'ydn',
  },
  {
    id: 4,
    label: 'Xリスティング',
    value: 'zeus',
  },
  {
    id: 5,
    label: 'DV360',
    value: 'dbm',
  },
  // {
  //   id: 6,
  //   label: 'D2C',
  //   value: '',
  // },
  {
    id: 7,
    label: 'Criteo',
    value: 'cr',
  },
  // {
  //   id: 8,
  //   label: 'YCD',
  //   value: '',
  // },
  {
    id: 9,
    label: 'Facebook',
    value: 'fb',
  },
  {
    id: 10,
    label: 'Twitter',
    value: 'tw',
  },
  {
    id: 11,
    label: 'Indeed',
    value: 'indeed',
  },
  {
    id: 12,
    label: 'LINE',
    value: 'line',
  },
  {
    id: 16,
    label: 'SmartNews',
    value: 'sn',
  },
  {
    id: 17,
    label: 'RTB House',
    value: 'rtbh',
  },
  {
    id: 18,
    label: 'Amazon',
    value: 'aa',
  },
  {
    id: 19,
    label: 'Apple',
    value: 'asa',
  },
  {
    id: 20,
    label: 'Microsoft',
    value: 'bing',
  },
  {
    id: 21,
    label: 'Logicad',
    value: 'la',
  },
  {
    id: 22,
    label: 'Universe ads',
    value: 'una',
  },
  {
    id: 99,
    label: 'その他',
    value: 'xmedia',
  },
]
