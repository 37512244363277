import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

import ButtonClose from '../atoms/ButtonClose';
import CheckboxWithLabel from '../atoms/CheckboxWithLabel';
import DividerDottedH from '../atoms/DividerDottedH';
import DividerDottedVWide from '../atoms/DividerDottedVWide';
import FlexBox from '../atoms/FlexBox';
import Search from '../molecules/Search';

import alignSearchQuery from '../_util/alignSearchQuery';

const StyledUsersWrap = styled.div`
  max-width: 1000px;
  display: flex;
  align-items: stretch;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
`
const StyledUsersColumn = styled.div`
  width: 50%;
  box-sizing: border-box;
  position: relative;
  padding-top: 32px;
  padding-right: 32px;
  padding-left: 32px;
`
const StyledClearAll = styled.div`
  cursor: pointer;
`
const StyledTargetsItems = styled.div`
  height: 240px;
  margin-top: -8px;
  margin-right: -32px;
  margin-left: -32px;
`
const StyledTargetsItemsIn = styled.div`
  padding-bottom: 32px;
  padding-right: 32px;
  padding-left: 32px;
`
const StyledSelectedItems = styled.div`
  width: 100%;
  position: absolute;
  top: calc(1em + 56px);
  bottom: 0;
  left: 0;
`
const StyledSelectedItemsIn = styled.div`
  margin-top: -8px;
  padding-bottom: 32px;
  padding-right: 32px;
  padding-left: 32px;
`

class SelectUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      checked: [],
      searchQuery: '',
    };
  }
  componentWillMount = async () => {
    this.filterData();
  }
  componentDidUpdate = async (prevProps, prevState) => {
    // クライアント/アカウント一覧の初期値を定義
    if (prevProps.users !== this.props.users) {
      this.filterData();
    }
  }
  filterData = () => {
    // 元データを指定の条件でフィルタする
    const data = this.props.users.filter(user => {
      const regexp = new RegExp(
        `^(?=.*${alignSearchQuery(this.state.searchQuery)}).*$`
      );

      return (
        this.state.searchQuery === ''
        || alignSearchQuery(user.userName).match(regexp) !== null
      )
    });
    const checked = data.filter(user => {
      return this.props.checked.indexOf(user.userId) > -1;
    });

    this.setState({
      data: data,
      checked: checked,
    });
  }
  render() {
    // フリーワード検索
    const setSearchQuery = async (e) => {
      e.preventDefault();
      await this.setState({
        searchQuery: e.target['searchQuery'].value,
      });
      this.filterData();
    }

    return (
      <StyledUsersWrap
        className = { this.props.className }
      >
        <StyledUsersColumn>
          <FlexBox justify="space-between" className="m-b-16">
            <div>全{ this.state.data.length }件</div>
            <Search
              name = "searchQuery"
              onSubmit = { setSearchQuery }
              value = { this.state.searchQuery }
            />
          </FlexBox>
          <FlexBox justify="space-between">
            <CheckboxWithLabel
              id = "user-select-all"
              onChange = {
                (() => {
                  if (this.state.checked.length < this.state.data.length) {
                    this.setState({
                      checked: this.state.data.map(user => {
                        return user.userId;
                      })
                    }, () => {
                      this.props.toggleCheckAll(this.state.checked, true);
                    });
                  } else {
                    this.props.toggleCheckAll(this.state.checked, false);
                    this.setState({
                      checked: []
                    });
                  }
                })
              }
              checked = { this.state.checked.length === this.state.data.length ? 'checked' : '' }
            >
              すべて選択
            </CheckboxWithLabel>
            <StyledClearAll
              onClick = { this.props.clearCheckAll }
            >
              すべてクリア
            </StyledClearAll>
          </FlexBox>
          <DividerDottedH hasMargin />
          <StyledTargetsItems>
            <Scrollbars>
              <StyledTargetsItemsIn>
                {
                  // 選択可能な各階層の要素一覧を生成
                  (() => {
                    return this.state.data.map((user, index) => {
                      return (
                        <CheckboxWithLabel
                          className = "m-t-8"
                          key = { _.uniqueId() }
                          name = "users"
                          id = { `users-${index}` }
                          value = { user.userId }
                          checked = { this.props.checked.indexOf(user.userId) > -1 ? 'checked' : '' }
                          onChange = {
                            ((e) => {
                              if (this.state.checked.indexOf(user.userId) > -1) {
                                this.state.checked.splice(this.state.checked.indexOf(user.userId), 1)
                              } else {
                                this.state.checked.push(user.userId);
                              }
                              this.props.check(e);
                            })
                          }
                        >
                          { `${user.userName} / ${user.sectionName} / ${user.groupName}` }
                        </CheckboxWithLabel>
                      )
                    });
                  })()
                }
              </StyledTargetsItemsIn>
            </Scrollbars>
          </StyledTargetsItems>
        </StyledUsersColumn>
        <DividerDottedVWide />
        <StyledUsersColumn>
          <div className="m-b-16">
            選択{ this.props.checked.length }件
          </div>
          <StyledSelectedItems>
            <Scrollbars>
              <StyledSelectedItemsIn>
                {
                  (() => {
                    return this.props.users.map(user => {
                      if (this.props.checked.indexOf(user.userId) > -1) {
                        return (
                          <FlexBox
                            className = "m-t-8"
                            key = { _.uniqueId() }
                            justify = "space-between"
                          >
                            <div>
                              { `${user.userName} / ${user.sectionName} / ${user.groupName}` }
                            </div>
                            <ButtonClose
                              onClick = { (() => {
                                const { checked } = this.props;
                                checked.splice(checked.indexOf(user.userId), 1);
                                this.setState({
                                  checked: checked
                                });
                              }) }
                            />
                          </FlexBox>
                        )
                      } else {
                          return false;
                      }
                    })
                  })()
                }
              </StyledSelectedItemsIn>
            </Scrollbars>
          </StyledSelectedItems>
        </StyledUsersColumn>
      </StyledUsersWrap>
    )
  }
}

export default SelectUsers;
