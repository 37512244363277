import React from 'react';
import styled from 'styled-components';

import FONTSIZE from '../_const/FONTSIZE';

import img from '../../img/tooltip_arrow.png';

const getAlignPropForWrap = (align) => {
  switch (align) {
    case 'left':
      return `
        justify-content: flex-start;
      `;
    case 'right':
      return `
        justify-content: flex-end;
      `;
    case 'center':
    default:
      return `
        justify-content: center;
      `;
  }
}
const StyledTooltipWrap = styled.div`
  width: 180px;
  display: flex;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  padding-bottom: 14px;
  transform: scale(0.95);
  transform-origin: center bottom;
  transition-duration: .2s;
  transition-property: opacity, transform;
  ${ props => getAlignPropForWrap(props.align) }
  &.is-shown {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
  }
`
const StyledTooltipIn = styled.div`
  max-width: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  position: relative;
  padding: 8px 16px;
  border-radius: 2px;
  font-size: ${ FONTSIZE.SS }px;
  background-color: rgb(255, 255, 255);
`
const getAlignPropForArrow = (align) => {
  switch (align) {
    case 'left':
      return `
        left: 15px;
      `;
    case 'right':
      return `
        right: 15px;
      `;
    case 'center':
    default:
      return `
        left: 50%;
        margin-left: -10px;
      `;
  }
}
const StyledTooltipArrow = styled.img`
  width: 20px;
  height: 14px;
  position: absolute;
  bottom: 0;
  ${ props => getAlignPropForArrow(props.align) }
`

const Tooltip = (props) => (
  <StyledTooltipWrap
    className = { props.className }
    align = { props.align }
  >
    <StyledTooltipIn>
      { props.str }
    </StyledTooltipIn>
    <StyledTooltipArrow src={ img } align={ props.align } />
  </StyledTooltipWrap>
);

export default Tooltip;
