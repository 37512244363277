import styled from 'styled-components';

import FONTSIZE from '../_const/FONTSIZE'

const HeadingM = styled.div`
  margin-bottom: 24px;
  font-size: ${FONTSIZE.M}px;
  font-weight: 600;
`

export default HeadingM;
