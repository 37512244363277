// ここに登録されたデータは常に合計を算出する

export default [
  "click",
  "imp",
  "cost",
  "cost_with_fee",
  "mediaBudget",
  "mediaBudget_with_fee",
  "estimateCost",
  "estimateCost_with_fee",
  "budget",
  "budget_with_fee",
  "cv",
  "revenue",
  "allCv",
]