import React from 'react';
import styled from 'styled-components';

const IconArrowLRSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0, 0, 6, 9" className={props.className}>
    <path fillRule="evenodd" fill="#666" d="M5.987 4.501l.002.002-.019.015-.577.602-.072-.061L.607 8.988l-.596-.622L4.65 4.5.011.633.607.012 5.32 3.941l.073-.061.578.604.018.015-.002.002z"/>
  </svg>
)
const IconArrowLR = styled(IconArrowLRSvg) `
  display: block;
`

export default IconArrowLR;
