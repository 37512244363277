import React from 'react';
import styled from 'styled-components';

const IconClose = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0, 0, 7, 7" className={props.className}>
    <path fillRule="evenodd" d="M6.989 6.269l-.721.72L3.5 4.222.732 6.989l-.721-.72 2.768-2.768L.011.733.732.01 3.5 2.779 6.268.01l.721.723-2.768 2.768 2.768 2.768z"/>
  </svg>
)
const StyledIconClose = styled(IconClose) `
  display: block;
  fill: #fff;
`

export default StyledIconClose;
