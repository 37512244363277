import React from 'react';

import Base from './Base';

class Account extends React.Component {
  constructor(props) {
    super(props);
    props.switchContentTitle('キーワード/ターゲティング');
  }
  render() {
    return (
      <Base
        target = { 5 }
        targetId = { 'keywordId' }
        targetName = { 'keywordName' }
        hasGraph = { false }
        { ...this.props }
      />
    )
  }
}

export default Account;
