import React from 'react';
import styled from 'styled-components';

const IconEdit = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0, 0, 9, 9" className={props.className}>
    <path fillRule="evenodd" fill="gray" d="M3.482 7.588l-2.07-2.07L6.931-.001 9 2.07 3.482 7.588zM-.002 9.002l.316-2.416 2.1 2.07-2.416.346z"/>
  </svg>
)
const StyledIconEdit = styled(IconEdit) `
  display: block;
`

export default StyledIconEdit;
