import React from 'react';
import styled from 'styled-components';

const IconStarSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0, 0, 26, 25" className={props.className}>
    <path fillRule="evenodd" stroke="gray" fill="none" d="M12.856 3.904a.695.695 0 0 1 .626-.406c.265 0 .508.157.626.406l2.245 4.757a.699.699 0 0 0 .526.397l5.022.763a.706.706 0 0 1 .563.496.752.752 0 0 1-.177.749l-3.633 3.703a.753.753 0 0 0-.201.645l.858 5.23a.747.747 0 0 1-.278.713.672.672 0 0 1-.735.056l-4.492-2.47a.681.681 0 0 0-.65 0l-4.491 2.469a.672.672 0 0 1-.736-.055.744.744 0 0 1-.277-.713l.857-5.23a.75.75 0 0 0-.201-.645l-3.634-3.703a.755.755 0 0 1-.176-.749.706.706 0 0 1 .564-.496l5.022-.763a.697.697 0 0 0 .525-.399l2.247-4.755z"/>
  </svg>
)
const IconStar = styled(IconStarSvg) `
  display: block;
`

export default IconStar;
