// type
export default function(type, source, dest) {
  switch(type) {
    case 0:
      return source === dest;
    case 1:
      return source <= dest;
    case 2:
      return source >= dest;
    case 3:
      return source < dest;
    case 4:
      return source > dest;
    default:
      return source === dest;
  }
}