import styled from 'styled-components'

import COLOR from '../_const/COLOR'

const StyledTableFooter = styled.div`
  display: flex;
  align-items: stretch;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 10;
  border-top: 1px solid #ccc;
  background-color: ${COLOR.LIGHTGRAY}
`

export default StyledTableFooter;
