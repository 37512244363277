import styled from 'styled-components';

import COLOR from '../_const/COLOR';

const StyledPullDownListItem = styled.div`
  cursor: pointer;
  position: relative;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 32px;
  padding-left: 32px;
  font-weight: 600;
  word-break: break-all;
  &.is-current {
    background-color: ${COLOR.GRAY};
  }
  &.is-disabled {
    pointer-events: none;
    color: ${COLOR.DARKGRAY};
  }
`

export default StyledPullDownListItem;
