import React from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';
import { DateRangePicker } from 'react-date-range';
import { addDays, format, isEqual } from 'date-fns';

import COLOR from '../_const/COLOR';
import DATA_RANGE from '../_const/DATA_RANGE';
import FONTSIZE from '../_const/FONTSIZE';

import Button from '../atoms/Button';
import ButtonSmall from '../atoms/ButtonSmall';
import IconArrowDown from '../atoms/IconArrowDown';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';
import Switch from '../atoms/Switch';

const DATE_TYPE = 'YYYY年MM月DD日';

const StyledPullDownDatePicker = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1;
  ${
    props => {
      if (props.isOnHeader) {
        return `
          height: 40px;
        `
      }
    }
  }
`
const StyledLabel = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`
const StyledDateLabelWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const StyledDateLabelCompare = styled.div`
  ${
    props => {
      if (props.isOnHeader) {
        return `
          margin-top: 4px;
          font-size: ${ FONTSIZE.SS }px;
        `
      } else {
        return `
          margin-top: 10px;
        `
      }
    }
  }
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  position: absolute;
  top: 50px;
  z-index: 10;
  ${
    props => {
      if (props.isOnHeader) {
        return `
          right: 0;
        `
      } else {
        return `
          left: 0;
        `
      }
    }
  }
`
const StyledDatePickerWrap = styled(PullDownListWrapIn)`
  position: relative;
`
const StyledDatePickerWrapIn = styled.div`
  display: none;
  &.is-enabled {
    display: block;
  }
`
const StyledSwitchWrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 16px;
  bottom: 14px;
  color: ${COLOR.BLACK};
  font-size: ${FONTSIZE.S}px;
`
const StyledButtonWrap = styled(PullDownListWrapIn)`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  padding: 8px;
`
const StyledButton = styled(Button)`
  width: 100px;
  justify-content: center;
  margin-left: 8px;
`

class PullDownDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: props.dateRange,
      dateRangeCompare: props.dateRangeCompare,
      ranges: [],
      focusedRange: [0, 0],
      focusedRangeCompare: [0, 0],
      isOpenedPullDown: false,
      isEnabledCompare: props.isEnabledCompare,
    };
  }
  componentDidUpdate = (prevProps, prevStat) => {
    if (prevProps.isEnabledCompare !== this.props.isEnabledCompare) {
      this.setState({
        isEnabledCompare: this.props.isEnabledCompare
      });
    }
  }
  handleClickOutside(e) {
    this.cancelDateRangeChange(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false
    });
  }
  switchDateCompare = () => {
    this.setState({
      isEnabledCompare: !this.state.isEnabledCompare,
    });
  }
  applyDateRangeChange = () => {
    this.props.applyDateRange({
      dateRange: this.state.dateRange,
      dateRangeCompare: this.state.dateRangeCompare,
      isEnabledCompare: this.state.isEnabledCompare,
    });
    this.setState({
      focusedRange: [0, 0],
      focusedRangeCompare: [0, 0],
    });
    this.closePullDown();
  }
  cancelDateRangeChange = () => {
    this.setState({
      dateRange: this.props.dateRange,
      dateRangeCompare: this.props.dateRangeCompare,
      focusedRange: [0, 0],
      focusedRangeCompare: [0, 0],
      isEnabledCompare: this.props.isEnabledCompare,
    });
    this.closePullDown();
  }
  render() {
    const switchDateRange = (ranges) => {
      this.setState(ranges);
    }

    return (
      <StyledPullDownDatePicker
        className = { this.props.className }
        isOnHeader = { this.props.isOnHeader }
      >
        <StyledLabel
          onClick = { this.togglePullDown }
        >
          <StyledDateLabelWrap>
            期間：
            {
              (() => {
                // 期間の開始日と終了日が同じ場合は日付表記を1つにする
                const d1 = this.props.dateRange.startDate;
                const d2 = this.props.dateRange.endDate;
                if (isEqual(d1, d2)) {
                  return format(d1, DATE_TYPE);
                } else {
                return `${format(d1, DATE_TYPE)} ～ ${format(d2, DATE_TYPE)}`;
                }
              })()
            }
            {
              (() => {
                const d1 = this.props.dateRangeCompare.startDate;
                const d2 = this.props.dateRangeCompare.endDate;
                const date = (isEqual(d1, d2))
                  ? format(d1, DATE_TYPE)
                  : `${format(d1, DATE_TYPE)} ～ ${format(d2, DATE_TYPE)}`;
                if (this.props.isEnabledCompare === true) {
                  return (
                    <StyledDateLabelCompare
                      isOnHeader = { this.props.isOnHeader }
                    >
                      比較：{date}
                    </StyledDateLabelCompare>
                  )
                }
              })()
            }
          </StyledDateLabelWrap>
          <ButtonSmall className="m-l-8">
            <IconArrowDown />
          </ButtonSmall>
        </StyledLabel>
        <StyledPullDownListWrap
          isOnHeader = { this.props.isOnHeader }
          className = {
            this.state.isOpenedPullDown === true
              ? `is-opened`
              : `is-closed`
          }
        >
          <StyledDatePickerWrap>
            <StyledDatePickerWrapIn
              className = { this.state.isEnabledCompare === false ? 'is-enabled' : '' }
            >
              <DateRangePicker
                ranges = {
                  [
                    this.state.dateRange,
                  ]
                }
                dateDisplayFormat = { DATE_TYPE }
                staticRanges = { DATA_RANGE }
                inputRanges = { [] }
                focusedRange = { this.state.focusedRangeCompare }
                onChange = { switchDateRange }
                onRangeFocusChange = { ((e) => {
                  this.setState({
                    focusedRangeCompare: e,
                  });
                }) }
              />
            </StyledDatePickerWrapIn>
            <StyledDatePickerWrapIn
              className = { this.state.isEnabledCompare === true ? 'is-enabled' : '' }
            >
              <DateRangePicker
                ranges = {
                  [
                    this.state.dateRange,
                    this.state.dateRangeCompare,
                  ]
                }
                dateDisplayFormat = { DATE_TYPE }
                staticRanges = { DATA_RANGE }
                inputRanges = { [] }
                focusedRange = { this.state.focusedRangeCompare }
                maxDate = { addDays(new Date(), 0) }
                onChange = { switchDateRange }
                onRangeFocusChange = { ((e) => {
                  this.setState({
                    focusedRangeCompare: e,
                  });
                }) }
              />
            </StyledDatePickerWrapIn>
          </StyledDatePickerWrap>
          <StyledButtonWrap>
            <StyledSwitchWrap>
              比較
              <Switch
                className = "m-l-16"
                isEnabled = { this.state.isEnabledCompare }
                onClick = { this.switchDateCompare }
              />
            </StyledSwitchWrap>
            <StyledButton
              thin
              onClick = { this.cancelDateRangeChange }
            >
              キャンセル
            </StyledButton>
            <StyledButton
              color = "orange"
              thin
              onClick = { this.applyDateRangeChange }
            >
              適用
            </StyledButton>
          </StyledButtonWrap>
        </StyledPullDownListWrap>
      </StyledPullDownDatePicker>
    )
  }
}

export default onClickOutside(PullDownDatePicker);
