import React from 'react';
import styled from 'styled-components';

const IconExpand = ({ ...attr }) => (
  <svg
    xmlns = "http://www.w3.org/2000/svg"
    width = "24"
    height = "24"
    viewBox = "0 0 24 24"
    { ...attr }
  >
    <path
      d = "M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
    />
  </svg>
)
const StyledIconExpand = styled(IconExpand) `
  width: 16px;
  height: 16px;
  display: block;
  transition-duration: .2s;
  transition-property: transform;
  &.is-expand {
    transform: rotate(180deg);
  }
`

export default StyledIconExpand;
