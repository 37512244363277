import React from 'react';
import styled from 'styled-components'

import ButtonSmall from './ButtonSmall';
import IconEdit from './IconEdit';

const ButtonEdit = props => (
  <ButtonSmall
    as = { props.tag }
    to = { props.to }
    size = "s"
    value = { props.value }
    className = { props.className }
    onClick = { props.onClick }
  >
    <IconEdit />
  </ButtonSmall>
);

const StyledButtonEdit = styled(ButtonEdit)`
  cursor: pointer;
`;

export default StyledButtonEdit;
