import styled from 'styled-components'

const TableBodyRow = styled.div`
  display: flex;
  align-items: stretch;
  position: relative;
  border-top: 1px solid #ccc;
  background-color: #fff;
  &:first-child {
    border-top: 0;
  }
  ${
    props => {
      if (props.isOpened === true || props.isOpened === undefined) {
        return `
          display: flex;
        `
      } else {
        return `
          display: none;
        `
      }
    }
  }
`;

export default TableBodyRow;
