export default [
  {
    id: 4,
    label: "budget",
    note: "ヨミ予算"
  },
  {
    id: 5,
    label: "bugetDigestibility",
    note: "ヨミ予算消化率"
  },
  {
    id: 6,
    label: "estimateCost",
    note: "着地予測"
  },
  {
    id: 7,
    label: "imp",
    note: "表示回数"
  },
  {
    id: 8,
    label: "click",
    note: "クリック数"
  },
  {
    id: 9,
    label: "ctr",
    note: "CTR"
  },
  {
    id: 10,
    label: "cost",
    note: "費用"
  },
  {
    id: 11,
    label: "cpc",
    note: "CPC"
  },
  {
    id: 12,
    label: "cv",
    note: "CV数"
  },
  {
    id: 13,
    label: "cvr",
    note: "CVR"
  },
  {
    id: 14,
    label: "cpa",
    note: "CPA"
  },
  {
    id: 18,
    label: "impDays",
    note: "掲載日数"
  },
  {
    id: 19,
    label: "remainDailyBudget",
    note: "1日あたりの残予算"
  },
  {
    id: 20,
    label: "remainDays",
    note: "月末までの残日数"
  },
  {
    id: 21,
    label: "lastImpDay",
    note: "最終掲載日"
  },
  {
    id: 22,
    label: "userName",
    note: "担当者"
  },
  {
    id: 23,
    label: "estimateCpa",
    note: "着地CPA"
  },
  {
    id: 24,
    label: "mediaBudget",
    note: "媒体予算"
  },
  {
    id: 25,
    label: "mediaBugetDigestibility",
    note: "媒体予算消化率"
  },
  {
    id: 26,
    label: "remainDailyMediaBudget",
    note: "1日あたりの残媒体予算"
  },
  {
    id: 27,
    label: "addItem27",
    note: "追加項目1"
  },
  {
    id: 28,
    label: "addItem28",
    note: "追加項目2"
  },
  {
    id: 29,
    label: "addItem29",
    note: "追加項目3"
  },
  {
    id: 30,
    label: "addItem30",
    note: "追加項目4"
  },
  {
    id: 31,
    label: "addItem31",
    note: "追加項目5"
  },
  {
    id: 35,
    label: "mediaFee",
    note: "媒体Fee"
  },
  {
    id: 36,
    label: "label",
    note: "ラベル情報"
  },
  {
    id: 37,
    label: "cost_with_fee",
    note: "費用(gross)"
  },
  {
    id: 38,
    label: "estimateCost_with_fee",
    note: "着地予測(gross)"
  },
  {
    id: 39,
    label: "budget_with_fee",
    note: "ヨミ予算(gross)"
  },
  {
    id: 40,
    label: "bugetDigestibility_with_fee",
    note: "ヨミ予算消化率(gross)"
  },
  {
    id: 41,
    label: "mediaBudget_with_fee",
    note: "媒体予算(gross)"
  },
  {
    id: 42,
    label: "mediaBugetDigestibility_with_fee",
    note: "媒体予算消化率(gross)"
  },
  {
    id: 43,
    label: "cpc_with_fee",
    note: "CPC(gross)"
  },
  {
    id: 43,
    label: "cpc_with_fee",
    note: "CPC(gross)"
  },
  {
    id: 45,
    label: "cpa_with_fee",
    note: "CPA(gross)"
  },
  {
    id: 46,
    label: "estimateCpa_with_fee",
    note: "着地CPA(gross)"
  },
  {
    id: 47,
    label: "remainDailyBudget_with_fee",
    note: "1日あたりの残予算(gross)"
  },
  {
    id: 48,
    label: "remainDailyMediaBudget_with_fee",
    note: "1日あたりの残媒体予算(gross)"
  },
  {
    id: 50,
    label: "targetCpa",
    note: "目標CPA"
  },
  {
    id: 51,
    label: "cpaSeparate",
    note: "目標CPA乖離"
  },
  {
    id: 52,
    label: "cpaSeparate_with_fee",
    note: "目標CPA乖離(gross)"
  },
  {
    id: 53,
    label: "costOfSales",
    note: "売上原価"
  },
  {
    id: 54,
    label: "roas_with_fee",
    note: "ROAS(gross)"
  },
  {
    id: 55,
    label: "roi_with_fee",
    note: "ROI(gross)"
  },
  {
    id: 56,
    label: "ctr_kakeru_cvr",
    note: "CTR×CVR"
  },
  {
    id: 57,
    label: "revenue",
    note: "コンバージョン値"
  },
  {
    id: 58,
    label: "roas",
    note: "ROAS"
  },
  {
    id: 59,
    label: "roi",
    note: "ROI"
  },
  {
    id: 60,
    label: "dayPerEndOfMonth",
    note: "月末まで1日あたり"
  },
  {
    id: 61,
    label: "dayPerEndOfMonth_with_fee",
    note: "月末まで1日あたり(gross)"
  },
  {
    id: 62,
    label: "customerUnitPrice",
    note: "顧客単価"
  },
  {
    id: 63,
    label: "revenueNotTax",
    note: "コンバージョン値(税抜)"
  },
  {
    id: 65,
    label: "avgPosition",
    note: "平均掲載順位"
  },
  {
    id: 66,
    label: "costEstimateDividedByMediaBudget",
    note: "媒体予算に対する着地予測"
  },
  {
    id: 67,
    label: "costEstimateDividedByMediaBudget_with_fee",
    note: "媒体予算に対する着地予測(gross)"
  },
  {
    id: 68,
    label: "costEstimateDividedByBudget",
    note: "ヨミ予算に対する着地予測"
  },
  {
    id: 69,
    label: "costEstimateDividedByBudget_with_fee",
    note: "ヨミ予算に対する着地予測(gross)"
  },
  {
    id: 70,
    label: "remainCv",
    note: "残CV"
  },
  {
    id: 71,
    label: "targetCv",
    note: "目標CV"
  },
  {
    id: 76,
    label: "alertInfo",
    note: "アラート(デフォルトアラートとユーザー設定アラート両方)"
  },
  {
    id: 77,
    label: "clientName",
    note: "クライアント名"
  },
  {
    id: 78,
    label: "accountName",
    note: "アカウント名"
  },
  {
    id: 79,
    label: "media",
    note: "媒体（各媒体のIDと媒体名をセットで返す） ※詳細は媒体シート参照"
  },
  {
    id: 80,
    label: "campaignName",
    note: "キャンペーン名"
  },
  {
    id: 81,
    label: "adgroupName",
    note: "広告グループ名"
  },
  {
    id: 82,
    label: "keywordName",
    note: "キーワード名"
  },
  {
    id: 83,
    label: "impressionShare",
    note: "インプレッションシェア"
  },
  {
    id: 84,
    label: "impressionShareLostBudget",
    note: "インプレッションシェア損失率(予算)"
  },
  {
    id: 85,
    label: "impressionShareLostRank",
    note: "インプレッションシェア損失率(広告ランク)"
  },
  {
    id: 86,
    label: "impressionShareExactMatch",
    note: "完全一致のインプレッションシェア"
  },
  {
    id: 87,
    label: "qualityScore",
    note: "品質スコア"
  },
  {
    id: 88,
    label: "clientId",
    note: "クライアントID"
  },
  {
    id: 89,
    label: "accountId",
    note: "アカウントID"
  },
  {
    id: 90,
    label: "campaignId",
    note: "キャンペーンID"
  },
  {
    id: 91,
    label: "adgroupId",
    note: "広告グループID"
  },
  {
    id: 92,
    label: "keywordId",
    note: "キーワードID"
  },
  {
    id: 93,
    label: "mediaId",
    note: "媒体ID"
  },
  {
    id: 94,
    label: "estimateCv",
    note: "着地CV"
  },
  {
    id: 96,
    label: "division",
    note: "分割"
  },
  {
    id: 97,
    label: "multiCv",
    note: "複数CV"
  },
  {
    id: 98,
    label: "thirdPartyCv",
    note: "外部CV"
  },
  {
    id: 99,
    label: "mediaCv",
    note: "媒体CV"
  },
  {
    id: 100,
    label: "mediaCvr",
    note: "媒体CVR"
  },
  {
    id: 101,
    label: "mediaCpa",
    note: "媒体CPA"
  },
  {
    id: 102,
    label: "mediaCpa_with_fee",
    note: "媒体CPA(gross)"
  },
  {
    id: 103,
    label: "thirdPartyCvVal",
    note: "外部CV"
  },
  {
    id: 104,
    label: "thirdPartyCvr",
    note: "外部CVR"
  },
  {
    id: 105,
    label: "thirdPartyCpa",
    note: "外部CPA"
  },
  {
    id: 106,
    label: "thirdPartyCpa_with_fee",
    note: "外部CPA(gross)"
  },
  {
    id: 107,
    label: "cv1",
    note: "外部CV1"
  },
  {
    id: 108,
    label: "cv1Cpa",
    note: "外部CV1CPA"
  },
  {
    id: 109,
    label: "cv1Cpa_with_fee",
    note: "外部CV1CPA(gross)"
  },
  {
    id: 110,
    label: "cv1Cvr",
    note: "外部CV1CVR"
  },
  {
    id: 111,
    label: "cv1Label",
    note: "外部CV1名称"
  },
  {
    id: 112,
    label: "revenue1",
    note: "外部CV2"
  },
  {
    id: 113,
    label: "revenue1Cpa",
    note: "外部CV2CPA"
  },
  {
    id: 114,
    label: "revenue1Cpa_with_fee",
    note: "外部CV2CPA(gross)"
  },
  {
    id: 115,
    label: "revenue1Cvr",
    note: "外部CV2CVR"
  },
  {
    id: 116,
    label: "revenue1Label",
    note: "外部CV2名称"
  },
  {
    id: 117,
    label: "assist1",
    note: "外部CV3"
  },
  {
    id: 118,
    label: "assist1Cpa",
    note: "外部CV3CPA"
  },
  {
    id: 119,
    label: "assist1Cpa_with_fee",
    note: "外部CV3CPA(gross)"
  },
  {
    id: 120,
    label: "assist1Cvr",
    note: "外部CV3CVR"
  },
  {
    id: 121,
    label: "assist1Label",
    note: "外部CV3名称"
  },
  {
    id: 122,
    label: "yobi1",
    note: "外部CV4"
  },
  {
    id: 123,
    label: "yobi1Cpa",
    note: "外部CV4CPA"
  },
  {
    id: 124,
    label: "yobi1Cpa_with_fee",
    note: "外部CV4CPA(gross)"
  },
  {
    id: 125,
    label: "yobi1Cvr",
    note: "外部CV4CVR"
  },
  {
    id: 126,
    label: "yobi1Label",
    note: "外部CV4名称"
  },
  {
    id: 127,
    label: "cv2",
    note: "外部CV5"
  },
  {
    id: 128,
    label: "cv2Cpa",
    note: "外部CV5CPA"
  },
  {
    id: 129,
    label: "cv2Cpa_with_fee",
    note: "外部CV5CPA(gross)"
  },
  {
    id: 130,
    label: "cv2Cvr",
    note: "外部CV5CVR"
  },
  {
    id: 131,
    label: "cv2Label",
    note: "外部CV5名称"
  },
  {
    id: 132,
    label: "revenue2",
    note: "外部CV6"
  },
  {
    id: 133,
    label: "revenue2Cpa",
    note: "外部CV6CPA"
  },
  {
    id: 134,
    label: "revenue2Cpa_with_fee",
    note: "外部CV6CPA(gross)"
  },
  {
    id: 135,
    label: "revenue2Cvr",
    note: "外部CV6CVR"
  },
  {
    id: 136,
    label: "revenue2Label",
    note: "外部CV6名称"
  },
  {
    id: 137,
    label: "assist2",
    note: "外部CV7"
  },
  {
    id: 138,
    label: "assist2Cpa",
    note: "外部CV7CPA"
  },
  {
    id: 139,
    label: "assist2Cpa_with_fee",
    note: "外部CV7CPA(gross)"
  },
  {
    id: 140,
    label: "assist2Cvr",
    note: "外部CV7CVR"
  },
  {
    id: 141,
    label: "assist2Label",
    note: "外部CV7名称"
  },
  {
    id: 142,
    label: "yobi2",
    note: "外部CV8"
  },
  {
    id: 143,
    label: "yobi2Cpa",
    note: "外部CV8CPA"
  },
  {
    id: 144,
    label: "yobi2Cpa_with_fee",
    note: "外部CV8CPA(gross)"
  },
  {
    id: 145,
    label: "yobi2Cvr",
    note: "外部CV8CVR"
  },
  {
    id: 146,
    label: "yobi2Label",
    note: "外部CV8名称"
  },
  {
    id: 147,
    label: "cv3",
    note: "外部CV9"
  },
  {
    id: 148,
    label: "cv3Cpa",
    note: "外部CV9CPA"
  },
  {
    id: 149,
    label: "cv3Cpa_with_fee",
    note: "外部CV9CPA(gross)"
  },
  {
    id: 150,
    label: "cv3Cvr",
    note: "外部CV9CVR"
  },
  {
    id: 151,
    label: "cv3Label",
    note: "外部CV9名称"
  },
  {
    id: 152,
    label: "revenue3",
    note: "外部CV10"
  },
  {
    id: 153,
    label: "revenue3Cpa",
    note: "外部CV10CPA"
  },
  {
    id: 154,
    label: "revenue3Cpa_with_fee",
    note: "外部CV10CPA(gross)"
  },
  {
    id: 155,
    label: "revenue3Cvr",
    note: "外部CV10CVR"
  },
  {
    id: 156,
    label: "revenue3Label",
    note: "外部CV10名称"
  },
  {
    id: 157,
    label: "assist3",
    note: "外部CV11"
  },
  {
    id: 158,
    label: "assist3Cpa",
    note: "外部CV11CPA"
  },
  {
    id: 159,
    label: "assist3Cpa_with_fee",
    note: "外部CV11CPA(gross)"
  },
  {
    id: 160,
    label: "assist3Cvr",
    note: "外部CV11CVR"
  },
  {
    id: 161,
    label: "assist3Label",
    note: "外部CV11名称"
  },
  {
    id: 162,
    label: "yobi3",
    note: "外部CV12"
  },
  {
    id: 163,
    label: "yobi3Cpa",
    note: "外部CV12CPA"
  },
  {
    id: 164,
    label: "yobi3Cpa_with_fee",
    note: "外部CV12CPA(gross)"
  },
  {
    id: 165,
    label: "yobi3Cvr",
    note: "外部CV12CVR"
  },
  {
    id: 166,
    label: "yobi3Label",
    note: "外部CV12名称"
  },
  {
    id: 167,
    label: "cv4",
    note: "外部CV13"
  },
  {
    id: 168,
    label: "cv4Cpa",
    note: "外部CV13CPA"
  },
  {
    id: 169,
    label: "cv4Cpa_with_fee",
    note: "外部CV13CPA(gross)"
  },
  {
    id: 170,
    label: "cv4Cvr",
    note: "外部CV13CVR"
  },
  {
    id: 171,
    label: "cv4Label",
    note: "外部CV13名称"
  },
  {
    id: 172,
    label: "revenue4",
    note: "外部CV14"
  },
  {
    id: 173,
    label: "revenue4Cpa",
    note: "外部CV14CPA"
  },
  {
    id: 174,
    label: "revenue4Cpa_with_fee",
    note: "外部CV14CPA(gross)"
  },
  {
    id: 175,
    label: "revenue4Cvr",
    note: "外部CV14CVR"
  },
  {
    id: 176,
    label: "revenue4Label",
    note: "外部CV14名称"
  },
  {
    id: 177,
    label: "assist4",
    note: "外部CV15"
  },
  {
    id: 178,
    label: "assist4Cpa",
    note: "外部CV15CPA"
  },
  {
    id: 179,
    label: "assist4Cpa_with_fee",
    note: "外部CV15CPA(gross)"
  },
  {
    id: 180,
    label: "assist4Cvr",
    note: "外部CV15CVR"
  },
  {
    id: 181,
    label: "assist4Label",
    note: "外部CV15名称"
  },
  {
    id: 182,
    label: "yobi4",
    note: "外部CV16"
  },
  {
    id: 183,
    label: "yobi4Cpa",
    note: "外部CV16CPA"
  },
  {
    id: 184,
    label: "yobi4Cpa_with_fee",
    note: "外部CV16CPA(gross)"
  },
  {
    id: 185,
    label: "yobi4Cvr",
    note: "外部CV16CVR"
  },
  {
    id: 186,
    label: "yobi4Label",
    note: "外部CV16名称"
  },
  {
    id: 187,
    label: "cv5",
    note: "外部CV17"
  },
  {
    id: 188,
    label: "cv5Cpa",
    note: "外部CV17CPA"
  },
  {
    id: 189,
    label: "cv5Cpa_with_fee",
    note: "外部CV17CPA(gross)"
  },
  {
    id: 190,
    label: "cv5Cvr",
    note: "外部CV17CVR"
  },
  {
    id: 191,
    label: "cv5Label",
    note: "外部CV17名称"
  },
  {
    id: 192,
    label: "revenue5",
    note: "外部CV18"
  },
  {
    id: 193,
    label: "revenue5Cpa",
    note: "外部CV18CPA"
  },
  {
    id: 194,
    label: "revenue5Cpa_with_fee",
    note: "外部CV18CPA(gross)"
  },
  {
    id: 195,
    label: "revenue5Cvr",
    note: "外部CV18CVR"
  },
  {
    id: 196,
    label: "revenue5Label",
    note: "外部CV18名称"
  },
  {
    id: 197,
    label: "assist5",
    note: "外部CV19"
  },
  {
    id: 198,
    label: "assist5Cpa",
    note: "外部CV19CPA"
  },
  {
    id: 199,
    label: "assist5Cpa_with_fee",
    note: "外部CV19CPA(gross)"
  },
  {
    id: 200,
    label: "assist5Cvr",
    note: "外部CV19CVR"
  },
  {
    id: 201,
    label: "assist5Label",
    note: "外部CV19名称"
  },
  {
    id: 202,
    label: "yobi5",
    note: "外部CV20"
  },
  {
    id: 203,
    label: "yobi5Cpa",
    note: "外部CV20CPA"
  },
  {
    id: 204,
    label: "yobi5Cpa_with_fee",
    note: "外部CV20CPA(gross)"
  },
  {
    id: 205,
    label: "yobi5Cvr",
    note: "外部CV20CVR"
  },
  {
    id: 206,
    label: "yobi5Label",
    note: "外部CV20名称"
  },
  {
    id: 207,
    label: "mediaCv1",
    note: "媒体CV1"
  },
  {
    id: 208,
    label: "mediaCv1Cpa",
    note: "媒体CV1CPA"
  },
  {
    id: 209,
    label: "mediaCv1Cpa_with_fee",
    note: "媒体CV1CPA(gross)"
  },
  {
    id: 210,
    label: "mediaCv1Cvr",
    note: "媒体CV1CVR"
  },
  {
    id: 211,
    label: "mediaCv1Label",
    note: "媒体CV1名称"
  },
  {
    id: 212,
    label: "mediaCv2",
    note: "媒体2CV"
  },
  {
    id: 213,
    label: "mediaCv2Cpa",
    note: "媒体CV2CPA"
  },
  {
    id: 214,
    label: "mediaCv2Cpa_with_fee",
    note: "媒体CV2CPA(gross)"
  },
  {
    id: 215,
    label: "mediaCv2Cvr",
    note: "媒体CV2CVR"
  },
  {
    id: 216,
    label: "mediaCv2Label",
    note: "媒体CV2名称"
  },
  {
    id: 217,
    label: "mediaCv3",
    note: "媒体CV3"
  },
  {
    id: 218,
    label: "mediaCv3Cpa",
    note: "媒体CV3CPA"
  },
  {
    id: 219,
    label: "mediaCv3Cpa_with_fee",
    note: "媒体CV3CPA(gross)"
  },
  {
    id: 220,
    label: "mediaCv3Cvr",
    note: "媒体CV3CVR"
  },
  {
    id: 221,
    label: "mediaCv3Label",
    note: "媒体CV3名称"
  },
  {
    id: 222,
    label: "mediaCv4",
    note: "媒体CV4"
  },
  {
    id: 223,
    label: "mediaCv4Cpa",
    note: "媒体CV4CPA"
  },
  {
    id: 224,
    label: "mediaCv4Cpa_with_fee",
    note: "媒体CV4CPA(gross)"
  },
  {
    id: 225,
    label: "mediaCv4Cvr",
    note: "媒体CV4CVR"
  },
  {
    id: 226,
    label: "mediaCv4Label",
    note: "媒体CV4名称"
  },
  {
    id: 227,
    label: "mediaCv5",
    note: "媒体CV5"
  },
  {
    id: 228,
    label: "mediaCv5Cpa",
    note: "媒体CV5CPA"
  },
  {
    id: 229,
    label: "mediaCv5Cpa_with_fee",
    note: "媒体CV5CPA(gross)"
  },
  {
    id: 230,
    label: "mediaCv5Cvr",
    note: "媒体CV5CVR"
  },
  {
    id: 231,
    label: "mediaCv5Label",
    note: "媒体CV5名称"
  },
  {
    id: 232,
    label: "mediaCv6",
    note: "媒体CV6"
  },
  {
    id: 233,
    label: "mediaCv6Cpa",
    note: "媒体CV6CPA"
  },
  {
    id: 234,
    label: "mediaCv6Cpa_with_fee",
    note: "媒体CV6CPA(gross)"
  },
  {
    id: 235,
    label: "mediaCv6Cvr",
    note: "媒体CV6CVR"
  },
  {
    id: 236,
    label: "mediaCv6Label",
    note: "媒体CV6名称"
  },
  {
    id: 237,
    label: "mediaCv7",
    note: "媒体CV7"
  },
  {
    id: 238,
    label: "mediaCv7Cpa",
    note: "媒体CV7CPA"
  },
  {
    id: 239,
    label: "mediaCv7Cpa_with_fee",
    note: "媒体CV7CPA(gross)"
  },
  {
    id: 240,
    label: "mediaCv7Cvr",
    note: "媒体CV7CVR"
  },
  {
    id: 241,
    label: "mediaCv7Label",
    note: "媒体CV7名称"
  },
  {
    id: 242,
    label: "mediaCv8",
    note: "媒体CV8"
  },
  {
    id: 243,
    label: "mediaCv8Cpa",
    note: "媒体CV8CPA"
  },
  {
    id: 244,
    label: "mediaCv8Cpa_with_fee",
    note: "媒体CV8CPA(gross)"
  },
  {
    id: 245,
    label: "mediaCv8Cvr",
    note: "媒体CV8CVR"
  },
  {
    id: 246,
    label: "mediaCv8Label",
    note: "媒体CV8名称"
  },
  {
    id: 247,
    label: "mediaCv9",
    note: "媒体CV9"
  },
  {
    id: 248,
    label: "mediaCv9Cpa",
    note: "媒体CV9CPA"
  },
  {
    id: 249,
    label: "mediaCv9Cpa_with_fee",
    note: "媒体CV9CPA(gross)"
  },
  {
    id: 250,
    label: "mediaCv9Cvr",
    note: "媒体CV9CVR"
  },
  {
    id: 251,
    label: "mediaCv9Label",
    note: "媒体CV9名称"
  },
  {
    id: 252,
    label: "mediaCv10",
    note: "媒体CV10"
  },
  {
    id: 253,
    label: "mediaCv10Cpa",
    note: "媒体CV10CPA"
  },
  {
    id: 254,
    label: "mediaCv10Cpa_with_fee",
    note: "媒体CV10CPA(gross)"
  },
  {
    id: 255,
    label: "mediaCv10Cvr",
    note: "媒体CV10CVR"
  },
  {
    id: 256,
    label: "mediaCv10Label",
    note: "媒体CV10名称"
  },
  {
    id: 257,
    label: "mediaCv11",
    note: "媒体CV11"
  },
  {
    id: 258,
    label: "mediaCv11Cpa",
    note: "媒体CV11CPA"
  },
  {
    id: 259,
    label: "mediaCv11Cpa_with_fee",
    note: "媒体CV11CPA(gross)"
  },
  {
    id: 260,
    label: "mediaCv11Cvr",
    note: "媒体CV11CVR"
  },
  {
    id: 261,
    label: "mediaCv11Label",
    note: "媒体CV11名称"
  },
  {
    id: 262,
    label: "mediaCv12",
    note: "媒体CV12"
  },
  {
    id: 263,
    label: "mediaCv12Cpa",
    note: "媒体CV12CPA"
  },
  {
    id: 264,
    label: "mediaCv12Cpa_with_fee",
    note: "媒体CV12CPA(gross)"
  },
  {
    id: 265,
    label: "mediaCv12Cvr",
    note: "媒体CV12CVR"
  },
  {
    id: 266,
    label: "mediaCv12Label",
    note: "媒体CV12名称"
  },
  {
    id: 267,
    label: "mediaCv13",
    note: "媒体CV13"
  },
  {
    id: 268,
    label: "mediaCv13Cpa",
    note: "媒体CV13CPA"
  },
  {
    id: 269,
    label: "mediaCv13Cpa_with_fee",
    note: "媒体CV13CPA(gross)"
  },
  {
    id: 270,
    label: "mediaCv13Cvr",
    note: "媒体CV13CVR"
  },
  {
    id: 271,
    label: "mediaCv13Label",
    note: "媒体CV13名称"
  },
  {
    id: 272,
    label: "mediaCv14",
    note: "媒体CV14"
  },
  {
    id: 273,
    label: "mediaCv14Cpa",
    note: "媒体CV14CPA"
  },
  {
    id: 274,
    label: "mediaCv14Cpa_with_fee",
    note: "媒体CV14CPA(gross)"
  },
  {
    id: 275,
    label: "mediaCv14Cvr",
    note: "媒体CV14CVR"
  },
  {
    id: 276,
    label: "mediaCv14Label",
    note: "媒体CV14名称"
  },
  {
    id: 277,
    label: "mediaCv15",
    note: "媒体CV15"
  },
  {
    id: 278,
    label: "mediaCv15Cpa",
    note: "媒体CV15CPA"
  },
  {
    id: 279,
    label: "mediaCv15Cpa_with_fee",
    note: "媒体CV15CPA(gross)"
  },
  {
    id: 280,
    label: "mediaCv15Cvr",
    note: "媒体CV15CVR"
  },
  {
    id: 281,
    label: "mediaCv15Label",
    note: "媒体CV15名称"
  },
  {
    id: 282,
    label: "mediaCv16",
    note: "媒体CV16"
  },
  {
    id: 283,
    label: "mediaCv16Cpa",
    note: "媒体CV16CPA"
  },
  {
    id: 284,
    label: "mediaCv16Cpa_with_fee",
    note: "媒体CV16CPA(gross)"
  },
  {
    id: 285,
    label: "mediaCv16Cvr",
    note: "媒体CV16CVR"
  },
  {
    id: 286,
    label: "mediaCv16Label",
    note: "媒体CV16名称"
  },
  {
    id: 287,
    label: "mediaCv17",
    note: "媒体CV17"
  },
  {
    id: 288,
    label: "mediaCv17Cpa",
    note: "媒体CV17CPA"
  },
  {
    id: 289,
    label: "mediaCv17Cpa_with_fee",
    note: "媒体CV17CPA(gross)"
  },
  {
    id: 290,
    label: "mediaCv17Cvr",
    note: "媒体CV17CVR"
  },
  {
    id: 291,
    label: "mediaCv17Label",
    note: "媒体CV17名称"
  },
  {
    id: 292,
    label: "mediaCv18",
    note: "媒体CV18"
  },
  {
    id: 293,
    label: "mediaCv18Cpa",
    note: "媒体CV18CPA"
  },
  {
    id: 294,
    label: "mediaCv18Cpa_with_fee",
    note: "媒体CV18CPA(gross)"
  },
  {
    id: 295,
    label: "mediaCv18Cvr",
    note: "媒体CV18CVR"
  },
  {
    id: 296,
    label: "mediaCv18Label",
    note: "媒体CV18名称"
  },
  {
    id: 297,
    label: "mediaCv19",
    note: "媒体CV19"
  },
  {
    id: 298,
    label: "mediaCv19Cpa",
    note: "媒体CV19CPA"
  },
  {
    id: 299,
    label: "mediaCv19Cpa_with_fee",
    note: "媒体CV19CPA(gross)"
  },
  {
    id: 300,
    label: "mediaCv19Cvr",
    note: "媒体CV19CVR"
  },
  {
    id: 301,
    label: "mediaCv19Label",
    note: "媒体CV19名称"
  },
  {
    id: 302,
    label: "mediaCv20",
    note: "媒体CV20"
  },
  {
    id: 303,
    label: "mediaCv20Cpa",
    note: "媒体CV20CPA"
  },
  {
    id: 304,
    label: "mediaCv20Cpa_with_fee",
    note: "媒体CV20CPA(gross)"
  },
  {
    id: 305,
    label: "mediaCv20Cvr",
    note: "媒体CV20CVR"
  },
  {
    id: 306,
    label: "mediaCv20Label",
    note: "媒体CV20名称"
  },
  {
    id: 307,
    label: "budget_label",
    note: "ヨミ予算(ラベル)"
  },
  {
    id: 308,
    label: "budget_with_fee_label",
    note: "ヨミ予算(gross)(ラベル)"
  },
  {
    id: 309,
    label: "bugetDigestibility_label",
    note: "ヨミ予算消化率(ラベル)"
  },
  {
    id: 310,
    label: "bugetDigestibility_with_fee_label",
    note: "ヨミ予算消化率(gross)(ラベル)"
  },
  {
    id: 311,
    label: "costEstimateDividedByBudget_label",
    note: "ヨミ予算に対する着地予測(着地予測/ヨミ予算)(ラベル)"
  },
  {
    id: 312,
    label: "costEstimateDividedByBudget_with_fee_label",
    note: "ヨミ予算に対する着地予測(着地予測/ヨミ予算)(gross)(ラベル)"
  },
  {
    id: 313,
    label: "allCv",
    note: "すべてのCV数"
  },
  {
    id: 314,
    label: "allCvr",
    note: "CVR(すべて)"
  },
  {
    id: 315,
    label: "allCpa",
    note: "CPA(すべて)"
  },
  {
    id: 316,
    label: "allCpa_with_fee",
    note: "CPA(すべて)(gross)"
  },
  {
    id: 317,
    label: "allRevenue",
    note: "すべてのCV値"
  },
  {
    id: 318,
    label: "vtCv",
    note: "ビュースルーCV数"
  },
  {
    id: 319,
    label: "cv1Roas",
    note: "外部CV1ROAS"
  },
  {
    id: 320,
    label: "cv1Roas_with_fee",
    note: "外部CV1ROAS(gross)"
  },
  {
    id: 321,
    label: "revenue1Roas",
    note: "外部CV2ROAS"
  },
  {
    id: 322,
    label: "revenue1Roas_with_fee",
    note: "外部CV2ROAS(gross)"
  },
  {
    id: 323,
    label: "assist1Roas",
    note: "外部CV3ROAS"
  },
  {
    id: 324,
    label: "assist1Roas_with_fee",
    note: "外部CV3ROAS(gross)"
  },
  {
    id: 325,
    label: "yobi1Roas",
    note: "外部CV4ROAS"
  },
  {
    id: 326,
    label: "yobi1Roas_with_fee",
    note: "外部CV4ROAS(gross)"
  },
  {
    id: 327,
    label: "cv2Roas",
    note: "外部CV5ROAS"
  },
  {
    id: 328,
    label: "cv2Roas_with_fee",
    note: "外部CV5ROAS(gross)"
  },
  {
    id: 329,
    label: "revenue2Roas",
    note: "外部CV6ROAS"
  },
  {
    id: 330,
    label: "revenue2Roas_with_fee",
    note: "外部CV6ROAS(gross)"
  },
  {
    id: 331,
    label: "assist2Roas",
    note: "外部CV7ROAS"
  },
  {
    id: 332,
    label: "assist2Roas_with_fee",
    note: "外部CV7ROAS(gross)"
  },
  {
    id: 333,
    label: "yobi2Roas",
    note: "外部CV8ROAS"
  },
  {
    id: 334,
    label: "yobi2Roas_with_fee",
    note: "外部CV8ROAS(gross)"
  },
  {
    id: 335,
    label: "cv3Roas",
    note: "外部CV9ROAS"
  },
  {
    id: 336,
    label: "cv3Roas_with_fee",
    note: "外部CV9ROAS(gross)"
  },
  {
    id: 337,
    label: "revenue3Roas",
    note: "外部CV10ROAS"
  },
  {
    id: 338,
    label: "revenue3Roas_with_fee",
    note: "外部CV10ROAS(gross)"
  },
  {
    id: 339,
    label: "assist3Roas",
    note: "外部CV11ROAS"
  },
  {
    id: 340,
    label: "assist3Roas_with_fee",
    note: "外部CV11ROAS(gross)"
  },
  {
    id: 341,
    label: "yobi3Roas",
    note: "外部CV12ROAS"
  },
  {
    id: 342,
    label: "yobi3Roas_with_fee",
    note: "外部CV12ROAS(gross)"
  },
  {
    id: 343,
    label: "cv4Roas",
    note: "外部CV13ROAS"
  },
  {
    id: 344,
    label: "cv4Roas_with_fee",
    note: "外部CV13ROAS(gross)"
  },
  {
    id: 345,
    label: "revenue4Roas",
    note: "外部CV14ROAS"
  },
  {
    id: 346,
    label: "revenue4Roas_with_fee",
    note: "外部CV14ROAS(gross)"
  },
  {
    id: 347,
    label: "assist4Roas",
    note: "外部CV15ROAS"
  },
  {
    id: 348,
    label: "assist4Roas_with_fee",
    note: "外部CV15ROAS(gross)"
  },
  {
    id: 349,
    label: "yobi4Roas",
    note: "外部CV16ROAS"
  },
  {
    id: 350,
    label: "yobi4Roas_with_fee",
    note: "外部CV16ROAS(gross)"
  },
  {
    id: 351,
    label: "cv5Roas",
    note: "外部CV17ROAS"
  },
  {
    id: 352,
    label: "cv5Roas_with_fee",
    note: "外部CV17ROAS(gross)"
  },
  {
    id: 353,
    label: "revenue5Roas",
    note: "外部CV18ROAS"
  },
  {
    id: 354,
    label: "revenue5Roas_with_fee",
    note: "外部CV18ROAS(gross)"
  },
  {
    id: 355,
    label: "assist5Roas",
    note: "外部CV19ROAS"
  },
  {
    id: 356,
    label: "assist5Roas_with_fee",
    note: "外部CV19ROAS(gross)"
  },
  {
    id: 357,
    label: "yobi5Roas",
    note: "外部CV20ROAS"
  },
  {
    id: 358,
    label: "yobi5Roas_with_fee",
    note: "外部CV20ROAS(gross)"
  },
  {
    id: 359,
    label: "targetRoas",
    note: "目標ROAS"
  },
  {
    id: 360,
    label: "targetMr",
    note: "目標MR"
  },
  {
    id: 361,
    label: "labelList",
    note: "媒体ラベル"
  },
]
