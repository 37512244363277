import React from 'react';
import styled from 'styled-components';

const IconUnfoldMore = ({ ...attr }) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width = "24"
    height = "24"
    viewBox = "0 0 24 24"
    { ...attr }
  >
    <path d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z"/>
  </svg>
)
const StyledIconUnfoldMore = styled(IconUnfoldMore) `
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
  display: block;
`

export default StyledIconUnfoldMore;
