import React from 'react';
import styled from 'styled-components';

import ButtonSmall from '../atoms/ButtonSmall';
import IconArrowDown from '../atoms/IconArrowDown';

const StyledPullDown = styled.div`
  cursor: pointer;
  display: flex;
`

const PullDown = (props) => (
  <StyledPullDown
    onClick = {props.onClick}
    className = {props.className}
  >
    {props.children}
    <ButtonSmall className="m-l-8">
      <IconArrowDown />
    </ButtonSmall>
  </StyledPullDown>
);

export default PullDown;
