import React from 'react';

import Base from './Base';

class Client extends React.Component {
  constructor(props) {
    super(props);
    props.switchContentTitle('クライアント');
  }
  render() {
    return (
      <Base
        target = { 1 }
        targetId = { 'clientId' }
        targetName = { 'clientName' }
        hasGraph = { true }
        { ...this.props }
      />
    )
  }
}

export default Client;
