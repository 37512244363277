import React from 'react';
import styled from 'styled-components';

const IconTricolon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="3" height="15" viewBox="0, 0, 3, 15" className={props.className}>
    <path fillRule="evenodd" fill="#FFF" d="M1.5 9a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 1.5 9zm0-6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 9a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 1.5 12z"/>
  </svg>
)
const StyledIconTricolon = styled(IconTricolon) `
  display: block;
`

export default StyledIconTricolon;
