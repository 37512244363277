import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'
import FONTSIZE from '../_const/FONTSIZE'

const InputText = ({ type, defaultValue, value, ...attr }) => (
  <input
    type = { type ? type : 'text' }
    defaultValue = { defaultValue }
    value = { value }
    { ...attr }
  />
);

const StyledInputText = styled(InputText)`
  width: ${props => props.width ? props.width : 160}px;
  height: 40px;
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
  border-top: 0;
  border-bottom: 1px solid #ccc;
  border-right: 0;
  border-left: 0;
  font-size: ${FONTSIZE.S}px;
  background-color: ${COLOR.LIGHTGRAY};

`

export default StyledInputText;
