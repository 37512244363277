import styled from 'styled-components'

import COLOR from '../_const/COLOR'

const getSize = (size) => {
  let s = 0;
  switch (size) {
    case 'm':  s = 17; break;
    case 's':  s = 15; break;
    case 'ss': s = 13; break;
    default:   s = 17;
  }
  return `
    width: ${s}px;
    height: ${s}px;
  `
}

const ButtonSmall = styled.button`
  ${ props => getSize(props.size) }
  box-shadow: 0 1px 0px ${
    props => props.dark ? '#6f6f6f' : '#cecece'
  };
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: ${
    props => props.circle === true ? '50%' : '2px'
  };
  font-size: 1em;
  background-color: ${
    props => props.dark ? COLOR.DARKGRAY : COLOR.GRAY
  };
`;

export default ButtonSmall;
