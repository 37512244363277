import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'
import FONTSIZE from '../_const/FONTSIZE'

import IconCheck from './IconCheck';

const StyledInput = styled.input`
  display: none;
`
const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-left: 24px;
  font-size: ${FONTSIZE.S}px;
  font-weight: 600;
`
const StyledCheckbox = styled.div`
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 3px;
  border: 1px solid #cdcdcd;
  background-color: ${COLOR.LIGHTGRAY};
  input:checked + label > & {
    border-color: ${COLOR.GREEN};
    background-color: ${COLOR.GREEN};
  }
`
const StyledIconCheck = styled(IconCheck)`
  display: none;
  input:checked + label & {
    display: block;
  }
`

const CheckboxWithLabel = (props) => (
  <div className={props.className}>
    <StyledInput
      type = "checkbox"
      id = {props.id}
      name = {props.name}
      value = {props.value}
      checked = {props.checked}
      onChange = {props.onChange}
    />
    <StyledLabel htmlFor={props.id}>
      <StyledCheckbox>
        <StyledIconCheck />
      </StyledCheckbox>
      { props.children }
    </StyledLabel>

  </div>
);

export default CheckboxWithLabel;
