import React from 'react';
import styled from 'styled-components';

const IconArrowLLSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0, 0, 6, 9" className={props.className}>
    <path fillRule="evenodd" fill="#666" d="M5.989 8.366l-.596.622L.679 5.059l-.072.061-.577-.602-.019-.015.002-.002-.002-.002.018-.015.578-.604.073.061L5.393.012l.596.621L1.35 4.5l4.639 3.866z"/>
  </svg>
)
const IconArrowLL = styled(IconArrowLLSvg) `
  display: block;
`

export default IconArrowLL;
