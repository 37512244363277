export default [
  {
    "name": "imp",
    "id": 7,
    "label": "表示回数",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "回"
  },
  {
    "name": "click",
    "id": 8,
    "label": "クリック数",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "回"
  },
  {
    "name": "ctr",
    "id": 9,
    "label": "CTR",
    "min": 0,
    "max": 100,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "cost",
    "id": 10,
    "label": "費用",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "cost_with_fee",
    "id": 37,
    "label": "費用(gross)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "cpc",
    "id": 11,
    "label": "CPC",
    "min": 0,
    "max": undefined,
    "step": 0,
    "unit": "円"
  },
  {
    "name": "cpc_with_fee",
    "id": 43,
    "label": "CPC(gross)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "cv",
    "id": 12,
    "label": "CV数",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "件"
  },
  {
    "name": "cvr",
    "id": 13,
    "label": "CVR",
    "min": 0,
    "max": 100,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "cpa",
    "id": 14,
    "label": "CPA",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "cpa_with_fee",
    "id": 45,
    "label": "CPA(gross)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "revenue",
    "id": 57,
    "label": "コンバージョン値",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "revenueNotTax",
    "id": 63,
    "label": "コンバージョン値(税抜)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "roas",
    "id": 58,
    "label": "ROAS",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "roas_with_fee",
    "id": 54,
    "label": "ROAS(gross)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "roi",
    "id": 59,
    "label": "ROI",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "roi_with_fee",
    "id": 55,
    "label": "ROI(gross)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "mediaBudget",
    "id": 24,
    "label": "媒体予算",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "mediaBudget_with_fee",
    "id": 41,
    "label": "媒体予算(gross)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "mediaBugetDigestibility",
    "id": 25,
    "label": "媒体予算消化率",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "mediaBugetDigestibility_with_fee",
    "id": 42,
    "label": "媒体予算消化率（込）",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByMediaBudget",
    "id": 66,
    "label": "媒体予算に対する着地予測",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByMediaBudget_with_fee",
    "id": 67,
    "label": "媒体予算に対する着地予測(gross)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByMediaBudget1",
    "id": 1012,
    "label": "媒体予算に対する着地予測(過去1日)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByMediaBudget_with_fee1",
    "id": 1013,
    "label": "媒体予算に対する着地予測(過去1日)(gross)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByMediaBudget3",
    "id": 1014,
    "label": "媒体予算に対する着地予測(過去3日)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByMediaBudget_with_fee3",
    "id": 1015,
    "label": "媒体予算に対する着地予測(過去3日)(gross)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByMediaBudget7",
    "id": 1016,
    "label": "媒体予算に対する着地予測(過去7日)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByMediaBudget_with_fee7",
    "id": 1017,
    "label": "媒体予算に対する着地予測(過去7日)(gross)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "budget",
    "id": 4,
    "label": "ヨミ予算",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "budget_with_fee",
    "id": 39,
    "label": "ヨミ予算(gross)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "bugetDigestibility",
    "id": 5,
    "label": "ヨミ予算消化率",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "bugetDigestibility_with_fee",
    "id": 40,
    "label": "ヨミ予算消化率(gross)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByBudget",
    "id": 68,
    "label": "ヨミ予算に対する着地予測",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByBudget_with_fee",
    "id": 69,
    "label": "ヨミ予算に対する着地予測(gross)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByBudget1",
    "id": 1006,
    "label": "ヨミ予算に対する着地予測(過去1日)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByBudget_with_fee1",
    "id": 1007,
    "label": "ヨミ予算に対する着地予測(過去1日)(gross)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByBudget3",
    "id": 1008,
    "label": "ヨミ予算に対する着地予測(過去3日)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByBudget_with_fee3",
    "id": 1009,
    "label": "ヨミ予算に対する着地予測(過去3日)(gross)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByBudget7",
    "id": 1010,
    "label": "ヨミ予算に対する着地予測(過去7日)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "costEstimateDividedByBudget_with_fee7",
    "id": 1011,
    "label": "ヨミ予算に対する着地予測(過去7日)(gross)",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "estimateCost",
    "id": 6,
    "label": "着地予測",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "estimateCost_with_fee",
    "id": 38,
    "label": "着地予測(gross)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "estimateCost1",
    "id": 1000,
    "label": "着地予測(過去1日)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "estimateCost1_with_fee",
    "id": 1003,
    "label": "着地予測(過去1日)(gross)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "estimateCost3",
    "id": 1001,
    "label": "着地予測(過去3日)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "estimateCost3_with_fee",
    "id": 1004,
    "label": "着地予測(過去3日)(gross)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "estimateCost7",
    "id": 1002,
    "label": "着地予測(過去7日)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "estimateCost7_with_fee",
    "id": 1005,
    "label": "着地予測(過去7日)(gross)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "estimateCpa",
    "id": 23,
    "label": "着地CPA",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "estimateCpa_with_fee",
    "id": 46,
    "label": "着地CPA(gross)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "estimateCv",
    "id": 94,
    "label": "着地CV",
    "min": 0,
    "max": undefined,
    "step": 0.01,
    "unit": "件"
  },
  {
    "name": "targetCv",
    "id": 71,
    "label": "目標CV",
    "min": 0,
    "max": 0,
    "step": 0,
    "unit": "件"
  },
  {
    "name": "targetCpa",
    "id": 50,
    "label": "目標CPA",
    "min": 0,
    "max": 0,
    "step": 0,
    "unit": "円"
  },
  {
    "name": "cpaSeparate",
    "id": 51,
    "label": "目標CPA乖離",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "cpaSeparate_with_fee",
    "id": 52,
    "label": "目標CPA乖離(gross)",
    "min": 0,
    "max": undefined,
    "step": 1,
    "unit": "円"
  },
  {
    "name": "impressionShare",
    "id": 83,
    "label": "インプレッションシェア",
    "min": 0,
    "max": 100,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "impressionShareLostBudget",
    "id": 84,
    "label": "インプレッションシェア損失率(予算)",
    "min": 0,
    "max": 100,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "impressionShareLostRank",
    "id": 85,
    "label": "インプレッションシェア損失率(広告ランク)",
    "min": 0,
    "max": 100,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "impressionShareExactMatch",
    "id": 86,
    "label": "完全一致のインプレッションシェア",
    "min": 0,
    "max": 100,
    "step": 0.01,
    "unit": "%"
  },
  {
    "name": "remainCv",
    "id": 70,
    "label": "残CV",
    "min": 0,
    "max": 0,
    "step": 0,
    "unit": "件"
  }
]
