import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  min-width: 1px;
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 0;
`;

const StyledInputVariableDummy = styled.div`
  min-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 3px;
  opacity: 0;
  &::before {
    content: '';
  }
`;

const StyledInputVariableBody = styled.div`
  position: relative;
`;


class InputVariable extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <StyledInputVariableBody>
        <StyledInputVariableDummy>{ this.props.inputValue }</StyledInputVariableDummy>
        <StyledInput
          type = 'text'
          value = { this.props.inputValue }
          ref = { this.props.innerRef }
          autoFocus = { this.props.isAutoFocus }
          onKeyDown = { this.props.handleKeyDown }
          onChange = { this.props.handleChange }
          onFocus = { this.props.handleFocus }
        />
      </StyledInputVariableBody>
    )
  }
}

export default InputVariable;
