import React from 'react';
import styled from 'styled-components';

const IconDisplay = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0, 0, 9, 18" className={props.className}>
    <path fillRule="evenodd" fill="gray" d="M8 18V0h1v18H8zM4 0h1v18H4V0zM0 0h1v18H0V0z"/>
  </svg>
)
const StyledIconDisplay = styled(IconDisplay) `
  display: block;
`

export default StyledIconDisplay;
