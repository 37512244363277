import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import COLOR from '../_const/COLOR'
import DISPITEM_ALERT from '../_const/DISPITEM_ALERT'
import FONTSIZE from '../_const/FONTSIZE'

const StyledLabel = styled.span`
  margin-right: 8px;
  margin-left: 8px;
  color: ${COLOR.GREEN};
  font-size: ${FONTSIZE.M}px;
  font-weight: 700;
  &:first-child {
    margin-left: 0;
  }
`

class AlertConditionText extends React.Component {
  render() {
    const renderText = (isAddCondition) => {
      let targetColId;
      let targetIsRelative;
      let timing;
      let timingCustom;
      let targetDay;
      let targetNum;
      let compEqualSign;

      if (isAddCondition === true) {
        targetColId = this.props.param.targetColIdAdd;
        targetIsRelative = this.props.param.targetIsRelativeAdd;
        timing = this.props.param.timingAdd;
        timingCustom = this.props.param.timingCustomAdd;
        targetDay = this.props.param.targetDayAdd;
        targetNum = this.props.param.targetNumAdd;
        compEqualSign = this.props.param.compEqualSignAdd;
      } else {
        targetColId = this.props.param.targetColId;
        targetIsRelative = this.props.param.targetIsRelative;
        timing = this.props.param.timing;
        timingCustom = this.props.param.timingCustom;
        targetDay = this.props.param.targetDay;
        targetNum = this.props.param.targetNum;
        compEqualSign = this.props.param.compEqualSign;
      }

      const dispItem = _.find(DISPITEM_ALERT, o => {
        return o.id === targetColId
      });

      if (dispItem) {
        return (
          <span>
            <StyledLabel>
              { dispItem.label }
            </StyledLabel>
            { (timing === 0) ? 'の' : 'において' }
            <StyledLabel>
              {
                (() => {
                  switch (timing) {
                    case 1: return '昨日と比較した';
                    case 2: return '過去7日間と比較した';
                    case 3: return '過去14日間と比較した';
                    case 4: return '過去30日間と比較した';
                    case 5: return '先月と比較した';
                    case 6: return `残り${timingCustom}日時点での`;
                    case 7: return `月初から${targetDay}日経過した時点での`;
                    case 0:
                    default: return '';
                  }
                })()
              }
              {
                (() => {
                  return (timing === 0)
                    ? '値'
                    : (targetIsRelative === 0)
                      ? '値の差'
                      : '値の割合';
                })()
              }
            </StyledLabel>
            が
            <StyledLabel>
              {
                (() => {
                  if (targetIsRelative === 2) {
                    return `${targetNum}%`
                  } else if (dispItem.unit !== '%') {
                    return `${targetNum.toLocaleString()}${dispItem.unit}`;
                  } else {
                    return `${targetNum}${dispItem.unit}`;
                  }
                })()
              }
              {
                (() => {
                  if (timing === 0) {
                    switch (compEqualSign) {
                      case 1: return 'より大きくなった';
                      case 2: return 'より小さくなった';
                      case 3: return '以上になった';
                      case 4: return '以下になった';
                      case 5: return 'と等しくなった';
                      case 0:
                      default: return '';
                    }
                  } if (timing === 6 || timing === 7) {
                    switch (compEqualSign) {
                      case 1: return 'より大きかった';
                      case 2: return 'より小さかった';
                      case 3: return '以上だった';
                      case 4: return '以下だった';
                      case 5: return 'と等しかった';
                      case 0:
                      default: return '';
                    }
                  } else {
                    switch (compEqualSign) {
                      case 1: return 'より大きく増加した';
                      case 2: return 'より大きく減少した';
                      case 3: return '以上増加した';
                      case 4: return '以下減少した';
                      case 5: return 'と等しくなった';
                      case 0:
                      default: return '';
                    }
                  }
                })()
              }
            </StyledLabel>
            ときに
            {
              (() => {
                if (isAddCondition === false && this.props.param.addConditionKind > 0) {
                  return '、';
                } else {
                  return 'アラートを出す。';
                }
              })()
            }
          </span>
        )
      } else {
        return '';
      }
    }

    return (
      <div
        className = { this.props.className }
      >
        {
          (() => {
            return renderText(false)
          })()
        }
        {
          (() => {
            if (this.props.param.addConditionKind > 0) {
              return (
                <div>
                  {
                    (() => {
                      switch (this.props.param.addConditionKind) {
                        case 1: return 'または、';
                        case 2: return '且つ、';
                        default: return '';
                      }
                    })()
                  }
                  {
                    (() => {
                      return renderText(true)
                    })()
                  }
                </div>
              )
            }
          })()
        }

      </div>
    )
  }
}

export default AlertConditionText;
