import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'

import IconClose from './IconClose'

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${ COLOR.RED };
`
const StyledIcon = styled.div`
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  border-radius: 50%;
  background-color: ${ COLOR.RED };
`

const LabelIncorrect = (props) => (
  <StyledLabel>
    { props.children }
    <StyledIcon>
      <IconClose />
    </StyledIcon>
  </StyledLabel>
);

export default LabelIncorrect;
