import React from 'react';
import styled from 'styled-components';

const IconQuestionSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0, 0, 25, 25" className={props.className}>
    <path fillRule="evenodd" stroke="gray" fill="none" d="M12.5 3.5a9 9 0 1 1 0 18 9 9 0 0 1 0-18z"/>
    <path fillRule="evenodd" fill="gray" d="M12.987 16.936c0 .312-.273.565-.606.565-.334 0-.606-.253-.606-.565 0-.309.272-.563.606-.563.331 0 .606.254.606.563zm2.513-6.675c0 .987-.606 1.893-1.728 2.511-.819.452-.788.987-.788 1.016V15.144c0 .282-.241.507-.545.507-.302 0-.575-.225-.575-.507v-1.27c-.03-.451.214-1.328 1.302-1.946.544-.311 1.181-.846 1.181-1.634 0-.96-.847-1.751-1.879-1.751-1.033 0-1.88.789-1.88 1.751 0 .281-.241.505-.544.505-.303 0-.544-.224-.544-.505 0-1.553 1.332-2.793 2.998-2.793 1.667 0 3.002 1.21 3.002 2.76z"/>
  </svg>
)
const IconQuestion = styled(IconQuestionSvg) `
  display: block;
  margin-left:15px;
`

export default IconQuestion;
