import React from 'react';
import styled from 'styled-components';

const IconCheck = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0, 0, 9, 6" className={props.className}>
    <path fillRule="evenodd" fill="#FFF" d="M8.995.839l-4.564 4.32.005.005-.89.833h-.001l-.89-.833.023-.022L.005 2.504l.89-.831 2.669 2.631L8.105.005l.89.834z"/>
  </svg>
)
const StyledIconCheck = styled(IconCheck)`
  display: block;
`

export default StyledIconCheck;
