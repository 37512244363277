import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import FONTSIZE from '../../_const/FONTSIZE';
import USER_AUTH from '../../_const/USER_AUTH';

import BarBottom from '../../atoms/BarBottom';
import Button from '../../atoms/Button';
import FlexBox from '../../atoms/FlexBox';
import HeadingM from '../../atoms/HeadingM';
import InputText from '../../atoms/InputText';
import LabelRequire from '../../atoms/LabelRequire';
import PullDownCommon from '../../organisms/PullDownCommon';

import User from '../../../utils/user';

const StyledInputLabel = styled.div`
  width: 10em;
  font-size: ${FONTSIZE.S}px;
`
const backendApi = process.env.REACT_APP_BACKEND_URI;

class AuthorityUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestParam: {
        id: 0, // ユーザーデータID ※新規追加の場合は0もしくは空
        userName: '', // ユーザー名
        mailAddress: '', // メールアドレス
        groupId: this.props.groups[1].groupId, // 部署グループID
        auth: 1, // 権限 1:編集者、2:管理者
      },
      selectedSectionCode: this.props.groups[1].sectionCode,
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('権限管理');
    props.toggleDatePicker(false);
  }
  render() {
    // ユーザー名を入力
    const inputUserName = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          userName: e.currentTarget.value,
        }
      });
    }

    // メールアドレスを入力
    const inputMailAddress = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          mailAddress: e.currentTarget.value,
        }
      });
    }

    // ドロップダウンで部署を選択
    const selectSection = (id) => {
      this.setState({
        selectedSectionCode: id,
        requestParam: {
          ...this.state.requestParam,
          groupId: _.find(this.props.groups, o => {
            return o.sectionCode === id;
          }).groupId,
        }
      });
    }

    // ドロップダウンでグループを選択
    const selectGroup = (id) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          groupId: id,
        }
      });
    }

    // ドロップダウンで権限を選択
    const selectUserAuth = (id) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          auth: id,
        }
      });
    }

    // ユーザー招待
    const inviteNewUser = () => {
      // プログレス表示
      this.props.startProgressing();

      axios.get(backendApi + 'userModify', {
        params: {
          ...User.apiParams(),
          func: 1,
          ...this.state.requestParam,
        }
      })
      .then((response) => {
        this.props.history.push('/setting/authority');

        //　プログレス非表示
        this.props.endProgressing();
      })
      .catch(() => {
      });
    }

    return (
      <div>
        <HeadingM>
          ユーザー招待
        </HeadingM>
        <FlexBox className="m-b-16">
          <StyledInputLabel>
            ユーザー名
          </StyledInputLabel>
          <InputText
            width = "320"
            onChange = { inputUserName }
          />
        </FlexBox>
        <FlexBox className="m-b-16">
          <StyledInputLabel>
            メールアドレス
          </StyledInputLabel>
          <InputText
            width = "320"
            onChange = { inputMailAddress }
          />
        </FlexBox>
        <FlexBox className="m-b-16">
          <StyledInputLabel>
            部署
          </StyledInputLabel>
          <PullDownCommon
            width = '200px'
            id = { this.state.selectedSectionCode }
            items = {
              _.filter(this.props.sections, o => {
                return o.sectionCode !== 'ALL'
              }).map(o => {
                return {
                  id: o.sectionCode,
                  label: o.sectionName
                };
              })
            }
            onChange = { selectSection }
          />
        </FlexBox>
        <FlexBox className="m-b-16">
          <StyledInputLabel>
            グループ
          </StyledInputLabel>
          <PullDownCommon
            width = '200px'
            id = { this.state.requestParam.groupId }
            items = {
              _.filter(this.props.groups, o => {
                return o.sectionCode === this.state.selectedSectionCode
              }).map(o => {
                return {
                  id: o.groupId,
                  label: o.groupName
                };
              })
            }
            onChange = { selectGroup }
          />
        </FlexBox>
        <FlexBox className="m-b-16">
          <StyledInputLabel>
            権限<LabelRequire />
          </StyledInputLabel>
          <PullDownCommon
            width = '200px'
            id = { this.state.requestParam.auth }
            items = {
              _.filter(USER_AUTH, o => {
                return o.id > 0
              })
            }
            onChange = { selectUserAuth }
          />
        </FlexBox>
        <BarBottom>
          <Button
            as = { Link }
            to = '/setting/authority'
            className = 'm-r-8'
          >
            キャンセル
          </Button>
          <Button
            color = "orange"
            onClick = { inviteNewUser }
          >
            ユーザーを招待する
          </Button>
        </BarBottom>
      </div>
    )
  }
}

export default AuthorityUser;
