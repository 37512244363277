import React from 'react';
import styled from 'styled-components';

import FONTSIZE from '../_const/FONTSIZE';

import TextEditable from '../molecules/TextEditable';

const StyledWrapper = styled.div`
  margin-top: -40px;
  margin-bottom: 40px;
  margin-right: -40px;
  margin-left: -40px;
  padding: 32px 40px;
  border-bottom: 1px solid #e6e6e6;
  font-size: ${FONTSIZE.M}px;
  font-weight: 600;
`

class TitleEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
    }
  }
  render() {
    // タイトル開閉
    const openTitle = () => {
      this.setState({
        isOpened: true,
      });
    }
    const closeTitle = () => {
      this.setState({
        isOpened: false,
      });
    }

    return (
      <StyledWrapper>
        <TextEditable
          name = { this.props.name }
          value = { this.props.value }
          blankLabel = { this.props.blankLabel }
          placeholder = { this.props.placeholder }
          isOpened = { this.state.isOpened }
          onChange = { this.props.onChange }
          open = { openTitle }
          close = { closeTitle }
        />
      </StyledWrapper>
    )
  }
}

export default TitleEdit;
