import React from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';

import IconMemo from '../atoms/IconMemo';
import Tooltip from '../atoms/Tooltip';

const StyledMemo = styled.div`
  width: 21px;
  height: 19px;
  position: relative;
`
const StyledIconWrap = styled.div`
  cursor: pointer;
`
const StyledTooltip = styled(Tooltip)`
  width: auto;
  white-space: pre;
  right: -15px;
  bottom: 18px;
  z-index: 100;
`

class Memo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: false
    }
  }
  handleClickOutside(e) {
    this.hideTooltip(e);
  }
  toggleTooltip = async () => {
    await this.setState({
      isShown: !this.state.isShown
    });
  }
  hideTooltip = () => {
    this.setState({
      isShown: false
    })
  }
  render() {
    const getClassNameTooltip = () => {
      return (this.state.isShown === true) ? 'is-shown' : '';
    }
    return (
      <StyledMemo>
        <StyledIconWrap onClick = { this.toggleTooltip }>
          <IconMemo/>
        </StyledIconWrap>
        <StyledTooltip
          str = { this.props.str }
          align = "right"
          className = { getClassNameTooltip() }
        />
      </StyledMemo>
    )
  }
}

export default onClickOutside(Memo);
