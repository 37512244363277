import styled from 'styled-components';

const StyledPullDownLabel = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 700;
  text-overflow: ellipsis;
`

export default StyledPullDownLabel;
