import React from 'react';
import User from '../../../utils/user';

class FileSend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('ファイル自動送付');
    props.toggleDatePicker(false);
  }
  render() {
    const backendApi = process.env.REACT_APP_SERVER;
    const url = backendApi + 'medix_innovator/dashboard/AutoFileSend.htm?rcode=' + User.apiParams().rcode;
    return (
      <div>
        <iframe id='inlineFrame' title='ファイル自動送付' width='1500' height='800' src= {url} ></iframe>
      </div>
    )
  }
}

export default FileSend;
