import React from 'react';
import styled from 'styled-components'

import IconSort from './IconSort';
import IconUnfoldLess from './IconUnfoldLess';
import IconUnfoldMore from './IconUnfoldMore';

import FONTSIZE from '../_const/FONTSIZE'

const StyledTableHeaderCell = styled.div`
  box-sizing: border-box;
  box-shadow: inset 0 3px 0 0 #f5f5f5;
  position: relative;
  white-space: nowrap;
  font-size: ${FONTSIZE.S}px;
  font-weight: 700;
  text-align: left;
  background-color: #fff;
`
const StyledTableCellCompareParent = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  position: relative;
  padding-top: 18px;
  padding-bottom: 10px;
  padding-right: 48px;
  padding-left: 18px;
  &:after {
    width: 1px;
    height: 7px;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #d9d9d9;
  }
`
const StyledTableChildCellWrap = styled.div`
  display: flex;
  border-top: 1px solid #d9d9d9;
`
const StyledTableChildCell = styled.div`
  width: 25%;
  height: calc(20px + 1.5em);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  flex-shrink: 0;
  padding-right: 48px;
  padding-left: 18px;
  &:after {
    width: 1px;
    height: 7px;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #d9d9d9;
  }
`
const StyledTableUnfoldMoreCell = styled.div`
  height: calc(20px + 1.5em);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d9d9d9;
  &:after {
    width: 1px;
    height: 7px;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #d9d9d9;
  }
`
const StyledSortIcon = styled(IconSort)`
  position: absolute;
  top: calc(50% - 5px);
  right: 12px;
  &.is-hidden {
    display: none;
  }
`
const StyledIconUnfoldLess = styled(IconUnfoldLess)`
  position: absolute;
  top: calc(50% - 4px);
  right: 12px;
  &.is-hidden {
    display: none;
  }
`

const TableHeaderCellHasCompare = ({
  children, cellId, sortId, sortCompareId, sortOrder, isUnfolded, sort, unfold,
  ...attr
}) => (
  <StyledTableHeaderCell
    { ...attr }
  >
    <StyledTableCellCompareParent
      data-id = { cellId }
      onClick = { ((e) => {
        if (isUnfolded === true) {
          unfold(e);
        } else {
          sort(e);
        }
      }) }
    >
      {
          children
      }
      <StyledIconUnfoldLess
        className = { (isUnfolded === false) ? 'is-hidden' : '' }
      />
      <StyledSortIcon
        className = { (isUnfolded === true) ? 'is-hidden' : '' }
        state = {
          (sortId !== cellId)
            ? 0
            : (sortOrder === 'asc')
              ? 1
              : 2
        }
      />
    </StyledTableCellCompareParent>
    {
      (() => {
        if (isUnfolded === false) {
          // 比較を展開していない
          return (
            <StyledTableUnfoldMoreCell
              onClick = { unfold }
            >
              <IconUnfoldMore
                className = { (isUnfolded === true) ? 'is-hidden' : '' }
              />
            </StyledTableUnfoldMoreCell>
          )
        } else {
          // 比較を展開している
          return (
            <StyledTableChildCellWrap>
              <StyledTableChildCell
                data-id = { cellId }
                data-compare-id = { 'src' }
                onClick = { sort }
              >
                比較元
                <StyledSortIcon
                  state = {
                    (sortId === cellId && sortCompareId === 'src')
                      ? (sortOrder === 'asc')
                        ? 1
                        : 2
                      : 0
                  }
                />
              </StyledTableChildCell>
              <StyledTableChildCell
                data-id = { cellId }
                data-compare-id = { 'dest' }
                onClick = { sort }
              >
                比較先
                <StyledSortIcon
                  state = {
                    (sortId === cellId && sortCompareId === 'dest')
                      ? (sortOrder === 'asc')
                        ? 1
                        : 2
                      : 0
                  }
                />
              </StyledTableChildCell>
              <StyledTableChildCell
                data-id = { cellId }
                data-compare-id = { 'diff' }
                onClick = { sort }
              >
                変化値
                <StyledSortIcon
                  state = {
                    (sortId === cellId && sortCompareId === 'diff')
                      ? (sortOrder === 'asc')
                        ? 1
                        : 2
                      : 0
                  }
                />
              </StyledTableChildCell>
              <StyledTableChildCell
                data-id = { cellId }
                data-compare-id = { 'ratio' }
                onClick = { sort }
              >
                変化率
                <StyledSortIcon
                  state = {
                    (sortId === cellId && sortCompareId === 'ratio')
                      ? (sortOrder === 'asc')
                        ? 1
                        : 2
                      : 0
                  }
                />
              </StyledTableChildCell>
            </StyledTableChildCellWrap>
          )
        }
      })()
    }
  </StyledTableHeaderCell>
);

export default TableHeaderCellHasCompare;
