import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import Button from '../atoms/Button';
import ModalSection from '../atoms/ModalSection';
import TextEditable from '../molecules/TextEditable';
import Modal from '../molecules/Modal';
import SelectDispItems from '../organisms/SelectDispItems';

const StyledHeading = styled(ModalSection)`
  width: 832px;
  box-sizing: border-box;
  margin-bottom: 4px;
  padding: 16px 24px;
`
const StyledBtnWrap = styled(ModalSection)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
`
// 表示項目の変更
class ModalProgressDisplayEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      checked: [],
      isOpenedTitleEdit: false,
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    // モーダルが開かれたとき
    if (
      prevProps.isOpened !== this.props.isOpened
      && this.props.isOpened === true
    ) {
      // 選択済みの項目をセットする。
      const edited = _.find(this.props.dispItems, o => {
        return o.id === this.props.editingDispItemId
      });
      this.setState({
        title: edited.name,
        checked: edited.registeredDispItem.slice(),
      });
    }
  }
  render() {
    // タイトル開閉
    const openTitle = () => {
      this.setState({
        isOpenedTitleEdit: true,
      });
    }
    const closeTitle = () => {
      this.setState({
        isOpenedTitleEdit: false,
      });
    }

    // タイトル編集
    const editTitle = (e) => {
      this.setState({
        title: e.currentTarget.value,
      });
    }

    return (
      <Modal
        heading = { this.props.heading }
        isOpened = { this.props.isOpened }
        isOpenedSideMenu = { this.props.isOpenedSideMenu }
        close = { this.props.close }
      >
        <StyledHeading>
          <TextEditable
            id = 'title'
            value = { this.state.title }
            blankLabel = { `無題${this.props.editingDispItemId}` }
            placeholder = '表示項目設定のタイトルを入力してください。'
            isOpened = { this.state.isOpenedTitleEdit }
            onChange = { editTitle }
            open = { openTitle }
            close = { closeTitle }
          />
        </StyledHeading>

        <ModalSection>
          <SelectDispItems
            selectableDispItems = { this.props.selectableDispItems }
            checked = { this.state.checked }
            updateChecked = {
              ((checked) => {
                this.setState({
                  checked: checked
                })
              })
            }
            openAddItemModal = { this.props.openAddItemModal }
            getReportAddItem = { this.props.getReportAddItem }
            addItems = { this.props.addItems }
            isAddItems = { true }
          />
        </ModalSection>

        <StyledBtnWrap>
          <Button
            onClick = {
              () => {
                this.setState({
                  isOpenedTitleEdit: false,
                });
                this.props.close();
              }
            }
          >
            キャンセル
          </Button>
          <Button
            color = "orange"
            className = "m-l-8"
            onClick = {
              () => {
                this.setState({
                  isOpenedTitleEdit: false,
                });
                this.props.save(this.state.title, this.state.checked);
              }
            }
          >
            保存して適用する
          </Button>
        </StyledBtnWrap>
      </Modal>
    )
  }
};

export default ModalProgressDisplayEdit;
