import styled from 'styled-components'
import img from '../../img/line_dotted_h.gif';

const DividerDottedH = styled.div`
  width: ${props => props.width ? props.width : '15px'};
  height: 1px;
  margin-top: ${props => props.hasMargin ? '16px' : '0'};
  margin-bottom: ${props => props.hasMargin ? '16px' : '0'};
  background-image: url(${img});
`;

export default DividerDottedH;
