import React from 'react';

import Base from './Base';

class Account extends React.Component {
  constructor(props) {
    super(props);
    props.switchContentTitle('アカウント');
  }
  render() {
    return (
      <Base
        target = { 2 }
        targetId = { 'accountId' }
        targetName = { 'accountName' }
        hasGraph = { true }
        { ...this.props }
      />
    )
  }
}

export default Account;
