import DISPITEM_ALERT from '../_const/DISPITEM_ALERT';
import _ from 'lodash';

export default function(id) {
  if (id == null) return '';
  const dispItem = _.find(DISPITEM_ALERT, o => {
    if (id.endsWith('Compare')) {
      return o.name.concat('Compare') === id;
    } else {
      return o.name === id;
    }
  });
  return dispItem ? dispItem.unit : '';
}
