import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom';

import COLOR from '../_const/COLOR'
import FONTSIZE from '../_const/FONTSIZE'

const StyledTabWrap = styled.div`
  height: 40px;
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-top: -8px;
  &:before {
    height: 1px;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: -40px;
    left: -40px;
    background-color: #e6e6e6;
  }
`
const StyledTabItem = styled(Link)`
  min-width: 106px;
  height: 33px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
  padding-right: 1em;
  padding-left: 1em;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  color: #b2b2b2;
  font-size: ${FONTSIZE.SS}px;
  font-weight: 600;
  text-decoration: none;
  background-color: #f5f5f5;
  &:first-child {
    border-radius: 3px 0 0 0;
  }
  &:last-child {
    border-right: 1px solid #e6e6e6;
    border-radius: 0 3px 0 0;
  }
  &.is-current {
    height: 40px;
    border-top: 2px solid ${COLOR.ORANGE};
    border-bottom: 0;
    border-right: 1px solid #e6e6e6;
    border-radius: 3px 3px 0 0;
    color: #4c4c4c;
    background-color: ${COLOR.WHITE};
  }
  &.is-current + & {
    border-left: 0;
  }
`

class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  createItems = () => {
    return this.props.items.map((item, index) => {
      const classNameCurrent = (this.props.currentId === index) ? 'is-current' : '';
      return (
        <StyledTabItem
          key = { `tab-${index}` }
          to = { item.link }
          className = { `${classNameCurrent}` }
        >
          { item.label }
        </StyledTabItem>
      )
    });
  }
  render() {
    return (
      <StyledTabWrap className={ this.props.className }>
        { this.createItems() }
      </StyledTabWrap>
    )
  }
};
export default Tab;
