import React from 'react';
import styled from 'styled-components';

import Modal from '../molecules/Modal';

const StyledModalBody = styled.div`
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
  overflow: auto;
  height: 250px;
`

class ModalInfoMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'info',
      selected: [],
    }
  }
  render() {
    return (
      <Modal
        heading = { this.props.heading }
        isOpened = { this.props.isOpened }
        close = { this.props.close }
      >
        <StyledModalBody>
        <div className="text" dangerouslySetInnerHTML={{__html: this.props.bodyText}}>
        </div>
        </StyledModalBody>

      </Modal>
    )
  }
};

export default ModalInfoMessage;
