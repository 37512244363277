import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import _ from 'lodash';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import COLOR from '../../_const/COLOR';

import Button from '../../atoms/Button';
import ButtonEdit from '../../atoms/ButtonEdit';
import ButtonDuplicate from '../../atoms/ButtonDuplicate';
import Checkbox from '../../atoms/Checkbox';
import DividerDottedV from '../../atoms/DividerDottedV';
import FlexBox from '../../atoms/FlexBox';
import IconPlus from '../../atoms/IconPlus';
import TableDataCell from '../../atoms/TableDataCell';
import TableBodyRow from '../../atoms/TableBodyRow';
import TableHeader from '../../atoms/TableHeader';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import BarEditDataForLabel from '../../molecules/BarEditDataForLabel';
import Memo from '../../molecules/Memo';
import Search from '../../molecules/Search';
import PullDownExportAndImport from '../../organisms/PullDownExportAndImport';
import Pagination from '../../organisms/Pagination';
import ModalFileExport from '../../organisms/ModalFileExport';
import ModalFileImport from '../../organisms/ModalFileImport';
import ModalErrorMessage from '../../organisms/ModalErrorMessage';
import ModalLabelEdit from '../../organisms/ModalLabelEdit';

import alignSearchQuery from '../../_util/alignSearchQuery';
import arrangeReportDataValue from '../../_util/arrangeReportDataValue';

import User from '../../../utils/user';

const TABLE_HEADER = [
  // －－－－－shareSetting－note

  {
    id: 'name',
    label: 'ラベル名',
    width: 'calc((100% - 220px) / 8)',
    hasSort: true,
  },
  {
    id: 'createDate',
    label: '作成日',
    width: 'calc((100% - 220px) / 8)',
    hasSort: true,
  },
  {
    id: 'lastModified',
    label: '最終更新日時',
    width: 'calc((100% - 220px) / 8)',
    hasSort: true,
  },
  {
    id: 'sectionName',
    label: '部署',
    width: 'calc((100% - 220px) / 8)',
    hasSort: true,
  },
  {
    id: 'groupName',
    label: 'グループ',
    width: 'calc((100% - 220px) / 8)',
    hasSort: true,
  },
  {
    id: 'userName',
    label: '作成ユーザー',
    width: 'calc((100% - 220px) / 8)',
    hasSort: true,
  },
  {
    id: 'budget',
    label: 'ヨミ予算',
    width: 'calc((100% - 220px) / 8)',
    hasSort: true,
  },
  {
    id: 'alertSettingName',
    label: '設定アラート',
    width: 'calc((100% - 220px) / 8)',
    hasSort: true,
  },
  {
    id: 'note',
    label: 'メモ',
    width: '100px',
    hasSort: false,
  },
  {
    id: 'edit',
    label: '',
    width: '80px',
    hasSort: false,
  },
];

const StyledTable = styled.div`
  width: 100%;
  max-height: ${ props => props.windowHeight - 120 - 106 - 44 - 40 }px;
  overflow: scroll;
  position: relative;
  z-index: 1;
`
const StyledEditLink = styled(Link)`
  color: ${COLOR.ORANGE};
  text-decoration: none;
`

const backendApi = process.env.REACT_APP_BACKEND_URI;

const ITEMS = [
  { type: 'importModal', label: '新しいデータをインポートする' },
  { type: 'export', label: '現在のラベル一覧をエクスポートする', url: backendApi + 'label', param: { dl: 1 } },
  { type: 'export', label: 'クライアントデータをエクスポートする', url: backendApi + 'client', param: { dl: 1 } },
  { type: 'export', label: 'アカウントデータをエクスポートする', url: backendApi + 'account', param: { dl: 1 } },
  { type: 'exportModal', label: 'クライアントを指定してエクスポートする' },
];

const EXPORT_MODAL = [
  { type: 'input', label: 'ID', heading: 'クライアントID' },
  { type: 'radio', heading: 'ターゲット', list: [
    { label: 'キャンペーン', id: 1, value: 3 },
    { label: '広告グループ', id: 2, value: 4 },
    { label: 'キーワード', id: 3, value: 5 }
  ]},
];

const EXPORT_URL = backendApi + 'camAdgKw';
const IMPORT_URL = backendApi + 'fileUpload';

class Label extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOriginal: [],
      dataFiltered: [],
      data: [],
      offset: 0,
      postPerPage: 50,
      searchQuery: '',
      checked: [],
      alertData: [],
      requestParam: {
        budget:         0, // 設定予算
        alertSettingId: 0, // 設定したアラート情報ID	アラート情報を設定するなら該当するアラート情報のIDをセット。設定しない場合は0
      },
    };
    props.switchGlobalCatId(3);
    props.switchContentTitle('ラベル');
    props.toggleDatePicker(false);
  }
  componentWillMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    await this.getData();
    this.arrangeViewItems();

    // アラートデータも取得しておく
    await Promise.all([
      this.getAlertData(),
    ]);

    //　プログレス非表示
    this.props.endProgressing();
  }
  getData = () => {
    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'label', {
      params: {
        ...User.apiParams(),
      }
    })
    .then((response) => {
      this.setState({
        dataOriginal: response.data,
        dataFiltered: response.data,
        data: response.data,
      });
    })
    .catch(() => {
    });
    
  }
  arrangeViewItems = () => {
    // ソートやページャの条件に従って表示するレポートデータを別途作成する。
    const { offset, postPerPage } = this.state;

    // 元データを指定の条件でフィルタする
    const filteredData = this.state.dataOriginal.filter((item, index) => {
      const regexp = new RegExp(
        `^(?=.*${alignSearchQuery(this.state.searchQuery)}).*$`
      );

      return (
        (
          // フリーワード検索のクエリに合致するか
          this.state.searchQuery === ''
          || alignSearchQuery(item.name).match(regexp) !== null
        )
      )
    });

    // データのソートには_.orderBy()を使う。
    const sortedDataTable = _.orderBy(
      filteredData,
      o => {
        return o[this.state.sortId];
      },
      this.state.sortOrder
    );

    // ページャ条件による絞り込みにはArray.slice()を使う。
    const arrangedDataTable = sortedDataTable.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    this.setState({
      dataFiltered: filteredData,
      data: arrangedDataTable,
    });
  }

  getAlertData = () => {
    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'alert', {
      params: {
        ...User.apiParams(),
        myAlert: 1,
      }
    })
    .then((response) => {
      this.setState({
        alertData: [
          {
            id: 0,
            label: '選択しない',
          },
          ...response.data.map((item) => {
            return {
              id: item.id,
              label: item.name,
            }
          })
        ],
      });
    })
    .catch(() => {
    });
  }

  render() {
    // フリーワード検索
    const setSearchQuery = async (e) => {
      e.preventDefault();
      await this.setState({
        offset: 0,
        searchQuery: e.target['searchQuery'].value,
        checked: [],
      });
      this.arrangeViewItems();
    }

    // 並び替え
    const sort = async (e) => {
      // セルの並び替え
      const id = e.currentTarget.dataset.id;
      const order = (id === this.state.sortId && this.state.sortOrder === 'desc') ? 'asc' : 'desc';

      await this.setState({
        sortId: id,
        sortOrder: order,
      })
      this.arrangeViewItems();
    }

    // 各行のチェック
    const checkItem = (e) => {
      const id = parseInt(e.currentTarget.value);
      const checked = this.state.checked.concat();

      if (checked.indexOf(id) > -1) {
        checked.splice(checked.indexOf(id), 1)
      } else {
        checked.push(id);
      }
      
      this.setState({
        checked: checked
      });
    }

    // コピー
    const duplicateLabel = async (label) => {
      // プログレス表示
      this.props.startProgressing();

      await axios.get(backendApi + 'labelModify', {
        params: {
          ...User.apiParams(),
          func: 3,
          id: label.id,
          myAlert: this.state.myAlert,
        }
      })
      .then((response) => {
        this.setState({
          dataOriginal: response.data,
          dataFiltered: response.data,
          data: response.data,
        });
      })
      .catch(() => {
      });

      this.arrangeViewItems();

      //　プログレス非表示
      this.props.endProgressing();
    }

    // 削除
    const deleteCustomReport = async () => {
      // プログレス表示
      this.props.startProgressing();

      await axios.get(backendApi + 'labelModify', {
        params: {
          ...User.apiParams(),
          func: 2,
          ids: this.state.checked.join(','),
        }
      })
      .then((response) => {
        this.setState({
          dataOriginal: response.data,
          dataFiltered: response.data,
          data: response.data,
          checked: [],
        });
      })
      .catch(() => {
      });

      this.arrangeViewItems();

      //　プログレス非表示
      this.props.endProgressing();
    }

    // 一括設定ボタン
    const labelCollectiveSetting = async () => {
      openLabelModal();
    }
    
    // ページャの表示件数を変更
    const switchPostPerPage = async (num) => {
      await this.setState({
        postPerPage: num
      })
      this.arrangeViewItems();
    }

    // ページャの戻るイベント
    const goToPrevPagination = async () => {
      if (this.state.offset <= 0) return;
      await this.setState({
        offset: this.state.offset - 1
      });
      this.arrangeViewItems();
    }

    // ページャの進むイベント
    const goToNextPagination = async () => {
      if (this.state.offset >= Math.ceil(this.state.dataFiltered.length / this.state.postPerPage) - 1) return;
      await this.setState({
        offset: this.state.offset + 1
      });
      this.arrangeViewItems();
    }

    // エクスポートモーダルを開く
    const openExportModal = () => {
      this.setState({
        isOpenedExportModal: true
      });
    }
    // エクスポートモーダルを閉じる
    const closeExportModal = () => {
      this.setState({
        isOpenedExportModal: false
      });
    }
    // インポートモーダルを開く
    const openImportModal = () => {
      this.setState({
        isOpenedImportModal: true
      });
    }
    // インポートモーダルを閉じる
    const closeImportModal = () => {
      this.setState({
        isOpenedImportModal: false
      });
    }
    // エラーモーダルを開く
    const openErrorModal = () => {
      this.setState({
        isOpenedErrorModal: true
      });
    }
    // エラーモーダルを閉じる
    const closeErrorModal = () => {
      this.setState({
        isOpenedErrorModal: false
      });
    }
    // エラーモーダルの本文を書き換える
    const changeErrorText = (text) => {
      this.setState({
        errorText: text
      });
    }

    // labelモーダルを開く
    const openLabelModal = () => {
      this.setState({
        isOpenedLabeltModal: true
      });
    }
    // labelモーダルを閉じる
    const closeLabelModal = () => {
      this.setState({
        isOpenedLabeltModal: false
      });
    }

    return (
      <div>
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isOpenedErrorModal }
          close = { closeErrorModal }
          bodyText = { this.state.errorText }
        ></ModalErrorMessage>
        <ModalFileExport
          heading = 'クライアントを指定してエクスポートする'
          isOpened = { this.state.isOpenedExportModal }
          closeExportModal = { closeExportModal }
          modalContents = { EXPORT_MODAL }
          apiUrl = { EXPORT_URL }
          apiParams = { User.apiParams() }
          changeErrorText = { changeErrorText }
          openErrorModal = { openErrorModal }
        ></ModalFileExport>
        <ModalFileImport
          heading = '新しいファイルをインポートする'
          isOpened = { this.state.isOpenedImportModal }
          closeImportModal = { closeImportModal }
          apiUrl = { IMPORT_URL }
          apiParams = { User.apiParams() }
          changeErrorText = { changeErrorText }
          openErrorModal = { openErrorModal }
          funcId = { 2 }
        ></ModalFileImport>
        <FlexBox className="m-b-32" justify="space-between">
          <FlexBox>
            <Search
              name = "searchQuery"
              onSubmit = { setSearchQuery }
              value = { this.state.searchQuery }
            />
            <DividerDottedV hasMargin />
            <PullDownExportAndImport
              items = { ITEMS }
              apiParams = { User.apiParams() }
              openExportModal = { openExportModal }
              openImportModal = { openImportModal }
            />
          </FlexBox>
          <Button
            as = { Link }
            to = '/label/edit'
          >
            新規ラベル作成
            <IconPlus className="m-l-8" />
          </Button>
        </FlexBox>
        <BarEditDataForLabel
          count = { this.state.checked.length }
          onClickDelete = { deleteCustomReport }
          onClickConfirm = { labelCollectiveSetting }
        />
        <ModalLabelEdit
          heading = '一括設定'
          isOpened = { this.state.isOpenedLabeltModal }
          closeLabelModal = { closeLabelModal }
          alertData = { this.state.alertData } // 取得しておいたアラート一覧データをmodalに渡す
          ids = { this.state.checked.join(',') } // チェックされたIDSをmodalに渡す
        ></ModalLabelEdit>

        <StyledTable
          windowHeight = { this.props.windowHeight }
        >
          <TableHeader>
            <TableHeaderCell
              className = { 'has-checkbox' }
            />
            {
              (() => {
                return TABLE_HEADER.map(o => {
                  return (
                    <TableHeaderCell
                      key = { _.uniqueId() }
                      style = { { width: `${o.width}` } }
                      sortState = {
                        (o.hasSort === false)
                          ? undefined
                          : (o.id !== this.state.sortId)
                            ? 0
                            : (this.state.sortOrder === 'asc')
                              ? 1
                              : 2
                      }
                      data-id = { o.id }
                      onClick = { sort }
                    >
                      { o.label }
                    </TableHeaderCell>
                  )
                })
              })()
            }
          </TableHeader>
          <div>
            {
              (() => {
                return this.state.data.map(r => {
                  return (
                    <TableBodyRow
                      key = { _.uniqueId() }
                    >
                      <TableDataCell
                        className = { 'has-checkbox' }
                      >
                        <Checkbox
                          value = { r.id }
                          onClick = { checkItem }
                          isChecked = { this.state.checked.indexOf(r.id) > -1 }
                        />
                      </TableDataCell>
                      {
                        (() => {
                          return TABLE_HEADER.map(o => {
                            return (
                              <TableDataCell
                                key = { _.uniqueId() }
                                style = { { width: `${o.width}` } }
                                className = {
                                  classnames({
                                    'jc-c': o.id === 'note'
                                  })
                                }
                              >
                                {
                                  (() => {
                                    switch (o.id) {
                                      case 'name':
                                        return (
                                          <StyledEditLink
                                            to = { `/progress/${1}/?label=${r.id}` }
                                          >
                                            { r[o.id] }
                                          </StyledEditLink>
                                        )
                                      case 'budget':
                                        return arrangeReportDataValue(r[o.id], 2);
                                      case 'note':
                                        return (r[o.id] !== '')
                                          ? (
                                            <Memo str = { r[o.id] } />
                                          )
                                          : r[o.id]
                                      case 'edit':
                                        return (
                                          <FlexBox
                                            width = '100%'
                                            justify = 'center'
                                          >
                                            <ButtonEdit
                                              tag = { Link }
                                              to = { `/label/edit/?id=${r.id}` }
                                            />
                                            <ButtonDuplicate
                                              className = 'm-l-8'
                                              onClick = {
                                                (() => {
                                                  duplicateLabel(r);
                                                })
                                              }
                                            />
                                          </FlexBox>
                                        )
                                      default:
                                        return r[o.id];
                                    }
                                  })()
                                }
                              </TableDataCell>
                            )
                          })
                        })()
                      }

                    </TableBodyRow>
                  )
                })
              })()
            }
          </div>
        </StyledTable>

        <div className="m-t-24">
          <Pagination
            offset = { this.state.offset }
            postPerPage = { this.state.postPerPage }
            dataCount = { this.state.dataFiltered.length }
            switchPostPerPage = { switchPostPerPage }
            goToPrevPagination = { goToPrevPagination }
            goToNextPagination = { goToNextPagination }
          />
        </div>
      </div>
    )
  }
}

export default Label;
