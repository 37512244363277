import styled from 'styled-components'

import COLOR from '../_const/COLOR';

const StyledDividerDoubleH = styled.div`
  height: 3px;
  border-top: 1px solid #d9d9d9;
  background-color: ${COLOR.LIGHTGRAY};
`;

export default StyledDividerDoubleH;
