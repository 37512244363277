export default [
  {
    id: 0,
    label: '全て'
  },
  {
    id: 1,
    label: '編集者'
  },
  {
    id: 2,
    label: '管理者'
  },
]
