import React from 'react'
import styled from 'styled-components'

import FONTSIZE from '../_const/FONTSIZE'
import MediaColor from '../atoms/MediaColor';
import MediaLink from '../atoms/MediaLink';

const StyledMediaBadge = styled.div.attrs(
  ({ id }) => {
    return {
      style: {
        backgroundColor: MediaColor(id)
      }
    }
  }
)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #fff;
  font-size: ${FONTSIZE.SS}px;
  letter-spacing: 0.06em;
`;

const StyledLink = styled.a`
width: 100%;
box-sizing: border-box;
display: flex;
justify-content: center;
align-items: center;
border-radius: 3px;
color: #fff;
letter-spacing: 0.06em;
text-decoration: none;
`

const MediaBadgeLink = ({ id, label }) => (
  <StyledMediaBadge
    id = { parseInt(id) }
  >
    <StyledLink
      href = { MediaLink(id) }
      target = "_blank"
    >
      { label }
    </StyledLink>
  </StyledMediaBadge>
)

export default MediaBadgeLink;
