import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR';

const StyledSwitchWrap = styled.div`
  width: 32px;
  height: 17px;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  background-color: #b3b3b3;
  transition-duration: .2s;
  transition-property: background-color;
  &.is-on {
    background-color: ${COLOR.GREEN};
  }
`
const StyledSwitchKnob = styled.div`
  width: 13px;
  height: 13px;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  background-color: ${COLOR.WHITE};
  transform: translateX(0);
  transition-duration: .2s;
  transition-property: transform;
  &.is-on {
    transform: translateX(15px);
  }
`

const Switch = prop => (
  <StyledSwitchWrap
    onClick = { prop.onClick }
    className = {`${prop.isEnabled === true ? 'is-on' : 'is-off'} ${prop.className}`}
  >
    <StyledSwitchKnob className={ prop.isEnabled === true ? 'is-on' : 'is-off' } />
  </StyledSwitchWrap>
);

export default Switch;
