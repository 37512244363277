import React from 'react';
import styled from 'styled-components';

const IconApps = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0, 0, 10, 10" className={props.className}>
    <path fillRule="evenodd" fill="gray" d="M8 10V8h2v2H8zm0-6h2v2H8V4zm0-4h2v2H8V0zM4 8h2v2H4V8zm0-4h2v2H4V4zm0-4h2v2H4V0zM0 8h2v2H0V8zm0-4h2v2H0V4zm0-4h2v2H0V0z"/>
  </svg>
)
const StyledIconApps = styled(IconApps) `
  display: block;
`

export default StyledIconApps;
