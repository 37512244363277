import React from 'react';
import axios from 'axios';
import _ from 'lodash';

import OccurredAlertItem from '../../atoms/OccurredAlertItem';
import Pagination from '../../organisms/Pagination';

import User from '../../../utils/user';

const backendApi = process.env.REACT_APP_BACKEND_URI;

class AlertNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOriginal: [],
      data: [],
      offset: 0,
      postPerPage: 50,
      searchQuery: '',
    };
    props.switchGlobalCatId(-1);
    props.switchContentTitle('発生しているアラート一覧');
    props.toggleDatePicker(false);
  }
  componentDidMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    await this.getData();

    //　プログレス非表示
    this.props.endProgressing();
  }
  getData() {
    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'alertNotification', {
      params: {
        ...User.apiParams(),
      }
    })
    .then((response) => {
      this.setState({
        dataOriginal: response.data,
        data: response.data,
      });
    })
    .catch(() => {
    });
  }
  arrangeViewItems = () => {
    // ソートやページャの条件に従って表示するレポートデータを別途作成する。
    const { offset, postPerPage } = this.state;

    // ページャ条件による絞り込みにはArray.slice()を使う。
    const arrangedDataTable = this.state.dataOriginal.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    this.setState({
      data: arrangedDataTable,
    });
  }
  render() {
    // ページャの表示件数を変更
    const switchPostPerPage = async (num) => {
      await this.setState({
        postPerPage: num
      })
      this.arrangeViewItems();
    }

    // ページャの戻るイベント
    const goToPrevPagination = async () => {
      if (this.state.offset <= 0) return;
      await this.setState({
        offset: this.state.offset - 1
      });
      this.arrangeViewItems();
    }

    // ページャの進むイベント
    const goToNextPagination = async () => {
      if (this.state.offset >= Math.ceil(this.state.dataOriginal.length / this.state.postPerPage) - 1) return;
      await this.setState({
        offset: this.state.offset + 1
      });
      this.arrangeViewItems();
    }

    return (
      <div>
        {
          (() => {
            return this.state.data.map(o => {
              return (
                <OccurredAlertItem
                  key = { _.uniqueId() }
                  opts = { o }
                />
              )
            })
          })()
        }
        <Pagination
          offset = { this.state.offset }
          postPerPage = { this.state.postPerPage }
          dataCount = { this.state.dataOriginal.length }
          switchPostPerPage = { switchPostPerPage }
          goToPrevPagination = { goToPrevPagination }
          goToNextPagination = { goToNextPagination }
          className = 'm-t-24'
        />
      </div>
    )
  }
}

export default AlertNotification;
