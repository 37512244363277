// mType: math operations type
// 0: Division (val1(sum) / val2(sum))
// 1: Multiplication (val1(sum) * val2(sum))

export default [
  {
    label: "ctr",
    val1: "click",
    val2: "imp",
    mType: 0,
    type: 5
  },
  {
    label: "cpc",
    val1: "cost",
    val2: "click",
    mType: 0,
    type: 0
  },
  {
    label: "cpc_with_fee",
    val1: "cost_with_fee",
    val2: "click",
    mType: 0,
    type: 0
  },
  {
    label: "mediaBugetDigestibility",
    val1: "cost",
    val2: "mediaBudget",
    mType: 0,
    type: 5
  },
  {
    label: "mediaBugetDigestibility_with_fee",
    val1: "cost_with_fee",
    val2: "mediaBudget_with_fee",
    mType: 0,
    type: 5
  },
  {
    label: "costEstimateDividedByMediaBudget",
    val1: "estimateCost",
    val2: "mediaBudget",
    mType: 0,
    type: 5
  },
  {
    label: "costEstimateDividedByMediaBudget_with_fee",
    val1: "estimateCost_with_fee",
    val2: "mediaBudget_with_fee",
    mType: 0,
    type: 5
  },
  {
    label: "bugetDigestibility",
    val1: "cost",
    val2: "budget",
    mType: 0,
    type: 5
  },
  {
    label: "bugetDigestibility_with_fee",
    val1: "cost_with_fee",
    val2: "budget_with_fee",
    mType: 0,
    type: 5
  },
  {
    label: "costEstimateDividedByBudget",
    val1: "estimateCost",
    val2: "budget",
    mType: 0,
    type: 5
  },
  {
    label: "costEstimateDividedByBudget_with_fee",
    val1: "estimateCost_with_fee",
    val2: "budget_with_fee",
    mType: 0,
    type: 5
  },
  {
    label: "cvr",
    val1: "cv",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "cpa",
    val1: "cost",
    val2: "cv",
    mType: 0,
    type: 0
  },
  {
    label: "cpa_with_fee",
    val1: "cost_with_fee",
    val2: "cv",
    mType: 0,
    type: 0
  },
  {
    label: "roas",
    val1: "revenue",
    val2: "cost",
    mType: 0,
    type: 5
  },
  {
    label: "roas_with_fee",
    val1: "revenue",
    val2: "cost_with_fee",
    mType: 0,
    type: 5
  },
  {
    label: "estimateCpa",
    val1: "estimateCost",
    val2: "estimateCv",
    mType: 0,
    type: 0
  },
  {
    label: "estimateCpa_with_fee",
    val1: "estimateCost_with_fee",
    val2: "estimateCv",
    mType: 0,
    type: 0
  },
  {
    label: "customerUnitPrice",
    val1: "revenue",
    val2: "cv",
    mType: 0,
    type: 0
  },
  {
    label: "allCvr",
    val1: "allCv",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "allCpa",
    val1: "cost",
    val2: "allCv",
    mType: 0,
    type: 0
  },
  {
    label: "allCpa_with_fee",
    val1: "cost_with_fee",
    val2: "allCv",
    mType: 0,
    type: 0
  },
  {
    label: "cv1Cvr",
    val1: "cv1",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "cv2Cvr",
    val1: "cv2",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "cv3Cvr",
    val1: "cv3",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "cv4Cvr",
    val1: "cv4",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "cv5Cvr",
    val1: "cv5",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "cv1Cpa",
    val1: "cv1",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "cv2Cpa",
    val1: "cv2",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "cv3Cpa",
    val1: "cv3",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "cv4Cpa",
    val1: "cv4",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "cv5Cpa",
    val1: "cv5",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "cv1Cpa_with_fee",
    val1: "cv1",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "cv2Cpa_with_fee",
    val1: "cv2",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "cv3Cpa_with_fee",
    val1: "cv3",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "cv4Cpa_with_fee",
    val1: "cv4",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "cv5Cpa_with_fee",
    val1: "cv5",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "cv1Roas",
    val1: "cv1",
    val2: "cost",
    mType: 0,
    type: 5
  },
  {
    label: "cv2Roas",
    val1: "cv2",
    val2: "cost",
    mType: 0,
    type: 5
  },
  {
    label: "cv3Roas",
    val1: "cv3",
    val2: "cost",
    mType: 0,
    type: 5
  },
  {
    label: "cv4Roas",
    val1: "cv4",
    val2: "cost",
    mType: 0,
    type: 5
  },
  {
    label: "cv5Roas",
    val1: "cv5",
    val2: "cost",
    mType: 0,
    type: 5
  },
  {
    label: "cv1Roas_with_fee",
    val1: "cv1",
    val2: "cost_with_fee",
    mType: 0,
    type: 5
  },
  {
    label: "cv2Roas_with_fee",
    val1: "cv2",
    val2: "cost_with_fee",
    mType: 0,
    type: 5
  },
  {
    label: "cv3Roas_with_fee",
    val1: "cv3",
    val2: "cost_with_fee",
    mType: 0,
    type: 5
  },
  {
    label: "cv4Roas_with_fee",
    val1: "cv4",
    val2: "cost_with_fee",
    mType: 0,
    type: 5
  },
  {
    label: "cv5Roas_with_fee",
    val1: "cv5",
    val2: "cost_with_fee",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv1Cvr",
    val1: "mediaCv1",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv2Cvr",
    val1: "mediaCv2",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv3Cvr",
    val1: "mediaCv3",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv4Cvr",
    val1: "mediaCv4",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv5Cvr",
    val1: "mediaCv5",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv6Cvr",
    val1: "mediaCv6",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv7Cvr",
    val1: "mediaCv7",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv8Cvr",
    val1: "mediaCv8",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv9Cvr",
    val1: "mediaCv9",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv10Cvr",
    val1: "mediaCv10",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv11Cvr",
    val1: "mediaCv11",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv12Cvr",
    val1: "mediaCv12",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv13Cvr",
    val1: "mediaCv13",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv14Cvr",
    val1: "mediaCv14",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv15Cvr",
    val1: "mediaCv15",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv16Cvr",
    val1: "mediaCv16",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv17Cvr",
    val1: "mediaCv17",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv18Cvr",
    val1: "mediaCv18",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv19Cvr",
    val1: "mediaCv19",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv20Cvr",
    val1: "mediaCv20",
    val2: "click",
    mType: 0,
    type: 5
  },
  {
    label: "mediaCv1Cpa",
    val1: "mediaCv1",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv2Cpa",
    val1: "mediaCv2",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv3Cpa",
    val1: "mediaCv3",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv4Cpa",
    val1: "mediaCv4",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv5Cpa",
    val1: "mediaCv5",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv6Cpa",
    val1: "mediaCv6",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv7Cpa",
    val1: "mediaCv7",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv8Cpa",
    val1: "mediaCv8",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv9Cpa",
    val1: "mediaCv9",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv10Cpa",
    val1: "mediaCv10",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv11Cpa",
    val1: "mediaCv11",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv12Cpa",
    val1: "mediaCv12",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv13Cpa",
    val1: "mediaCv13",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv14Cpa",
    val1: "mediaCv14",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv15Cpa",
    val1: "mediaCv15",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv16Cpa",
    val1: "mediaCv16",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv17Cpa",
    val1: "mediaCv17",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv18Cpa",
    val1: "mediaCv18",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv19Cpa",
    val1: "mediaCv19",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv20Cpa",
    val1: "mediaCv20",
    val2: "cost",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv1Cpa",
    val1: "mediaCv1",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv2Cpa_with_fee",
    val1: "mediaCv2",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv3Cpa_with_fee",
    val1: "mediaCv3",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv4Cpa_with_fee",
    val1: "mediaCv4",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv5Cpa_with_fee",
    val1: "mediaCv5",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv6Cpa_with_fee",
    val1: "mediaCv6",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv7Cpa_with_fee",
    val1: "mediaCv7",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv8Cpa_with_fee",
    val1: "mediaCv8",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv9Cpa_with_fee",
    val1: "mediaCv9",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv10Cpa_with_fee",
    val1: "mediaCv10",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv11Cpa_with_fee",
    val1: "mediaCv11",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv12Cpa_with_fee",
    val1: "mediaCv12",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv13Cpa_with_fee",
    val1: "mediaCv13",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv14Cpa_with_fee",
    val1: "mediaCv14",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv15Cpa_with_fee",
    val1: "mediaCv15",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv16Cpa_with_fee",
    val1: "mediaCv16",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv17Cpa_with_fee",
    val1: "mediaCv17",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv18Cpa_with_fee",
    val1: "mediaCv18",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv19Cpa_with_fee",
    val1: "mediaCv19",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  },
  {
    label: "mediaCv20Cpa_with_fee",
    val1: "mediaCv20",
    val2: "cost_with_fee",
    mType: 0,
    type: 0
  }
]