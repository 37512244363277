import React from 'react';
import styled from 'styled-components'

import COLOR from '../_const/COLOR'

const StyledLabelRequire = styled.span`
  color: ${COLOR.ORANGE};
`;

const LabelRequire = props => (
  <StyledLabelRequire className={props.className}>
    *
  </StyledLabelRequire>
)

export default LabelRequire;
