import React from 'react';
import styled from 'styled-components';

const IconArrowDoubleSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0, 0, 10, 9" className={props.className}>
    <path fillRule="evenodd" d="M10.015 8.287l-.726.728-4.175-4.188.326-.327-.326-.327L9.289-.014l.726.727L6.238 4.5l3.777 3.787zm-5.854.728L-.014 4.827.312 4.5l-.326-.327L4.161-.014l.725.727L1.11 4.5l3.776 3.787-.725.728z"/>
  </svg>
)
const IconArrowDouble = styled(IconArrowDoubleSvg) `
  display: block;
  fill: #fff;
`

export default IconArrowDouble;
