import React from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';
import _ from 'lodash';

import PullDownListItem from '../atoms/PullDownListItem';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';
import PullDownHasBorder from '../molecules/PullDownHasBorder';

const StyledPullDownGraphSplit = styled.div`
  position: relative;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 20;
  white-space: nowrap;
`
const StyledPullDownListWrapIn = styled(PullDownListWrapIn)`
  padding-top: 24px;
  padding-bottom: 24px;
`

class PullDownAlertRuleCompare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        { id: 1, label: 'よりも大きい（＞）' },
        { id: 2, label: 'よりも小さい（＜）' },
        { id: 3, label: '以上（≧）' },
        { id: 4, label: '以下（≦）' },
        { id: 5, label: '等しい（=）' },
      ],
      isOpenedPullDown: false,
    };
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown,
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false,
    });
  }
  render() {
    const switchValue = (item) => {
      this.props.setCompEqualSign(item.id);
      this.closePullDown();
    }

    return (
      <StyledPullDownGraphSplit className={this.props.className}>
        <PullDownHasBorder
          width = "calc(23px + 16em)"
          onClick = {this.togglePullDown}
        >
          <strong>
            {
              (() => {
                const item = _.find(this.state.items, o => {
                  return o.id === this.props.id
                });
                return (item) ? item.label: '';
              })()
            }
          </strong>
        </PullDownHasBorder>
        <StyledPullDownListWrap
          className = {
            this.state.isOpenedPullDown === true
              ? `is-opened`
              : `is-closed`
          }
        >
          <StyledPullDownListWrapIn>
            {
              (() => {
                return this.state.items.map((item) => {
                  return (
                    <PullDownListItem
                      key = { _.uniqueId() }
                      onClick = { (() => {
                        switchValue(item)
                      }) }
                      className = { this.props.id === item.id ? 'is-current' : '' }
                    >
                      { item.label }
                    </PullDownListItem>
                  )
                });
              })()
            }
          </StyledPullDownListWrapIn>
        </StyledPullDownListWrap>
      </StyledPullDownGraphSplit>
    )
  }
}
export default onClickOutside(PullDownAlertRuleCompare);
