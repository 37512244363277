import styled from 'styled-components';

const StyledPullDownListWrap = styled.div`
  box-sizing: border-box;
  pointer-events: none;
  padding: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(217, 217, 217);
  border-radius: 2px;
  background-color: rgb(245, 245, 245);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  transition-duration: .2s;
  transition-property: opacity, transform;
  &.is-closed {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }
  &.is-opened {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
  }
`

export default StyledPullDownListWrap;
