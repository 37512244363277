import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import React from 'react';
import _ from 'lodash';
import axios from 'axios';

import ScrollToTop from './components/_util/ScrollToTop';

import SignIn from './SignIn';
import SignOut from './SignOut';

import User from './utils/user';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      validAccessToken: false,
      isForceLogout: false,
      isInitAccess: false,
      isNoticeLogout: false,
    };
  }

  componentWillMount = () => {
    // Windowのresizeイベントを定義
    const resizeWindow = () => {
      this.setState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }
    window.addEventListener('resize', _.debounce(resizeWindow, 500));
  }

  componentDidMount = () => {
    // トークン有効確認
    this.checkToken();
  }

  checkToken = () => {
    const ssoApi = process.env.REACT_APP_REDIRECT_URI;
    return axios.get(ssoApi, {
      params: {
        rcode: User.apiParams().rcode,
      }
    })
    .then((response) => {
      if(!this.state.validAccessToken) {
        this.setState({
          validAccessToken: true,
          isForceLogout: false,
          isInitAccess: true,
        });
      }
    })
    .catch((error) => {
      if(error.response) {
        switch(error.response.status) {
          case 401: // 401判定
            if(window.localStorage.getItem('user')) {
              this.setState({
                isForceLogout: true,
              });
              window.localStorage.removeItem('user');
            }
            this.setState({
              validAccessToken: false,
              isInitAccess: true,
            });
            break;
          default:
            // nothing
        }
      }
    });
  }

  setIsNoticeLogout = () => {
    if(!this.state.isNoticeLogout) {
      this.setState({
        isNoticeLogout: true,
      });
    }
  }

  render() {
    if(this.state.isInitAccess) {
      if (User.apiParams().userId && this.state.validAccessToken) {
        return (
          <ScrollToTop>
            <SignIn
              { ...this.props }
              { ...this.state }
              checkToken = {this.checkToken}
            />
          </ScrollToTop>
        )
      } else {
        return(
          <SignOut
            { ...this.props }
            { ...this.state }
            isForceLogout = { this.state.isForceLogout }
            setIsNoticeLogout = { this.setIsNoticeLogout }
            isNoticeLogout = { this.state.isNoticeLogout }
          />
        )
      }
    } else {
      return (<div></div>)
    }
  }
}

export default App;
