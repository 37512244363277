import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

import REPORT_TARGETS from '../_const/REPORT_TARGETS'

import ButtonClose from '../atoms/ButtonClose';
import CheckboxWithLabel from '../atoms/CheckboxWithLabel';
import DividerDottedH from '../atoms/DividerDottedH';
import DividerDottedVWide from '../atoms/DividerDottedVWide';
import FlexBox from '../atoms/FlexBox';
import Search from '../molecules/Search';

import alignSearchQuery from '../_util/alignSearchQuery';

const StyledTargetItemsWrap = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 3px;
`
const StyledTargetItemsHead = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid #d9d9d9;
  font-weight: 600;
`
const StyledTargetItemsBody = styled.div`
  display: flex;
  align-items: stretch;
`
const StyledTargetItemsColumn = styled.div`
  width: 50%;
  box-sizing: border-box;
  position: relative;
  padding-top: 32px;
  padding-right: 32px;
  padding-left: 32px;
`
const StyledClearAll = styled.div`
  cursor: pointer;
`
const StyledTargetsItems = styled.div`
  height: 240px;
  margin-top: -8px;
  margin-right: -32px;
  margin-left: -32px;
`
const StyledTargetsItemsIn = styled.div`
  padding-bottom: 32px;
  padding-right: 32px;
  padding-left: 32px;
`
const StyledSelectedItems = styled.div`
  width: 100%;
  position: absolute;
  top: calc(1em + 56px);
  bottom: 0;
  left: 0;
`
const StyledSelectedItemsIn = styled.div`
  margin-top: -8px;
  padding-bottom: 32px;
  padding-right: 32px;
  padding-left: 32px;
`

class SelectTargetItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      checked: [],
      searchQuery: '',
    };
  }
  componentDidUpdate = async (prevProps, prevState) => {
    if (
      // targetIdが更新された場合に、指定のtargetの要素一覧を取得しなおす
      prevProps.targetData !== this.props.targetData
    ) {
      this.setState({
        data: [],
      });
      this.filterData();
    }
  }
  filterData = () => {
    // 元データを指定の条件でフィルタする
    const data = this.props.targetData.filter(item => {
      const regexp = new RegExp(
        `^(?=.*${alignSearchQuery(this.state.searchQuery)}).*$`
      );

      return (
        this.state.searchQuery === ''
        || alignSearchQuery(item.clientName).match(regexp) !== null
        || alignSearchQuery(item.accountName).match(regexp) !== null
        || alignSearchQuery(item.campaignName).match(regexp) !== null
        || alignSearchQuery(item.adgroupName).match(regexp) !== null
        || alignSearchQuery(item.keywordName).match(regexp) !== null
      )
    });
    const checked = data.filter(item => {
      return this.props.checked.indexOf(this.getTargetId(item)) > -1;
    }).map(item => {
      return this.getTargetId(item);
    });
    this.setState({
      data: data,
      checked: checked,
    });
  }
  getTargetId = (item) => {
    // 対象のカテゴリーごとに参照するIDを変更する
    switch (this.props.targetId) {
      case 1: return parseInt(item.clientId);
      case 2: return parseInt(item.accountId);
      case 3: return parseInt(item.campaignId);
      case 4: return parseInt(item.adgroupId);
      case 5: return parseInt(item.keywordId);
      default: return;
    }
  }
  render() {
    // アラートを設定する対象のラベルを出力
    const getLabel = (item) => {
      switch (this.props.targetId) {
        case 1: return ([
          <span key = { _.uniqueId() }>{ item.clientName }</span>,
        ])
        case 2: return ([
          <span key = { _.uniqueId() }>{ item.accountName }</span>,
          <small key = { _.uniqueId() }>（{ item.clientName } / { item.media.label }）</small>
        ])
        case 3: return ([
          <span key = { _.uniqueId() }>{ this.props.isLabelList ? item.labelList : item.campaignName }</span>,
          <small key = { _.uniqueId() }>（{ item.clientName } / { item.media.label } / { item.accountName }）</small>
        ])
        case 4: return ([
          <span key = { _.uniqueId() }>{ this.props.isLabelList ? item.labelList : item.adgroupName }</span>,
          <small key = { _.uniqueId() }>（{ item.clientName } / { item.media.label } / { item.accountName } / { item.campaignName }）</small>
        ])
        case 5: return ([
          <span key = { _.uniqueId() }>{ this.props.isLabelList ? item.labelList : item.keywordName }</span>,
          <small key = { _.uniqueId() }>（{ item.clientName } / { item.media.label } / { item.accountName } / { item.campaignName } / { item.adgroupName }）</small>
        ])
        default: return;
      }
    }

    // フリーワード検索
    const setSearchQuery = async (e) => {
      e.preventDefault();
      await this.setState({
        searchQuery: e.target['searchQuery'].value,
      });
      this.filterData();
    }

    return (
      <StyledTargetItemsWrap>
        <StyledTargetItemsHead>
          {
              // 選択したカテゴリーのラベルを表示
            (() => {
              const obj = _.find(
                REPORT_TARGETS, o => {
                    return o.id === this.props.targetId;
                }
              );
              return (obj) ? obj.label : '';
            })()
          }
        </StyledTargetItemsHead>
        <StyledTargetItemsBody>
          <StyledTargetItemsColumn>
            <FlexBox justify="space-between" className="m-b-16">
              <div>全{ this.state.data.length }件</div>
              <Search
                name = "searchQuery"
                onSubmit = { setSearchQuery }
                value = { this.state.searchQuery }
              />
            </FlexBox>
            <FlexBox justify="space-between">
              <CheckboxWithLabel
                id = "target-select-all"
                onChange = {
                  (() => {
                    if (this.state.checked.length < this.state.data.length) {
                      this.setState({
                        checked: this.state.data.map(item => {
                          return this.getTargetId(item);
                        })
                      }, () => {
                        this.props.toggleCheckAll(this.state.checked, true);
                      });
                    } else {
                      this.props.toggleCheckAll(this.state.checked, false);
                      this.setState({
                        checked: []
                      });
                    }
                  })
                }
                checked = { this.state.checked.length === this.state.data.length ? 'checked' : '' }
              >
                すべて選択
              </CheckboxWithLabel>
              <StyledClearAll
                onClick = {
                  (() => {
                    this.setState({
                      checked: []
                    });
                    this.props.clearCheckAll();
                  })
                }
              >
                すべてクリア
              </StyledClearAll>
            </FlexBox>
            <DividerDottedH hasMargin />
            <StyledTargetsItems>
              <Scrollbars>
                <StyledTargetsItemsIn>
                  {
                      // 選択可能な各階層の要素一覧を生成
                    (() => {
                      return this.state.data.map((item, index) => {
                        const id = this.getTargetId(item);
                        return (
                          <CheckboxWithLabel
                            className = "m-t-8"
                            key = { _.uniqueId() }
                            name = "targets"
                            id = { `targets-${index}` }
                            value = { String(id) }
                            checked = { this.state.checked.indexOf(id) > -1 ? 'checked' : '' }
                            onChange = {
                              ((e) => {
                                if (this.state.checked.indexOf(id) > -1) {
                                  this.state.checked.splice(this.state.checked.indexOf(id), 1)
                                } else {
                                  this.state.checked.push(id);
                                }
                                this.props.checkItem(e);
                              })
                            }
                          >
                            { getLabel(item) }
                          </CheckboxWithLabel>
                        )
                      });
                    })()
                  }
                </StyledTargetsItemsIn>
              </Scrollbars>
            </StyledTargetsItems>
          </StyledTargetItemsColumn>
          <DividerDottedVWide />
          <StyledTargetItemsColumn>
            <div className="m-b-16">
              選択{ this.props.checked.length }件
            </div>
            <StyledSelectedItems>
              <Scrollbars>
                <StyledSelectedItemsIn>
                  {
                    (() => {
                      const removeItem = (id) => {
                        const checked = this.props.checked;
                        checked.splice(this.props.checked.indexOf(id), 1);
                        this.setState({
                            checked: checked.concat()
                        });
                      }

                      return this.props.targetData.map(item => {
                        const id = this.getTargetId(item);
                        if (this.props.checked.indexOf(id) > -1) {
                          return (
                            <FlexBox
                              className = "m-t-8"
                              key = { _.uniqueId() }
                              justify = "space-between"
                            >
                              <div>
                                { getLabel(item) }
                              </div>
                              <ButtonClose
                                onClick = { (() => { removeItem(id) }) }
                              />
                            </FlexBox>
                          )
                        } else {
                          return false;
                        }
                      })
                    })()
                  }
                </StyledSelectedItemsIn>
              </Scrollbars>
            </StyledSelectedItems>
          </StyledTargetItemsColumn>
        </StyledTargetItemsBody>
      </StyledTargetItemsWrap>
    )
  }
}

SelectTargetItems.defaultProps = {
  isLabelList: false,
};

export default SelectTargetItems;
