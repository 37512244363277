import React from 'react';
import styled from 'styled-components';

const IconMinusSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="1" viewBox="0, 0, 7, 1" className={props.className}>
    <path fillRule="evenodd" fill="gray" d="M0 0h7v1H0V0z"/>
  </svg>
)
const IconMinus = styled(IconMinusSvg) `
  display: block;
`

export default IconMinus;
