import styled from 'styled-components'
import img from '../../img/line_dotted_v.gif';

const DividerDottedV = styled.div`
  width: 1px;
  height: ${props => props.height ? props.height : '15px'};
  margin-right: ${props => props.hasMargin ? '24px' : '0'};
  margin-left: ${props => props.hasMargin ? '24px' : '0'};
  background-image: url(${img});
`;

export default DividerDottedV;
