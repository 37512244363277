import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import GraphTooltip from './GraphTooltip';

const StyledGraphHoverItem = styled.div`
  width: ${ props =>
    (props.length === 1)
      ? '100%'
      : (props.index === 0 || props.index === props.length - 1)
        ? `calc(1 / ${props.length - 1} * 100% / 2)`
        : `calc(1 / ${props.length - 1} * 100%)`
    };
  height: 100%;
  position: relative;
  flex-shrink: 0;
`

class GraphHoverItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    }
  }
  render() {
    const mouseon = () => {
      this.setState({
        isHovered: true
      });
    }
    const mouseout = () => {
      this.setState({
        isHovered: false
      });
    }

    return (
      <StyledGraphHoverItem
        index = { this.props.index }
        length = { this.props.length }
        onMouseOver = { mouseon }
        onMouseOut = { mouseout }
      >
        <GraphTooltip
          summary1 = { this.props.summary1 }
          summary2 = { this.props.summary2 }
          date = { this.props.date }
          dateCompare = { this.props.dateCompare }
          data1 = { this.props.data1 }
          data1Compare = { this.props.data1Compare }
          data2 = { this.props.data2 }
          data2Compare = { this.props.data2Compare }
          max1 = { this.props.max1 }
          max2 = { this.props.max2 }
          isEnabledCompare = { this.props.isEnabledCompare }
          className = {
            classnames([
              (this.state.isHovered === true) ? 'is-shown' : 'is-hidden',
              (this.props.length === 1)
                ? 'is-only'
                : (this.props.index === 0)
                  ? 'is-first'
                  : (this.props.index === this.props.length - 1)
                    ? 'is-last'
                    : (this.props.index <= this.props.length / 2)
                      ? 'is-first-half'
                      : 'is-latter-half',
            ])
          }
        />
      </StyledGraphHoverItem>
    )
  }
}
export default GraphHoverItem;
