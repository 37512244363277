import React from 'react';
import styled from 'styled-components'

import ButtonSmall from './ButtonSmall';
import IconClose from './IconClose';

const ButtonClose = ({...attr}) => (
  <ButtonSmall
    dark
    size = "s"
    { ...attr }
  >
    <IconClose />
  </ButtonSmall>
);

const StyledButtonClose = styled(ButtonClose)`
  cursor: pointer;
  flex-shrink: 0;
`;

export default StyledButtonClose;
