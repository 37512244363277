import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  background-color: ${ COLOR.GREEN };
  padding: 8px 10px 5px 15px;
  border-radius: 20px;
`

const LabelStatus = (props) => (
  <StyledLabel>
    { props.children }
  </StyledLabel>
)

export default LabelStatus;
