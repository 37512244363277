import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import COLOR from '../_const/COLOR'
import FONTSIZE from '../_const/FONTSIZE'

import DividerDottedV from '../atoms/DividerDottedV';
//import LogoRakuda from '../atoms/LogoRakuda';
import LogoRakuda from '../../img/logo-header.png'
import IconAlertNotification from '../atoms/IconAlertNotification';
import IconQuestion from '../atoms/IconQuestion';
import IconTricolon from '../atoms/IconTricolon';
import IconStar from '../atoms/IconStar';
import PullDownAccount from '../organisms/PullDownAccount';
import PullDownDatePicker from '../organisms/PullDownDatePicker';

const StyledHeader = styled.header`
  width: 100%;
  height: 120px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`
const StyledHeaderRow1 = styled.div`
  width: 100%;
  height: 64px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 10;
  background-color: ${COLOR.WHITE};
`
const StyledHeaderRow2 = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 180px;
  color: ${COLOR.WHITE};
  font-size: ${FONTSIZE.M}px;
  font-weight: 600;
  background-color: ${COLOR.ORANGE};
`
const StyledHeaderLogo = styled(Link)`
  box-sizing: border-box;
  display: flex;
  position: absolute;
  left: 24px;
  top: 20px;
`
// const StyledHeaderLogoRakuda = styled(LogoRakuda)`
//   width: 136px;
//   height: 20px;
// `
const StyledHeaderMenu = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 40px;
`
const StyledContentTitle = styled.div`
  transform: translate3d(0, 0, 0);
  transition-duration: .2s;
  transition-property: transform;
  &.is-closed {
    transform: translate3d(-110px, 0, 0);
  }
`
const StyledPullDownDatePickerWrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 40px;
`
const StyledPullDownDatePickerWrapIn = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  background-color: #000;
`
class Header extends React.Component {
  render() {
    return (
      <StyledHeader>
        <StyledHeaderRow1>
          <StyledHeaderLogo
            to = '/'
          >
            <img src={LogoRakuda} alt="MMerge" />
          </StyledHeaderLogo>
          <StyledHeaderMenu>
            <Link
              to = '/favorite/'
              className = 'm-l-16'
            >
              <IconStar/>
            </Link>
            <Link
              to = '/alert-notification/'
              className = 'm-l-16'
            >
              <IconAlertNotification/>
            </Link>
            <a
              href="https://inf.mmerge.jp/"
              target="_blank"
	            rel="noreferrer noopener"
            >
              <IconQuestion/>
            </a>
            <DividerDottedV hasMargin />
            <PullDownAccount
              user = { this.props.user }
            />
          </StyledHeaderMenu>
        </StyledHeaderRow1>
        <StyledHeaderRow2>
          <StyledContentTitle className={this.props.isOpenedSideMenu === true ? "is-opened" : "is-closed"}>
            { this.props.currentContentTitle }
          </StyledContentTitle>
          {
            (() => {
              if (this.props.isShownDatePicker) {
                return (
                  <StyledPullDownDatePickerWrap>
                    <IconTricolon
                      className = "m-r-16"
                    />
                    <StyledPullDownDatePickerWrapIn>
                      <PullDownDatePicker
                        dateRange = { this.props.dateRange }
                        dateRangeCompare = { this.props.dateRangeCompare }
                        isEnabledCompare = { this.props.isEnabledCompare }
                        applyDateRange = { this.props.applyDateRange }
                        isOnHeader
                      />
                    </StyledPullDownDatePickerWrapIn>
                  </StyledPullDownDatePickerWrap>
                )
              }
            })()
          }
        </StyledHeaderRow2>
      </StyledHeader>
    )
  }
}

export default Header;
