import React from 'react';
import styled from 'styled-components';

const IconSplit = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="9" viewBox="0, 0, 18, 9" className={props.className}>
    <path fillRule="evenodd" fill="gray" d="M0 1V0h18v1H0zm14 4H0V4h14v1zm-4 4H0V8h10v1z"/>
  </svg>
)
const StyledIconSplit = styled(IconSplit) `
  display: block;
`

export default StyledIconSplit;
