import React from 'react';
import styled, { keyframes } from 'styled-components';

import FONTSIZE from '../_const/FONTSIZE'

const StyledProgressingCover = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  pointer-events: ${ props => props.isProgressing ? 'auto' : 'none' };
  transition-duration: .2s;
  transition-property: opacity;
  ${
    props => {
      if (props.isProgressing) {
        return `
          opacity: 1;
        `
      } else {
        return `
          opacity: 0;
        `
      }
    }
  }
`
const StyledProgressingCoverContent = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 10;
`
const KeyFramesRotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const StyledProgressingCoverIcon = styled.div`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  margin-bottom: 16px;
  border: 3px solid #fff;
  animation-name: ${ KeyFramesRotate };
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transition-duration: .6s;
  transition-property: opacity, transform;
`
const StyledProgressingCoverLabel = styled.div`
  color: #fff;
  font-size: ${ FONTSIZE.S }px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  transition-duration: .6s;
  transition-property: opacity;
`
const StyledProgressingCoverBg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`

class ProgressingCover extends React.Component {
  render() {
    return (
      <StyledProgressingCover
        isProgressing = { this.props.isProgressing }
      >
        <StyledProgressingCoverContent>
          <StyledProgressingCoverIcon />
          <StyledProgressingCoverLabel>
            Progressing...
          </StyledProgressingCoverLabel>
        </StyledProgressingCoverContent>
        <StyledProgressingCoverBg/>
      </StyledProgressingCover>
    )
  }
};

export default ProgressingCover;
