import React from 'react';
import styled from 'styled-components';

const IconPlusSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0, 0, 7, 7" className={props.className}>
    <path fillRule="evenodd" d="M7 4H4v3H3V4H0V3h3V0h1v3h3v1z"/>
  </svg>
)
const IconPlus = styled(IconPlusSvg) `
  display: block;
  fill: gray;
`

export default IconPlus;
