import React from 'react';
import styled from 'styled-components';

import IconMore from '../atoms/IconMore';

const StyledPullDownMore = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
`
const StyledIconMore = styled(IconMore)`
  position: absolute;
  top: 0;
  left: 0;
`

const PullDownMore = (props) => (
  <StyledPullDownMore
    onClick = {props.onClick}
    className = {props.className}
  >
    {props.children}
    <StyledIconMore />
  </StyledPullDownMore>
);

export default PullDownMore;
