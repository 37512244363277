import _ from 'lodash';
import DISPITEM_TYPE from '../_const/DISPITEM_TYPE';

function parseAddItem(strNum) {
  let regex = null;
  let ans = Number(strNum);
  if (_.isNaN(ans)) {
    if (strNum == null) {
      return '-';
    }
    if (strNum.indexOf(',') !== -1) {
      regex = /,/g;
    } else if (strNum.indexOf('%') !== -1) {
      regex = '%';
    } else if (strNum.indexOf('¥') !== -1) {
      regex = '¥';
    } else {
      return '-';
    }
    ans = Number(strNum.replace(regex, ''));
  }
  return ans;
}

function addtionStr(prev, crr, def) {
  if (def === '-') return def;
  if (crr === '-') {
    return prev;
  } else if (prev === '-') {
    return crr;
  } else {
    return Number(prev) + Number(crr);
  }
}

export default function(obj, keysArr, baseObj) {
  return obj.reduce((prevArr, currentArr) => {
    keysArr.forEach(id => {
      const type = DISPITEM_TYPE[id];
      switch(type) {
        case 3:
          if (_.has(prevArr[id], 'src')) {
            prevArr[id] = { src: '-', dest: '-' };
          } else {
            prevArr[id] = '-';
          }
          break;
        case 4:
          if (_.has(prevArr[id], 'src')) {
            prevArr[id] = { src: ['-'], dest: ['-'] };
          } else {
            prevArr[id] = ['-'];
          }
          break;
        case 9:
          let tmpPrev = prevArr[id];
          let tmpCrr = currentArr[id];
          if (_.has(currentArr[id], 'src')) {
            if (id.indexOf('Item') !== -1) {
              tmpPrev.src = parseAddItem(_.get(prevArr, `${id}.src`, null));
              tmpPrev.dest = parseAddItem(_.get(prevArr, `${id}.dest`, null));
              tmpCrr.src = parseAddItem(_.get(currentArr, `${id}.src`, null));
              tmpCrr.dest = parseAddItem(_.get(currentArr, `${id}.dest`, null));
            }
            _.set(prevArr, `${id}.src`, addtionStr(tmpPrev.src, tmpCrr.src, baseObj[id].src));
            _.set(prevArr, `${id}.dest`, addtionStr(tmpPrev.dest, tmpCrr.dest, baseObj[id].dest));
          } else {
            if (id.indexOf('Item') !== -1) {
              tmpPrev = parseAddItem(prevArr[id]);
              tmpCrr = parseAddItem(currentArr[id]);
            }
            prevArr[id] = addtionStr(tmpPrev, tmpCrr, baseObj[id]);
          }
          break;
        default:
          if (_.has(currentArr, `${id}.src`)) {
            _.set(prevArr, `${id}.src`, _.get(prevArr, `${id}.src`, 0) + _.get(currentArr, `${id}.src`, 0));
            _.set(prevArr, `${id}.dest`, _.get(prevArr, `${id}.dest`, 0) + _.get(currentArr, `${id}.dest`, 0));
          } else {
            _.set(prevArr, id, _.get(prevArr, id, 0) + _.get(currentArr, id, 0));
          }
      }
    });
    return prevArr;
  }, {});
}
