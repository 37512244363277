import React from 'react';
import styled from 'styled-components';
import COLOR from '../_const/COLOR'
import REPORT_ITEMS from '../_const/REPORT_ITEMS'

// TODO: test import/delete
import axios from 'axios';
import _ from 'lodash';

import User from '../../utils/user';

// components
import Button from '../atoms/Button';
import FlexBox from '../atoms/FlexBox';
import InputText from '../atoms/InputText';
import SmallText from '../atoms/SmallText';
import PullDownCommon from '../organisms/PullDownCommon';
import PullDownFormula from '../organisms/PullDownFormula';
import InputVariable from '../atoms/InputVariable';

const backendApi = process.env.REACT_APP_BACKEND_URI;

const StyledInputText = styled(InputText)`
  width: 240px;
  &.is-error {
    outline: auto 1px ${COLOR.RED};
    background-color: ${COLOR.LIGHTRED};
  }
`

const StyledCustomBox = styled.div`
  width: 100%;
  height: 400px;
`

const StyledPullDownUnit = styled(PullDownCommon)`
  width: 140px;
`

const StyledSymbolButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition-duration: .2s;
  transition-property: background-color;
  :hover {
    background-color: ${COLOR.GRAY};
    transition-duration: .2s;
    transition-property: background-color;
  }
`

const StyledButton = styled(Button)`
  margin-left: auto;
  margin-bottom: 8px;
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`

const StyledItemLabel = styled.div`
  padding-top: 2px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 15px;
  color: #fff;
  background-color: ${COLOR.GREEN};
`

const StyledLabel = styled.div`
  padding-top: 2px;
`

const StyledCustomArea = styled.div`
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;
  gap: 10px 0;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  &.is-error {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: ${COLOR.LIGHTRED};
    input {
      background-color: ${COLOR.LIGHTRED};
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      border: 2px solid ${COLOR.RED};
      border-radius: 4px;
      pointer-events: none;
    }
  }
`

class Formula extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFirstFocus: false,
      cFormula: [{
        key: 'input0',
        label: '',
        value: '',
        elm: '',
        type: 'input',
        ref: React.createRef()
      }],
      focusIndex: 0,
      unit: 1,
      name: '',
      note: '',
      isError: false,
      isNameError: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpened && !this.state.isFirstFocus) {
      if (this.props.addItemParam) {
        this.setFomula(this.props.addItemParam.itemInfo);
        this.setState({
          unit: this.props.addItemParam.dispUnit,
          name: this.props.addItemParam.name,
          note: this.props.addItemParam.note,
          isFirstFocus: true,
        });
      } else {
        // 最初にモーダルを開いた時に、inputにフォーカスを当てる
        this.state.cFormula[0].ref.current.focus();
        this.setState({
          isFirstFocus: true,
        });
      }
    }
    // モーダルを閉じた時にstateを削除する
    if (prevProps.isOpened && !this.props.isOpened) {
      this.clearFormula();
    }
  }

  // 計算式を再設定する
  setFomula(str) {
    const ariSigns = [
      {
        label: '＋',
        elm: 'tasu',
      },
      {
        label: '−',
        elm: 'hiku',
      },
      {
        label: '×',
        elm: 'kakeru',
      },
      {
        label: '÷',
        elm: 'waru',
      },
    ];
    const params = str.split('^');
    let formula = [];
    const regex = /^\[.*\]$/;
    for (var i = 0; i < params.length; i++) {
      const sign = _.find(ariSigns, ['elm', params[i]]);
      if (regex.test(params[i])) {
        const value = params[i].substr(1, params[i].length - 2)
        formula.push({
          key: 'input',
          label: '',
          value: value,
          elm: '',
        });
      } else {
        const base = formula[formula.length - 1];
        if (_.has(base, 'key')) {
          if (base.key !== 'input') {
            formula.push(null);
          }
        } else {
          formula.push(null);
        }
        if (sign) {
          formula.push({
            key: 'sign',
            label: sign.label,
            value: '',
            elm: sign.elm,
          });
        } else if (params[i].indexOf('|') >= 0) {
          const arr = params[i].split('|');
          formula.push({
            key: 'dispItem',
            label: arr[1],
            value: '',
            elm: params[i],
          });
        } else {
          // 再現できないデータ
        }
      }
    }
    if (formula[0] !== null) {
      if (formula[0].key !== 'input') formula.push(null);
    }
    if (formula[formula.length - 1] !== null) {
      if (formula[formula.length - 1].key !== 'input') formula.push(null);
    }
    const cFormula = formula.map((item, index) => {
      return {
        key: _.has(item, 'key') ? `${item.key}${index}` : `input${index}`,
        label: _.has(item, 'label') ? item.label : '',
        value: _.has(item, 'value') ? item.value : '',
        elm: _.has(item, 'elm') ? item.elm : '',
        type: _.has(item, 'key') ? item.key : 'input',
        ref: React.createRef()
      }
    });
    this.setState({
      cFormula: cFormula
    });
  }

  // 計算式に項目・記号を追加
  async addChar(char = '', elm) {
    const focusInput = this.state.cFormula[this.state.focusIndex];
    const cursorIndex = focusInput.ref.current.selectionStart;
    const currentInputLen = focusInput.value.length;
    let insertIndex = this.state.focusIndex;
    if (cursorIndex === 0) {
      // 文字列の前に挿入
      await this.addInput('', insertIndex);
      await this.addInput(char, insertIndex + 1, elm);
      insertIndex++;
    } else if (currentInputLen === cursorIndex){
      // 文字列の後ろに挿入
      insertIndex++;
      await this.addInput(char, insertIndex, elm);
      await this.addInput('', insertIndex + 1);
    } else {
      // 文字列の途中に挿入
      insertIndex++;
      const valueBef = focusInput.value.substr(0, cursorIndex);
      const valueAft = focusInput.value.substr(cursorIndex);
      this.state.cFormula[this.state.focusIndex].value = valueBef
      await this.addInput(char, insertIndex, elm);
      await this.addInput('', insertIndex + 1);
      this.state.cFormula[insertIndex + 1].value = valueAft;
    }
    await this.state.cFormula[insertIndex + 1].ref.current.focus();
    this.state.cFormula[insertIndex + 1].ref.current.setSelectionRange(0, 0);
  }

  // 配列追加
  addInput(label = '', index, elm) {
    const isDispItem = elm ? elm.indexOf('|') !== -1 : false;
    const type = isDispItem
      ? 'dispItem'
      : (elm ? 'sign' : 'input');
    var newInputKey = `${type}${index}`;
    let isUnique = false
    const getfindIndex = (key) => _.findIndex(this.state.cFormula, (o) => o.key === key);
    do {
      isUnique = getfindIndex(newInputKey) < 0
      if (isUnique) {
        break;
      } else {
        newInputKey = newInputKey + 'a';
      }
    } while (!isUnique);
    const cFormula = this.state.cFormula.slice();
    cFormula.splice(index, 0, {
      key: newInputKey,
      value: '',
      label: label,
      elm: elm,
      type: type,
      ref: React.createRef()
    });
    this.setState({
      cFormula: cFormula
    });
  }

  // backspaceで項目・記号を削除する
  async handleKeyDown(e, index) {
    if (e.key === 'Backspace' && e.currentTarget.selectionStart === 0) {
      if (index > 0) {
        e.preventDefault();
        let newInputs = [
          ...this.state.cFormula.slice(0, index - 1),
          ...this.state.cFormula.slice(index + 1)
        ];
        await this.setState({
          cFormula: newInputs
        });
        this.state.cFormula[index - 2].ref.current.focus();
      }
    }
  }

  // 入力された数値や項目・記号を記録する
  handleChange(event, index) {
    let newInputs = this.state.cFormula;
    newInputs[index].value = event.target.value;
    this.setState({
      cFormula: newInputs
    });
  }

  // フォーカスの当たっている配列番号を記録する
  handleFocus(index) {
    this.setState({
      focusIndex: index
    });
  }

  // 何も入力がない場合に計算式エリアをクリックした場合、最初の要素にフォーカスを当てる
  handleClick() {
    if (this.state.cFormula.length === 1 && this.state.cFormula[0].value.length === 0) {
      this.state.cFormula[0].ref.current.focus();
    }
  }

  // エディタの内容をクリアする
  clearFormula() {
    this.setState({
      isFirstFocus: false,
      cFormula: [{
        key: 'input0',
        label: '',
        value: '',
        elm: '',
        ref: React.createRef()
      }],
      focusIndex: 0,
      unit: 1,
      name: '',
      note: '',
    });
  }

  // 名前検証
  validateName(name) {
    const disuse = [
      '[',
      ']',
      ',',
      '^',
      '|',
      'tasu',
      'hiku',
      'kakeru',
      'waru'
    ];
    const isNameError = disuse.some(str => name.indexOf(str) !== -1);
    this.setState({
      isNameError
    });
  }

  render() {
    // 計算式の単位を変更する
    const switchFomulaUnit = (id) => {
      this.setState({
        unit: id,
      });
    }

    // 追加項目の名前
    const editName = (e) => {
      this.setState({
        name: e.target.value,
      });
    }

    // 追加項目の補足事項
    const editNote = (e) => {
      this.setState({
        note: e.target.value,
      });
    }

    // レポートデータ項目を引用
    const switchTarget = async (label) => {
      const item = _.filter(REPORT_ITEMS, { label: label })[0];
      this.addChar(item.note ,`${item.id}|${item.note}`);
    }

    // 確認
    const checkFormula = async (itemInfoTmp) => {
      let prevType = 'input';
      const len = itemInfoTmp.length;
      // 初期化
      await this.setState({
        isError: false,
      });
      // 数式が記号で終始しないか
      if (itemInfoTmp[0].type === 'sign' || itemInfoTmp[len - 1].type === 'sign') {
        await this.setState({
          isError: true,
        });
        return;
      }
      // 同じ種別のものが連続しない
      for (var i = 1; i < itemInfoTmp.length; i++) {
        const type = itemInfoTmp[i].type;
        if (type === prevType) {
          this.setState({
            isError: true,
          });
          break;
        } else {
          prevType = type;
        }
      }
    }

    // API実行
    const save = async () => {
      // データ取得処理
      const itemInfoTmp = await this.state.cFormula.map(item => {
        let elm = {
          type: item.type,
          value: item.elm
        };
        if (item.value) elm.value = `[${item.value.replace(',', '')}]`;
        return elm;
      }).filter(x => x.value);
      // 数式整合性チェック
      await checkFormula(itemInfoTmp);
      // 名前整合性チェック
      await this.validateName(this.state.name);
      // エラーフラグが立った場合はAPIを実行しない
      if (this.state.isError || this.state.isNameError) {
        return;
      }
      // itemInfo生成
      const itemInfo = itemInfoTmp.map(x => x.value).join('^');
      // 項目追加API実行
      await axios.get(backendApi + 'addReportItemModify', {
        params: {
          ...User.apiParams(),
          id: this.props.addItemParam ? this.props.addItemParam.id : 0,
          name: this.state.name,
          itemInfo: itemInfo,
          dispUnit: this.state.unit,
          note: this.state.note,
          func: 1,
        },
      })
      .then((response) => {
      })
      .catch((error) => {
      });
      // 追加項目一覧再取得
      await this.props.getReportAddItem();
      // モーダルを閉じる
      await this.props.close();
      // エディタの内容をクリアする
      this.clearFormula();
    }

    // 名前の登録があり、計算式に要素が存在する場合に登録可能と判定
    const canSendData =
      (this.state.cFormula.length > 1 || this.state.cFormula[0].value.length > 0)
      && this.state.name.length > 0;

    return (
      <StyledCustomBox>
        <FlexBox>
          <div>
            <div>名前：</div>
            <StyledInputText
              value = { this.state.name }
              onChange = { editName }
              className = { this.state.isNameError ? 'is-error' : '' }
            />
          </div>
          <div
            className = 'm-l-8'
          >
            <div>説明文（任意）：</div>
            <StyledInputText
              value = { this.state.note }
              onChange = { editNote }
            />
          </div>
        </FlexBox>
        <SmallText>※[],^|の記号、および"tasu"・"hiku"・"kakeru"・"waru"はシステムで使用しているため、名前には使用できません。</SmallText>
        <FlexBox>
          <PullDownFormula
            items = { this.props.items.length > 0 ? this.props.items : [] }
            listWidth = { '300px' }
            onChange = { (e) => switchTarget(e) }
          />
          <StyledSymbolButton
            onClick = { () => this.addChar('＋', 'tasu') }
          >＋</StyledSymbolButton>
          <StyledSymbolButton
            onClick = { () => this.addChar('−', 'hiku') }
          >−</StyledSymbolButton>
          <StyledSymbolButton
            onClick = { () => this.addChar('×', 'kakeru') }
          >×</StyledSymbolButton>
          <StyledSymbolButton
            onClick = { () => this.addChar('÷', 'waru') }
          >÷</StyledSymbolButton>
        </FlexBox>
        <StyledCustomArea
          onClick = { e => this.handleClick() }
          className = { this.state.isError ? 'is-error' : '' }
        >
        {
          (() => {
            const elm = this.state.cFormula.map((item, index) => {
              if (item.key.indexOf('dispItem') !== -1) {
                return (<StyledItemLabel
                  key = { item.key }
                  >{ item.label }
                  </StyledItemLabel>
                );
              } else if (item.label) {
                return (<StyledLabel
                  key = { item.key }
                  >{ item.label }
                  </StyledLabel>
                );
              } else {
                return (<InputVariable
                  key = { item.key }
                  isAutoFocus = { index === 0 }
                  innerRef = { item.ref }
                  inputValue = { item.value }
                  handleKeyDown = { e => this.handleKeyDown(e, index) }
                  handleChange = { e => this.handleChange(e, index) }
                  handleFocus = { e => this.handleFocus(index) }
                />)
              }
            })
            return elm;
          })()
        }
        </StyledCustomArea>
        <FlexBox
          justify = 'between'
          className = 'm-t-8'
        >
          <FlexBox>
            データ形式：
            <StyledPullDownUnit
              id = { this.state.unit }
              listWidth = '200px'
              items = {
                [
                  { id: 1, label: '数字' },
                  { id: 2, label: 'パーセント（%）' },
                ]
              }
              onChange = { switchFomulaUnit }
            />
          </FlexBox>
          <StyledButton
            color = 'orange'
            onClick = { save }
            disabled = { !canSendData }
          >
            保存
          </StyledButton>
        </FlexBox>
      </StyledCustomBox>
    )
  }
}
export default Formula;
