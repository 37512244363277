import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR';

const IconWarning = ({ ...attr }) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width = "24"
    height = "24"
    viewBox = "0 0 24 24"
    { ...attr }
  >
    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/>
  </svg>
)
const StyledIconWarning = styled(IconWarning) `
  width: 20px;
  height: 20px;
  display: block;
  fill: ${ COLOR.RED };
`

export default StyledIconWarning;
