import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';
import Sortable from 'react-sortablejs';
import classnames from 'classnames';

import User from '../../utils/user';

import Button from '../atoms/Button';
import IconPlus from '../atoms/IconPlus';
import ButtonToggle from '../atoms/ButtonToggle';
import CheckboxWithLabel from '../atoms/CheckboxWithLabel';
import DividerDottedVWide from '../atoms/DividerDottedVWide';
import FlexBox from '../atoms/FlexBox';
import HeadingS from '../atoms/HeadingS';
import SmallText from '../atoms/SmallText';
import Draggable from '../molecules/Draggable';

const StyledMenuAndSelected = styled.div`
  height: 340px;
  display: flex;
  align-items: stretch;
  margin-bottom: 4px;
  &.has-border {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
  }
`
const StyledMenu = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-right: 4px;
`
const StyledMenuIn = styled.div`
  padding: 32px 24px;
`
const StyledMenuSection = styled.div`
  position: relative;
`
const StyledMenuHeading = styled(HeadingS)`
  cursor: pointer;
  position: relative;
`
const StyledMenuItems = styled(FlexBox)`
  height: 0;
  align-items: flex-start;
  overflow: hidden;
  &.is-active {
    height: auto;
  }
`
const StyledSelected = styled.div`
  width: 284px;
  flex-shrink: 0;
`
const StyledSelectedIn = styled.div`
  padding: 32px 16px 8px 8px;
`
const StyledSelectedHeader = styled.div`
  margin-bottom: 16px;
  padding-left: 16px;
`
const StyledCheckbox = styled(CheckboxWithLabel)`
  width: 160px;
  box-sizing: border-box;
  margin-top: 8px;
  padding-right: 1em;
`
const StyledCheckboxLine = styled(StyledCheckbox)`
  width: auto;
  padding-right: 0;
`
const StyledButtonToggle = styled(ButtonToggle)`
  position: absolute;
  top: 0;
  right: 0;
`
const StyledMenuItemsLine = styled.div`
  width: 100%;
  height: 0;
  margin-top: 4px;
  overflow: hidden;
  &.is-active {
    height: auto;
  }
`
const StyledMenuItemsBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 8px;
`
const StyledMenuItemsBodyLine = styled(StyledMenuItemsBody)`
  width: 100%;
  align-items: flex-start;
`
const StyledMenuItemsBodyIn = styled.div`
  width: 400px;
`
const StyledMenuItemHead = styled.div`
  position: relative;
  width: 4em;
  &::after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    content: '：';
  }
`
const StyledMenuItemBody = styled.div`
  flex: 1;
`
const StyledButton = styled(Button)`
  width: 5em;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 1px;
`
const StyledButtonAdd = styled(StyledButton)`
  width: auto;
`
const StyleIconPlus = styled(IconPlus)`
  margin-right: 5px;
  margin-bottom: 3px;
`
const StyledCautionText = styled(SmallText)`
  text-align: right;
`

const backendApi = process.env.REACT_APP_BACKEND_URI;
class SelectDispItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked,
      isOpened: [false, false, false, false],
      isOpenedAdd: false,
    };
  }

  // 初期値
  static defaultProps = {
    isAddItems: false,
  };

  componentDidMount = () => {
    if (this.props.getReportAddItem) {
      this.props.getReportAddItem();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      // チェックした要素が更新されたとき
      prevProps.checked !== this.props.checked
    ) {
      this.setState({
        checked: this.props.checked
      });
    }
  }
  labelName(item) {
    if (item.id.indexOf('addItem') !== -1) {
      const itemNo = Number(item.id.replace('addItem', ''));
      const addItem = _.find(this.props.addItems, ['addItem', itemNo]);
      if (addItem) {
        return addItem.name;
      } else {
        return '(undefined)';
      }
    } else {
      return item.label;
    }
  }
  render() {
    // 表示項目カテゴリを開閉する
    const toggleMenu = (i) => {
      const isOpened = this.state.isOpened;
      isOpened[i] = !isOpened[i];
      this.setState({
        isOpened: isOpened
      })
    }

    // 追加項目カテゴリを開閉する
    const toggleMenuAdd = () => {
      let isOpenedAdd = this.state.isOpenedAdd
      isOpenedAdd = !isOpenedAdd;
      this.setState({
        isOpenedAdd: isOpenedAdd
      })
    }

    // 表示項目を選択する
    const selectItem = (e) => {
      const item = e.target.value.split(',');
      const checked = _.clone(this.state.checked);
      if (e.target.checked === true) {
        checked.push({
          id: item[0],
          label: item[1],
        });
      } else {
        checked.splice(
          _.findIndex(checked, o => { return o.id === item[0] }),
          1
        );
      }
      this.setState({
        checked: checked
      });
      this.props.updateChecked(checked);
    }

    // 選択済みの表示項目を削除する
    const removeItem = (id) => {
      const checked = _.clone(this.state.checked);
      const index = _.findIndex(checked, o => { return o.id === id });
      if (index >= 0) {
        checked.splice(index, 1);
        this.setState({
          checked: checked
        });
        this.props.updateChecked(checked);
      }
    }

    // 表示項目をソートする
    const sortItems = (sortedItems) => {
      const checked = sortedItems.map((str) => {
        const item = str.split(',');
        return {
          id: item[0],
          label: item[1],
        }
      });
      this.setState({
        checked: checked
      });
      this.props.updateChecked(checked);
    }

    // 追加項目を変更する
    const changeAddItem = async (e, param) => {
      this.props.openAddItemModal(e, param);
    }

    // 追加項目を削除する
    const deleteAddItem = async (id) => {
      //　表示選択項目に削除したい追加項目が含まれる場合
      const addItem = await _.find(this.props.addItems, ['id', id]);
      if (addItem) {
        const addItemId = `addItem${addItem.addItem}`;
        const hasAddItem = await _.findIndex(this.state.checked, ['id', addItemId]) >= 0;
        // 表示選択項目からも追加項目を削除する
        if (hasAddItem) {
          const addItemLabel = `addItem${addItem.addItem}`;
          await removeItem(addItemLabel);
        }
      }
      // 追加項目から削除する
      await axios.get(backendApi + 'addReportItemModify', {
        params: {
          ...User.apiParams(),
          ids: id,
          func: 2,
        },
      })
      .then((response) => {
      })
      .catch((error) => {
      });
      // 追加項目を再取得する
      await this.props.getReportAddItem();
    }

    return (
      <StyledMenuAndSelected
        className = {
          classnames({
            'has-border': this.props.hasBorder === true,
          })
        }
      >
        <StyledMenu>
          <Scrollbars>
            <StyledMenuIn>
              {
                (() => {
                  // 選択可能な表示項目一覧を生成
                  return this.props.selectableDispItems.map((category, index) => {
                    return (
                      <StyledMenuSection
                        key = { _.uniqueId() }
                        className = { index > 0 ? 'm-t-16' : '' }
                      >
                        <StyledMenuHeading
                          noMargin
                          onClick = { (() => { toggleMenu(index) }) }
                        >
                          { category.categoryName }
                          <StyledButtonToggle
                            isActive = { this.state.isOpened[index] === true }
                          />
                        </StyledMenuHeading>
                        <StyledMenuItems
                          flexWrap
                          className = {(() => {
                            return (this.state.isOpened[index] === true) ? 'is-active' : ''
                          })()}
                        >
                          {
                            (() => {
                              return category.dispItem.map((item, indexChild) => {
                                return (
                                  <StyledCheckbox
                                    key = { _.uniqueId() }
                                    id = { `progress_display_select_${index}_${indexChild}` }
                                    value = { `${item.id},${item.label}` }
                                    checked = {(() => {
                                      return (
                                        _.findIndex(this.state.checked, o => {
                                          return o.id === item.id
                                        }) > -1)
                                          ? 'checked'
                                          : '';
                                    })()}
                                    onChange = { selectItem }
                                  >
                                    { item.label }
                                  </StyledCheckbox>
                                )
                              });
                            })()
                          }
                        </StyledMenuItems>
                      </StyledMenuSection>
                    )
                  })
                })()
              }
              {
                (() => {
                  if (this.props.isAddItems === true) {
                    return (
                      <StyledMenuSection
                        key = 'add_item_key'
                        className = 'm-t-16'
                      >
                      <StyledMenuHeading
                        noMargin
                        onClick = { toggleMenuAdd }
                      >
                        追加項目
                      <StyledButtonToggle
                        isActive = { this.state.isOpenedAdd === true }
                      />
                      </StyledMenuHeading>
                      <StyledMenuItemsLine
                        className = {(() => {
                          return (this.state.isOpenedAdd === true) ? 'is-active' : ''
                        })()}
                      >
                        {
                          (() => {
                            if (this.props.addItems.length < 5) {
                              return (
                                <StyledButtonAdd
                                  onClick = { this.props.openAddItemModal }
                                  className = 'm-b-8'
                                ><StyleIconPlus />項目を追加する</StyledButtonAdd>
                              )
                            }
                          })()
                        }
                        {
                          (() => {
                            if (this.props.addItems.length > 0) {
                              return this.props.addItems.map((item, index) => {
                                return (
                                  <StyledCheckboxLine
                                    key = { _.uniqueId() }
                                    id = { `progress_display_add_${index}` }
                                    value = { `addItem${item.addItem},追加項目${item.addItem - 26}` }
                                    checked = {(() => {
                                      return (
                                        _.findIndex(this.state.checked, o => {
                                          return o.id === `addItem${item.addItem}`
                                        }) > -1)
                                        ? 'checked'
                                        : '';
                                      })()}
                                      onChange = { selectItem }
                                  >
                                    <StyledMenuItemsBodyLine>
                                      <StyledMenuItemsBodyIn>
                                        <FlexBox>
                                          <StyledMenuItemHead>名前</StyledMenuItemHead>
                                          <StyledMenuItemBody>{ item.name }</StyledMenuItemBody>
                                        </FlexBox>
                                        <FlexBox>
                                          <StyledMenuItemHead>メモ</StyledMenuItemHead>
                                          <StyledMenuItemBody>{ item.note ? item.note : '(undefined)' }</StyledMenuItemBody>
                                        </FlexBox>
                                        <FlexBox>
                                          <StyledMenuItemHead>計算式</StyledMenuItemHead>
                                          <StyledMenuItemBody>{ item.itemInfo }</StyledMenuItemBody>
                                        </FlexBox>
                                      </StyledMenuItemsBodyIn>
                                      <div>
                                        <StyledButton
                                          thin
                                          className = 'm-b-8'
                                          onClick = { (e) => changeAddItem(e, item) }
                                        >変更</StyledButton>
                                        <StyledButton
                                          color = 'darkgray'
                                          thin
                                          onClick = { () => deleteAddItem(item.id) }
                                        >削除</StyledButton>
                                      </div>
                                    </StyledMenuItemsBodyLine>
                                  </StyledCheckboxLine>
                                  )
                                });
                              }
                            })()
                          }
                          <StyledCautionText>
                            ※追加できる項目は5つまでです。
                          </StyledCautionText>
                        </StyledMenuItemsLine>
                      </StyledMenuSection>
                    )
                  }
                })()
              }
            </StyledMenuIn>
          </Scrollbars>
        </StyledMenu>
        <DividerDottedVWide />
        <StyledSelected>
          <Scrollbars>
            <StyledSelectedIn>
              <StyledSelectedHeader>
                <HeadingS noMargin>
                  選択した表示項目
                </HeadingS>
                <SmallText>
                  ドラッグ&ドロップで並べ替え
                </SmallText>
              </StyledSelectedHeader>
              <Sortable
                onChange = {
                  (order, sortable, e) => {
                    sortItems(order)
                  }
                }
              >
                {
                  (() => {
                    // 選択済みの表示項目一覧を生成
                    return this.state.checked.map((item, index) => {
                      return (
                        <div
                          key = { _.uniqueId() }
                          data-id = { `${item.id},${item.label}` }
                        >
                          <Draggable
                            onClose = { (() => {
                              removeItem(item.id)
                            }) }
                          >
                            { this.labelName(item) }
                          </Draggable>
                        </div>
                      )
                    });
                  })()
                }
              </Sortable>
            </StyledSelectedIn>
          </Scrollbars>
        </StyledSelected>
      </StyledMenuAndSelected>
    )
  }
}

export default SelectDispItems;
