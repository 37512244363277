import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'

import IconCheck from './IconCheck';

const StyledCheckbox = styled.button`
  width: 15px;
  height: 15px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
  border-radius: 3px;
  border: 1px solid;
  ${
    props => {
      if (props.isChecked === true) {
        return `
          border-color: ${COLOR.GREEN};
          background-color: ${COLOR.GREEN};
        `
      } else {
        return `
          border-color: #cdcdcd;
          background-color: ${COLOR.LIGHTGRAY};
        `
      }
    }
  }
`
const StyledIconCheck = styled(IconCheck)`
  ${
    props => {
      if (props.isChecked === true) {
        return `
          display: block;
        `
      } else {
        return `
          display: none;
        `
      }
    }
  }
`

const Checkbox = ({ ...attr }) => (
  <StyledCheckbox
    { ...attr }
  >
    <StyledIconCheck
      { ...attr }
    />
  </StyledCheckbox>
);

export default Checkbox;
