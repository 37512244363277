import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR';
import FONTSIZE from '../_const/FONTSIZE';

import ButtonSmall from '../atoms/ButtonSmall';
import IconArrowDown from '../atoms/IconArrowDown';

const StyledButtonSmall = styled(ButtonSmall)`
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -8px;
`

const PullDownHasBorderS = (props) => (
  <div
    onClick = {props.onClick}
    className = {props.className}
  >
    {props.children}
    <StyledButtonSmall className="m-l-8">
      <IconArrowDown />
    </StyledButtonSmall>
  </div>
);

const StyledPullDownHasBorderS = styled(PullDownHasBorderS)`
  width: ${props => props.width ? props.width : '100px'};
  min-width: ${props => props.minWidth ? props.minWidth : 'auto'};
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 23px;
  border-top: 1px solid #d9d9d9;
  font-size: ${props => props.small ? FONTSIZE.SS : FONTSIZE.S}px;
  &:before {
    height: 3px;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: ${COLOR.LIGHTGRAY};
  }
`

export default StyledPullDownHasBorderS;
