import React from 'react';
import styled from 'styled-components';

const IconArrowDownSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="4" viewBox="0, 0, 7, 4" className={props.className}>
    <path fillRule="evenodd" fill="gray" d="M3.5 4L.497-.003h6.006L3.5 4z"/>
  </svg>
)
const IconArrowDown = styled(IconArrowDownSvg) `
  display: block;
`

export default IconArrowDown;
