import React from 'react';
import styled from 'styled-components';

const IconFilter = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0, 0, 18, 16" className={props.className}>
    <path fillRule="evenodd" fill="gray" d="M17.948.303A.547.547 0 0 0 17.455 0H.546a.547.547 0 0 0-.494.303c-.09.184-.062.4.073.558L7 8.464v7.01a.52.52 0 0 0 .258.447.552.552 0 0 0 .529.024l2.874-1.745a.527.527 0 0 0 .304-.471l.007-5.265L17.875.861a.509.509 0 0 0 .073-.558zm-7.803 7.64a.52.52 0 0 0-.128.34l-.007 5.154-2.027 1.375V8.285a.522.522 0 0 0-.128-.342L1.486.988h14.993l-6.334 6.955z"/>
  </svg>
)
const StyledIconFilter = styled(IconFilter) `
  display: block;
`

export default StyledIconFilter;
