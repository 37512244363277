import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import FlexBox from '../atoms/FlexBox';
import PullDownProgressFilter from './PullDownProgressFilter';
import ButtonStatus from '../molecules/ButtonStatus';
import LabelStatus from '../molecules/LabelStatus';
import getUnit from '../_util/getUnit';

import MEDIAS from '../_const/MEDIA';
import MATCH_TYPE from '../_const/MATCH_TYPE';

const DEVICE = [
  {
    id: null,
    label: 'すべて',
  },
  {
    id: 1,
    label: 'パソコン',
  },
  {
    id: 4,
    label: 'モバイル'
  },
  {
    id: 8,
    label: 'タブレット'
  }
];

const StyledFilteredResult = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: bold;
`

class StatusFilter extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        className = "m-b-32"
      >
        <FlexBox
          flexWrap = 'true'
        >
          <PullDownProgressFilter
            // フィルタ
            requestParam = { this.props.requestParam }
            setFilterData = { this.props.setFilterData }
            editingDispItemId = { this.props.editingDispItemId }
            labelData = { this.props.labelDataFiltered }
            headerThresholdTmp = { this.props.headerThresholdTmp }
            openThresholdModal = { this.props.openThresholdModal }
            removeThreshold = { this.props.removeThreshold }
            isOpenedThresholdModal = { this.props.isOpenedThresholdModal }
            addItems = { this.props.addItems }
            className = "m-r-16"
          />
          <StyledFilteredResult>
            <LabelStatus>
              ステータス（{
                this.props.requestParam.dataExist ? 'アクティブのみ' : 'すべて'
              }）
            </LabelStatus>
          </StyledFilteredResult>
          {
            (() => {
              if (this.props.requestParam.device) {
                return DEVICE.map(device => {
                  if (device.id === this.props.requestParam.device) {
                    return (
                      <StyledFilteredResult
                        key = { `key_${device.id}` }
                      >
                        <LabelStatus>
                          デバイス（{ device.label }）
                        </LabelStatus>
                      </StyledFilteredResult>
                    )
                  } else {
                    return null;
                  }
                })
              } else {
                return (
                  <StyledFilteredResult>
                    <LabelStatus>
                      デバイス（すべて）
                    </LabelStatus>
                  </StyledFilteredResult>
                )
              }
            })()
          }
          {
            (() => {
              let ex = null;
              switch (this.props.requestParam.ex) {
                case 1:
                  ex = 'データ連携（外部CV）';
                  break;
                case 2:
                  ex = 'データ連携（すべて）';
                  break;
                default:
                  ex = 'データ連携（なし）'
                  // nothing
              }
              if (ex) {
                return (
                  <StyledFilteredResult>
                    <LabelStatus>{ ex }</LabelStatus>
                  </StyledFilteredResult>
                )
              }
            })()
          }
          {
            (() => {
              if (this.props.selectedLabel) {
                return (
                  <StyledFilteredResult>
                    <LabelStatus>ラベル（「{ this.props.selectedLabel.name }」）</LabelStatus>
                  </StyledFilteredResult>
                )
              }
            })()
          }
          {
            (() => {
              const elem = MEDIAS.map((media, i) => {
                if (this.props.requestParam[media.value] === 1) {
                  return (
                    <StyledFilteredResult
                      key = { media.value }
                    >
                      <ButtonStatus
                        remove = { () => {
                          this.props.remove(media.value);
                        } }
                      >媒体（{ media.label }）</ButtonStatus>
                    </StyledFilteredResult>
                  )
                } else {
                  return null;
                }
              })
              if (elem.every(x => x) || elem.every(x => !x)) {
                return (
                  <StyledFilteredResult>
                    <LabelStatus>媒体（すべて）</LabelStatus>
                  </StyledFilteredResult>
                )
              } else {
                return _.compact(elem);
              }
            })()
          }
          {
            (() => {
              if (this.props.headerThreshold.length > 0) {
                const thresholdsTmp = this.props.headerThreshold.map(items => {
                  if (items.dispItem.length > 0) {
                    return items.dispItem.map(item => {
                      if (item.number != null) {
                        return (
                          <StyledFilteredResult
                            key = { `key_${item.id}` }
                          >
                            <ButtonStatus
                              remove = { () => {
                                this.props.removeThresholdTable(item.id);
                              } }
                            >{ item.label }{ MATCH_TYPE[item.matchType].sign }{ item.number }{ getUnit(item.id) }</ButtonStatus>
                          </StyledFilteredResult>
                        )
                      } else {
                        return null;
                      }
                    })
                  } else {
                    return null;
                  }
                })
                const thresholds = _
                  .chain(thresholdsTmp)
                  .flatten()
                  .compact()
                  .value();
                return thresholds;
              }
            })()
          }
        </FlexBox>
      </div>
    );
  }
}

export default StatusFilter;
