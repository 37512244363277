import React from 'react';
import styled from 'styled-components'
import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { format, addDays, addMonths, isFirstDayOfMonth, startOfMonth } from 'date-fns';
import queryString from 'query-string';

import REPORT_TARGETS from '../../_const/REPORT_TARGETS'

import BarBottom from '../../atoms/BarBottom';
import Button from '../../atoms/Button';
import DividerContent from '../../atoms/DividerContent';
import FlexBox from '../../atoms/FlexBox';
import HeadingS from '../../atoms/HeadingS';
import Radio from '../../atoms/Radio';
import Textarea from '../../atoms/Textarea';
import InputText from '../../atoms/InputText';
import CheckboxWithLabel from '../../atoms/CheckboxWithLabel';
import PullDownDatePicker from '../../organisms/PullDownDatePicker';
import SelectDispItems from '../../organisms/SelectDispItems';
import SelectTargetItems from '../../organisms/SelectTargetItems';
import SelectUsers from '../../organisms/SelectUsers';
import TitleEdit from '../../organisms/TitleEdit';
import PullDownCommon from '../../organisms/PullDownCommon';
import PullDownCalendar from '../../organisms/PullDownCalendar';
import ModalErrorMessage from '../../organisms/ModalErrorMessage';

import User from '../../../utils/user';

const BLANK_TITLE_LABEL = '無題カスタムレポート';
const DATE_FORMAT = 'YYYY-MM-DD';
const CAMPAIGN_TARGETS = [
  {
    label: "キャンペーン",
    id: 3
  },
  {
    label: "広告グループ",
    id: 4
  },
  {
    label: "キーワード/ターゲティング",
    id: 5
  },
];

const StyledProgressSection = styled.div`
  display: none;
  &.is-shown {
    display: block;
  }
`
const StyledUnitText = styled.span`
  display: block;
  height: 40px;
  margin-top: 8px;
  padding-top: 20px;
`
const backendApi = process.env.REACT_APP_BACKEND_URI;

const DAY_OF_WEEK = [
  {
    id: 1,
    label: '日曜日',
    value: 1,
  },
  {
    id: 2,
    label: '月曜日',
    value: 2,
  },
  {
    id: 3,
    label: '火曜日',
    value: 3,
  },
  {
    id: 4,
    label: '水曜日',
    value: 4,
  },
  {
    id: 5,
    label: '木曜日',
    value: 5,
  },
  {
    id: 6,
    label: '金曜日',
    value: 6,
  },
  {
    id: 7,
    label: '土曜日',
    value: 7,
  },
];

// 表示回数、クリック数、CTR、費用、費用（込）、CPC、CPC（込）、CV数、CVR、CPA、CPA（込）がデフォルト項目
const defaultDispItems = [
  { id: 'imp', label: '表示回数' },
  { id: 'click', label: 'クリック数' },
  { id: 'ctr', label: 'CTR' },
  { id: 'cost', label: '費用' },
  { id: 'cost_with_fee', label: '費用(gross)' },
  { id: 'cpc', label: 'CPC' },
  { id: 'cpc_with_fee', label: 'CPC(gross)' },
  { id: 'cv', label: 'CV数' },
  { id: 'cvr', label: 'CVR' },
  { id: 'cpa', label: 'CPA' },
  { id: 'cpa_with_fee', label: 'CPA(gross)' },
];

const LABEL_TARGETS = REPORT_TARGETS.concat({
  label: "媒体ラベル",
  id: 0
});

class CustomReportEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createUserId: '',
      targetId: -1,
      targetIdTmp: -1,
      targetLabelId: 0,
      targetData: [],
      checkedTargets: [],
      checkedDispItems: defaultDispItems,
      checkedUsers: [],
      isMediaLabel: 0,
      dateRange: {
        key: 'dateRange',
        startDate: (isFirstDayOfMonth(new Date()) ? addMonths(new Date(), -1) : startOfMonth(new Date())),
        endDate:   addDays(new Date(), -1),
      },
      dateRangeCompare: {
        key: 'dateRangeCompare',
        startDate: (isFirstDayOfMonth(new Date()) ? addMonths(new Date(), -2) : addMonths(startOfMonth(new Date()), -1)),
        endDate:   addMonths(addDays(new Date(), -1), -1),
      },
      startDateRepeat: format(new Date()),
      endDateRepeat: addMonths(new Date(), 1),
      isEnabledCompare: false,
      requestParam: {
        id: 0, //レポートデータのID 1 ※新規追加の場合は0もしくは空
        name: '',
        clientIds:    null, // クライアントID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        accountIds:   null, // アカウントID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        campaignIds:  null, // キャンペーンID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        adgroupIds:   null, // 広告グループID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        keywordIds:   null, // キーワードID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        target:       0,    // ラベル設定対象とするレポートの種類 1:クライアント、2:アカウント、3:キャンペーン、4:広告グループ、5:キーワード
        shareSetting: 0,    // 公開設定 0:自身、1:グループ、2:部署、3:全社
        note:         '',   // 備考
        startDate:    '',   // （レポートデータ取得）開始日 2017/12/22
        endDate:      '',   // （レポートデータ取得）終了日 2017/12/22
        compare:      null, // 比較設定 過去データを比較するなら1、しないなら0もしくは空
        startDateC:   '',   // （レポートデータ取得）開始日（比較用） 2017/12/22
        endDateC:     '',   // （レポートデータ取得）終了日（比較用） 2017/12/22
        timeSpan:     null, // （レポートデータ取得）指定期間 1:昨日、2:過去7日間、3:過去14日間、4:今月、5:過去30日間、6:6先月、7:全期間 ※指定なしならsDate、eDateの値をそのまま適用する
        timeSpanC:    null, // （レポートデータ取得）指定期間（比較用） 1:前の期間、2:前年同期 ※指定なしならsDateC、eDateCの値をそのまま適用する
        division:     0,    // 分割 0:分割なし（もしくは項目未指定）、1:日別、2:週別、3:月別、4:曜日別、5:デバイス別、6:CV別
        dispItemData: '',   // 表示項目の設定 各表示項目の設定を記載。表示項目が複数ある場合は配列（,区切り） （例）imp,click,cv,CTR
        mediaLabel:   0,    // 媒体ラベル指定か(0:いいえ、1:はい)
      },
      requestParamRepeat: {
        id:                0,    // レポートデータのID 1 ※新規追加の場合は0もしくは空
        name:              '',   // 名称
        userId:            '',   // 作成ユーザーID
        repeatKind:        1,    // 繰り返し設定 0:期間指定、1:回数指定
        startDate:         '',   // 繰り返し開始日 2019-01-01
        endDate:           '',   // 繰り返し終了日 2019-01-01
        execCount:         null, // 繰り返し回数 20
        termUnit:          1,    // 繰り返しの単位 1:日次、2:週次、3:月次
        dailyKind:         0,    // 日次の設定 0:常時作成、1:土日祝日は作成しない、2:土日祝日のみ作成
        targetWeek:        null, // 週次の設定 対象となる曜日(複数の場合、カンマ区切り) ※1～7(日曜～土曜)
        monthlyKind:       0,    // 月次の場合の設定。日そのもの指定か営業日指定か 0:対象日、1:対象営業日
        targetDay:         null, // 月次の場合で日指定の場合の日 1
        targetBusinessDay: null, // 月次の場合で英病日指定の場合の営業日 1
        runInterval:       null, // 作成間隔 1～6
        reportCreateTime:  13,   // レポートを作成する時間 12～19
      },
      isRepeat:          0, // 繰り返し設定の有無
      execCount:         1, // 繰り返し回数
      targetDay:         2, // 月次の場合で日指定の場合の日 1
      targetBusinessDay: 1, // 月次の場合で営業日指定の場合の営業日 1
      runInterval:       1, // 作成間隔 1～6
      paramDayOfWeek: [
        null,
        2,
        null,
        null,
        null,
        null,
        null,
      ],
      isErrorOpened: false,
      bodyText: '',
      external:          0, // 外部データ
    };
    props.switchGlobalCatId(2);
    props.switchContentTitle('カスタムレポート編集');
    props.toggleDatePicker(false);
  }
  componentDidMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    // データを取得
    await this.getExistingData();

    //　プログレス非表示
    this.props.endProgressing();
  }
  getExistingData = async () => {
    // URIのクエリパラメータにidが存在するかどうかを判断し、
    // 存在する場合は既存カスタムレポートの情報を取得してsetStateする。
    const queries = queryString.parse(window.location.search);

    if (
      // idが存在する場合はAPIを実行
      queries.id !== undefined
    ) {
      let customReportData = [];
      await axios.get(backendApi + 'customReport', {
        params: {
          ...User.apiParams(),
          id: parseInt(queries.id),
        }
      })
      .then((response) => {
        if (response.data.length > 0) customReportData = response.data[0];
        if (customReportData.name === BLANK_TITLE_LABEL) customReportData.name = '';
      })
      .catch((error) => {
        let errorMessage = error.message;
        if(error.response) {
          errorMessage = error.response.data.errorMessage ? error.response.data.errorMessage : error.message
        }
        this.setState({
          isErrorOpened: true,
          bodyText: errorMessage,
        });
        this.props.endProgressing();
      });

      // responseのdata配列が0件の場合はreturn
      if (customReportData.length === 0) {
        return;
      };

      let targetId = customReportData.target;
      let checkedTargets = '';
      if (targetId === 1) {
        // client
        checkedTargets = customReportData.clientIds;
      } else if (targetId === 2) {
        // account
        checkedTargets = customReportData.accountIds;
      } else if (targetId === 3) {
        // campaign
        checkedTargets = customReportData.campaignIds;
      } else if (targetId === 4) {
        // adgroup
        checkedTargets = customReportData.adgroupIds;
      } else if (targetId === 5) {
        // keyword
        checkedTargets = customReportData.keywordIds;
      }
      if (checkedTargets !== '') {
        checkedTargets = checkedTargets.split(',').map(o => {
          return parseInt(o);
        })
      } else {
        checkedTargets = [];
      }

      let checkedUsers = [];
      if (customReportData.userIds) {
        checkedUsers = customReportData.userIds.split(',').map(o => {
          return o;
        })
      }

      await this.setState({
        createUserId: customReportData.userId,
        targetId: targetId,
        targetIdTmp: customReportData.mediaLabel ? 0 : targetId,
        targetLabelId: customReportData.mediaLabel ? targetId : -1,
        checkedTargets: checkedTargets,
        checkedDispItems: customReportData.dispItem,
        checkedUsers: checkedUsers,
        dateRange: {
          ...this.state.dateRange,
          startDate: new Date(customReportData.startDate),
          endDate:   new Date(customReportData.endDate),
        },
        dateRangeCompare: {
          ...this.state.dateRangeCompare,
          startDate: new Date(customReportData.startDateC),
          endDate:   new Date(customReportData.endDateC),
        },
        isEnabledCompare: customReportData.compare === 1,
        isMediaLabel: customReportData.mediaLabel,
        requestParam: {
          ...this.state.requestParam,
          ...customReportData,
        },
        external: customReportData.external,
      });

      // 繰り返し設定データがあれば読み込み
      let checkedRepeatData = [];
      let paramDayOfWeek;
      if(customReportData.repeatSetting) {
        checkedRepeatData = customReportData.repeatSetting;
        let dow = checkedRepeatData.targetWeek.split(',').map(x => parseInt(x));
        if(Number.isInteger(dow[0])) {
          paramDayOfWeek = [null, null, null, null, null, null, null];
          for (var i = 0; i < dow.length; i++) {
            let num = parseInt(dow[i]);
            paramDayOfWeek[num-1] = num;
          }
        } else {
          paramDayOfWeek = [null, 2, null, null, null, null, null];
        }

        await this.setState({
          startDateRepeat: checkedRepeatData.startDate,
          endDateRepeat: (checkedRepeatData.endDate ? checkedRepeatData.endDate : this.state.endDateRepeat),
          execCount: checkedRepeatData.execCount,
          requestParamRepeat: checkedRepeatData,
          paramDayOfWeek: paramDayOfWeek,
          isRepeat: 1,
          targetDay: checkedRepeatData.targetDay ? checkedRepeatData.targetDay : 2,
          targetBusinessDay: checkedRepeatData.targetBusinessDay,
          runInterval: checkedRepeatData.runInterval ? checkedRepeatData.runInterval : 1,
        })
      }
    } else if (
      // target/targetIdsがいずれも存在する場合は
      queries.target !== undefined
      && queries.targetIds !== undefined
    ) {
      await this.setState({
        targetId: parseInt(queries.target),
        checkedTargets: queries.targetIds.split(',').map(n => { return parseInt(n) }),
        requestParam: {
          ...this.state.requestParam,
          division: 0,
        },
      })
    } else {
      // id/target/targetIdsがいずれも存在しない場合はreturn
      return;
    }

    await this.getTargetData();

    return;
  }
  getTargetData = () => {
    // 各階層の要素一覧の取得
    return axios.get(backendApi + 'report', {
      params: {
        ...User.apiParams(),
        target: this.state.targetId,
        timeSpan: 8,
        dataExist: 1,
        groupby: this.state.isMediaLabel
      }
    })
    .then((response) => {
      if (this.state.isMediaLabel > 0) {
        // 媒体ラベルあり
        const targetData = response.data.reportJsonList.filter(x => x.labelList.length > 0);
        this.setState({
          targetData: targetData,
        });
      } else {
        // 媒体ラベルなし
        this.setState({
          targetData: response.data.reportJsonList,
        });
      }
    })
    .catch((error) => {
      let errorMessage = error.message;
      if(error.response) {
        errorMessage = error.response.data.errorMessage ? error.response.data.errorMessage : error.message
      }
      this.setState({
        isErrorOpened: true,
        bodyText: errorMessage,
      });
      this.props.endProgressing();
    });
  }
  render() {
    // タイトル編集
    const editTitle = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          name: e.currentTarget.value,
        },
      });
    }

    // targetの階層を選択
    const switchTargetId = async (e) => {
      const targetId = parseInt(e.target.value);
      if (targetId === 0) {
        await this.setState({
          targetIdTmp: targetId,
          targetId: -1,
          isMediaLabel: 1,
          targetData: [],
          checkedTargets: []
        });
      } else {
        // プログレス表示
        this.props.startProgressing();

        await this.setState({
          targetId: targetId,
          targetIdTmp: targetId,
          isMediaLabel: 0,
          targetData: [],
          checkedTargets: [],
        });
        await this.getTargetData();

        //　プログレス非表示
        this.props.endProgressing();
      }
    }

    // 媒体ラベルの階層を選択
    const switchTargetLabelId = async (e) => {
      // プログレス表示
      this.props.startProgressing();

      const targetId = parseInt(e.target.value);
      await this.setState({
        targetId: targetId,
        targetLabelId: targetId,
        targetData: [],
        checkedTargets: [],
      });
      await this.getTargetData();

      //　プログレス非表示
      this.props.endProgressing();
    }

    // targetの各要素をチェック
    const checkItem = (e) => {
      const id = parseInt(e.target.value);
      const { checkedTargets } = this.state;

      if (checkedTargets.indexOf(id) > -1) {
        checkedTargets.splice(checkedTargets.indexOf(id), 1)
      } else {
        checkedTargets.push(id);
      }
      this.setState({
        checkedTargets: checkedTargets
      });
    }

    // チェックボックスをすべて選択
    const toggleCheckTargetsAll = (checkedFiltered, bool) => {
      const { checkedTargets } = this.state;

      if (bool) {
        this.setState({
          checkedTargets: _.union(
            checkedTargets,
            checkedFiltered
          )
        });
      } else {
        this.setState({
          checkedTargets: _.without(
            checkedTargets,
            ...checkedFiltered
          )
        });
      }
    }

    // チェックボックスをすべてクリア
    const clearCheckAll = () => {
      this.setState({
        checkedTargets: []
      })
    }

    const CheckboxHasProps = ({ name, id, value, keyValue, children, index, col }) => (
      <CheckboxWithLabel
        name = { name }
        id = { id }
        value = { String(value) }
        checked = { this.state.paramDayOfWeek[keyValue] === value ? 'checked' : '' }
        className = 'm-t-8 m-r-8'
        onChange = {
          ((e) => {
            const param = this.state.paramDayOfWeek;
            param[keyValue] = (e.target.checked) ? value : null;
            this.setState({
              paramDayOfWeek: param
            });
            this.setState({
              requestParamRepeat: {
                ...this.state.requestParamRepeat,
                targetWeek: _.compact(this.state.paramDayOfWeek).join()
              }
            });
          })
        }
      >
        { children }
      </CheckboxWithLabel>
    );

    // 期間を指定
    const applyDateRange = async (ranges) => {
      await this.setState({
        ...ranges
      });
    }

    // メモの編集
    const editNote = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          note: e.target.value,
        }
      });
    }

    // 公開範囲のラジオボタンを制御
    const switchShareSetting = async (e) => {
      const id = parseInt(e.target.value);

      this.setState({
        checkedUsers: (id === 4) ? this.state.checkedUsers : [],
        requestParam: {
          ...this.state.requestParam,
          shareSetting: id,
        }
      });
    }

    // ユーザーの各要素をチェック
    const checkUser = (e) => {
      const id = e.target.value;
      const { checkedUsers } = this.state;

      if (checkedUsers.indexOf(id) > -1) {
        checkedUsers.splice(checkedUsers.indexOf(id), 1)
      } else {
        checkedUsers.push(id);
      }
      this.setState({
        checkedUsers: checkedUsers,
      });
    }

    // ユーザーのチェックボックスをすべて選択
    const toggleCheckUsersAll = (checkedFiltered, bool) => {
      const { checkedUsers } = this.state;

      if (bool) {
        this.setState({
          checkedUsers: _.union(
            checkedUsers,
            checkedFiltered
          )
        });
      } else {
        this.setState({
          checkedUsers: _.without(
            checkedUsers,
            ...checkedFiltered
          )
        });
      }
    }

    // ユーザーのチェックボックスをすべてクリア
    const clearCheckUsersAll = () => {
      this.setState({
        checkedUsers: []
      })
    }

    // 繰り返し開始日を指定
    const applyStartDateRepeat = async (date) => {
      await this.setState({
        startDateRepeat: date
      });
    }
    // 繰り返し終了日を指定
    const applyEndDateRepeat = async (date) => {
      await this.setState({
        endDateRepeat: date
      });
    }

    // 繰り返し作成間隔リスト
    const targetDayList = [...Array(31).keys()].map(i => {
      return {
        id : i + 1,
        label : i + 1,
      }
    });

    // 繰り返し日付指定（月次の場合）
    const changeTargetDay = (e) => {
      this.setState({
        targetDay: e,
      });
    }

    // 繰り返し営業日指定（月次の場合）
    const changeTargetBusinessDay = (e) => {
      this.setState({
        targetBusinessDay: e,
      });
    }

    // 繰り返しの単位設定
    const changeTermUnit = (e) => {
      this.setState({
        requestParamRepeat: {
          ...this.state.requestParamRepeat,
          termUnit: e,
        }
      });
    }

    // 繰り返し作成間隔リスト
    const runIntervalList = [...Array(6).keys()].map(i => {
      return {
        id : i + 1,
        label : i + 1,
      }
    });

    // 繰り返し作成間隔の設定
    const changeRunInterval = (e) => {
      this.setState({
        runInterval: e,
      });
    }

    // 繰り返し作成時間リスト
    const createTime = [...Array(8).keys()].map(i => {
      return {
        id : i + 12,
        label : i + 12,
      }
    });

    // 繰り返し作成時間の設定
    const changeCreateTime = (e) => {
      this.setState({
        requestParamRepeat: {
          ...this.state.requestParamRepeat,
          reportCreateTime: e,
        }
      });
    }

    // カスタムレポートを保存する。
    const save = async () => {
      let repeatId = null;
      let isError = false;

      // プログレス表示
      this.props.startProgressing();

      // 繰り返し設定登録処理
      if(this.state.isRepeat === 1) {
        this.state.requestParamRepeat.targetWeek = _.compact(this.state.paramDayOfWeek).join();
        await axios.get(backendApi + 'customReportRepeatModify', {
          params: {
            ...User.apiParams(),
            ...this.state.requestParamRepeat,
            func             : 1,
            name             : (this.state.requestParam.name === '') ? BLANK_TITLE_LABEL : this.state.requestParam.name,
            userId           : User.apiParams().userId,
            repeatKind       : this.state.requestParamRepeat.repeatKind,
            startDate        : format(this.state.startDateRepeat, DATE_FORMAT),
            endDate          : (this.state.requestParamRepeat.repeatKind === 0 ? format(this.state.endDateRepeat, DATE_FORMAT) : ''),
            execCount        : (this.state.requestParamRepeat.repeatKind === 1 ? this.state.execCount : null),
            dailyKind        : this.state.requestParamRepeat.termUnit === 1 ? this.state.requestParamRepeat.dailyKind : null,
            targetWeek       : this.state.requestParamRepeat.termUnit === 2 ? this.state.requestParamRepeat.targetWeek : null,
            monthlyKind      : this.state.requestParamRepeat.termUnit === 3 ? this.state.requestParamRepeat.monthlyKind : null,
            targetDay        : this.state.requestParamRepeat.termUnit === 3 ? this.state.targetDay : null,
            targetBusinessDay: this.state.requestParamRepeat.termUnit === 3 ? this.state.targetBusinessDay : null,
            runInterval      : this.state.requestParamRepeat.termUnit > 1 ?  this.state.runInterval : null,
          }
        })
        .then((response) => {
          if(response.data.length > 0) {
            let data = response.data[0];
            repeatId = data.id;
          }
        })
        .catch((error) => {
          isError = true;
          //　プログレス非表示
          this.props.endProgressing();
        })

        // 繰り返し設定がうまくいかなかった場合、次の処理を行わない
        if(isError) return;
      } else {
        if(this.state.requestParamRepeat.id) {
          await axios.get(backendApi + 'customReportRepeatModify', {
            params: {
              ...User.apiParams(),
              func: 2,
              ids : this.state.requestParamRepeat.id
            }
          })
          .then((response) => {
          })
          .catch((error) => {
            let errorMessage = error.message;
            if(error.response) {
              errorMessage = error.response.data.errorMessage ? error.response.data.errorMessage : error.message
            }
            this.setState({
              isErrorOpened: true,
              bodyText: errorMessage,
            });
            this.props.endProgressing();
          })
        }
      }

      // 選択したIDを含むデータを抽出
      let keyname = '';
      switch(this.state.targetId) {
        case 1:
          keyname = 'clientId';
          break;
        case 2:
          keyname = 'accountId';
          break;
        case 3:
          keyname = 'campaignId';
          break;
        case 4:
          keyname = 'adgroupId';
          break;
        case 5:
          keyname = 'keywordId';
          break;
        default:
      }

      // チェックされたIDかつ取得データにあるIDのみ保持する（チェックされたIDでも取得データにない場合エラーになるので ※登録時は取得データにあったが、変更時にはないパターンもあるため）
      // オブジェクトなのでtargetデータのオブジェクトであるoを入れる必要がある
      const checkedDataFilter = this.state.checkedTargets.map(x => {
        return _.find(this.state.targetData, (o) => {
          return o[keyname] === parseInt(x)
        });
      }).filter(y => y);

      // カスタムレポート登録処理
      await axios(backendApi + 'customReportModify', {
        params: {
          ...User.apiParams(),
          ...this.state.requestParam,
          func        : 1,
          name        : (this.state.requestParam.name === '') ? BLANK_TITLE_LABEL : this.state.requestParam.name,
          clientIds   :  _.uniq(checkedDataFilter.map(x => x.clientId)).join(),
          accountIds  : (this.state.targetId > 1) ? _.uniq(checkedDataFilter.map(x => x.accountId)).join() : null,
          campaignIds : (this.state.targetId > 2) ? _.uniq(checkedDataFilter.map(x => x.campaignId)).join() : null,
          adgroupIds  : (this.state.targetId > 3) ? _.uniq(checkedDataFilter.map(x => x.adgroupId)).join() : null,
          keywordIds  : (this.state.targetId > 4) ? _.uniq(checkedDataFilter.map(x => x.keywordId)).join() : null,
          target      : this.state.targetId,
          startDate   : format(this.state.dateRange.startDate, DATE_FORMAT),
          endDate     : format(this.state.dateRange.endDate, DATE_FORMAT),
          compare     : (this.state.isEnabledCompare === true) ? 1 : 0,
          startDateC  : format(this.state.dateRangeCompare.startDate, DATE_FORMAT),
          endDateC    : format(this.state.dateRangeCompare.endDate, DATE_FORMAT),
          dispItemData: this.state.checkedDispItems.map(o => { return o.id }).join(','),
          userIds     : this.state.checkedUsers.join(','),
          external    : this.state.external,
          repeatId    : repeatId,
          mediaLabel  : this.state.isMediaLabel,
        }
      }).then((response) => {
        this.props.history.push('/custom-report/');
        //　プログレス非表示
        this.props.endProgressing();
      }).catch((error) => {
        let errorMessage = error.message;
        if(error.response) {
          errorMessage = error.response.data.errorMessage ? error.response.data.errorMessage : error.message
        }
        this.setState({
          isErrorOpened: true,
          bodyText: errorMessage,
        });
        this.props.endProgressing();
      });
    }

    // エラーモーダル開閉
    const toggleErrorModal = (boolean) => {
      this.setState({
        isErrorOpened: boolean
      });
    }

    // 繰り返し設定確認
    const isRepeatSetting = () => {
      if (this.state.isRepeat === 0) return true;
      if (!(this.state.requestParamRepeat.repeatKind === 0 || this.state.requestParamRepeat.repeatKind === 1)) return false;
      if (this.state.requestParamRepeat.repeatKind === 1) {
        if (!(this.state.execCount > 0)) return false;
      }
      if (!(this.state.requestParamRepeat.termUnit > 0)) return false;
      switch(this.state.requestParamRepeat.termUnit) {
        case 1:
          return !!(this.state.requestParamRepeat.dailyKind === 0 || this.state.requestParamRepeat.dailyKind === 1);
        case 2:
          return true;
        case 3:
          return !!(this.state.requestParamRepeat.monthlyKind === 0 || this.state.requestParamRepeat.monthlyKind === 1);
        default:
          return false;
      }
    }

    return (
      <div>
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isErrorOpened }
          close = { () => toggleErrorModal(false) }
          bodyText = { this.state.bodyText }
        />
        <TitleEdit
          name = 'customReportTitle'
          value = { this.state.requestParam.name }
          blankLabel = { BLANK_TITLE_LABEL }
          placeholder = 'カスタムレポート名を入力してください。'
          onChange = { editTitle }
        />

        <HeadingS>
          カスタムレポートの分割方法を選んでください　
        </HeadingS>
        <FlexBox
          className = 'm-t-16'
        >
          {
            (() => {
              const items = [
              '分割なし',
              '日別',
              '週別',
              '月別',
              '曜日別',
              'デバイス別',
              ];
              return items.map((item, index) => {
                return (
                  <Radio
                    key = { _.uniqueId() }
                    name = "division"
                    id = { `division-${index}` }
                    value = { index }
                    checked = { this.state.requestParam.division === index }
                    onChange = { (() => {
                      if (
                        (this.state.targetId > 2 && index === 1)
                        || ((this.state.targetId < 2 || this.state.targetId > 3) && index === 6)
                      ) {
                        // キャンペーン以下の階層を選択したあとに日分割を選択しなおした場合は各プロパティをリセットする
                        this.setState({
                          targetId: 0,
                          targetData: [],
                          checkedTargets: [],
                          checkedDispItems: defaultDispItems,
                          requestParam: {
                            ...this.state.requestParam,
                            division: index,
                          }
                        });
                      } else {
                        this.setState({
                          requestParam: {
                            ...this.state.requestParam,
                            division: index,
                          }
                        })
                      }
                    }) }
                    className = 'm-r-16'
                  >
                    { item }
                  </Radio>
                )
              });
            })()
          }
        </FlexBox>

        <StyledProgressSection
          className = {
            classnames({
              'is-shown': this.state.requestParam.division > -1
            })
          }
        >
          <HeadingS
            className = 'm-t-40'
          >
            カスタムレポートを設定するカテゴリーを選んでください　
          </HeadingS>
          <FlexBox>
            {
              (() => {
                return LABEL_TARGETS.map((item, index) => {
                  if (
                    (index > 1 && this.state.requestParam.division === 1)
                    || ((index < 1 || index > 2) && this.state.requestParam.division === 6)
                  ) {
                    return '';
                  } else {
                    return (
                      <Radio
                        className = 'm-r-24'
                        key = { _.uniqueId() }
                        name = 'target-category'
                        id = { `target-category-${item.id}` }
                        value = { String(item.id) }
                        checked = { this.state.targetIdTmp === item.id ? 'checked' : '' }
                        onChange = { switchTargetId }
                      >
                        { item.label }
                      </Radio>
                    )
                  }
                });
              })()
            }
          </FlexBox>
        </StyledProgressSection>
        {
          (() => {
            if (this.state.isMediaLabel) {
              return (
                <div>
                  <HeadingS
                    className = 'm-t-40'
                  >
                    媒体ラベルからカテゴリーを選んでください　
                  </HeadingS>
                  <FlexBox>
                    {
                      (() => {
                        return CAMPAIGN_TARGETS.map(item => {
                          return (
                            <Radio
                              className = 'm-r-24'
                              key = { _.uniqueId() }
                              name = 'target-label-category'
                              id = { `target-label-category-${item.id}` }
                              value = { String(item.id) }
                              checked = { this.state.targetLabelId === item.id ? 'checked' : '' }
                              onChange = { switchTargetLabelId }
                            >
                              { item.label }
                            </Radio>
                          )
                        });
                      })()
                    }
                  </FlexBox>
                </div>
              )
            }
          })()
        }
        <StyledProgressSection
          className = {
            classnames({
              'is-shown': this.state.targetId > 0
            })
          }
        >
          <HeadingS
            className = 'm-t-40'
          >
            カスタムレポートを設定する対象を選んでください　
          </HeadingS>
          <SelectTargetItems
            targetId = { this.state.targetId }
            targetData = { this.state.targetData }
            checked = { this.state.checkedTargets }
            checkItem = { checkItem }
            toggleCheckAll = { toggleCheckTargetsAll }
            clearCheckAll　= { clearCheckAll }
            isLabelList = { this.state.isMediaLabel }
          />

          <HeadingS
            className = 'm-t-40'
          >
            カスタムレポートの対象期間を設定してください
          </HeadingS>

          <PullDownDatePicker
            dateRange = { this.state.dateRange }
            dateRangeCompare = { this.state.dateRangeCompare }
            isEnabledCompare = { this.state.isEnabledCompare }
            applyDateRange = { applyDateRange }
          />

          <HeadingS
            className = 'm-t-40'
          >
            カスタムレポートに表示させたい項目を選択してください
          </HeadingS>
          <SelectDispItems
            selectableDispItems = { this.props.selectableDispItems }
            checked = { this.state.checkedDispItems }
            hasBorder = { true }
            updateChecked = {
              ((checked) => {
                this.setState({
                  checkedDispItems: checked
                })
              })
            }
          />

          <HeadingS
            className = 'm-t-40'
          >
            カスタムレポートで外部データを表示しますか？
          </HeadingS>
          <FlexBox
            className = 'm-t-8'
          >
            {
              (() => {
                const items = [
                'なし',
                '外部CVのみ',
                'すべて',
                ];
                return items.map((item, index) => {
                  return (
                    <Radio
                      key = { _.uniqueId() }
                      name = "external"
                      id = { `external-${index}` }
                      value = { index }
                      checked = { this.state.external === index }
                      onChange = { (() => {
                        this.setState({
                          external: index
                        });
                      }) }
                      className = 'm-r-16'
                    >
                      { item }
                    </Radio>
                  )
                });
              })()
            }
          </FlexBox>
          
          <HeadingS
            className = 'm-t-40'
          >
            メモを記入してください
            <small
              className = 'm-l-8'
            >
              (任意)
            </small>
          </HeadingS>

          <Textarea
            placeholder = "メモを記入してください。"
            value = { this.state.requestParam.note }
            onChange = { editNote }
          />
          <DividerContent />
          <HeadingS
            className = 'm-t-40'
          >
            カスタムレポートの繰り返し設定を行いますか？
          </HeadingS>
          <FlexBox
            className = 'm-t-8'
          >
            {
              (() => {
                const items = [
                '1回のみ',
                '繰り返し設定を行う',
                ];
                return items.map((item, index) => {
                  return (
                    <Radio
                      key = { _.uniqueId() }
                      name = "isRepeat"
                      id = { `isRepeat-${index}` }
                      value = { index }
                      checked = { this.state.isRepeat === index }
                      onChange = { (() => {
                        this.setState({
                          isRepeat: index
                        });
                      }) }
                      className = 'm-r-16'
                    >
                      { item }
                    </Radio>
                  )
                });
              })()
            }
          </FlexBox>
          <StyledProgressSection
            className = {
              classnames({
                'is-shown': this.state.isRepeat === 1,
                'm-t-8': true
              })
            }
          >
            <HeadingS
              className = 'm-t-40 m-b-0'
            >
              カスタムレポートの繰り返し作成期間を指定してください。
            </HeadingS>
            <HeadingS
              className = 'm-t-24 m-b-0'
            >開始日</HeadingS>
            <PullDownCalendar
              date = { this.state.startDateRepeat }
              applyDateRepeat = { applyStartDateRepeat }
              className = 'm-t-8'
            ></PullDownCalendar>
            <HeadingS
              className = 'm-t-24 m-b-0'
            >終了日</HeadingS>
            <FlexBox
              className = 'm-t-8'
            >
              {
                (() => {
                  const items = [
                  '終了日カレンダー選択',
                  '回数',
                  ];
                  return items.map((item, index) => {
                    return (
                      <Radio
                        key = { _.uniqueId() }
                        name = "repeatKind"
                        id = { `repeatKind-${index}` }
                        value = { index }
                        checked = { this.state.requestParamRepeat.repeatKind === index }
                        onChange = { (() => {
                          this.setState({
                            requestParamRepeat: {
                              ...this.state.requestParamRepeat,
                              repeatKind: index
                            }
                          });
                        }) }
                        className = 'm-r-16'
                      >
                        { item }
                      </Radio>
                    )
                  });
                })()
              }
            </FlexBox>
            <StyledProgressSection
              className = {
                classnames({
                  'is-shown': this.state.requestParamRepeat.repeatKind === 0,
                  'm-t-8': true
                })
              }
            >
              <PullDownCalendar
                date = { this.state.endDateRepeat }
                applyDateRepeat = { applyEndDateRepeat }
              ></PullDownCalendar>
            </StyledProgressSection>
            <StyledProgressSection
              className = {
                classnames({
                  'is-shown': this.state.requestParamRepeat.repeatKind === 1,
                })
              }
            >
              <FlexBox>
                <InputText
                  type = "number"
                  width = { 80 }
                  max = { 100 }
                  min = { 0 }
                  step = { 1 }
                  value = { this.state.execCount }
                  className = "m-t-8 m-r-8"
                  onChange = { ((e) => {
                    this.setState({
                      execCount: e.currentTarget.value
                    })
                  }) }
                ></InputText>
                <StyledUnitText>回</StyledUnitText>
              </FlexBox>
            </StyledProgressSection>
            <HeadingS
              className = 'm-t-40'
            >
              カスタムレポートの繰り返し作成タイミングを指定してください。
            </HeadingS>
            <PullDownCommon
              id = { this.state.requestParamRepeat.termUnit }
              width = '100px'
              listWidth = '130px'
              items = {[
                { id: 1, label: '日次' },
                { id: 2, label: '週次' },
                { id: 3, label: '月次' },
              ]}
              onChange = { changeTermUnit }
              className = 'm-r-8'
            />
            <StyledProgressSection
              className = {
                classnames({
                  'is-shown': this.state.requestParamRepeat.termUnit === 1
                })
              }
            >
              <FlexBox
                className = 'm-t-16'
              >
                {
                  (() => {
                    const items = [
                    '常時作成',
                    '土日祝日は作成しない',
                    '土日祝日のみ作成する',
                    ];
                    return items.map((item, index) => {
                      return (
                        <Radio
                          key = { _.uniqueId() }
                          name = "dailyKind"
                          id = { `dailyKind-${index}` }
                          value = { index }
                          checked = { this.state.requestParamRepeat.dailyKind === index }
                          onChange = { (() => {
                            this.setState({
                              requestParamRepeat: {
                                ...this.state.requestParamRepeat,
                                dailyKind: index,
                              }
                            });
                          }) }
                          className = 'm-r-16'
                        >
                          { item }
                        </Radio>
                      )
                    });
                  })()
                }
              </FlexBox>
            </StyledProgressSection>
            <StyledProgressSection
              className = {
                classnames({
                  'is-shown': this.state.requestParamRepeat.termUnit === 2
                })
              }
            >
              <FlexBox flexWrap className = "m-b-32">
                {
                  (() => {
                    return DAY_OF_WEEK.map((m, i) => {
                      return (
                        <CheckboxHasProps
                          key = { _.uniqueId() }
                          name = 'targetWeek'
                          id = { `targetWeek-${m.value}` }
                          value = { m.value }
                          keyValue = { m.value - 1 }
                          index = { i }
                          col = { 7 }
                        >
                          { m.label }
                        </CheckboxHasProps>
                      )
                    })
                  })()
                }
              </FlexBox>
            </StyledProgressSection>
            <StyledProgressSection
              className = {
                classnames({
                  'is-shown': this.state.requestParamRepeat.termUnit === 3
                })
              }
            >
              <FlexBox
                className = 'm-t-16'
              >
                {
                  (() => {
                    const items = [
                    '対象日',
                    '対象営業日',
                    ];
                    return items.map((item, index) => {
                      return (
                        <Radio
                          key = { _.uniqueId() }
                          name = "monthlyKind"
                          id = { `monthlyKind-${index}` }
                          value = { index }
                          checked = { this.state.requestParamRepeat.monthlyKind === index }
                          onChange = { (() => {
                            this.setState({
                              requestParamRepeat: {
                                ...this.state.requestParamRepeat,
                                monthlyKind: index,
                              }
                            });
                          }) }
                          className = 'm-r-16'
                        >
                          { item }
                        </Radio>
                      )
                    });
                  })()
                }
              </FlexBox>
              <StyledProgressSection
                className = {
                  classnames({
                    'is-shown': this.state.requestParamRepeat.monthlyKind === 0
                  })
                }
              >
                <PullDownCommon
                  id = { this.state.targetDay }
                  width = '100px'
                  listWidth = '130px'
                  items = { targetDayList }
                  onChange = { changeTargetDay }
                  className = 'm-r-8 m-t-16'
                />
              </StyledProgressSection>
              <StyledProgressSection
                className = {
                  classnames({
                    'is-shown': this.state.requestParamRepeat.monthlyKind === 1
                  })
                }
              >
                <PullDownCommon
                  id = { this.state.targetBusinessDay }
                  width = '100px'
                  listWidth = '130px'
                  items = { targetDayList }
                  onChange = { changeTargetBusinessDay }
                  className = 'm-r-8 m-t-16'
                />
              </StyledProgressSection>
            </StyledProgressSection>
            <StyledProgressSection
              className = {
                classnames({
                  'is-shown': this.state.requestParamRepeat.termUnit > 1
                })
              }
            >
              <HeadingS
                className = 'm-t-40'
              >
                カスタムレポートの繰り返し作成間隔を指定してください。
              </HeadingS>
              <FlexBox>
                <PullDownCommon
                  id = { this.state.runInterval }
                  width = '100px'
                  listWidth = '130px'
                  items = { runIntervalList }
                  onChange = { changeRunInterval }
                  className = 'm-r-8'
                />{ this.state.requestParamRepeat.termUnit === 2 ? '週' : 'か月' }
              </FlexBox>
            </StyledProgressSection>
            <StyledProgressSection
              className = {
                classnames({
                  'is-shown': this.state.requestParamRepeat.termUnit > 0
                })
              }
            >
              <HeadingS
                className = 'm-t-40'
              >
                カスタムレポートの繰り返し作成時間を指定してください。
              </HeadingS>
              <FlexBox>
                <PullDownCommon
                  id = { this.state.requestParamRepeat.reportCreateTime }
                  width = '100px'
                  listWidth = '130px'
                  items = { createTime }
                  onChange = { changeCreateTime }
                  className = 'm-r-8'
                />時
              </FlexBox>
            </StyledProgressSection>
          </StyledProgressSection>
          <DividerContent />
          <HeadingS>
            公開範囲を指定してください。
          </HeadingS>
          <FlexBox>
            {
              (() => {
                const items = [
                  '自身',
                  'グループ',
                  '部署',
                  '全社',
                  '特定のユーザー',
                ]
                return items.map((o, i) => {
                  return (
                    <Radio
                      className = 'm-r-24'
                      key = { _.uniqueId() }
                      name = 'share-setting'
                      id = { `share-setting-${i}` }
                      value = { String(i) }
                      checked = { this.state.requestParam.shareSetting === i ? 'checked' : '' }
                      onChange = { switchShareSetting }
                    >
                      { o }
                    </Radio>
                  )
                });
              })()
            }
          </FlexBox>
          {
            (() => {
              // 特定のユーザーを選択した場合はユーザー一覧から対象を選択するためのUIを出す。
              if (this.state.requestParam.shareSetting === 4) {
                return (
                  <SelectUsers
                    users = { this.props.users }
                    checked = { this.state.checkedUsers }
                    check = { checkUser }
                    toggleCheckAll = { toggleCheckUsersAll }
                    clearCheckAll = { clearCheckUsersAll }
                    className = 'm-t-24'
                  />
                )
              }
            })()
          }
        </StyledProgressSection>

        <BarBottom>
          <Button
            as = { Link }
            to = '/custom-report/'
          >
            キャンセル
          </Button>
          {
            (() => {
              if (!this.state.createUserId || (this.state.createUserId === User.apiParams().userId)) {
                if (this.state.targetId > 0) {
                  if (isRepeatSetting()) {
                    return (
                      <Button
                        color="orange"
                        onClick = { save }
                        className = 'm-l-8'
                      >
                        カスタムレポートを保存する
                      </Button>
                    )
                  }
                }
              }
            })()
          }
        </BarBottom>
      </div>
    )
  }
}

export default CustomReportEdit;
