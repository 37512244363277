import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';
import MEDIAS from '../_const/MEDIA';

import Button from '../atoms/Button';
import IconExpand from '../atoms/IconExpand';
import CheckboxWithLabel from '../atoms/CheckboxWithLabel';
import FlexBox from '../atoms/FlexBox';
import HeadingS from '../atoms/HeadingS';
import IconFilter from '../atoms/IconFilter';
import MediaBadge from '../atoms/MediaBadge';
import Radio from '../atoms/Radio';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';
import PullDownS from '../molecules/PullDownS';
import SelectDispItemsFilter from '../organisms/SelectDispItemsFilter';

const StyledPullDownProgressFilter = styled.div`
  position: relative;
  width: 100px;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  width: 602px;
  max-height: 800px;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
  &.is-modal-opened {
    pointer-events: none;
  }
`
const StyledPullDownListWrapIn1 = styled(PullDownListWrapIn)`
  padding: 32px;
`
const StyledPullDownListWrapIn2 = styled(PullDownListWrapIn)`
  padding: 8px;
  border-top: 1px solid #e6e6e6;
`
const StyledRadio = styled(Radio)`
  width: 120px;
  margin-top: 8px;
`
const StyledCheckbox = styled(CheckboxWithLabel)`
  ${
    ({col, index}) => {
      return `
        width: calc((100% - ${16 * (col - 1)}px) / ${ col });
        margin-left: ${(index % col !== 0) ? '16px' : 0}
      `
    }
  }
  margin-top: 8px;
`
const StyledAccordion = styled.div`
  height: 0;
  &.is-current {
    height: auto;
    margin-top: 0;
  }
`
const StyledAccordionIn = styled(FlexBox)`
  visibility: hidden;
  opacity: 0;
  .is-current & {
    opacity: 1;
    visibility: visible;
  }
`
const StyledAccordionInBlock = styled.div`
  width: 100%;
`
const StyledAccordionHeading = styled(HeadingS)`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
`

class PullDownProgressFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openLabel: '',
      openLabels: [],
      checked: [],
      isMediaAll: true,
      param: {
        // 部署・グループ
        sectionId: null,
        groupId:   null,
        // ステータス
        dataExist: 1,
        // 媒体
        aw:        null,
        ss:        null,
        ydn:       null,
        cr:        null,
        tw:        null,
        fb:        null,
        zeus:      null,
        line:      null,
        dbm:       null,
        indeed:    null,
        sn:        null,
        rtbh:      null,
        aa:        null,
        asa:       null,
        bing:      null,
        la:        null,
        una:       null,
        xmedia:    null,
        // デバイス
        device:    null,
        // アラート
        alNot:     null,
        al1:       null,
        al2:       null,
        al3:       null,
        al4:       null,
        // 外部データ（外部CV）
        ex: 0,
        // ラベル
        label:     null,
      },
      isOpenedPullDown: false,
      labelData: [],
    };
  }
  handleClickOutside(e) {
    if (!this.props.isOpenedThresholdModal) {
      this.closePullDown(e);
    }
  }
  componentDidUpdate = async (prevProps, prevState) => {
    // ポップアップを開いた際の処理
    if (
      prevState.isOpenedPullDown === false
      && this.state.isOpenedPullDown === true
    ) {
      const requestParam = this.props.requestParam;
      const param = this.state.param;
      _.forEach(param, (value, key) => {
        param[key] = requestParam[key];
      });
      const labelData = this.props.labelData;
      await this.setState({
        param: param,
        labelData: labelData
      });
      const isMediaAll = await MEDIAS.map((media) => this.props.requestParam[media.value] === 1).every(x => x);
      await this.setState({
        isMediaAll: isMediaAll
      })
    }
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false
    });
  }
  saveLocalStorage = () => {
    // フィルタ条件をlocalStorageに保存
    const def = window.localStorage.getItem('filter');
    const data = {
      dataExist: this.state.param.dataExist,
      division: _.get(def, 'division', null),
      aw: this.state.param.aw,
      ss: this.state.param.ss,
      ydn: this.state.param.ydn,
      cr: this.state.param.cr,
      tw: this.state.param.tw,
      fb: this.state.param.fb,
      zeus: this.state.param.zeus,
      line: this.state.param.line,
      dbm: this.state.param.dbm,
      indeed: this.state.param.indeed,
      sn: this.state.param.sn,
      rtbh: this.state.param.rtbh,
      aa: this.state.param.aa,
      asa: this.state.param.asa,
      bing: this.state.param.bing,
      la: this.state.param.la,
      una: this.state.param.una,  
      xmedia: this.state.param.xmedia,
      device: this.state.param.device,
      ex: this.state.param.ex
    }
    localStorage.setItem('filter', JSON.stringify(data));
  }
  render() {
    const toggleMediaCheckbox = (val) => {
      const param = this.state.param;
      this.setState({
        param: {
          ...param,
          aw:        val,
          ss:        val,
          ydn:       val,
          cr:        val,
          tw:        val,
          fb:        val,
          zeus:      val,
          line:      val,
          dbm:       val,
          indeed:    val,
          sn:        val,
          rtbh:      val,
          aa:        val,
          asa:       val,
          bing:      val,
          la:        val,
          una:       val,
          xmedia:    val,
        }
      })
    }

    const RadioHasProps = ({ name, id, value, keyValue, children }) => (
      <StyledRadio
        name = { name }
        id = { id }
        value = { String(value) }
        checked = { this.state.param[keyValue] === value ? 'checked' : '' }
        onChange = {
          ((e) => {
            const param = this.state.param;
            param[keyValue] = value;
            this.setState({
              param: param
            });
          })
        }
      >
        { children }
      </StyledRadio>
    );

    const CheckboxHasProps = ({ name, id, value, keyValue, children, index, col }) => (
      <StyledCheckbox
        name = { name }
        id = { id }
        value = { String(value) }
        checked = { this.state.param[keyValue] === value ? 'checked' : '' }
        index = { index }
        col = { col }
        onChange = {
          (async (e) => {
            const param = this.state.param;
            param[keyValue] = (e.target.checked) ? value : null;
            await this.setState({
              param: param
            });
            const isMediaAll = await MEDIAS.map((media) => this.state.param[media.value] === 1).every(x => x);
            await this.setState({
              isMediaAll: isMediaAll
            });
          })
        }
      >
        { children }
      </StyledCheckbox>
    );

    const CheckboxHasAll = ({ value }) => (
      <StyledCheckbox
        name = 'media-all'
        id = 'media-all'
        value = { value }
        checked = { this.state.isMediaAll === value ? 'checked' : '' }
        index = { 0 }
        col = { 1 }
        onChange = {
          ((e) => {
            const val = (e.target.checked) ? 1 : null;
            toggleMediaCheckbox(val);
            this.setState({
              isMediaAll: !!val,
            });
          })
        }
      >
        すべてにチェックを入れる
      </StyledCheckbox>
    );

    const submit = async () => {
      await this.props.setFilterData(this.state);
      await this.saveLocalStorage();
      this.closePullDown();
    }

    const toggleFilter = (label) => {
      let labels = this.state.openLabels.slice();
      if (labels.indexOf(label) < 0) {
        labels.push(label);
      } else {
        labels = _.pull(labels, label);
      }
      this.setState({
        openLabels: labels
      })
    }

    const checkOpenLabel = (label) => {
      return !(this.state.openLabels.indexOf(label) < 0)
    }

    return (
      <StyledPullDownProgressFilter
        className = { this.props.className }
      >
        <PullDownS
          onClick = { this.togglePullDown }
        >
          <FlexBox>
            <IconFilter
              className = 'm-r-8'
            />
            フィルタ
          </FlexBox>
        </PullDownS>
        <StyledPullDownListWrap
          isOpenedPullDown = { this.state.isOpenedPullDown }
          className = { classnames({
            'is-opened': this.state.isOpenedPullDown,
            'is-closed': !this.state.isOpenedPullDown,
            'is-modal-opened': this.props.isOpenedThresholdModal
          })}
        >
          <StyledPullDownListWrapIn1>
            <StyledAccordionHeading
              noMargin
              onClick = { () => toggleFilter('status') }
            >ステータス
            <IconExpand
              className = {
                checkOpenLabel('status') ? 'is-expand' : ''
              }
            />
            </StyledAccordionHeading>
            <StyledAccordion
              className={ classnames({
                'is-current': checkOpenLabel('status'),
                'p-b-32': true
              })}
            >
              <StyledAccordionIn
                flexWrap
              >
                <RadioHasProps
                  name = 'dataExist'
                  id = 'dataExist-0'
                  value = { 0 }
                  keyValue = 'dataExist'
                >
                  すべて
                </RadioHasProps>
                <RadioHasProps
                  name = 'dataExist'
                  id = 'dataExist-1'
                  value = { 1 }
                  keyValue = 'dataExist'
                >
                  アクティブのみ
                </RadioHasProps>
                </StyledAccordionIn>
              </StyledAccordion>
              <StyledAccordionHeading
                noMargin
                onClick = { () => toggleFilter('media') }
              >媒体
                <IconExpand
                  className = {
                    checkOpenLabel('media') ? 'is-expand' : ''
                  }
                />
              </StyledAccordionHeading>
              <StyledAccordion
                height = '120px'
                className = { classnames({
                  'is-current': checkOpenLabel('media'),
                  'p-b-32': true
                })}
              >
                <StyledAccordionIn
                  flexWrap
                >
                <StyledAccordionInBlock>
                  <CheckboxHasAll
                    value = { true }
                  />
                </StyledAccordionInBlock>
                {
                  (() => {
                    return MEDIAS.map((m, i) => {
                      return (
                        <CheckboxHasProps
                          key = { _.uniqueId() }
                          name = 'media'
                          id = { `media-${m.value}` }
                          value = { 1 }
                          keyValue = { m.value }
                          index = { i }
                          col = { 3 }
                        >
                          <MediaBadge
                            id = { m.id }
                            label = { m.label }
                          />
                        </CheckboxHasProps>
                      )
                    })
                  })()
                }
              </StyledAccordionIn>
            </StyledAccordion>
            <StyledAccordionHeading
              noMargin
              onClick = { () => toggleFilter('device') }
            >デバイス
              <IconExpand
                className = {
                  checkOpenLabel('device') ? 'is-expand' : ''
                }
              />
            </StyledAccordionHeading>
            <StyledAccordion
              className={ classnames({
                'is-current': checkOpenLabel('device'),
                'p-b-32': true
              })}
            >
              <StyledAccordionIn
                flexWrap
              >
                <RadioHasProps
                  name = 'device'
                  id = 'device0'
                  value = { null }
                  keyValue = 'device'
                >
                  すべて
                </RadioHasProps>
                <RadioHasProps
                  name = 'device'
                  id = 'device1'
                  value = { 1 }
                  keyValue = 'device'
                >
                  パソコン
                </RadioHasProps>
                <RadioHasProps
                  name = 'device'
                  id = 'device4'
                  value = { 4 }
                  keyValue = 'device'
                >
                  モバイル
                </RadioHasProps>
                <RadioHasProps
                  name = 'device'
                  id = 'device8'
                  value = { 8 }
                  keyValue = 'device'
                >
                  タブレット
                </RadioHasProps>
              </StyledAccordionIn>
            </StyledAccordion>
            <StyledAccordionHeading
              noMargin
              onClick = { () => toggleFilter('external') }
            >データ連携
              <IconExpand
                className = {
                  checkOpenLabel('external') ? 'is-expand' : ''
                }
              />
            </StyledAccordionHeading>
            <StyledAccordion
              className={ classnames({
                'is-current': checkOpenLabel('external'),
                'p-b-32': true
              })}
            >
              <StyledAccordionIn
                flexWrap
              >
                <RadioHasProps
                  name = 'ex'
                  id = 'ex-0'
                  value = { 0 }
                  keyValue = 'ex'
                >
                  なし
                </RadioHasProps>
                <RadioHasProps
                  name = 'ex'
                  id = 'ex-1'
                  value = { 1 }
                  keyValue = 'ex'
                >
                  外部データ
                </RadioHasProps>
                <RadioHasProps
                  name = 'ex'
                  id = 'ex-2'
                  value = { 2 }
                  keyValue = 'ex'
                >
                  すべて
                </RadioHasProps>
              </StyledAccordionIn>
            </StyledAccordion>
            <StyledAccordionHeading
              noMargin
              onClick = { () => toggleFilter('label') }
            >ラベル
              <IconExpand
                className = {
                  checkOpenLabel('label') ? 'is-expand' : ''
                }
              />
            </StyledAccordionHeading>
            <StyledAccordion
              className={ classnames({
                'is-current': checkOpenLabel('label'),
                'p-b-32': true
              })}
            >
              <StyledAccordionIn
                flexWrap
              >
                <RadioHasProps
                  name = 'label'
                  id = 'label-0'
                  value = { null }
                  keyValue = 'label'
                >
                  なし
                </RadioHasProps>
                {
                  (() => {
                    return this.state.labelData.map((m, i) => {
                      return (
                      <RadioHasProps
                        name = 'label'
                        id = { `label-${m.id}` }
                        value = { m.id }
                        keyValue = 'label'
                        key = { `label-${m.id}` }
                      >
  	                  { m.name }
  	                  </RadioHasProps>
                      )
                    })
                  })()
                }
              </StyledAccordionIn>
            </StyledAccordion>
            {
              (() => {
                if (this.props.headerThresholdTmp.length > 0) {
                  return (
                    <SelectDispItemsFilter
                      selectableDispItems = { this.props.headerThresholdTmp }
                      openThresholdModal = { this.props.openThresholdModal }
                      removeThreshold = { this.props.removeThreshold }
                      addItems = { this.props.addItems }
                    />
                  )
                }
              })()
            }
          </StyledPullDownListWrapIn1>
          <StyledPullDownListWrapIn2>
            <Button
              width = "100%"
              justify = "center"
              onClick = { submit }
            >
              適用
            </Button>
          </StyledPullDownListWrapIn2>
        </StyledPullDownListWrap>
      </StyledPullDownProgressFilter>
    )
  }
}

export default onClickOutside(PullDownProgressFilter);
