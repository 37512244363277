import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import _ from 'lodash';

import Button from '../atoms/Button';
import ModalL from '../molecules/ModalL';
import HeadingS from '../atoms/HeadingS';
import Radio from '../atoms/Radio';
import Textarea from '../atoms/Textarea';
import FlexBox from '../atoms/FlexBox';
import SelectUsers from '../organisms/SelectUsers';
import PullDownCalendar from '../organisms/PullDownCalendar';
import PullDownCommon from '../organisms/PullDownCommon';

const StyledButton = styled(Button)`
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

const StyledModalBody = styled.div`
  min-height: 100%;
  width: 700px;
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
  overflow-y: auto;
  box-sizing: border-box;
`

const StyledPullDownWrap = styled(FlexBox)`
  align-items: baseline;
`

const DATE_FORMAT = 'YYYY-MM-DD';

class ModalOperationMemoEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '運用メモ',
      selected: [],
      date: new Date(),
      clientName: '',
      accountName: '',
      clientItems: [],
      accountItems: [],
      accountTemp: [],
      mediaItems: [],
      param: {
        id: 0,
        memo: '',
        note: '',
        clientId: '',
        accountId: '',
        mediaType: '',
        shareSetting: 0,
        createDate: '',
        userIds: [],
      },
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    // モーダルが開かれたとき
    if (
      prevProps.isOpened !== this.props.isOpened
      && this.props.isOpened === true
    ) {
      // 選択済みの項目をセットする。
      const checkedUsers = this.props.modifyParams ? this.props.modifyParams.userIds : [];
      // 頻出するpropsを定数に設定
      const clientId = this.props.modifyParams.clientId;
      const accountId = this.props.modifyParams.accountId;

      const clientItems = this.props.clients.map(x => {
        return {
          id: x.id,
          label: x.clientName
        };
      });
      let accountTemp = [];
      let accountItems = [];
      let mediaItems = [];
      accountTemp = _.groupBy(this.props.accounts, (o) => {
        return o.clientId
      });
      if (clientId) {
        accountItems = accountTemp[clientId].map(x => {
          return {
            id: x.id,
            label: x.accountName
          };
        });
        accountItems.unshift({
          id: '',
          label: ' - '
        });
      }
      if (accountId) {
        mediaItems = accountTemp[clientId].map(x => {
          if (x.id === accountId) {
            return {
              id: x.mediaTypeId,
              label: x.media
            };
          } else {
            return null;
          }
        }).filter(Boolean);
        mediaItems = _.uniqWith(mediaItems, _.isEqual);
        mediaItems.unshift({
          id: '',
          label: ' - '
        });
      }
      // stateに設定
      this.setState({
        date: this.props.modifyParams.createDate ? new Date(this.props.modifyParams.createDate) : new Date(),
        checkedUsers: checkedUsers,
        clientItems: clientItems,
        accountItems: accountItems,
        accountTemp: accountTemp,
        mediaItems: mediaItems,
        param: {
          ...this.props.modifyParams
        }
      });
    }
  }

  render() {
    // 日付設定
    const switchDate = (date) => {
      this.setState({
        date: date,
        param: {
          ...this.state.param,
          createDate: format(date, DATE_FORMAT)
        },
      });
    }
    // 登録処理
    const submit = async () => {
      const checkedUsers = this.state.checkedUsers;
      await this.setState({
        param: {
          ...this.state.param,
          checkedUsers: checkedUsers
        }
      })
      // 運用メモ登録処理（API実行）
      await this.props.setOperationMemo(this.state.param);
      this.props.close();
    }

    // 公開範囲のラジオボタンを制御
    const switchShareSetting = async (e) => {
      const id = parseInt(e.target.value);

      this.setState({
        checkedUsers: (id === 4) ? this.state.checkedUsers : [],
        param: {
          ...this.state.param,
          shareSetting: id,
        }
      });
    }

    // ユーザーの各要素をチェック
    const checkUser = (e) => {
      const id = e.target.value;
      const { checkedUsers } = this.state;

      if (checkedUsers.indexOf(id) > -1) {
        checkedUsers.splice(checkedUsers.indexOf(id), 1)
      } else {
        checkedUsers.push(id);
      }
      this.setState({
        checkedUsers: checkedUsers,
      });
    }

    // ユーザーのチェックボックスをすべて選択
    const toggleCheckUsersAll = (checkedFiltered, bool) => {
      const { checkedUsers } = this.state;

      if (bool) {
        this.setState({
          checkedUsers: _.union(
            checkedUsers,
            checkedFiltered
          )
        });
      } else {
        this.setState({
          checkedUsers: _.without(
            checkedUsers,
            ...checkedFiltered
          )
        });
      }
    }

    // ユーザーのチェックボックスをすべてクリア
    const clearCheckUsersAll = () => {
      this.setState({
        checkedUsers: []
      })
    }

    // クライアントID設定
    const switchClientId = (id) => {
      let accountItems = this.state.accountTemp[id].map(x => {
        if (id !== 0) {
          return {
            id: x.id,
            label: x.accountName
          };
        } else {
          return null;
        }
      }).filter(y => y);
      accountItems.unshift({
        id: '',
        label: ' - '
      });
      this.setState({
        accountItems: accountItems,
        param: {
          ...this.state.param,
          clientId: id,
          accountId: null,
          mediaType: null
        },
      });
    }

    // アカウントID設定
    const switchAccountId = (id) => {
      const accountTemp = this.state.accountTemp[this.state.param.clientId];
      let mediaTypeId = '';
      let mediaItems = accountTemp.map(x => {
        if (x.id === id) {
          mediaTypeId = x.mediaTypeId;
          return {
            id: x.mediaTypeId,
            label: x.media
          }
        } else {
          return null
        }
      }).filter(Boolean);
      mediaItems = _.uniqWith(mediaItems, _.isEqual);
      mediaItems.unshift({
        id: '',
        label: ' - '
      });
      this.setState({
        mediaItems: mediaItems,
        param: {
          ...this.state.param,
          accountId: id,
          mediaType: mediaTypeId,
        },
      });
    }
    // メディアID設定
    const switchMediaTypeId = (id) => {
      this.setState({
        param: {
          ...this.state.param,
          mediaType: id,
        },
      });
    }


    return (
      <ModalL
        heading = { this.props.heading }
        isOpened = { this.props.isOpened }
        close = { this.props.close }
      >
        <StyledModalBody>
          <StyledPullDownWrap>
            <HeadingS>
              クライアント：
            </HeadingS>
            <PullDownCommon
              id = { this.state.param.clientId }
              width = '400px'
              listWidth = '400px'
              items = { this.state.clientItems }
              onChange = { switchClientId }
            />
          </StyledPullDownWrap>
          <StyledPullDownWrap>
            <HeadingS>
              アカウント：
            </HeadingS>
            {
              (() => {
                return (
                  <div>
                    <PullDownCommon
                    id = { this.state.param.accountId }
                    width = '400px'
                    listWidth = '400px'
                    items = { this.state.accountItems }
                    onChange = { switchAccountId }
                    />
                  </div>
                );
              })()
            }
          </StyledPullDownWrap>
          <StyledPullDownWrap>
            <HeadingS>
              媒体：
            </HeadingS>
            {
              (() => {
                return (
                  <div>
                    <PullDownCommon
                      id = { this.state.param.mediaType }
                      width = '200px'
                      listWidth = '200px'
                      items = { this.state.mediaItems }
                      onChange = { switchMediaTypeId }
                    />
                  </div>
                );
              })()
            }
          </StyledPullDownWrap>
          <FlexBox>
            <HeadingS noMargin>日付：</HeadingS>
            <PullDownCalendar
              date = { this.state.date }
              applyDateRepeat = { switchDate }
              isOnHeader = { false }
            />
          </FlexBox>
          <HeadingS
            className = 'm-t-32'
          >
            実施したこと
          </HeadingS>
          <Textarea
            width = { 636 }
            value = { this.state.param.memo }
            onChange = {
              ((e) => {
                this.setState({
                  param: {
                    ...this.state.param,
                    memo: e.currentTarget.value
                  }
                });
              })
            }
          />
          <HeadingS
            className = 'm-t-32'
          >
            備考
          </HeadingS>
          <Textarea
            width = { 636 }
            value = { this.state.param.note }
            onChange = {
              ((e) => {
                this.setState({
                  param: {
                    ...this.state.param,
                    note: e.currentTarget.value
                  }
                });
              })
            }
          />
          <HeadingS
            className = 'm-t-32'
          >
            公開範囲
          </HeadingS>
          <FlexBox>
            {
              (() => {
                const items = [
                  '自身',
                  'グループ',
                  '部署',
                  '全社',
                  '特定のユーザー',
                ]
                return items.map((o, i) => {
                  return (
                    <Radio
                      className = 'm-r-24'
                      key = { _.uniqueId() }
                      name = 'share-setting'
                      id = { `ope-share-setting-${i}` }
                      value = { String(i) }
                      checked = { this.state.param.shareSetting === i ? 'checked' : '' }
                      onChange = { switchShareSetting }
                    >
                      { o }
                    </Radio>
                  )
                });
              })()
            }
          </FlexBox>
          {
            (() => {
              // 特定のユーザーを選択した場合はユーザー一覧から対象を選択するためのUIを出す。
              if (this.state.param.shareSetting === 4) {
                return (
                  <SelectUsers
                    users = { this.props.users }
                    checked = { this.state.checkedUsers }
                    check = { checkUser }
                    toggleCheckAll = { toggleCheckUsersAll }
                    clearCheckAll = { clearCheckUsersAll }
                    className = 'm-t-24'
                  />
                )
              }
            })()
          }
          <FlexBox
            justify = "space-between"
          >
            <StyledButton
              type = "button"
              width = "calc(50% - 2px)"
              justify = "center"
              className = 'm-t-24'
              onClick = { this.props.close }
            >キャンセル
            </StyledButton>
            <StyledButton
              type = "submit"
              width = "calc(50% - 2px)"
              justify = "center"
              className = 'm-t-24'
              color = 'orange'
              disabled = { !this.state.param.memo }
              onClick = { submit }
            >保存する
            </StyledButton>
          </FlexBox>
        </StyledModalBody>
      </ModalL>
    )
  }
};

export default ModalOperationMemoEdit;
