import React from 'react';
import styled from 'styled-components'

import ButtonEdit from '../atoms/ButtonEdit';
import InputText from '../atoms/InputText';

const StyledLabel = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  line-height: 1;
  font-weight: 600;
`
const StyledButtonEdit = styled(ButtonEdit)`
  margin-left: 8px;
`
const StyledInput = styled(InputText)`
  width: 100%;
`

class TextEditable extends React.Component {
  render() {
    const close = (e) => {
      if (e.key === 'Enter') {
        this.props.close();
      }
    }

    if (this.props.isOpened === true) {
      return (
        <StyledInput
          name = { this.props.name }
          type = 'text'
          value = { this.props.value }
          placeholder = { this.props.placeholder }
          onKeyDown = { close }
          onChange = { this.props.onChange }
        />
      )
    } else {
      return (
        <StyledLabel>
          <div>
            { (this.props.value === '') ? this.props.blankLabel : this.props.value }
          </div>
          <StyledButtonEdit
            onClick = { this.props.open }
          />
        </StyledLabel>
      )
    }
  }
};
export default TextEditable;
