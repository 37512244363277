import React from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';

import FONTSIZE from '../_const/FONTSIZE';

import DividerDottedV from '../atoms/DividerDottedV';
import FlexBox from '../atoms/FlexBox';
import IconArrowLL from '../atoms/IconArrowLL';
import IconArrowLR from '../atoms/IconArrowLR';
import PullDown from '../molecules/PullDown';
import PullDownListItem from '../atoms/PullDownListItem';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';

const StyledPaginationWrap = styled(FlexBox)`
  height: 20px;
  justify-content: flex-end;
  font-size: ${FONTSIZE.S}px;
`
const StyledViewCountLabel = styled.div`
  color: #b2b2b2;
`
const StyledViewCount = styled.div`
  position: relative;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  width: 100px;
  position: absolute;
  bottom: 24px;
  right: 0;
  z-index: 10;
`
const StyledPullDownListWrapIn = styled(PullDownListWrapIn)`
  margin-top: 10px;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: ${FONTSIZE.S}px;
  &:first-child {
    margin-top: 0;
  }
`
const StyledPage = styled.div`
  color: #b2b2b2;
`
const StyledCurrentPage = styled.span`
  color: #4c4c4c;
  font-weight: 600;
`
const StyledAll = styled.span`
  margin-right: 8px;
  margin-left: 8px;
`
const StyledNeighborLink = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &.is-disabled {
    pointer-events: none;
    opacity: .4;
  }
`

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenedPullDown: false,
    };
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false
    });
  }
  switchPostPerPage = (num) => {
    this.props.switchPostPerPage(num);
    this.closePullDown();
  }
  createPullDownListItems = () => {
    const nums = [10, 30, 50, 100, 200, 500];
    return nums.map((num) => {
      return (
        <PullDownListItem
          key = { `num${num}` }
          onClick = { () => this.switchPostPerPage(num) }
          className = { (this.props.postPerPage === num) ? 'is-current' : '' }
        >
          { num }
        </PullDownListItem>
      )
    });
  }
  render () {
    return (
      <StyledPaginationWrap
        className = { this.props.className }
      >
        <FlexBox>
          <StyledViewCountLabel>
            表示桁数：
          </StyledViewCountLabel>
          <StyledViewCount>
            <PullDown onClick = {this.togglePullDown}>
              <strong>{ this.props.postPerPage }</strong>
            </PullDown>
            <StyledPullDownListWrap
              className = {
                this.state.isOpenedPullDown === true
                  ? `is-opened`
                  : `is-closed`
              }
            >
              <StyledPullDownListWrapIn>
                { this.createPullDownListItems() }
              </StyledPullDownListWrapIn>
            </StyledPullDownListWrap>
          </StyledViewCount>
        </FlexBox>
        <DividerDottedV hasMargin />
        <StyledPage>
          <StyledCurrentPage>
            { this.props.offset + 1 }
          </StyledCurrentPage>
          /{ (() => {
            const num = Math.ceil(this.props.dataCount / this.props.postPerPage);
            return (num === 0) ? 1 : num;
          })() }
        </StyledPage>
        <StyledAll>
          ({ this.props.dataCount })
        </StyledAll>
        <StyledNeighborLink
          onClick = { this.props.goToPrevPagination }
          className = { (this.props.offset <= 0)
            ?'is-disabled'
            : ''
          }
        >
          <IconArrowLL />
        </StyledNeighborLink>
        <StyledNeighborLink
          onClick = { this.props.goToNextPagination }
          className = { (Math.floor((this.props.dataCount - 1) / this.props.postPerPage) <= this.props.offset)
            ?'is-disabled'
            : ''
          }
        >
          <IconArrowLR />
        </StyledNeighborLink>
      </StyledPaginationWrap>
    )
  }
}
export default onClickOutside(Pagination);
