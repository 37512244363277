import styled from 'styled-components'

import COLOR from '../_const/COLOR'
import FONTSIZE from '../_const/FONTSIZE'

const getColors = (color) => {
  switch (color) {
    case 'orange': return `
      box-shadow: 0 2px 0 0 #e18868;
      color: #fff;
      background-color: ${COLOR.ORANGE};
    `;
    case 'darkgray': return `
      box-shadow: 0 2px 0 0 #6f6f6f;
      color: #fff;
      background-color: ${COLOR.DARKGRAY};
    `
    default: return `
      box-shadow: 0 2px 0 0 #d8d8d8;
      color: #808080;
      background-color: ${COLOR.GRAY};
    `
  }
}

const Button = styled.button`
  width: ${ props => props.width ? `${props.width}` : 'auto'};
  cursor: pointer;
  display: flex;
  justify-content: ${props => props.justify ? props.justify : 'flex-start'};
  align-items: center;
  padding: ${ props => props.thin ? '4px 8px 2px' : '8px 16px 6px'};
  border: 0;
  border-radius: 2px;
  font-size: ${FONTSIZE.S}px;
  font-weight: 600;
  text-decoration: none;
  ${ props => getColors(props.color) };
`;

export default Button;
