import styled from 'styled-components';

import COLOR from '../_const/COLOR';

const LinkText = styled.div `
  cursor: pointer;
  color: ${COLOR.ORANGE};
  font-weight: 600;
`

export default LinkText;
