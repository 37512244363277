import React from 'react';
import styled from 'styled-components';

import Button from '../atoms/Button';
import FlexBox from '../atoms/FlexBox';
import Modal from '../molecules/Modal';

const StyledModalBody = styled.div`
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
`

const StyledModal = styled(Modal)`
  z-index: 150;
`

class ModalErrorMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'エラー',
      selected: [],
    }
  }
  render() {
     // bodyTextの内容を改行文字を<br>タグに変換
     const formattedBodyText = this.props.bodyText ? this.props.bodyText.replace(/\n/g, '<br>') : '';
    return (
      <StyledModal
        heading = { this.props.heading }
        isOpened = { this.props.isOpened }
        close = { this.props.close }
      >
        <StyledModalBody>
          <div dangerouslySetInnerHTML={{ __html: formattedBodyText }} />
          {/* { this.props.bodyText } */}
          <FlexBox className="m-t-24">
            <Button
              width = '90px'
              justify = 'center'
              color = 'orange'
              value = '1'
              className = 'm-r-8'
              onClick = { this.props.close }
            >
              OK
            </Button>
          </FlexBox>
        </StyledModalBody>
      </StyledModal>
    )
  }
};

export default ModalErrorMessage;
