// 全角英数字を半角英数字に変換する。
const toHalf = (str) => {
  return str.replace(/[！-～]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}

// 半角英数字に統一した文字列の大文字を小文字に変換して返す。
export default function(str) {
  return toHalf(str).toLowerCase();
}
