import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'

import IconClose from '../atoms/IconClose'

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  border: 1px solid ${ COLOR.GREEN };
  background: ${ COLOR.GREEN };
  padding: 6px 10px 4px 12px;
  border-radius: 20px;
`

const StyledIconClose = styled(IconClose)`
  fill: ${ COLOR.GREEN };
`

const StyledIcon = styled.div`
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
  margin-left: 8px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
`

class ButtonStatus extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <StyledLabel>
      { this.props.children }
        <StyledIcon
          onClick = { this.props.remove }
        >
          <StyledIconClose />
        </StyledIcon>
      </StyledLabel>
    )
  }
}

export default ButtonStatus;
