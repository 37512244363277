import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import _ from 'lodash';
import Url from 'url-parse';
import { addDays, parse, isWithinRange } from 'date-fns';

import FONTSIZE from '../../_const/FONTSIZE';

import DividerDottedV from '../../atoms/DividerDottedV';
import FlexBox from '../../atoms/FlexBox';
import IconDownload from '../../atoms/IconDownload';
import TableDataCell from '../../atoms/TableDataCell';
import TableBodyRow from '../../atoms/TableBodyRow';
import TableHeader from '../../atoms/TableHeader';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import Search from '../../molecules/Search';
import Pagination from '../../organisms/Pagination';
import PullDownCommon from '../../organisms/PullDownCommon';
import PullDownDateRange from '../../organisms/PullDownDateRange';

import alignSearchQuery from '../../_util/alignSearchQuery';

import User from '../../../utils/user';

const TABLE_HEADER = [
  {
    id: 'category',
    label: 'カテゴリ',
    width: '20%',
  },
  {
    id: 'activityInfo',
    label: 'アクティビティ',
    width: '40%',
  },
  {
    id: 'userName',
    label: 'ユーザー',
    width: '20%',
  },
  {
    id: 'execDate',
    label: '日時',
    width: '20%',
  },
];

const StyledPullDownLabel = styled.div`
  margin-right: 8px;
  font-size: ${FONTSIZE.S}px;
  font-weight: 600;
`
const StyledTable = styled.div`
  width: 100%;
  max-height: ${ props => props.windowHeight - 120 - 113 - 44 - 40 }px;
  overflow: scroll;
  position: relative;
  z-index: 1;
`
const backendApi = process.env.REACT_APP_BACKEND_URI;

class Activity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOriginal: [],
      dataFiltered: [],
      data: [],
      categories: [],
      sortId: 'execDate',
      sortOrder: 'desc',
      offset: 0,
      postPerPage: 50,
      searchQuery: '',
      startDate: null,
      endDate: null,
      selectedCategoryId: 0,
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('アクティビティ履歴');
    props.toggleDatePicker(false);
  }
  componentWillMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    await Promise.all([
      this.getData(),
      this.getCategories(),
    ]);
    this.arrangeViewItems();

    //　プログレス非表示
    this.props.endProgressing();
  }
  getData = () => {
    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'activity', {
      params: {
        ...User.apiParams(),
      }
    })
    .then((response) => {
      this.setState({
        dataOriginal: response.data,
        dataFiltered: response.data,
        data: response.data,
      });
    })
    .catch(() => {
    });
  }
  getCategories = () => {
    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'activity', {
      params: {
        ...User.apiParams(),
        categoryList: 1,
      }
    })
    .then((response) => {
      this.setState({
        categories: response.data,
      });
    })
    .catch(() => {
    });
  }
  arrangeViewItems = () => {
    // ソートやページャの条件に従って表示するレポートデータを別途作成する。
    const { offset, postPerPage } = this.state;

    // 元データを指定の条件でフィルタする
    const filteredData = this.state.dataOriginal.filter((item, index) => {
      const regexp = new RegExp(
        `^(?=.*${alignSearchQuery(this.state.searchQuery)}).*$`
      );
      
      return (
        (
          // フリーワード検索のクエリに合致するか
          this.state.searchQuery === ''
          || alignSearchQuery(item.activityInfo).match(regexp) !== null
        ) && (
          // 選択したカテゴリに合致するか
          this.state.selectedCategoryId === 0
          || this.state.selectedCategoryId === item.category.id
        ) && (
          // 指定した日付の範囲に含まれるか
          (this.state.startDate === null && this.state.endDate === null)
          || isWithinRange(parse(item.execDate), this.state.startDate, addDays(this.state.endDate, 1))
        )
      )
    });

    // データのソートには_.orderBy()を使う。
    const sortedDataTable = _.orderBy(
      filteredData,
      o => {
        if (this.state.sortId === 'category') {
          return o[this.state.sortId].id;
        } else {
          return o[this.state.sortId];
        }
      },
      this.state.sortOrder
    );

    // ページャ条件による絞り込みにはArray.slice()を使う。
    const arrangedDataTable = sortedDataTable.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    this.setState({
      dataFiltered: filteredData,
      data: arrangedDataTable,
    });
  }
  render() {
    // フリーワード検索
    const setSearchQuery = async (e) => {
      e.preventDefault();
      await this.setState({
        offset: 0,
        searchQuery: e.target['searchQuery'].value,
      });
      this.arrangeViewItems();
    }

    // 期間を指定
    const applyDateRange = async (ranges) => {
      await this.setState(ranges);
      this.arrangeViewItems();
    }

    // カテゴリを変更
    const switchCategory = async (categoryId) => {
      await this.setState({
        offset: 0,
        selectedCategoryId: categoryId,
      });
      this.arrangeViewItems();
    }

    // CSVダウンロード
    const download = async (e) => {
      e.preventDefault();

      const url = new Url(backendApi + 'activity', true);
      url.set('query', {
        ...User.apiParams(),
        dl : 1,
      });

      var element = document.createElement('a');
      element.href = url.toString();
      element.setAttribute('download', 'reportdata.csv');
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    // 並び替え
    const sort = async (e) => {
      // セルの並び替え
      const id = e.currentTarget.dataset.id;
      const order = (id === this.state.sortId && this.state.sortOrder === 'desc') ? 'asc' : 'desc';

      await this.setState({
        sortId: id,
        sortOrder: order,
      })
      this.arrangeViewItems();
    }

    // ページャの表示件数を変更
    const switchPostPerPage = async (num) => {
      await this.setState({
        postPerPage: num
      })
      this.arrangeViewItems();
    }

    // ページャの戻るイベント
    const goToPrevPagination = async () => {
      if (this.state.offset <= 0) return;
      await this.setState({
        offset: this.state.offset - 1
      });
      this.arrangeViewItems();
    }

    // ページャの進むイベント
    const goToNextPagination = async () => {
      if (this.state.offset >= Math.ceil(this.state.dataFiltered.length / this.state.postPerPage) - 1) return;
      await this.setState({
        offset: this.state.offset + 1
      });
      this.arrangeViewItems();
    }

    return (
      <div>
        <FlexBox className="m-b-32">
          <Search
            name = "searchQuery"
            onSubmit = { setSearchQuery }
            value = { this.state.searchQuery }
          />
          <DividerDottedV hasMargin />
          <StyledPullDownLabel>
            期間を指定：
          </StyledPullDownLabel>
          <PullDownDateRange
            startDate = { this.state.startDate }
            endDate = { this.state.endDate }
            applyDateRange = { applyDateRange }
            className = "m-r-24"
          />
          <StyledPullDownLabel>
            カテゴリを指定：
          </StyledPullDownLabel>
          <PullDownCommon
            id = { this.state.selectedCategoryId }
            items = { this.state.categories }
            onChange = { switchCategory }
          />
          <DividerDottedV hasMargin />
          <div
            style = {{ 'cursor': 'pointer' }}
            onClick = { download }
          >
            <IconDownload />
          </div>
        </FlexBox>
        <StyledTable
          windowHeight = { this.props.windowHeight }
        >
          <TableHeader>
            {
              (() => {
                return TABLE_HEADER.map(o => {
                  return (
                    <TableHeaderCell
                      key = { _.uniqueId() }
                      style = { { width: `${o.width}` } }
                      sortState = {
                        (o.id !== this.state.sortId)
                          ? 0
                          : (this.state.sortOrder === 'asc')
                            ? 1
                            : 2
                      }
                      data-id = { o.id }
                      onClick = { sort }
                    >
                      { o.label }
                    </TableHeaderCell>
                  )
                })
              })()
            }
          </TableHeader>
          <div>
            {
              (() => {
                return this.state.data.map(r => {
                  return (
                    <TableBodyRow
                      key = { _.uniqueId() }
                    >
                      {
                        (() => {
                          return TABLE_HEADER.map(o => {
                            const label = (o.id === 'category') ? r[o.id].label : r[o.id];
                            return (
                              <TableDataCell
                                key = { _.uniqueId() }
                                style = { { width: `${o.width}` } }
                              >
                                { label }
                              </TableDataCell>
                            )
                          })
                        })()
                      }
                    </TableBodyRow>
                  )
                })
              })()
            }
          </div>
        </StyledTable>

        <div className="m-t-24">
          <Pagination
            offset = { this.state.offset }
            postPerPage = { this.state.postPerPage }
            dataCount = { this.state.dataFiltered.length }
            switchPostPerPage = { switchPostPerPage }
            goToPrevPagination = { goToPrevPagination }
            goToNextPagination = { goToNextPagination }
          />
        </div>
      </div>
    )
  }
}

export default Activity;
