import React from 'react';
import styled from 'styled-components';

const IconInput = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0, 0, 24, 24" className={props.className}>
    <path d="M21 3.01H3c-1.1 0-2 .9-2 2V9h2V4.99h18v14.03H3V15H1v4.01c0 1.1.9 1.98 2 1.98h18c1.1 0 2-.88 2-1.98v-14c0-1.11-.9-2-2-2zM11 16l4-4-4-4v3H1v2h10v3z"/>
  </svg>
)

const StyledIconInput = styled(IconInput) `
  display: block;
`

export default StyledIconInput;
