import React from 'react';
import styled from 'styled-components'

import COLOR from '../_const/COLOR'

const StyledWrap = styled.div`
  position: relative;
  margin-bottom: 40px;
  padding: 16px 24px;
  border: 1px solid ${COLOR.BLUE};
  border-radius: 4px;
  background-color: ${COLOR.LIGHTBLUE};
`
const StyledTitle = styled.div`
  position: relative;
  font-weight: 700;
`
class ProgressStatus extends React.Component {
  render() {
    return (
      <StyledWrap>
        <StyledTitle>
          { this.props.applyName }
          「{ this.props.title }」
          の設定が適用されています。
        </StyledTitle>
      </StyledWrap>
    )
  }
};

export default ProgressStatus;
