import React from 'react';

class SmartPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('スマートフォン表示設定');
    props.toggleDatePicker(false);
  }
  render() {
    return (
      <div>
        <p>SmartPhone メディックス様が開発担当されます。</p>
      </div>
    )
  }
}

export default SmartPhone;
