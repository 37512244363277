import React from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import IconArrowDouble from '../atoms/IconArrowDouble';
import Graph from './Graph';

const StyledGraphWrap = styled.div`
  position: relative;
`
const StyledGraphWrapIn = styled.div`
  height: ${88 + 16 + 280 + 16 + 40}px;
  overflow: hidden;
  pointer-events: auto;
  transition-duration: .2s;
  transition-delay: .02s;
  transition-property: height;
  &.is-closed {
    height: 0;
  }
`
const StyledGraph = styled(Graph)`
  width: 100%;
`
const StyledToggleBtn = styled.div`
  width: 44px;
  height: 40px;
  box-sizing: border-box;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -40px;
  right: 0;
  border-bottom: 2px solid #595959;
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-radius: 0 0 2px 2px;
  background-color: #fff;
`
const StyledIconArrowDouble = styled(IconArrowDouble)`
  fill: #595959;
  transform: rotate(90deg);
  transition-duration: .2s;
  transition-property: transform;
  &.is-closed {
    transform: rotate(-90deg);
  }
`

class GraphWithToggleBtn extends React.Component {
  constructor(props) {
    super(props);

    const cookieGraphOpen = Cookies.getJSON('rakuda-graph-open');

    this.state = {
      isOpened: (cookieGraphOpen) ? cookieGraphOpen : false,
    }
  }
  render() {
    // グラフセクションの開閉
    const toggleWrap = () => {
      Cookies.set('rakuda-graph-open', !this.state.isOpened);
      this.setState({
        isOpened: !this.state.isOpened
      });
    }

    return (
      <StyledGraphWrap
        className = { !this.state.isOpened ? 'is-closed': '' }
      >
        <StyledGraphWrapIn
          className = { !this.state.isOpened ? 'is-closed': '' }
        >
          <StyledGraph
            dateRange = { this.props.dateRange }
            dateRangeCompare = { this.props.dateRangeCompare }
            endDate = { this.props.endDate }
            data = { this.props.data }
            dataCompare = { this.props.dataCompare }
            candidate = { this.props.candidate }
            targetId = { this.props.targetId }
            division = { this.props.division }
            isEnabledCompare = { this.props.isEnabledCompare }
            isEnabledSelectTarget = { false }
            switchGraphSplit = { this.props.switchGraphSplit }
          />
        </StyledGraphWrapIn>
        {
          (() => {
            if (this.props.hasToggleBtn === true) {
              return (
                <StyledToggleBtn
                  onClick = { toggleWrap }
                >
                  <StyledIconArrowDouble
                    className = { !this.state.isOpened ? 'is-closed': '' }
                  />
                </StyledToggleBtn>
              )
            }
          })()
        }
      </StyledGraphWrap>
    )
  }
}
export default GraphWithToggleBtn;
