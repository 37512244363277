import React from 'react';
import styled from 'styled-components';

import Bar from '../atoms/Bar';
import Button from '../atoms/Button';
import DividerDottedV from '../atoms/DividerDottedV';

const StyledBar = styled(Bar)`
  height: 0;
  overflow: hidden;
  padding-right: 24px;
  padding-left: 24px;
  transition-duration: .2s;
  transition-property: height;
  &.is-opened {
    height: 48px;
  }
`
const StyledButton = styled(Button)`
  width: 5em;
  justify-content: center;
  margin-bottom: 1px;
`
const StyledButton10 = styled(Button)`
  width: 10em;
  justify-content: center;
  margin-bottom: 1px;
`

const BarEditDataForLabel = (props) => (
  <StyledBar
    className = { `${props.className} ${
      props.count > 0 ? 'is-opened' : ''
    }` }
  >
    { props.count }個を選択済み
    <DividerDottedV hasMargin />
    <StyledButton
      color = 'darkgray'
      thin
      onClick = { props.onClickDelete }
    >
      削除
    </StyledButton>
    <DividerDottedV hasMargin />
    <StyledButton10
      color = 'orange'
      thin
      onClick = { props.onClickConfirm }
    >
      一括設定
    </StyledButton10>
  </StyledBar>
);

export default BarEditDataForLabel;
