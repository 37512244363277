import React from 'react';
import styled from 'styled-components'

import IconSort from './IconSort';

import FONTSIZE from '../_const/FONTSIZE'

const StyledTableHeaderCell = styled.div`
  min-height: calc(1.75em + 28px);
  box-sizing: border-box;
  box-shadow: inset 0 3px 0 0 #f5f5f5;
  cursor: ${props => (props.sortState !== undefined) ? 'pointer' : 'default'};
  display: flex;
  align-items: center;
  position: relative;
  white-space: pre-wrap;
  line-height: 1.75;
  padding-top: 18px;
  padding-bottom: 10px;
  padding-right: 32px;
  padding-left: 18px;
  font-size: ${FONTSIZE.S}px;
  font-weight: 700;
  text-align: left;
  background-color: #fff;
  &.has-checkbox {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  &:after {
    width: 1px;
    height: 7px;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #d9d9d9;
  }
`;
const StyledSortIcon = styled(IconSort)`
  position: absolute;
  top: calc(50% - 1px);
  right: 12px;
`

const TableHeaderCell = ({ children, sortState, ...attr }) => (
  <StyledTableHeaderCell
    sortState = { sortState }
    { ...attr }
  >
    {
      children
    }
    {
      (() => {
        if (sortState !== undefined) {
          return (
            <StyledSortIcon
              state = { sortState }
            />
          )
        }
      })()
    }
  </StyledTableHeaderCell>
);

export default TableHeaderCell;
