import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';

import FlexBox from '../atoms/FlexBox';
import IconInput from '../atoms/IconInput';
import PullDownListItem from '../atoms/PullDownListItem';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';
import PullDownS from '../molecules/PullDownS';

const ITEMS = [
  { id: null, label: '当月の平均費用から当月の着地予測を算出する' },
  { id: 1, label: '過去1日間の費用から当月の着地予測を算出する' },
  { id: 3, label: '過去3日間の費用から当月の着地予測を算出する' },
  { id: 7, label: '過去7日間の費用から当月の着地予測を算出する' },
]

const StyledPullDownWrap = styled.div`
  position: relative;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  white-space: nowrap;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 20;
`
const StyledPullDownListWrapIn = styled(PullDownListWrapIn)`
  padding-top: 24px;
  padding-bottom: 24px;
`

class PullDownProgressAvgData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenedPullDown: false,
    };
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false
    });
  }
  render() {
    return (
      <StyledPullDownWrap
        className = { this.props.className }
      >
        <PullDownS
          onClick = {this.togglePullDown}
        >
          <FlexBox>
            <IconInput
              className = 'm-r-8'
            />
            着地予測の計算
          </FlexBox>
        </PullDownS>
        <StyledPullDownListWrap
          className = {
            this.state.isOpenedPullDown === true
              ? `is-opened`
              : `is-closed`
          }
        >
          <StyledPullDownListWrapIn>
            {
              (() => {
                return ITEMS.map((item) => {
                  return (
                    <PullDownListItem
                      key = { _.uniqueId() }
                      onClick = {
                        (() => {
                          this.props.setAvgData(item.id);
                          this.closePullDown();
                        })
                      }
                      className = {
                        classnames({
                          'is-current': this.props.id === item.id
                        })
                      }
                    >
                      { item.label }
                    </PullDownListItem>
                  )
                });
              })()
            }
          </StyledPullDownListWrapIn>
        </StyledPullDownListWrap>
      </StyledPullDownWrap>
    )
  }
}

export default onClickOutside(PullDownProgressAvgData);
