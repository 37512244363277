import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'
import FONTSIZE from '../_const/FONTSIZE'

const Textarea = (props) => (
  <textarea
    type = "text"
    value = { props.value }
    placeholder = { props.placeholder }
    className = { props.className }
    onChange = { props.onChange }
    disabled =  { props.disabled }
  >
  </textarea>
);

const StyledTextarea = styled(Textarea)`
  width: ${props => props.width ? props.width : 680}px;
  height: ${props => props.height ? props.height : 120}px;
  box-sizing: border-box;
  padding: 16px;
  border-top: 0;
  border-bottom: 1px solid #ccc;
  border-right: 0;
  border-left: 0;
  font-size: ${FONTSIZE.S}px;
  background-color: ${COLOR.LIGHTGRAY};

`

export default StyledTextarea;
