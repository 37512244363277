import styled from 'styled-components';

import FONTSIZE from '../_const/FONTSIZE'

const StyledSmallText = styled.div`
  color: #b2b2b2;
  font-size: ${FONTSIZE.SS}px;
`

export default StyledSmallText;
