import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';

import IconWarning from '../atoms/IconWarning';
import Tooltip from '../atoms/Tooltip';

const StyledAlert = styled.div`
  width: 21px;
  height: 19px;
  position: relative;
`
const StyledIconWrap = styled.div`
  cursor: pointer;
`
const StyledTooltip = styled(Tooltip)`
  right: -15px;
  bottom: 18px;
  z-index: 100;
`

class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: false
    }
  }
  handleClickOutside(e) {
    this.hideTooltip(e);
  }
  toggleTooltip = async () => {
    await this.setState({
      isShown: !this.state.isShown
    });
  }
  hideTooltip = () => {
    this.setState({
      isShown: false
    })
  }
  render() {
    return (
      <StyledAlert>
        <StyledIconWrap onClick = { this.toggleTooltip }>
          <IconWarning/>
        </StyledIconWrap>
        <StyledTooltip
          str = {
            (() => {
              return this.props.alertInfo.map(o => {
                return (
                  <div
                    key = { _.uniqueId() }
                  >
                    { o }
                  </div>
                )
              })
            })()
          }
          align = "right"
          className = { (this.state.isShown === true) ? 'is-shown' : '' }
        />
      </StyledAlert>
    )
  }
}

export default onClickOutside(Alert);
