import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import DISPITEM_ALERT from '../_const/DISPITEM_ALERT'

import Button from '../atoms/Button';
import FlexBox from '../atoms/FlexBox';
import IconClose from '../atoms/IconClose';
import InputText from '../atoms/InputText';
import PullDownAlertRuleCompare from './PullDownAlertRuleCompare';
import PullDownAlertRuleIndex from './PullDownAlertRuleIndex';
import PullDownAlertRuleRange from './PullDownAlertRuleRange';
import PullDownCommon from './PullDownCommon';

const StyledPullDownLabel = styled.div`
  width: 6em;
  margin-right: 8px;
  font-weight: 600;
`

class EditAlertCondition extends React.Component {
  render() {
    // アラートのルールの指標を選択
    const setTargetColId = (id) => {
      if (this.props.isAddCondition === true) {
        this.props.setAddConditionValue({
          targetColIdAdd: id,
          targetNumAdd: 0,
        });
      } else {
        this.props.setAddConditionValue({
          targetColId: id,
          targetNum: 0,
        });
      }
    }

    // アラートのルールの判定対象を選択
    const setTiming = (id) => {
      if (this.props.isAddCondition === true) {
        this.props.setAddConditionValue({
          timingAdd: id,
          targetIsRelativeAdd: (id === 0) ? 0 : this.props.targetIsRelative,
        });
      } else {
        this.props.setAddConditionValue({
          timing: id,
          targetIsRelative: (id === 0) ? 0 : this.props.targetIsRelative,
        });
      }
    }

    // アラートの比較方法を選択
    const setTargetIsRelative = (id) => {
      if (this.props.isAddCondition === true) {
        this.props.setAddConditionValue({
          targetIsRelativeAdd: id,
        });
      } else {
        this.props.setAddConditionValue({
          targetIsRelative: id,
        });
      }
    }

    // アラートのルールの数値を入力
    const setTargetNum = (e) => {
      const v = parseFloat(e.currentTarget.value);
      const max = (e.currentTarget.attributes.max) ? parseFloat(e.currentTarget.attributes.max.value) : undefined;
      const min = (e.currentTarget.attributes.min) ? parseFloat(e.currentTarget.attributes.min.value) : undefined;

      const updateValue = (max !== undefined && v > max)
        ? max
        : (min !== undefined && v < min)
          ? min
          : v;

      if (this.props.isAddCondition === true) {
        this.props.setAddConditionValue({
          targetNumAdd: updateValue,
        });
      } else {
        this.props.setAddConditionValue({
          targetNum: updateValue,
        });
      }
    }

    // アラートのルールの比較演算子を選択
    const setCompEqualSign = (id) => {
      if (this.props.isAddCondition === true) {
        this.props.setAddConditionValue({
          compEqualSignAdd: id,
        });
      } else {
        this.props.setAddConditionValue({
          compEqualSign: id,
        });
      }
    }

    const setTimingCustom = (e) => {
      if (this.props.isAddCondition === true) {
        this.props.setAddConditionValue({
          timingCustomAdd: e.currentTarget.value,
        });
      } else {
        this.props.setAddConditionValue({
          timingCustom: e.currentTarget.value,
        });
      }
    }

    const setTargetDay = (e) => {
      if (this.props.isAddCondition === true) {
        this.props.setAddConditionValue({
          targetDayAdd: e.currentTarget.value,
        });
      } else {
        this.props.setAddConditionValue({
          targetDay: e.currentTarget.value,
        });
      }
    }

    return (
      <div>
        <FlexBox>
          <StyledPullDownLabel>
            対象の項目：
          </StyledPullDownLabel>
          <PullDownAlertRuleIndex
            id = { this.props.targetColId }
            items = { DISPITEM_ALERT }
            setTargetColId = { setTargetColId }
          />
        </FlexBox>
        <FlexBox
          className = 'm-t-8'
        >
          <StyledPullDownLabel>
            比較対象：
          </StyledPullDownLabel>
          <PullDownAlertRuleRange
            id = { this.props.timing }
            timingCustom = { this.props.timingCustom }
            targetDay = { this.props.targetDay }
            setTiming = { setTiming }
            setTimingCustom = { setTimingCustom }
            setTargetDay = { setTargetDay }
          />
        </FlexBox>
        {
          (() => {
            if (this.props.timing !== 0) {
              return (
                <FlexBox
                  className = 'm-t-8'
                >
                  <StyledPullDownLabel>
                    比較方法：
                  </StyledPullDownLabel>
                  <PullDownCommon
                    width = "calc(23px + 16em)"
                    listWidth = "calc(72px + 9em)"
                    id = { this.props.targetIsRelative }
                    items = {
                      [
                      { id: 0, label: '差（絶対比較）' },
                      { id: 2, label: '割合（相対比較）' },
                      ]
                    }
                    onChange = { setTargetIsRelative }
                  />
                </FlexBox>
              )
            }
          })()
        }
        <FlexBox
          className = 'm-t-8'
        >
          <StyledPullDownLabel>
            基準値：
          </StyledPullDownLabel>
          {
            (() => {
              const dispItem = _.find(DISPITEM_ALERT, o => {
                return (o.id === this.props.targetColId)
              });

              if (dispItem) {
                return (
                  <div>
                    <InputText
                      type = "number"
                      value = { this.props.targetNum }
                      max = { (dispItem.max) ? dispItem.max : '' }
                      min = { (dispItem.min) ? dispItem.min : '' }
                      step = { (dispItem.step) ? dispItem.step : '' }
                      className = "m-r-8"
                      onChange = { setTargetNum }
                    />
                    {
                      (this.props.targetIsRelative === 2) ? '%' : dispItem.unit
                    }
                  </div>
                )
              }
            })()
          }
        </FlexBox>
        <FlexBox
          className = 'm-t-8'
        >
          <StyledPullDownLabel>
            比較演算子：
          </StyledPullDownLabel>
          <PullDownAlertRuleCompare
            id = { this.props.compEqualSign }
            setCompEqualSign = { setCompEqualSign }
          />
        </FlexBox>
        {
          (() => {
            if (this.props.addConditionKind > 0) {
              return (
                <FlexBox
                  justify = 'flex-end'
                  className = 'm-t-8'
                >
                  <Button
                    thin
                    color = 'darkgray'
                    onClick = { this.props.removeRule }
                  >
                    <IconClose
                      className = 'm-r-8'
                    />
                    このルールを削除
                  </Button>
                </FlexBox>
              )
            }
          })()
        }
      </div>
    )
  }
}

export default EditAlertCondition;
