import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'

const Bar = (props) => (
  <div
    className = {props.className}
  >
    {props.children}
  </div>
);

const StyledBar = styled(Bar)`
  box-sizing: border-box;
  display: flex;
  justify-content: ${props => props.justify ? props.justify : 'flex-start'};
  align-items: center;
  background-color: ${COLOR.LIGHTGRAY};
`

export default StyledBar;
