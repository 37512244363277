import React from 'react';
import styled from 'styled-components';

import IconArrowDown from '../atoms/IconArrowDown';

const StyledPullDownS = styled.div`
  cursor: pointer;
  position: relative;
  padding-right: 14px;
`
const StyledIconArrowDown = styled(IconArrowDown)`
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -2px;
`

const PullDownS = (props) => (
  <StyledPullDownS
    onClick = {props.onClick}
    className = {props.className}
  >
    {props.children}
    <StyledIconArrowDown />
  </StyledPullDownS>
);

export default PullDownS;
