import React from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';
import { Calendar } from 'react-date-range';
import { format } from 'date-fns';

import Button from '../atoms/Button';
import ButtonSmall from '../atoms/ButtonSmall';
import IconArrowDown from '../atoms/IconArrowDown';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';

const DATE_TYPE = 'YYYY年MM月DD日';
const DATE_TYPE_DB = 'YYYY-MM-DD';

const StyledPullDownDatePicker = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1;
  ${
    props => {
      if (props.isOnHeader) {
        return `
          height: 40px;
        `
      }
    }
  }
`
const StyledLabel = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`
const StyledDateLabelWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  position: absolute;
  top: 50px;
  z-index: 10;
  ${
    props => {
      if (props.isOnHeader) {
        return `
          right: 0;
        `
      } else {
        return `
          left: 0;
        `
      }
    }
  }
`
const StyledDatePickerWrap = styled(PullDownListWrapIn)`
  position: relative;
`
const StyledButtonWrap = styled(PullDownListWrapIn)`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  padding: 8px;
`
const StyledButton = styled(Button)`
  width: 100px;
  justify-content: center;
  margin-left: 8px;
`

class PullDownCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date,
      isOpenedPullDown: false,
    };
  }
  handleClickOutside(e) {
    this.cancelDateRepeatChange(e);
  }
  applyDateRepeatChange = () => {
    this.props.applyDateRepeat(this.state.date);
    this.closePullDown();
  }
  cancelDateRepeatChange = () => {
    this.setState({
      date: this.props.date,
    });
    this.closePullDown();
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false
    });
  }

  render() {
    const switchDate = (date) => {
      this.setState({
        date: format(date, DATE_TYPE_DB)
      });
    }

    return (
      <StyledPullDownDatePicker
        className = { this.props.className }
      >
        <StyledLabel
          onClick = { this.togglePullDown }
        >
          <StyledDateLabelWrap>
            {
              (() => {
                return format(this.props.date, DATE_TYPE);
              })()
            }
          </StyledDateLabelWrap>
          <ButtonSmall className="m-l-8">
            <IconArrowDown />
          </ButtonSmall>
        </StyledLabel>
        <StyledPullDownListWrap
          isOnHeader = { this.props.isOnHeader }
          className = {
            this.state.isOpenedPullDown === true
              ? `is-opened`
              : `is-closed`
          }
        >
          <StyledDatePickerWrap>
            <Calendar
              date={ new Date(this.state.date) }
              onChange={ switchDate }
            />
          </StyledDatePickerWrap>
          <StyledButtonWrap>
            <StyledButton
              thin
              onClick = { this.cancelDateRepeatChange }
            >
              キャンセル
            </StyledButton>
            <StyledButton
              color = "orange"
              thin
              onClick = { this.applyDateRepeatChange }
            >
              適用
            </StyledButton>
          </StyledButtonWrap>
        </StyledPullDownListWrap>
      </StyledPullDownDatePicker>
    )
  }
}

export default onClickOutside(PullDownCalendar);
