import React from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';
import { DateRange } from 'react-date-range';

import { addDays, format, isEqual, addMonths, isFirstDayOfMonth, startOfMonth } from 'date-fns';
import Button from '../atoms/Button';
import ButtonSmall from '../atoms/ButtonSmall';
import IconArrowDown from '../atoms/IconArrowDown';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';

const DATE_TYPE = 'YYYY年MM月DD日';

const StyledPullDownDateRange = styled.div`
  position: relative;
`
const StyledPullDownDateRangeLabel = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  position: absolute;
  top: 24px;
  left: 0;
  z-index: 20;
`
const StyledButtonWrap = styled(PullDownListWrapIn)`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  padding: 8px;
`
const StyledButton = styled(Button)`
  width: 33.3%;
  justify-content: center;
  margin-left: 8px;
  &:first-child {
    margin-left: 0;
  }
`

class PullDownDateRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: {
        key: 'dateRange',
        startDate: (isFirstDayOfMonth(new Date()) ? addMonths(new Date(), -1) : startOfMonth(new Date())),
        endDate: (props.endDate) ? props.endDate : addDays(new Date(), -1),
      },
      focusedRange: [0, 0],
      isOpenedPullDown: false,
    };
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false
    });
  }
  render() {
    const switchDateRange = (ranges) => {
      this.setState(ranges);
    }

    // 変更内容を適用する
    const applyDateRangeChange = () => {
      this.props.applyDateRange({
        startDate: this.state.dateRange.startDate,
        endDate: this.state.dateRange.endDate,
      });
      this.setState({
        focusedRange: [0, 0],
      });
      this.closePullDown();
    }

    // 変更内容を初期化する
    const clearDateRange = () => {
      this.props.applyDateRange({
        dateRange: {
          ...this.state.dateRange,
          startDate: null,
          endDate: null,
        }
      });
      this.setState({
        dateRange: {
          ...this.state.dateRange,
          startDate: addDays(new Date(), -7),
          endDate: addDays(new Date(), -1),
        },
        focusedRange: [0, 0],
      });
      this.closePullDown();
    }

    // 変更内容をキャンセルする
    const cancelDateRangeChange = () => {
      if (this.props.startDate === null && this.props.endDate === null) {
        this.setState({
          dateRange: {
            ...this.state.dateRange,
            startDate: addDays(new Date(), -7),
            endDate: addDays(new Date(), -1),
          },
          focusedRange: [0, 0],
        });
      } else {
        this.setState({
          dateRange: {
            ...this.state.dateRage,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
          },
          focusedRange: [0, 0],
        });
      }
      this.closePullDown();
    }

    return (
      <StyledPullDownDateRange
        className = { this.props.className }
      >
        <StyledPullDownDateRangeLabel
          onClick = { this.togglePullDown }
        >
          {
            (() => {
              if (this.props.startDate === null && this.props.endDate === null) {
                return '期間設定なし'
              } else {
                const d1 = this.props.startDate;
                const d2 = this.props.endDate;
                // 期間の開始日と終了日が同じ場合は日付表記を1つにする
                if (isEqual(d1, d2)) {
                  return format(d1, DATE_TYPE);
                } else {
                  return `${format(d1, DATE_TYPE)} ～ ${format(d2, DATE_TYPE)}`;
                }
              }
            })()
          }
          <ButtonSmall
            className = "m-l-8"
            as = "div"
          >
            <IconArrowDown />
          </ButtonSmall>
        </StyledPullDownDateRangeLabel>
        <StyledPullDownListWrap
          className = {
            this.state.isOpenedPullDown === true
              ? `is-opened`
              : `is-closed`
          }
        >
          <PullDownListWrapIn>
            <DateRange
              ranges = {
                [
                  this.state.dateRange,
                ]
              }
              dateDisplayFormat = { DATE_TYPE }
              inputRanges = { [] }
              focusedRange = { this.state.focusedRangeCompare }
              months = { 1 }
              staticRanges = { [] }
              onChange = { switchDateRange }
              onRangeFocusChange = { ((e) => {
                this.setState({
                  focusedRange: e,
                });
              }) }
            />
          </PullDownListWrapIn>
          <StyledButtonWrap>
            <StyledButton
              thin
              onClick = { clearDateRange }
            >
              クリア
            </StyledButton>
            <StyledButton
              thin
              onClick = { cancelDateRangeChange }
            >
              キャンセル
            </StyledButton>
            <StyledButton
              color = "orange"
              thin
              onClick = { applyDateRangeChange }
            >
              適用
            </StyledButton>
          </StyledButtonWrap>
        </StyledPullDownListWrap>
      </StyledPullDownDateRange>
    )
  }
}
export default onClickOutside(PullDownDateRange);
