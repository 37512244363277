import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import classnames from 'classnames';

import FlexBox from '../atoms/FlexBox';
import IconExpand from '../atoms/IconExpand';
import IconPlus from '../atoms/IconPlus';
import IconClose from '../atoms/IconClose';
import HeadingS from '../atoms/HeadingS';
import getUnit from '../_util/getUnit';

import COLOR from '../_const/COLOR';
import MATCH_TYPE from '../_const/MATCH_TYPE';

const StyledMenuAndSelected = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 4px;
  padding-top: 10px;
  &.has-border {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
  }
`
const StyledMenuSection = styled.div`
  position: relative;
`
const StyledMenuHeading = styled(HeadingS)`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
`
const StyledMenuItems = styled.div`
  height: 0;
  overflow: hidden;
  &.is-active {
    height: auto;
  }
`
const StyledSubSection = styled(FlexBox)`
  margin-top: 8px;
  padding-left: 1em;
  padding-right: 1em;
  font-weight: bold;
`

const StyledSubClick = styled(FlexBox)`
  cursor: pointer;
`

const StyledIconPlus = styled(IconPlus)`
  margin-right: 8px;
  margin-bottom: 2px;
`

const StyledThereshold = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1em;
  color: ${ COLOR.GREEN };
  font-weight: bold;
`

const StyledIconClose = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  background-color: ${ COLOR.GREEN };
  border-radius: 10px;
  cursor: pointer;
`

class SelectDispItemsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked,
      isOpened: [false, false, false, false],
    };
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      // チェックした要素が更新されたとき
      prevProps.checked !== this.props.checked
    ) {
      this.setState({
        checked: this.props.checked
      });
    }
  }

  render() {
    // 表示項目カテゴリを開閉する
    const toggleMenu = (i) => {
      const isOpened = this.state.isOpened;
      isOpened[i] = !isOpened[i];
      this.setState({
        isOpened: isOpened
      })
    }

    const label = (name, item) => {
      let addItemName = '';
      if (name === 'カスタマイズ') {
        const id = item.id.replace('addItem', '');
        const addItem = _.find(this.props.addItems, ['addItem', Number(id)]);
        if (addItem) {
          addItemName = `（${addItem.name}）`;
        }
      }
      return `${item.label}${addItemName}`;
    }

    return (
      <StyledMenuAndSelected
        className = {
          classnames({
            'has-border': this.props.hasBorder === true,
          })
        }
      >
        {
          (() => {
            // 選択可能な表示項目一覧を生成
            if (this.props.selectableDispItems.length === 0) return (<div></div>)
            return this.props.selectableDispItems.map((category, index) => {
              return (
                <StyledMenuSection
                  key = { _.uniqueId() }
                  className = { index > 0 ? 'm-t-16' : '' }
                >
                  <StyledMenuHeading
                    noMargin
                    onClick = { (() => { toggleMenu(index) }) }
                  >
                    { category.categoryName }
                    <IconExpand
                      className = {(() => {
                        return (this.state.isOpened[index] === true) ? 'is-expand' : ''
                      })()}
                    />
                  </StyledMenuHeading>
                  <StyledMenuItems
                    flexWrap
                    className = {(() => {
                      return (this.state.isOpened[index] === true) ? 'is-active' : ''
                    })()}
                  >
                    {
                      (() => {
                        return category.dispItem.map((item, indexChild) => {
                          return (
                            <StyledSubSection
                              key = { _.uniqueId() }
                              id = { `progress_display_select_filter_${index}_${indexChild}` }
                            >
                              <StyledSubClick
                                onClick = { (e) => this.props.openThresholdModal(e, item) }
                              >
                                <StyledIconPlus />
                                { label(category.categoryName, item) }
                              </StyledSubClick>
                              {
                                (() => {
                                  if (item.matchType != null) {
                                    return (
                                      <StyledThereshold>
                                        { MATCH_TYPE[item.matchType].sign }{ item.number }{ getUnit(item.id) }
                                        <StyledIconClose
                                          onClick = { () => this.props.removeThreshold(item.id) }
                                        >
                                          <IconClose />
                                        </StyledIconClose>
                                      </StyledThereshold>
                                    )
                                  }
                                })()
                              }
                            </StyledSubSection>
                          )
                        });
                      })()
                    }
                  </StyledMenuItems>
                </StyledMenuSection>
              )
            })
          })()
        }
      </StyledMenuAndSelected>
    )
  }
}

export default SelectDispItemsFilter;
