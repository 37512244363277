import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import Bar from '../atoms/Bar';
import Button from '../atoms/Button';
import DividerDottedV from '../atoms/DividerDottedV';
import IconPlus from '../atoms/IconPlus';

const StyledBar = styled(Bar)`
  height: 0;
  overflow: hidden;
  padding-right: 24px;
  padding-left: 24px;
  transition-duration: .2s;
  transition-property: height;
  &.is-opened {
    height: 48px;
  }
`
const StyledButton = styled(Button)`
  margin-bottom: 1px;
  margin-left: 8px;
  text-align: left;
`
const StyledIconPlus = styled(IconPlus)`
  margin-left: 8px;
  fill: #fff;
`

class BarEditProgressData extends React.Component {
  render() {
    const queries = queryString.stringify({
      target: this.props.target,
      targetIds: this.props.checked
    }, {
      arrayFormat: 'comma'
    });

    return (
      <StyledBar
        className = { `${this.props.className} ${
          this.props.count > 0 ? 'is-opened' : ''
        }` }
      >
        { this.props.count }個を選択済み
        <DividerDottedV hasMargin />
        <div>
          選択した項目で
        </div>
        <StyledButton
          as = { Link }
          to = { `/label/edit/?${queries}` }
          color = 'orange'
          thin = 'true'
        >
          ラベル作成
          <StyledIconPlus />
        </StyledButton>
        <StyledButton
          as = { Link }
          to = { `/custom-report/edit/?${queries}` }
          color = 'orange'
          thin = 'true'
        >
          カスタムレポート作成
          <StyledIconPlus />
        </StyledButton>
        <StyledButton
          as = { Link }
          to = { `/setting/alert/edit/?${queries}` }
          color = 'orange'
          thin = 'true'
        >
          アラート作成
          <StyledIconPlus />
        </StyledButton>
        {
          (() => {
            if (this.props.target <= 2) { // クライアントタブ、アカウントタブの場合のみ表示
              return (
                <StyledButton
                  as = { Link }
                  to = { `/setting/budget/?${queries}` }
                  color = 'orange'
                  thin = 'true'
                >
                  予算設定
                </StyledButton>
              )
            } else {
              return '';
            }
          })()
        }
        {
          (() => {
            if (this.props.target === 1) { // クライアントタブの場合のみ表示
              return (
                <StyledButton
                  as = { Link }
                  to = { `/setting/new-item/?${queries}` }
                  color = 'orange'
                  thin = 'true'
                >
                  目標設定
                </StyledButton>
              )
            } else {
              return '';
            }
          })()
        }
        {
          (() => {
            if (this.props.target <= 4) { // クライアントタブ、アカウント、キャンペーン、広告グループタブの場合のみ表示
              let search = `?filter=${this.props.checked}`;
              if (this.props.division) search += `&division=${this.props.division}`;

              return (
                <StyledButton
                  as = { Link }
                  to = { {
                    pathname: `/progress/${this.props.target + 1}/`,
                    search: search
                  } }
                  color = 'orange'
                  thin = 'true'
                >
                  下の階層へ
                </StyledButton>
              )
            }
          })()
        }
        {
          (() => {
            // 運用メモ
            if (this.props.count > 1 && this.props.target === 1) return;
            return (
              <StyledButton
                color = 'orange'
                thin = 'true'
                onClick = { ((e) => {
                  this.props.openOpeMemoEditModal(e, queries)
                }) }
              >
                運用メモ
                <StyledIconPlus />
              </StyledButton>
            )
          })()
        }
      </StyledBar>
    )
  }
};

export default BarEditProgressData;
