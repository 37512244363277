import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
} from 'date-fns';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  startOfPast7day: startOfDay(addDays(new Date(), -7)),
  startOfPast14day: startOfDay(addDays(new Date(), -14)),
  startOfPast30day: startOfDay(addDays(new Date(), -30)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  firstDate: startOfDay(addDays(new Date(), -1).setDate(1)), // endOfYesterdayの月初の日をセット
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export default createStaticRanges([
  {
    label: '今日',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '昨日',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: '今週',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: '先週',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: '過去7日',
    range: () => ({
      startDate: defineds.startOfPast7day,
      endDate: defineds.endOfYesterday,
    }),
  },
  {
    label: '過去14日',
    range: () => ({
      startDate: defineds.startOfPast14day,
      endDate: defineds.endOfYesterday,
    }),
  },
  {
    label: '過去30日',
    range: () => ({
      startDate: defineds.startOfPast30day,
      endDate: defineds.endOfYesterday,
    }),
  },
  {
    label: '今月',
    range: () => ({
      startDate: defineds.firstDate,
      endDate: defineds.endOfYesterday,
    }),
  },
  {
    label: '先月',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
]);
