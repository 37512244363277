import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import Login from './components/views/login/Login';

class SignOut extends React.Component {
  componentWillUpdate = () => {
    const loc = this.props.location.pathname;
    const regex = RegExp('\\/login\\/?$');
    const isLocLogin = (loc === '/' || regex.test(loc));
    if(!isLocLogin) {
      this.props.setIsNoticeLogout();
    }
  }

  render() {
    if (this.props.isForceLogout) {
      return (
        <Switch>
          <Route
            exact
            path = '/login'
            render = { (props) => (
              <Login { ...props } { ...this.props } />
            )}
          />
          <Route
            path = '/*'
            render = { () => (
              <Redirect
                to = '/login'
              />
            ) }
          />
        </Switch>
      )
    } else {
      return (
        <Switch>
          <Route
            exact
            path = '/login'
            render = { (props) => (
              <Login { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/'
            render = { (props) => (
              <Redirect
                to = {{
                  pathname: '/login',
                }}
              />
            ) }
          />
          <Route
            path = '/*'
            render = { (props) => {
              return (
                <Redirect
                  to = {{
                    pathname: '/login',
                  }}
                />
              )
            } }
          />
        </Switch>
      )
    }
  }
}

export default withRouter(SignOut);
