import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR';

import Gauge from '../atoms/Gauge';
import MediaBadgeLink from '../atoms/MediaBadgeLink';

import arrangeReportDataValue from '../_util/arrangeReportDataValue';

const StyledTableCellInner = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.is-wrap {
    overflow: visible;
    white-space: pre-wrap;
    text-overflow: clip;
  }
  &.is-positive {
    color: ${ COLOR.BLUE }
  }
  &.is-negative {
    color: ${ COLOR.RED }
  }
  ${
    props => {
      switch (props.type) {
        case 0: // 数値
        case 1: // 少数
        case 2: // 通貨（￥）
        case 5: // 少数・割合（%）ゲージなし
        case 6: // 少数・割合（%）ゲージあり
        case 7: // 少数（小数点以下4位まで表示）
        case 9: // 文字列（数値だがハイフン許容）
        case 99: // 比較の差
          return `
            text-align: right;
          `;
        default:
      }
    }
  }
`

export default ({ ...attr }) => {
  if (attr.type === 3 || attr.type === 9) {
    return (
      <StyledTableCellInner
        type = { attr.type }
        className = { (attr.value < 0) ? 'is-negative is-wrap' : 'is-wrap' }
      >
        {
          (() => {
            if (attr.value !== undefined) {
              return arrangeReportDataValue(attr.value, attr.type);
            } else {
              return attr.children;
            }
          })()
        }
      </StyledTableCellInner>
    )
  } else if (attr.type === 6) {
    // 6: 少数・割合（%）ゲージあり
    return (
      <StyledTableCellInner
        type = { attr.type }
        className = { (attr.value < 0) ? 'is-negative' : '' }
      >
        <Gauge
          ratio = { attr.value }
        />
      </StyledTableCellInner>
    )
  } if (attr.type === 8) {
    // 8:媒体
    if (attr.value.id > 0) {
      return (
        <StyledTableCellInner
          type = { attr.type }
        >
          <MediaBadgeLink
            id = { attr.value.id }
            label = { attr.value.label }
          />
        </StyledTableCellInner>
      )
    } else {
      return '';
    }
  } if (attr.type === 99) {
    // 99: 比較の差
    return (
      <StyledTableCellInner
        type = { attr.type }
        className = {
          (attr.value < 0)
            ? 'is-negative'
            : (attr.value > 0)
              ? 'is-positive'
              : ''
        }
      >
        {
          (attr.value > 0)
            ? `+${attr.value.toLocaleString()}`
            : attr.value.toLocaleString()
        }
      </StyledTableCellInner>
    )
  } else {
    return (
      <StyledTableCellInner
        type = { attr.type }
        className = { (attr.value < 0) ? 'is-negative' : '' }
      >
        {
          (() => {
            if (attr.value !== undefined) {
              return arrangeReportDataValue(attr.value, attr.type);
            } else {
              return attr.children;
            }
          })()
        }
      </StyledTableCellInner>
    )
  }

};
