import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import FONTSIZE from '../../_const/FONTSIZE';
import USER_AUTH from '../../_const/USER_AUTH';

import Button from '../../atoms/Button';
import DividerDottedV from '../../atoms/DividerDottedV';
import FlexBox from '../../atoms/FlexBox';
import IconPlus from '../../atoms/IconPlus';
import TableDataCell from '../../atoms/TableDataCell';
import TableBodyRow from '../../atoms/TableBodyRow';
import TableHeader from '../../atoms/TableHeader';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import Search from '../../molecules/Search';
import Pagination from '../../organisms/Pagination';
import PullDownCommon from '../../organisms/PullDownCommon';

import alignSearchQuery from '../../_util/alignSearchQuery';

const TABLE_HEADER = [
  {
    id: 'userName',
    label: 'ユーザー名',
    width: '20%',
  },
  {
    id: 'mailAddress',
    label: 'メールアドレス',
    width: '20%',
  },
  {
    id: 'sectionName',
    label: '部署',
    width: '20%',
  },
  {
    id: 'groupName',
    label: 'グループ',
    width: '20%',
  },
  {
    id: 'auth',
    label: '権限',
    width: '20%',
  },
];

const StyledPullDownLabel = styled.div`
  margin-right: 8px;
  font-size: ${FONTSIZE.S}px;
  font-weight: 600;
`
const StyledTable = styled.div`
  width: 100%;
  max-height: ${ props => props.windowHeight - 120 - 106 - 44 - 40 }px;
  overflow: scroll;
  position: relative;
  z-index: 1;
`

class Authority extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOriginal: this.props.users,
      dataFiltered: [],
      data: [],
      sortId: '',
      sortOrder: 'desc',
      offset: 0,
      postPerPage: 50,
      searchQuery: '',
      selectedSectionName: '全て',
      selectedGroupId: 0,
      selectedAuth: 0,
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('権限管理');
    props.toggleDatePicker(false);
  }
  componentWillMount = async () => {
    this.arrangeViewItems();
  }
  arrangeViewItems = () => {
    // ソートやページャの条件に従って表示するレポートデータを別途作成する。
    const { offset, postPerPage } = this.state;

    // 元データを指定の条件でフィルタする
    const filteredData = this.state.dataOriginal.filter((item, index) => {
      const regexp = new RegExp(
        `^(?=.*${alignSearchQuery(this.state.searchQuery)}).*$`
      );

      return (
        (
          // フリーワード検索のクエリに合致するか
          this.state.searchQuery === ''
          || alignSearchQuery(item.userName).match(regexp) !== null
          || alignSearchQuery(item.mailAddress).match(regexp) !== null
        ) && (
          // 選択した部署に合致するか
          this.state.selectedSectionName === '全て'
          || this.state.selectedSectionName === item.sectionName
        ) && (
          // 選択したグループに合致するか
          this.state.selectedGroupId === 0
          || this.state.selectedGroupId === item.groupId
        ) && (
          // 選択した権限に合致するか
          this.state.selectedAuth === 0
          || this.state.selectedAuth === item.auth
        )
      )
    });

    // データのソートには_.orderBy()を使う。
    const sortedDataTable = _.orderBy(
      filteredData,
      o => {
        return o[this.state.sortId];
      },
      this.state.sortOrder
    );

    // ページャ条件による絞り込みにはArray.slice()を使う。
    const arrangedDataTable = sortedDataTable.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    this.setState({
      dataFiltered: filteredData,
      data: arrangedDataTable,
    });
  }
  render() {
    // ユーザー追加画面に移動
    const goToAddUser = (e) => {
      e.preventDefault();
      this.props.history.push('/setting/authority/user');
    }

    // フリーワード検索
    const setSearchQuery = async (e) => {
      e.preventDefault();
      await this.setState({
        offset: 0,
        searchQuery: e.target['searchQuery'].value,
      });
      this.arrangeViewItems();
    }

    // ドロップダウンで部署を選択してフィルタ
    const selectSection = async (id) => {
      await this.setState({
        selectedSectionName: id,
        selectedGroupId: 0,
      });
      this.arrangeViewItems();
    }

    // ドロップダウンでグループを選択してフィルタ
    const selectGroup = async (id) => {
      await this.setState({
        selectedGroupId: id,
      });
      this.arrangeViewItems();
    }

    // ドロップダウンで権限を選択してフィルタ
    const selectUserAuth = async (id) => {
      await this.setState({
        selectedAuth: id,
      });
      this.arrangeViewItems();
    }

    // 並び替え
    const sort = async (e) => {
      // セルの並び替え
      const id = e.currentTarget.dataset.id;
      const order = (id === this.state.sortId && this.state.sortOrder === 'desc') ? 'asc' : 'desc';

      await this.setState({
        sortId: id,
        sortOrder: order,
      })
      this.arrangeViewItems();
    }

    // ページャの表示件数を変更
    const switchPostPerPage = async (num) => {
      await this.setState({
        postPerPage: num
      })
      this.arrangeViewItems();
    }

    // ページャの戻るイベント
    const goToPrevPagination = async () => {
      if (this.state.offset <= 0) return;
      await this.setState({
        offset: this.state.offset - 1
      });
      this.arrangeViewItems();
    }

    // ページャの進むイベント
    const goToNextPagination = async () => {
      if (this.state.offset >= Math.ceil(this.state.dataFiltered.length / this.state.postPerPage) - 1) return;
      await this.setState({
        offset: this.state.offset + 1
      });
      this.arrangeViewItems();
    }

    return (
      <div>
        <FlexBox className="m-b-32" justify="space-between">
          <FlexBox>
            <Search
              name = "searchQuery"
              onSubmit = { setSearchQuery }
              value = { this.state.searchQuery }
            />
            <DividerDottedV hasMargin />
            <StyledPullDownLabel>
              部署：
            </StyledPullDownLabel>
            <PullDownCommon
              id = { this.state.selectedSectionName }
              items = {
                this.props.sections.map(o => {
                  return {
                    id: o.sectionName,
                    label: o.sectionName
                  };
                })
              }
              onChange = { selectSection }
              className = 'm-r-24'
            />
            <StyledPullDownLabel>
              グループ：
            </StyledPullDownLabel>
            <PullDownCommon
              id = { this.state.selectedGroupId }
              items = {
                _.filter(this.props.groups, o => {
                  return (
                    o.sectionName === this.state.selectedSectionName
                    || o.groupId === 0
                  )
                }).map(o => {
                  return {
                    id: o.groupId,
                    label: o.groupName
                  };
                })
              }
              onChange = { selectGroup }
              className = 'm-r-24'
            />
            <StyledPullDownLabel>
              権限：
            </StyledPullDownLabel>
            <PullDownCommon
              id = { this.state.selectedAuth }
              items = { USER_AUTH }
              onChange = { selectUserAuth }
            />
          </FlexBox>
          <FlexBox>
            <Button onClick={goToAddUser} className="m-l-8">
              ユーザー招待
              <IconPlus className="m-l-8" />
            </Button>
          </FlexBox>
        </FlexBox>

        <StyledTable
          windowHeight = { this.props.windowHeight }
        >
          <TableHeader>
            {
              (() => {
                return TABLE_HEADER.map(o => {
                  return (
                    <TableHeaderCell
                      key = { _.uniqueId() }
                      style = { { width: `${o.width}` } }
                      sortState = {
                        (o.id !== this.state.sortId)
                          ? 0
                          : (this.state.sortOrder === 'asc')
                            ? 1
                            : 2
                      }
                      data-id = { o.id }
                      onClick = { sort }
                    >
                      { o.label }
                    </TableHeaderCell>
                  )
                })
              })()
            }
          </TableHeader>
          <div>
            {
              (() => {
                return this.state.data.map(r => {
                  return (
                    <TableBodyRow
                      key = { _.uniqueId() }
                    >
                      {
                        (() => {
                          return TABLE_HEADER.map(o => {
                            const value = (o.id === 'category') ? r[o.id].label : r[o.id];
                            return (
                              <TableDataCell
                                key = { _.uniqueId() }
                                style = { { width: `${o.width}` } }
                              >
                                {
                                  (() => {
                                    if (o.id === 'auth') {
                                      return _.find(USER_AUTH, o => { return o.id === value; }).label;
                                    } else {
                                      return value;
                                    }
                                  })()
                                }
                              </TableDataCell>
                            )
                          })
                        })()
                      }
                    </TableBodyRow>
                  )
                })
              })()
            }
          </div>
        </StyledTable>

        <div className="m-t-24">
          <Pagination
            offset = { this.state.offset }
            postPerPage = { this.state.postPerPage }
            dataCount = { this.state.dataFiltered.length }
            switchPostPerPage = { switchPostPerPage }
            goToPrevPagination = { goToPrevPagination }
            goToNextPagination = { goToNextPagination }
          />
        </div>
      </div>
    )
  }
}

export default Authority;
