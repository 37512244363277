import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import { addDays, addMonths, isFirstDayOfMonth, startOfMonth } from 'date-fns';
import Cookies from 'js-cookie';

import Header from './components/templates/Header';
import Content from './components/templates/Content';
import SideMenu from './components/templates/SideMenu';
import ProgressingCover from './components/templates/ProgressingCover';

import User from './utils/user';

const cookieDatePicker = Cookies.getJSON('rakuda-daterange');

const backendApi = process.env.REACT_APP_BACKEND_URI;

const getSections = () => {
  return axios.get(
    backendApi + 'organization',
    {
      params: {
        ...User.apiParams(),
        func: 1,
      }
    }
  );
}
const getGroups = () => {
  return axios.get(
    backendApi + 'organization',
    {
      params: {
        ...User.apiParams(),
        func: 2,
      }
    }
  );
}
const getUsers = () => {
  return axios.get(
    backendApi + 'user',
    {
      params: {
        ...User.apiParams()
      }
    }
  );
}
const getClients = () => {
  return axios.get(
    backendApi + 'client',
    {
      params: {
        ...User.apiParams()
      }
    }
  );
}
const getAccounts = () => {
  return axios.get(
    backendApi + 'account',
    {
      params: {
        ...User.apiParams()
      }
    }
  );
}
const getDispItemList = () => {
  return axios.get(
    backendApi + 'dispItem',
    {
      params: {
        ...User.apiParams(),
        func: 3,
      }
    }
  );
}
const getUserInfo = () => {
  return axios.get(
    backendApi + 'sso',
    {
      params: {
        ...User.apiParams()
      }
    }
  );
}

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadedData: false,
      isOpenedSideMenu: true,
      isShownDatePicker: false,
      openGlobalCatId: -1,
      currentContentTitle: '',
      sections: [],
      groups: [],
      users: [],
      clients: [],
      accounts: [],
      selectableDispItems: [],
      selectableDispItemsForCustom: [],
      dateRange: {
        key: 'dateRange',
        startDate: (cookieDatePicker)
          ? new Date(cookieDatePicker.dateRange.startDate)
          : (isFirstDayOfMonth(new Date()) ? addMonths(new Date(), -1) : startOfMonth(new Date())),
        endDate: (cookieDatePicker)
          ?  new Date(cookieDatePicker.dateRange.endDate)
          : addDays(new Date(), -1),
      },
      dateRangeCompare: {
        key: 'dateRangeCompare',
        startDate: (cookieDatePicker)
          ?  new Date(cookieDatePicker.dateRangeCompare.startDate)
          : addDays(new Date(), -14),
        endDate: (cookieDatePicker)
          ?  new Date(cookieDatePicker.dateRangeCompare.endDate)
          : addDays(new Date(), -8),
      },
      isEnabledCompare: (cookieDatePicker) ? cookieDatePicker.isEnabledCompare : false,
      isProgressing: false,
      gaUserId: '',
      gaCompanyCode: '',
      gaCategory: '',
      gaDiffDatePre: '',
      gaDiffDate1st: '',
    };
  }
  componentWillMount = () => {
    // 必要な情報を取得する
    Promise.all([
      getSections(),
      getGroups(),
      getUsers(),
      getClients(),
      getAccounts(),
      getDispItemList(),
      getUserInfo(),
    ]).then((response) => {
      this.setState({
        sections: response[0].data,
        groups: response[1].data,
        users: response[2].data,
        user: _.find(response[2].data, o => {
          return o.userId === User.apiParams().userId;
        }),
        clients: response[3].data,
        accounts: response[4].data,
        selectableDispItems: response[5].data,
        isLoadedData: true,
        gaUserId: response[6].data.userId,
        gaCompanyCode: response[6].data.companyCode,
        gaCategory: response[6].data.category,
        gaDiffDatePre: response[6].data.diffDatePre,
        gaDiffDate1st: response[6].data.diffDate1st,
      });
    });
  }

  render() {
    const switchGlobalCatId = (i) => {
      this.setState({
        openGlobalCatId: i
      });
    }
    const toggleSideMenu = () => {
      this.setState({
        isOpenedSideMenu: !this.state.isOpenedSideMenu
      });
    }
    const switchContentTitle = (str) => {
      this.setState({
        currentContentTitle: str
      });
    }
    const toggleDatePicker = (bool) => {
      this.setState({
        isShownDatePicker: bool
      });
    }
    const applyDateRange = (ranges) => {
      this.setState(ranges);
      Cookies.set(
        'rakuda-daterange',
        ranges
      );
    }
    const offDateCompare = () => {
      this.setState({
        isEnabledCompare: false
      });
      Cookies.set(
        'rakuda-daterange',
        Object.assign(
          Cookies.getJSON('rakuda-daterange'),
          {
            isEnabledCompare: false,
          }
        )
      );
    }
    const startProgressing = () => {
      this.setState({
        isProgressing: true
      });
    }
    const endProgressing = () => {
      setTimeout(() => {
        this.setState({
          isProgressing: false
        });
      }, 300)
    }

    return (
      <React.Fragment>
        <Header
          { ...this.props }
          { ...this.state }
          applyDateRange = { applyDateRange }
        />
        <Content
          { ...this.props }
          { ...this.state }
          switchGlobalCatId = { switchGlobalCatId }
          toggleDatePicker = { toggleDatePicker }
          switchContentTitle = { switchContentTitle }
          applyDateRange = { applyDateRange }
          offDateCompare = { offDateCompare }
          startProgressing = { startProgressing }
          endProgressing = { endProgressing }
        />
        <SideMenu
          { ...this.props }
          { ...this.state }
          toggleSideMenu = { toggleSideMenu }
        />
        <ProgressingCover
          isProgressing = { this.state.isProgressing }
        />
        {/* <script
        dangerouslySetInnerHTML={{ __html: `
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event':'signin',
            'userId': '${this.state.gaUserId}',
            'userCompanyId': '${this.state.gaCompanyCode}',
            'userCategory': '${this.state.gaCategory}',
            'diffDatePre': '${this.state.gaDiffDatePre}',
            'diffDate1st': '${this.state.gaDiffDate1st}'
          });
        ` }}
        ></script> */}
        <input type="hidden" id="ga_event" value='signin'></input>
        <input type="hidden" id="ga_userId" value={this.state.gaUserId}></input>
        <input type="hidden" id="ga_userCompanyId" value={this.state.gaCompanyCode}></input>
        <input type="hidden" id="ga_userCategory" value={this.state.gaCategory}></input>
        <input type="hidden" id="ga_diffDatePre" value={this.state.gaDiffDatePre}></input>
        <input type="hidden" id="ga_diffDate1st" value={this.state.gaDiffDate1st}></input>
      </React.Fragment>
      
    )
  }
}

export default SignIn;
