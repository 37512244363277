import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Url from 'url-parse';

import Button from '../atoms/Button';
import Radio from '../atoms/Radio';
import IconExport from '../atoms/IconExport';
import Modal from '../molecules/Modal';
import InputText from '../atoms/InputText';
import HeadingS from '../atoms/HeadingS';
import PullDownCommon from '../organisms/PullDownCommon';

const StyledModalBody = styled.div`
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
`

const StyledRadio = styled(Radio)`
  margin-top: 8px;
`

class ModalFileExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: true,
      selected: [],
      text: '',
      clientName: '',
      param: {
        dl: 1,
      },
    };
  }
  render() {
    // ダウンロードに使用するパラメータをクリア
    const clearParam = () => {
      this.setState({
        param: {
          dl: 1,
        },
      });
    }

    // ダウンロード
    const download = async (e, params) => {
      if (this.props.isTarget) {
        if(!(params.target && params.clientId)) return;
      } else {
        if (!params.clientId) return;
      }
      e.preventDefault();
      const url = new Url(this.props.apiUrl, true);
      url.set('query', params);
      await axios.get(url)
      .then((response) => {
        var element = document.createElement('a');
        element.href = url.toString();
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.props.closeExportModal();
        clearParam();
      })
      .catch((error) => {
        if(error.response) {
          this.props.changeErrorText(error.response.data.errorMessage);
          this.props.openErrorModal();
        }
      })
    }

    const RadioHasProps = ({ name, id, keyValue, value, children }) => (
      <StyledRadio
        name = { name }
        id = { id }
        value = { value }
        checked = { this.state.param[keyValue] === value ? 'checked' : '' }
        onChange = {
          ((e) => {
            const param = this.state.param;
            param[keyValue] = value;
            this.setState({
              param: param
            });
          })
        }
      >
        { children }
      </StyledRadio>
    );

    return (
      <Modal
        heading = { this.props.heading }
        isOpened = { this.props.isOpened }
        close = { this.props.closeExportModal }
      >
        <StyledModalBody>
          {
            (() => {
              return this.props.modalContents.map((item, index) => {
                switch (item.type) {
                  case 'radio':
                    return (
                      <div
                        key = { 'radio_' + index }
                        className = 'm-b-32'
                      >
                        <HeadingS noMargin>{ item.heading }</HeadingS>
                        {
                          (() => {
                            return (item.list.map((list, li) => {
                              return (
                                <RadioHasProps
                                  name = { `radio_${index}` }
                                  id = { `radio_${index}_${li}` }
                                  value = { list.value }
                                  keyValue = 'target'
                                  key = { `radio_${index}_${li}` }
                                >
                                  { list.label }
                                </RadioHasProps>
                              )
                            }));
                          })()
                        }
                      </div>
                    );
                  case 'input':
                    return (
                      <React.Fragment
                      key = { 'input_' + index }
                      >
                      <HeadingS
                      className = 'm-b-8'
                      >{ item.heading }</HeadingS>
                      <InputText
                      type = 'number'
                      value = { this.state.param.clientId }
                      min = { 0 }
                      step = { 1 }
                      className = "m-b-32"
                      onChange = {
                        ((e) => {
                          this.setState({
                            param: {
                              ...this.state.param,
                              clientId: e.currentTarget.value
                            }
                          });
                        })
                      }
                      />
                      </React.Fragment>
                    )
                  case 'pulldown':
                    return (
                      <React.Fragment
                        key = { 'pulldown_' + index }
                      >
                        <HeadingS
                          className = 'm-b-8'
                        >{ item.heading }</HeadingS>
                        <PullDownCommon
                          id = { this.state.param.clientId }
                          listWidth = { this.props.pullDownWidth }
                          className = "m-b-32"
                          items = { this.props.items }
                          onChange = {
                            ((id) => {
                              this.setState({
                                param: {
                                  ...this.state.param,
                                  clientId: id
                                }
                              });
                            })
                          }
                        />
                      </React.Fragment>
                    )
                  default:
                    return (
                      <div
                        key = { 'default_' + index }
                      >{ item.heading }
                      </div>
                    )
                }

              });
            })()
          }
          <Button
            width = "100%"
            justify = "center"
            disabled = { this.props.isTarget ? !(this.state.param.target && this.state.param.clientId) : !this.state.param.clientId }
            onClick = {
              ((e) => {
                download(e, Object.assign(this.props.apiParams, this.state.param))
              })
            }
          >
            <IconExport
              className = "m-r-8"
            />エクスポート
          </Button>
        </StyledModalBody>
      </Modal>
    )
  }
};

ModalFileExport.defaultProps = {
  isTarget: true,
  pullDownWidth: '200px'
};

export default ModalFileExport;
