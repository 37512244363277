import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import _ from 'lodash';
import Url from 'url-parse';
import { Link } from 'react-router-dom';

import COLOR from '../../_const/COLOR';

import ButtonEdit from '../../atoms/ButtonEdit';
import ButtonDuplicate from '../../atoms/ButtonDuplicate';
import Checkbox from '../../atoms/Checkbox';
import FlexBox from '../../atoms/FlexBox';
import IconDownload from '../../atoms/IconDownload';
import TableDataCell from '../../atoms/TableDataCell';
import TableBodyRow from '../../atoms/TableBodyRow';
import TableHeader from '../../atoms/TableHeader';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import BarEditData from '../../molecules/BarEditData';
import Search from '../../molecules/Search';
import Pagination from '../../organisms/Pagination';
import ModalErrorMessage from '../../organisms/ModalErrorMessage';
import ModalModifyFav from '../../organisms/ModalModifyFav';
import ModalConfirmDelete from '../../organisms/ModalConfirmDelete';

import alignSearchQuery from '../../_util/alignSearchQuery';
import arrangeReportDataValue from '../../_util/arrangeReportDataValue';

import User from '../../../utils/user';

const TABLE_HEADER = [
  {
    id: 'name',
    label: '名称',
    width: 'calc((100% - 120px) / 4)',
    hasSort: true,
  },
  {
    id: 'lastModified',
    label: '最終更新日時',
    width: 'calc((100% - 120px) / 4)',
    hasSort: true,
  },
  {
    id: 'userName',
    label: '作成ユーザー',
    width: 'calc((100% - 120px) / 4)',
    hasSort: true,
  },
  {
    id: 'shareSetting',
    label: '公開範囲',
    width: 'calc((100% - 120px) / 4)',
    hasSort: false,
  },
  {
    id: 'edit',
    label: '',
    width: '80px',
    hasSort: false,
  },
];

const StyledTable = styled.div`
  width: 100%;
  max-height: ${ props => props.windowHeight - 120 - 106 - 44 - 40 }px;
  overflow: scroll;
  position: relative;
  z-index: 1;
`
const StyledEditLink = styled(Link)`
  color: ${COLOR.ORANGE};
  text-decoration: none;
`

const backendApi = process.env.REACT_APP_BACKEND_URI;

class Favorite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOriginal: [],
      dataFiltered: [],
      isOpenedFavModal: false,
      isOpenedConfirmDeleteModal: false,
      isOpenedErrorModal: false,
      errorText: 'エラーが発生しました',
      data: [],
      offset: 0,
      postPerPage: 50,
      searchQuery: '',
      checked: [],
      alertData: [],
      modifyParams: {
        id: 0,
        name: '',
        url: '',
        shareSetting: 0,
        userIds: [],
      },
    };
    props.switchGlobalCatId(7);
    props.switchContentTitle('お気に入り');
    props.toggleDatePicker(false);
  }
  componentWillMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    await this.getData();
    this.arrangeViewItems();

    //　プログレス非表示
    this.props.endProgressing();
  }

  // APIをリクエストしてデータを取得
  getData = () => {
    return axios.get(backendApi + 'favorite', {
      params: {
        ...User.apiParams(),
      }
    })
    .then((response) => {
      this.setState({
        dataOriginal: response.data,
        dataFiltered: response.data,
        data: response.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        this.setState({
          isOpenedErrorModal: true,
          errorText: error.response.data.errorMessage
        })
      } else {
        this.setState({
          isOpenedErrorModal: true,
        })
      }
    });
  }

  arrangeViewItems = () => {
    // ソートやページャの条件に従って表示するレポートデータを別途作成する。
    const { offset, postPerPage } = this.state;

    // 元データを指定の条件でフィルタする
    const filteredData = this.state.dataOriginal.filter((item, index) => {
      const regexp = new RegExp(
        `^(?=.*${alignSearchQuery(this.state.searchQuery)}).*$`
      );

      return (
        (
          // フリーワード検索のクエリに合致するか
          this.state.searchQuery === ''
          || alignSearchQuery(item.name).match(regexp) !== null
        )
      )
    });

    // データのソートには_.orderBy()を使う。
    const sortedDataTable = _.orderBy(
      filteredData,
      o => {
        return o[this.state.sortId];
      },
      this.state.sortOrder
    );

    // ページャ条件による絞り込みにはArray.slice()を使う。
    const arrangedDataTable = sortedDataTable.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    this.setState({
      dataFiltered: filteredData,
      data: arrangedDataTable,
    });
  }

  render() {
    // フリーワード検索
    const setSearchQuery = async (e) => {
      e.preventDefault();
      await this.setState({
        offset: 0,
        searchQuery: e.target['searchQuery'].value,
        checked: [],
      });
      this.arrangeViewItems();
    }

    // 並び替え
    const sort = async (e) => {
      // セルの並び替え
      const id = e.currentTarget.dataset.id;
      const order = (id === this.state.sortId && this.state.sortOrder === 'desc') ? 'asc' : 'desc';

      await this.setState({
        sortId: id,
        sortOrder: order,
      })
      this.arrangeViewItems();
    }

    // 各行のチェック
    const checkItem = (e) => {
      const id = parseInt(e.currentTarget.value);
      const checked = this.state.checked.concat();

      if (checked.indexOf(id) > -1) {
        checked.splice(checked.indexOf(id), 1)
      } else {
        checked.push(id);
      }

      this.setState({
        checked: checked
      });
    }

    // コピー
    const duplicateFav = async (item) => {
      // プログレス表示
      this.props.startProgressing();

      await axios.get(backendApi + 'favoriteModify', {
        params: {
          ...User.apiParams(),
          func: 3,
          id: item.id,
          myAlert: this.state.myAlert,
        }
      })
      .then((response) => {
        this.setState({
          dataOriginal: response.data,
          dataFiltered: response.data,
          data: response.data,
        });
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            isOpenedErrorModal: true,
            errorText: error.response.data.errorMessage
          })
        } else {
          this.setState({
            isOpenedErrorModal: true,
          })
        }
      });

      this.arrangeViewItems();

      //　プログレス非表示
      this.props.endProgressing();
    }
    // 削除確認モーダルを開く
    const openConfirmDeleteModal = () => {
      this.setState({
        isOpenedConfirmDeleteModal: true,
      });
    }

    // 削除確認モーダルを閉じる
    const closeConfirmDeleteModal = () => {
      this.setState({
        isOpenedConfirmDeleteModal: false,
      });
    }

    // 削除
    const deleteFavoriteItem = async () => {
      // プログレス表示
      this.props.startProgressing();
      // 削除API実行
      await axios.get(backendApi + 'favoriteModify', {
        params: {
          ...User.apiParams(),
          func: 2,
          ids: this.state.checked.join(','),
        }
      })
      .then((response) => {
        this.setState({
          dataOriginal: response.data,
          dataFiltered: response.data,
          data: response.data,
          checked: [],
        });
        // 確認モーダルを閉じる
        closeConfirmDeleteModal();
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            isOpenedErrorModal: true,
            errorText: error.response.data.errorMessage
          })
        } else {
          this.setState({
            isOpenedErrorModal: true,
          })
        }
      });

      this.arrangeViewItems();

      //　プログレス非表示
      this.props.endProgressing();
    }

    // ページャの表示件数を変更
    const switchPostPerPage = async (num) => {
      await this.setState({
        postPerPage: num
      })
      this.arrangeViewItems();
    }

    // ページャの戻るイベント
    const goToPrevPagination = async () => {
      if (this.state.offset <= 0) return;
      await this.setState({
        offset: this.state.offset - 1
      });
      this.arrangeViewItems();
    }

    // ページャの進むイベント
    const goToNextPagination = async () => {
      if (this.state.offset >= Math.ceil(this.state.dataFiltered.length / this.state.postPerPage) - 1) return;
      await this.setState({
        offset: this.state.offset + 1
      });
      this.arrangeViewItems();
    }

    // エラーモーダルを閉じる
    const closeErrorModal = () => {
      this.setState({
        isOpenedErrorModal: false
      });
      window.setTimeout(() => {
        this.setState({
          errorText: 'エラーが発生しました',
        });
      }, 500);
    }

    // お気に入り情報編集
    const setFavoriteParam = (param) => {
      return axios.get(backendApi + 'favoriteModify', {
        params: {
          ...User.apiParams(),
          id: param.id,
          name: param.name,
          url: param.url,
          shareSetting: param.shareSetting,
          func: 1,
          userIds: param.checkedUsers.join(','),
        }
      })
      .then((response) => {
        this.setState({
          dataOriginal: response.data,
          dataFiltered: response.data,
          data: response.data,
          checked: [],
        });
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            isOpenedErrorModal: true,
            errorText: error.response.data.errorMessage
          })
        } else {
          this.setState({
            isOpenedErrorModal: true,
          })
        }
      });
    }

    // お気に入りモーダルを開く
    const openFavModal = (e, data) => {
      this.setState({
        modifyParams: {
          id: data.id,
          name: data.name,
          url: data.url,
          shareSetting: data.shareSetting,
          userIds: data.userIds.split(','),
        }
      });
      this.setState({
        isOpenedFavModal: true
      });
    }

    // お気に入りモーダルを閉じる
    const closeFavModal = (e) => {
      this.setState({
        isOpenedFavModal: false
      });
    }

    // IDを名前に変換
    const changeIdToUserName = (ids) => {
      const names = [];
      ids.forEach((name) => {
        const user = _.filter(this.props.users, (o) => {
          return o.userId === name;
        })
        if (user.length > 0) {
          names.push(user[0].userName);
        }
      });
      return names.join(', ');
    }

    // 表示項目設定レポートデータをダウンロード
    const download = async (e) => {
      e.preventDefault();

      const url = new Url(backendApi + 'favorite', true);
      url.set('query', Object.assign(
        User.apiParams(),
        this.state.requestParam,
        {
          dl: 1
        }
      ));
      var element = document.createElement('a');
      element.href = url.toString();
      element.setAttribute('download', 'favorite.csv');
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    return (
      <div>
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isOpenedErrorModal }
          close = { closeErrorModal }
          bodyText = { this.state.errorText }
        ></ModalErrorMessage>
        <ModalModifyFav
          heading = 'お気に入り情報修正'
          bodyText = '登録されているお気に入り情報を修正します'
          users = { this.props.users }
          isOpened = { this.state.isOpenedFavModal }
          setFavorite = { setFavoriteParam }
          closeFavModal = { closeFavModal }
          modifyParams = { this.state.modifyParams }
          data = { this.state.dataOriginal }
        />
        <ModalConfirmDelete
          heading = 'お気に入り削除'
          bodyText = '本当に削除しますか？'
          isOpened = { this.state.isOpenedConfirmDeleteModal }
          close = { closeConfirmDeleteModal }
          delete = { deleteFavoriteItem }
        />
        <FlexBox className="m-b-32" justify="space-between">
          <Search
            name = "searchQuery"
            onSubmit = { setSearchQuery }
            value = { this.state.searchQuery }
          />
          <FlexBox
            style = {{ 'cursor': 'pointer' }}
            onClick = { download }
          >
            <IconDownload
              className = 'm-r-8'
            />
            CSVダウンロード
          </FlexBox>
        </FlexBox>
        <BarEditData
          count = { this.state.checked.length }
          onClickDelete = { openConfirmDeleteModal }
        />

        <StyledTable
          windowHeight = { this.props.windowHeight }
        >
          <TableHeader>
            <TableHeaderCell
              className = { 'has-checkbox' }
            />
            {
              (() => {
                return TABLE_HEADER.map(o => {
                  return (
                    <TableHeaderCell
                      key = { _.uniqueId() }
                      style = { { width: `${o.width}` } }
                      sortState = {
                        (o.hasSort === false)
                          ? undefined
                          : (o.id !== this.state.sortId)
                            ? 0
                            : (this.state.sortOrder === 'asc')
                              ? 1
                              : 2
                      }
                      data-id = { o.id }
                      onClick = { sort }
                    >
                      { o.label }
                    </TableHeaderCell>
                  )
                })
              })()
            }
          </TableHeader>
          <div>
            {
              (() => {
                return this.state.data.map(r => {
                  return (
                    <TableBodyRow
                      key = { _.uniqueId() }
                    >
                      <TableDataCell
                        className = { 'has-checkbox' }
                      >
                        <Checkbox
                          value = { r.id }
                          onClick = { checkItem }
                          isChecked = { this.state.checked.indexOf(r.id) > -1 }
                        />
                      </TableDataCell>
                      {
                        (() => {
                          return TABLE_HEADER.map(o => {
                            return (
                              <TableDataCell
                                key = { _.uniqueId() }
                                style = { { width: `${o.width}` } }
                              >
                                {
                                  (() => {
                                    switch (o.id) {
                                      case 'name':
                                        return (
                                          <StyledEditLink
                                            to = { r.url }
                                          >
                                            { r[o.id] }
                                          </StyledEditLink>
                                        )
                                      case 'budget':
                                        return arrangeReportDataValue(r[o.id], 2);
                                      case 'shareSetting':
                                        const ShareType = [
                                          '自身',
                                          'グループ',
                                          '部署',
                                          '全社',
                                          'ユーザー指定'
                                        ];
                                        if (r.shareSetting < 4) {
                                          return ShareType.filter((x, index) => {
                                            return r.shareSetting === index
                                          })
                                        } else {
                                          const ids = r.userIds.split(',');
                                          return changeIdToUserName(ids);
                                        }
                                      case 'edit':
                                        return (
                                          <FlexBox
                                            width = '100%'
                                            justify = 'center'
                                          >
                                            <ButtonEdit
                                              onClick = { (e) => openFavModal(e, r) }
                                            />
                                            <ButtonDuplicate
                                              className = 'm-l-8'
                                              onClick = {
                                                (() => {
                                                  duplicateFav(r);
                                                })
                                              }
                                            />
                                          </FlexBox>
                                        )
                                      default:
                                        return r[o.id];
                                    }
                                  })()
                                }
                              </TableDataCell>
                            )
                          })
                        })()
                      }
                    </TableBodyRow>
                  )
                })
              })()
            }
          </div>
        </StyledTable>

        <div className="m-t-24">
          <Pagination
            offset = { this.state.offset }
            postPerPage = { this.state.postPerPage }
            dataCount = { this.state.dataFiltered.length }
            switchPostPerPage = { switchPostPerPage }
            goToPrevPagination = { goToPrevPagination }
            goToNextPagination = { goToNextPagination }
          />
        </div>
      </div>
    )
  }
}

export default Favorite;
