import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'

const BarBottom = (props) => (
  <div
    className = {props.className}
  >
    {props.children}
  </div>
);

const StyledBarBottom = styled(BarBottom)`
  box-sizing: border-box;
  display: flex;
  justify-content: ${props => props.justify ? props.justify : 'flex-end'};
  margin-top: 32px;
  margin-bottom: -40px;
  margin-right: -40px;
  margin-left: -40px;
  padding: 15px 40px 16px;
  border-top: 1px solid #e6e6e6;
  background-color: ${COLOR.LIGHTGRAY};
`

export default StyledBarBottom;
