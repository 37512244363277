import styled from 'styled-components';

const FlexBox = styled.div`
  width: ${props => props.width ? props.width: 'auto'};
  display: flex;
  align-items: ${props => props.align ? props.align: 'center'};
  justify-content: ${props => props.justify ? props.justify : 'flex-start'};
  flex-wrap: ${props => props.flexWrap ? 'wrap': 'nowrap'};
`;

export default FlexBox;
