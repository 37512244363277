// 媒体のバックエンド色を定義
const mediaColor = (id) => {
  switch (id) {
    case  1: return '#1E88E5'; // Google Ads
    case  2: return '#C62828'; // Yahoo!SS
    case  3: return '#C62828'; // YDN
    case  4: return '#444444'; // Xリスティング
    case  5: return '#C8E6C9'; // Display&Video360
    case  6: return '#FFCDD2'; // D2C
    case  7: return '#FB8C00'; // Criteo
    case  8: return '#444444'; // YCD
    case  9: return '#01579B'; // Facebook
    case 10: return '#00B0FF'; // Twitter
    case 11: return '#444444'; // Indeed
    case 12: return '#558B2F'; // LINE
    default: return '#444444';
  }
}

export default mediaColor;
