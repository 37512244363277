import React from 'react';
import styled from 'styled-components';

import COLOR from '../_const/COLOR'

const StyledGauge = styled.div`
  display: flex;
  align-items: center;
`
const StyledGaugeBar = styled.div`
  width: 77px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  margin-right: 8px;
  border-radius: 2px;
  background-color: #fff;
  &:before, &:after {
    height: 100%;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:before {
    width: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid ${COLOR.GRAY};
  }
  &:after {
    width: ${ props => Math.max(0, Math.min(100, props.ratio)) }%;
    z-index: 2;
    ${
      props => {
        if (props.ratio < 100) {
          return `
            background-color: ${COLOR.GREEN}
          `
        } else {
          return `
            background-color: ${COLOR.RED}
          `
        }
      }
    }
  }
`
const StyledGaugeBarScale = styled.div`
  width: 1px;
  height: ${ props => (props.position === 2) ? 6 : 4 }px;
  position: absolute;
  bottom: 0;
  left: ${ props => props.position / 4 * 100 }%;
  z-index: 3;
  background-color: ${COLOR.GRAY};
`
const StyledGaugeLable = styled.div`
  width: 54px;
  text-align: right;
`

const Gauge = ({ ratio, ...attr }) => (
  <StyledGauge { ...attr }>
    <StyledGaugeBar
      ratio = { ratio }
    >
      <StyledGaugeBarScale position = { 1 } />
      <StyledGaugeBarScale position = { 2 } />
      <StyledGaugeBarScale position = { 3 } />
    </StyledGaugeBar>
    <StyledGaugeLable>
      {
        (() => {
          return ratio.toFixed(2)
        })()
      }%
    </StyledGaugeLable>
  </StyledGauge>
);

export default Gauge;
