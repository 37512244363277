import React from 'react';
import styled from 'styled-components';

const IconMemo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0, 0, 21, 19" className={props.className}>
    <path fillRule="evenodd" fill="#FFF" d="M12.426 12.813C9.4 15.9 3.57 16.643 7 14.073c.582-.437 1.011-.877 1.323-1.312C5.25 12.136 3 10.253 3 8c0-2.761 3.358-5 7.5-5C14.642 3 18 5.239 18 8c0 2.315-2.371 4.243-5.574 4.813z"/>
  </svg>
)
const StyledIconMemo = styled(IconMemo) `
  display: block;
  stroke: #808080;
  stroke-width: 1px;
`

export default StyledIconMemo;
